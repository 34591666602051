import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'

import Step from './components/Step'
import StepContent from './components/StepContent'

const styles = theme => ({
  root: {

  },
  header: {
    position: 'relative',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
})

/**
 * See https://material.io/guidelines/components/steppers.html
 */
const Stepper = ({ current, steps, classes, onClick }) => (
  <div
    className={classes.root}
  >
    <div
      className={classes.header}
    >
      {steps.map((step, index) => (
        <Step
          key={step.key}
          index={index}
          step={step}
          isCurrent={current === step.key}
          isLast={index === steps.length - 1}
          test={index}
          test2={steps.length - 1}
          onClick={() => onClick && onClick(step.key)}
        />
      ))}
    </div>
    <div
      className={classes.contentArea}
    >
      {steps.map((step, index) => (
        <StepContent
          key={step.key}
          show={current === step.key}
        >
          {step.content}
        </StepContent>
      ))}
    </div>
  </div>
)

Stepper.defaultProps = {
  onClick: null,
}

Stepper.propTypes = {
  onClick: PropTypes.func,

  /**
   * key of the current step
   */
  current: PropTypes.any.isRequired,

  /**
  * Define the different steps
  *
  * ```
  * [
  *   {
  *     key: Step.USER,
  *     title: 'Title',
  *     icon: // element, optionnal,
  *     children: // react component
  *     done: // boolean, display the step has done
  *   },
  * ]
  * ```
  */
  steps: PropTypes.array.isRequired,
}

export default withStyles(styles)(Stepper)
