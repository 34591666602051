import Immutable from 'immutable'

import {
  FetchUserAddressListAction,
  PostUserAddressAction,
  EditUserAddressAction,
  DeleteUserAddressAction,
} from './actions'

import {
  updateArrayObject,
} from 'pmt-utils/array'

export * from './actions'
export * from './selectors'
export * from './components'
export * from './middlewares'
export * from './constants'
export * from './utils'

// Example of user address data
// {
//   list: {
//     data: null,
//     isFetching: false,
//     error: null,
//     lastUpdated: null,
//   },
//   post: {
//     data: null,
//     isFetching: false,
//     error: null,
//     lastUpdated: null,
//   }
// }

const DEFAULT = Immutable.fromJS({
  list: {
    data: null,
    isFetching: false,
    error: null,
    lastUpdated: null,
  },
  post: {
    data: null,
    isFetching: false,
    error: null,
    lastUpdated: null,
  },
  edit: {
    isFetching: null,
    error: null,
  }
})

export const userAddressReducer = (state = DEFAULT, action) => {

  switch (action.type) {

    case FetchUserAddressListAction.REQUEST:
      return state.mergeIn(['list'], {
        data: null,
        isFetching: true,
        error: null,
      })

    case FetchUserAddressListAction.SUCCESS:
      return state.mergeIn(['list'], {
        data: action.response,
        isFetching: false,
        error: null,
        lastUpdated: new Date(),
      })

    case FetchUserAddressListAction.FAILURE:
      return state.mergeIn(['list'], {
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: new Date(),
      })

    case PostUserAddressAction.REQUEST:
      return state.mergeIn(['post'], {
        data: null,
        isFetching: true,
        error: null,
      })

    case PostUserAddressAction.SUCCESS:
      state = state.mergeIn(['post'], {
        data: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

      const stateDataWhenAdd = state.toJS()
      stateDataWhenAdd.list.data.push(action.response)

      return state.mergeIn(['list'], {
        data: stateDataWhenAdd.list.data,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case PostUserAddressAction.FAILURE:
      return state.mergeIn(['post'], {
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case EditUserAddressAction.REQUEST:
      return state.mergeIn(['edit'], {
        isFetching: true,
        error: null,
      })

    case EditUserAddressAction.SUCCESS:
      state = state.mergeIn(['edit'], {
        isFetching: false,
        error: null,
      })

      const stateDataWhenEdit = state.toJS()
      const userAddressListEdited = updateArrayObject(stateDataWhenEdit.list.data, action.response, action.response.id)

      return state.mergeIn(['list'], {
        data: userAddressListEdited,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case EditUserAddressAction.FAILURE:
      return state.mergeIn(['edit'], {
        isFetching: false,
        error: action.error,
      })

    case DeleteUserAddressAction.REQUEST:
      return state.mergeIn(['delete'], {
        isFetching: true,
        error: null,
      })

    case DeleteUserAddressAction.SUCCESS:
      state = state.mergeIn(['delete'], {
        isFetching: false,
        error: null,
      })

      const stateDataWhenDelete = state.toJS()
      const userAddressListCleaned = stateDataWhenDelete.list.data.filter(address => address.id !== action.data.addressId)

      return state.mergeIn(['list'], {
        data: userAddressListCleaned,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case DeleteUserAddressAction.FAILURE:
      return state.mergeIn(['delete'], {
        isFetching: false,
        error: action.error,
      })

    default:
      return state
  }
}
