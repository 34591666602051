import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { updateOrderProductComment } from 'pmt-modules/orderProduct'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import TextField from 'pmt-ui/TextField'
import CommentOutlineIcon from 'pmt-ui/svg-icons/communication/comment-outline'

import { ButtonLink } from '../../../components/Button'

const styles = theme => ({
  title: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  commentContainer: {
    position: 'relative',
  },
  commentTextField: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.pmt.colors.grey500}`,
  },
  commentCount: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
    color: theme.pmt.colors.grey500,
  },
})

/**
 * This view contains two modes:
 * - simple, we just a tip asking to add a comment
 * - the comment area
 */
class Comment extends React.Component {
  state = {
    displayComment: this.shouldDisplayComment(),
    // used to avoid autoFocus if we display the comment area on first render
    commentDisplayedAtMount: this.shouldDisplayComment(),
  }

  shouldDisplayComment() {
    return !isEmpty(this.props.comment)
  }

  handleDisplayComment = () => {
    this.setState(
      {
        displayComment: true,
      },
      () => {
        // scroll to top when displayed
        setTimeout(() => {
          this.rootElement.scrollIntoView(true)
        }, 1000)
      }
    )
  }

  render() {
    const { classes, comment, orderAppConfig, updateOrderProductComment } = this.props

    if (!orderAppConfig.comments.items.enabled) {
      return null
    }

    return (
      <div ref={e => (this.rootElement = e)} className="u-marginTop40">
        {!this.state.displayComment ? (
          /* -- TIP MODE */
          <ButtonLink
            label={tr('order.product.comment.label')}
            icon={<CommentOutlineIcon className="u-marginRight10" />}
            onClick={this.handleDisplayComment}
            classes={{ root: 'u-textAlignLeft' }}
          />
        ) : (
          /* -- COMMENT AREA MODE */
          <div>
            <TypographyCustom type="207" className={classes.title} component="div">
              {tr('order.product.comment.title')}
            </TypographyCustom>

            <div className={classes.commentContainer}>
              <TextField
                id="multiline-flexible"
                multiline
                fullWidth
                rows="4"
                placeholder={tr('order.product.comment.placeholder')}
                value={comment}
                onChange={e => updateOrderProductComment(e.target.value)}
                className={classes.commentTextField}
                margin="normal"
                // we want to autoFocus only if the text field has been shown via an user interaction
                // on the tip title
                autoFocus={!this.state.commentDisplayedAtMount}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    maxLength: orderAppConfig.comments.items.maxLength,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TypographyCustom type="124" className={classes.commentCount} component="div">
                {comment.length} / {orderAppConfig.comments.items.maxLength}
              </TypographyCustom>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      updateOrderProductComment,
    }
  )
)(Comment)
