import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    position: 'relative',
    color: theme.palette.primary.main,
  },
  tipIcon: {
    float: 'left',
  },
  tipText: {
    float: 'left',
    width: `calc(100% - 24px)`,
    paddingLeft: theme.spacing(1),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
  },
})

class TipWithIcon extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.element.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  }

  render() {
    const { icon, label, classes } = this.props

    return (
      <div className={classes.root}>
        {React.cloneElement(icon, {
          className: classes.tipIcon,
        })}
        <p className={classes.tipText}>{label}</p>
      </div>
    )
  }
}

export default withStyles(styles)(TipWithIcon)
