import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'pmt-ui/Checkbox'
import { withStyles, withTheme } from 'pmt-ui/styles'

const styles = theme => ({
  disabled: {
    color: theme.palette.primary.main,
    opacity: 0.4,
  },
})

// CheckboxCustom - used to add special style in disable for order plugin
const CheckboxCustom = ({ disabled, classes, ...otherProps }) => (
  <Checkbox
    disabled={disabled}
    classes={{ disabled: classes.disabled, ...classes }}
    {...otherProps}
  />
)

CheckboxCustom.defaultProps = {
  disabled: false,
}

CheckboxCustom.propTypes = {
  disabled: PropTypes.bool,
}

export default withStyles(styles)(withTheme(CheckboxCustom))
