import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import Hidden from 'pmt-ui/Hidden'
import Add from 'pmt-ui/svg-icons/content/add'
import AlertButton from 'pmt-ui/AlertButton'
import isNil from 'lodash/isNil'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Button from '../../../components/Button'
import SubmitBtnText from './SubmitBtnText'

const styles = (theme) => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  rootDialog: {
    margin: 0,
    borderRadius: 0,
  },
  cancelBtn: {
    // same as submitBtn marginLeft
    marginRight: theme.spacing(1),
  },
  submitBtn: {
    [theme.breakpoints.up('md')]: {
      // same as cancelBtn marginRight
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  submitBtnMobile: {
    borderRadius: 0,
  },
})

const Actions = ({
  appConfigPreset,
  fromDialog,
  orderProduct,
  isMenuMode,
  isEditMode,
  isMobile,
  classes,
  onClose,
  onSubmit,
  width,
  orderProperties,
  saveAlcoholApproval,
  frontSettings,
}) =>
  // see https://zpl.io/b6QwNnP
  {
    const btnDisabled = !orderProduct.isValid || !orderProduct.available
    return (
      (orderProduct.hasOptions || isWidthDown('sm', width)) && (
        <div
          className={classNames(classes.root, {
            [classes.rootDialog]: fromDialog && isWidthDown('sm', width),
          })}
        >
          <Hidden smDown>
            <Button
              variant="outlined"
              onClick={onClose}
              classes={{ root: classes.cancelBtn }}
              size="large"
            >
              {tr('order.global.cancel')}
            </Button>
          </Hidden>
          {frontSettings.display.ageCheckForAlcohol &&
          orderProduct.containsAlcohol &&
          !orderProperties.alcoholApprovalAnswer ? (
            <CustomTextsContainer>
              {({ texts }) => (
                <AlertButton
                  alertTitle={
                    !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                      ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                      : tr('order.cart.age_check_for_alcohol.dialog.title')
                  }
                  alertContent={
                    !isNil(texts) &&
                    !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                      ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                      : tr('order.cart.age_check_for_alcohol.dialog.description')
                  }
                  okLabel={
                    !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                      ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                      : tr('order.cart.age_check_for_alcohol.dialog.ok')
                  }
                  cancelLabel={
                    !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                      ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                      : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                  }
                  button={<Button />}
                  disabled={btnDisabled}
                  fullWidth={isMobile}
                  onAgree={(e) => {
                    e.stopPropagation()
                    saveAlcoholApproval()
                    onSubmit(e)
                  }}
                  classes={{
                    root: classNames(classes.submitBtn, {
                      [classes.submitBtnMobile]: fromDialog && isWidthDown('sm', width),
                    }),
                  }}
                  size="large"
                  icon={!isEditMode && !isMenuMode && <Add />}
                >
                  <SubmitBtnText
                    appConfigPreset={appConfigPreset}
                    orderProduct={orderProduct}
                    isMenuMode={isMenuMode}
                    isEditMode={isEditMode}
                  />
                </AlertButton>
              )}
            </CustomTextsContainer>
          ) : (
            <Button
              disabled={btnDisabled}
              fullWidth={isMobile}
              onClick={onSubmit}
              classes={{
                root: classNames(classes.submitBtn, {
                  [classes.submitBtnMobile]: fromDialog && isWidthDown('sm', width),
                }),
              }}
              size="large"
              icon={!isEditMode && !isMenuMode && <Add />}
            >
              <SubmitBtnText
                appConfigPreset={appConfigPreset}
                orderProduct={orderProduct}
                isMenuMode={isMenuMode}
                isEditMode={isEditMode}
              />
            </Button>
          )}
        </div>
      )
    )
  }

export default compose(withStyles(styles), withWidth())(Actions)
