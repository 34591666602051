import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

import { getOrderProperties } from 'pmt-modules/orderPlugin'
import AppSecurityContainer from 'pmt-modules/appSecurity/components/AppSecurityContainer'
import { storageAvailable } from 'pmt-modules/storage'

import { withStyles } from 'pmt-ui/styles'

import Footer from './Footer'
import Header from './Header'
import PageContent from './PageContent'
import ErrorLayout from './components/ErrorLayout'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      padding: 0,
      paddingBottom: theme.spacing(6),
      background: theme.pmt.colors.greyBackground,
    },
  },
  whiteBackground: {
    background: theme.pmt.colors.white,
  },

  localStorage: {
    position: 'absolute',
    width: '100%',
    minHeight: 'calc(100% + 32px)',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,.9)',
    zIndex: 1500,
  },
  localStorageContainer: {
    background: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-100px',
    marginLeft: '-255px',
    width: '510px',
    padding: '20px',
    textAlign: 'center',
    fontSize: '0.95em',
    [theme.breakpoints.down('sm')]: {
      width: '360px',
      marginLeft: '-180px',
      marginTop: '-125px',
    },
  },
  localStorageTitle: {
    fontSize: '1.5em',
    fontWeight: '700',
    paddingBottom: '0.8em',
    marginTop: '0.1em',
    marginBottom: '1em',
    borderBottom: '2px solid #EFEFF4',
  },
  localStorageCaption: {
    marginTop: '1em',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1em',
    },
  },
})

class OrderPage extends React.Component {
  componentDidCatch(error, info) {
    console.log(error, info)
  }

  renderChildren() {
    const { children } = this.props

    if (isFunction(children)) {
      return children()
    }

    return children
  }

  render() {
    const {
      headerProps,
      orderPluginProperties,
      isWhiteBackground,
      classes,
      ...otherProps
    } = this.props

    return (
      <div
        className={classNames(classes.root, {
          [classes.whiteBackground]: isWhiteBackground,
        })}
      >
        {storageAvailable('localStorage') ? (
          <AppSecurityContainer>
            {({ appSecurityError }) =>
              appSecurityError ? (
                <ErrorLayout error={appSecurityError.localizedMessage} />
              ) : !isNil(orderPluginProperties.configurationError) ? (
                <ErrorLayout error={orderPluginProperties.configurationError} />
              ) : (
                <React.Fragment>
                  <Header {...headerProps} {...otherProps} />
                  <PageContent>{this.renderChildren()}</PageContent>
                  <Footer />
                </React.Fragment>
              )
            }
          </AppSecurityContainer>
        ) : (
          <div className={classes.localStorage}>
            <div className={classes.localStorageContainer}>
              <div className={classes.localStorageTitle}>
                {tr('global.cookies.disabled_modal.title')}
              </div>
              <span>{tr('global.cookies.disabled_modal.browser')}</span>
              <p
                className={classes.localStorageCaption}
                dangerouslySetInnerHTML={{
                  __html: tr('global.cookies.disabled_modal.caption', {
                    bold: `<b>`,
                    boldEnd: `</b>`,
                  }),
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

OrderPage.propTypes = {
  /**
   * The page content Component.
   * the view to display when not loading
   *
   * Can be a function that will received:
   * - isFetchingRestaurantsGroup: boolean
   * - restaurantsGroup: the selectedRGroup
   * - domain: the selected domain
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  // force white background (use on mobile)
  isWhiteBackground: PropTypes.bool,
}

OrderPage.defaultProps = {
  isWhiteBackground: false,
}

const mapStateToProps = state => ({
  orderPluginProperties: getOrderProperties(state),
})

export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  withStyles(styles)
)(OrderPage)
