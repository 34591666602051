import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { getOrderProperties } from 'pmt-modules/orderPlugin'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import TableNumberInput from '../../../components/TableNumberInput'

const styles = (theme) => ({
  buttonTable: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  hideDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  tableExample: {
    height: 56,
    lineHeight: '56px',
  },
  warningContainer: {
    maxWidth: 380,
    margin: '0 auto',
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  bigButton: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
})

class TableNumberForm extends React.PureComponent {
  render() {
    const { isFetchingRestaurantList, restaurant, orderProperties, classes } = this.props

    return (
      <div className="u-relative u-marginTop10">
        {isFetchingRestaurantList ? (
          <div className="u-relative">
            <LoadingBlockWrapper show={isFetchingRestaurantList} />
          </div>
        ) : isNil(restaurant) ? (
          <div className="u-marginTop60 u-textAlignCenter">
            <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
            <TypographyCustom
              type="204"
              align="center"
              className={classNames(classes.warningContainer, classes.grey500)}
            >
              {tr('order.store_locator.table_number.access_warning_restaurant')}
            </TypographyCustom>
          </div>
        ) : (
          <div>
            <TableNumberInput tableNumber={orderProperties.tableNumber} />
            <CustomTextsContainer>
              {({ texts }) => (
                <TypographyCustom
                  type="164"
                  className={classNames('u-marginTop30 u-marginBottom30', classes.grey500)}
                >
                  {!isNil(texts) && !isNil(texts.ORDER__GLOBAL__TABLE_NUMBER_HELPER)
                    ? texts.ORDER__GLOBAL__TABLE_NUMBER_HELPER
                    : tr('order.store_locator.table_number.helper')}
                </TypographyCustom>
              )}
            </CustomTextsContainer>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  orderProperties: getOrderProperties(state),
})

export default compose(connect(mapStateToProps), withStyles(styles))(TableNumberForm)
