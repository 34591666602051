import { tr } from 'pmt-modules/i18n'
export const ModifiersVerificationsReason = {
  /**
   * No CartModifier found for provided code
   */
  CODE_NOT_FOUND: 'CODE_NOT_FOUND',

  /**
   * CartModifier limit date is in the past
   */
  CART_MODIFIER_EXPIRED: 'CART_MODIFIER_EXPIRED',

  /**
   * The User needs to be logged-in
   */
  CART_MODIFIER_REQUIRES_USER_AUTHENTICATION: 'CART_MODIFIER_REQUIRES_USER_AUTHENTICATION',

  /**
   * In case there is already another CartModifier applied which hasn't been applied with a code OR if the CartModifier cannot be applied because its required conditions are not met
   */
  CART_MODIFIER_NOT_APPLICABLE: 'CART_MODIFIER_NOT_APPLICABLE',
}

export const getVerificationsModifiersReasonLabel = reason => {
  switch (reason) {
    case ModifiersVerificationsReason.CODE_NOT_FOUND:
      return tr('global.order.verifications.modifiers.reason.CODE_NOT_FOUND')
    case ModifiersVerificationsReason.CART_MODIFIER_EXPIRED:
      return tr('global.order.verifications.modifiers.reason.CART_MODIFIER_EXPIRED')
    case ModifiersVerificationsReason.CART_MODIFIER_REQUIRES_USER_AUTHENTICATION:
      return tr(
        'global.order.verifications.modifiers.reason.CART_MODIFIER_REQUIRES_USER_AUTHENTICATION'
      )
    case ModifiersVerificationsReason.CART_MODIFIER_NOT_APPLICABLE:
      return tr('global.order.verifications.modifiers.reason.CART_MODIFIER_NOT_APPLICABLE')
    default:
      return reason
  }
}
