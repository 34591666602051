import React from 'react'

import ProductItem from './ProductItem'

const Item = ({
  item,
  orderMenu,
  restaurant,
  partHasReachedMaxQuantity,
  onSelectOrderProduct,
  onUnselectOrderProduct,
  onEditOrderProduct,
  onAddToCartFromShortcut,
  orderProperties,
  saveAlcoholApproval,
  frontSettings,
}) => (
  <ProductItem
    orderMenu={orderMenu}
    product={item}
    restaurant={restaurant}
    isSelectable={!partHasReachedMaxQuantity}
    onSelectOrderProduct={onSelectOrderProduct}
    onUnselectOrderProduct={onUnselectOrderProduct}
    onEditOrderProduct={onEditOrderProduct}
    onAddToCartFromShortcut={onAddToCartFromShortcut}
    orderProperties={orderProperties}
    saveAlcoholApproval={saveAlcoholApproval}
    frontSettings={frontSettings}
  />
)

export default Item
