import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import OptionValue from './OptionValue'
import Grid from 'pmt-ui/Grid'
import Snackbar from 'pmt-ui/Snackbar'
import AlertButton from 'pmt-ui/AlertButton'
import isNil from 'lodash/isNil'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
    },
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  quantityTip: {
    paddingTop: theme.spacing(1),
    color: theme.pmt.colors.grey500,
    fontSize: 16,
  },
  valueGrid: {
    padding: 0,
  },
})

class OptionView extends React.PureComponent {
  handleOnClickDisabledOption = () => {
    this.props.updateUI({ openedSnackbar: true })
  }

  handleClose = () => {
    this.props.updateUI({ openedSnackbar: false })
  }

  render() {
    const {
      option,
      onSelectOptionValue,
      onUnselectOptionValue,
      classes,
      ui,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <TypographyCustom type="207">
            {option.name}
            {option.mandatory && '*'}
          </TypographyCustom>

          {/*
           * - 0 to MAX
           * - MIN to MAX where MIN > 0 and MAX !== MIN
           * - mandatory: nothing to display
           */}

          {/* 0 to MAX */}
          {option.quantityMin === 0 && option.quantityMax > 1 && (
            <div className={classes.quantityTip}>
              {tr('order.product.options.up_quantity', { max: option.quantityMax })}
            </div>
          )}

          {/* MIN to MAX where MIN > 0 and MAX !== MIN */}
          {option.quantityMin > 0 && option.quantityMax !== option.quantityMin && (
            <div className={classes.quantityTip}>
              {tr('order.product.options.from_quantity', {
                min: option.quantityMin,
                max: option.quantityMax,
              })}
            </div>
          )}

          {/* MAX === MIN && MIN === 1 */}
          {option.quantityMin === 1 && option.quantityMax === option.quantityMin && (
            <div className={classes.quantityTip}>
              {tr('order.product.options.quantity.singular', {
                count: option.quantityMax,
              })}
            </div>
          )}

          {/* MAX === MIN && MIN > 1 */}
          {option.quantityMin > 1 && option.quantityMax === option.quantityMin && (
            <div className={classes.quantityTip}>
              {tr('order.product.options.quantity.plural', {
                count: option.quantityMax,
              })}
            </div>
          )}
        </div>

        <Grid container spacing={0}>
          {option.values.map((value) => {
            // when there is multiple choices (square buttons), we disabled the values that can't
            // be selected (hasReachedMaxQuantity)
            const disabled =
              option.hasReachedMaxQuantity &&
              (option.quantityMax !== option.quantityMin || option.quantityMin > 1) &&
              value.quantity === 0

            //
            // - Option is mandatory: display radio button. The user can select only one value. If he
            // select another value, the already selected one is replaced.
            // - 0 to N / 1 to N: display square button. Block the number that can be selected to N.
            //  If the number of selected values equals N, and the user want another value, he must
            // unselect a value first.
            //
            const buttonType =
              option.quantityMin === option.quantityMax && option.quantityMin === 1
                ? OptionValue.ButtonType.ROUND
                : OptionValue.ButtonType.SQUARE
            const optionDisabled = (disabled || value.isOutOfStock) && value.disableAdd
            const onSelect = () => {
              // for now, an option value can be selected only once
              if (value.quantity === 0) {
                onSelectOptionValue(option, value)
              } else {
                onUnselectOptionValue(option, value)
              }
            }
            return (
              <Grid
                key={value.id}
                item
                xs={12}
                sm={6}
                className={{ root: classes.valueGrid }}
                onClick={() => {
                  if (disabled) {
                    this.handleOnClickDisabledOption()
                  }
                }}
              >
                {!disabled &&
                frontSettings.display.ageCheckForAlcohol &&
                value.containsAlcohol &&
                !orderProperties.alcoholApprovalAnswer ? (
                  <CustomTextsContainer>
                    {({ texts }) => (
                      <AlertButton
                        key={value.id}
                        alertTitle={
                          !isNil(texts) &&
                          !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                            ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                            : tr('order.cart.age_check_for_alcohol.dialog.title')
                        }
                        alertContent={
                          !isNil(texts) &&
                          !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                            ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                            : tr('order.cart.age_check_for_alcohol.dialog.description')
                        }
                        okLabel={
                          !isNil(texts) &&
                          !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                            ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                            : tr('order.cart.age_check_for_alcohol.dialog.ok')
                        }
                        cancelLabel={
                          !isNil(texts) &&
                          !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                            ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                            : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                        }
                        button={
                          <OptionValue
                            key={value.id}
                            value={value}
                            buttonType={buttonType}
                            disabled={optionDisabled}
                          />
                        }
                        disabled={optionDisabled}
                        onAgree={(e) => {
                          e.stopPropagation()
                          saveAlcoholApproval()
                          onSelect()
                        }}
                      />
                    )}
                  </CustomTextsContainer>
                ) : (
                  <OptionValue
                    key={value.id}
                    value={value}
                    buttonType={buttonType}
                    disabled={optionDisabled}
                    onSelect={onSelect}
                  />
                )}
              </Grid>
            )
          })}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={ui.openedSnackbar}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{tr('global.option.disabled')}</span>}
        />
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      openedSnackbar: false,
    },
  }),
  withStyles(styles)
)(OptionView)
