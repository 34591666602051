import isNil from 'lodash/isNil'
import invariant from 'invariant'

import { DISABLE_FORM_DEV_DATA_QUERY_PARAM } from 'pmt-modules/form'
import { APP_TOKEN_QUERY_PARAM } from 'pmt-modules/appSecurity'

import RoutingEnum from './routingEnum'

import HomePage from '../pages/home'
import ModePage from '../pages/mode'
import StoreLocatorPage from '../pages/storeLocator'
import CatalogPage from '../pages/catalog'
import MenuPage from '../pages/menu'
import ProductPage from '../pages/product'
import CartPage from '../pages/cart'
import DeliveryAddressPage from '../pages/deliveryAddress'
import PaymentPage from '../pages/payment'
import PaymentVerificationPage from '../pages/payment/verification'
import PaymentAsyncFormPage from '../pages/payment/asyncForm'
import PaymentSuccessPage from '../pages/payment/success'
import PaymentFailurePage from '../pages/payment/failure'
import PaymentCancelPage from '../pages/payment/cancel'
import ConfirmPage from '../pages/confirm'
// login
import LoginPage from '../pages/login'
import ForgotPasswordPage from '../pages/forgotPassword'
import UserProfilePage from '../pages/user/profile'
import UserAccountPage from '../pages/user/account'

// list of the queries that are global to all our urls, and should be kept
const globalQueries = [
  // lang
  'l',
  'apiconsumer', // for retro compat
  'apiConsumer',
  DISABLE_FORM_DEV_DATA_QUERY_PARAM,
  // app token
  APP_TOKEN_QUERY_PARAM,
  'apiVersion',
  'simulate',

  // used for Google Analytics
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',

  // used for Newrest to :
  //   - go back on catalog
  //   - auth attemptRefresh failed
  //   - postAuthorize and redirect to authorize.uri with auth code and state
  'accessToken',
  'callbackUri',
  'state',

  // used for tests
  'testLockedBeforePayment',
  // for support
  'disableRestaurantClosedDialog',
]

// merge our globalQueries with the specific queries of the route
const getQueries = (queries = []) => [...globalQueries, ...queries]

/**
 * We define  the `handler` for each route of our RoutingEnum.
 * The `handler` is the React Component to display for the route.
 * It should be a container in most cases.
 */
const routes = [
  {
    definition: RoutingEnum.HOME,
    handler: HomePage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__MODE,
    handler: ModePage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__STORE_LOCATOR,
    handler: StoreLocatorPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__CATALOG,
    handler: CatalogPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__CATEGORY,
    handler: CatalogPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__MENU,
    handler: MenuPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__PRODUCT,
    handler: ProductPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__CART,
    handler: CartPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.LOGIN,
    handler: LoginPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.ORDER__DELIVERY_ADDRESS,
    handler: DeliveryAddressPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.FORGOT_PASSWORD,
    handler: ForgotPasswordPage,
    props: {
      authRequired: false,
    },
  },
  {
    definition: RoutingEnum.USER_PROFILE,
    handler: UserProfilePage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__USER__ACCOUNT,
    handler: UserAccountPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT,
    handler: PaymentPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.PAYMENT__ASYNC__FORM,
    handler: PaymentAsyncFormPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__VERIFICATION,
    handler: PaymentVerificationPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__SUCCESS,
    handler: PaymentSuccessPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__FAILURE,
    handler: PaymentFailurePage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__CANCEL,
    handler: PaymentCancelPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__CONFIRM,
    handler: ConfirmPage,
    props: {
      authRequired: true,
    },
  },
]

export default routes.map((route) => {
  // handle props.query to set global queries
  if (!route.props.query) {
    route.props.query = getQueries([])
  }

  // check config is ok.
  invariant(
    !isNil(route.props.authRequired),
    `${route.definition.name}: props authRequired not set`
  )
  invariant(!isNil(route.handler), `${route.definition.name}: handler is required`)
  invariant(!isNil(route.definition), `${route}: definition is required`)

  return route
})
