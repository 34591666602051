import React, { Fragment } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { tr } from 'pmt-modules/i18n'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { haveAuthCredentials } from 'pmt-modules/auth'
import { updateComment } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { getOrderPreview, getOrderPreviewVerifications } from 'pmt-modules/orderPreview'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import TextField from 'pmt-ui/TextField'
import Tooltip from 'pmt-ui/Tooltip'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import ToggleContainer from 'pmt-ui/ToggleContainer'
import Typography, { TypographyCustom } from 'pmt-ui/Typography'
import CommunicationMessage from 'pmt-ui/svg-icons/communication/message'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import withWidth, { isWidthDown, isWidthUp } from 'pmt-ui/utils/withWidth'
import BlockContainer from 'app/components/BlockContainer'

import Button, { ButtonLink } from '../Button'
import MessageBlock from '../MessageBlock'

const styles = (theme) => ({
  fee: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  feeIcon: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  gridInverse: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  italic: {
    fontStyle: 'italic',
  },
  bigButton: {
    width: '100%',
  },
  bigButtonMargin: {
    marginTop: theme.spacing(3),
  },
  bigButtonMarginNeg: {
    marginTop: -theme.spacing(0.5),
  },
  commentButton: {
    textTransform: 'initial',
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(4),
    },
  },
  inputComment: {
    padding: theme.spacing(1),
    margin: 0,
    border: `1px solid ${theme.pmt.colors.grey500}`,
    background: theme.pmt.colors.white,
  },
  commentContainer: {
    position: 'relative',
  },
  commentCount: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.pmt.colors.grey500,
  },
  centerTextMobile: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cartCaption: {
    borderTop: `1px solid ${theme.pmt.colors.grey500}`,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: theme.pmt.colors.grey600,
    fontStyle: 'italic',
    textAlign: 'justify',
  },
  subTotalWrapper: {
    marginBottom: theme.spacing(2),
  },
  feeLabel: {
    color: theme.pmt.colors.grey500,
  },
  feePrice: {
    float: 'right',
    color: theme.pmt.colors.grey500,
  },
  modifiers: {
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  modifierTotalPrice: {
    fontStyle: 'italic',
    float: 'right',
    color: theme.palette.primary.main,
  },
  restaurantUnavailable: {
    marginTop: theme.spacing(1),
    color: theme.pmt.status.error,
  },
})

const Type = {
  BIG: 'big',
  SMALL: 'small',
}

const CartFooterView = ({
  appConfigPreset,
  classes,
  errorPostOrder,
  frontSettings,
  haveAuthCredentials,
  orderProperties,
  type,
  itemList,
  enabledSubmitCart,
  fees,
  minimumPrice,
  minimumPriceFormatted,
  restaurant,
  orderPreview,
  orderPreviewVerifications,
  totalCartPrice,
  totalCartPriceAndFeesFormatted,
  totalCartPriceFormatted,
  containsAlcohol,
  comment,
  displayMinimumPricesForFree,
  updateComment,
  onSubmitAction,
  shouldDisplayComment,
  width,
  orderAppConfig,
  orderModifiers,
}) => {
  if (!orderModifiers) {
    orderModifiers = orderPreview?.modifiers || []
  }

  const totalSubsidiesGrantAmount = get(
    orderPreviewVerifications,
    'userAccount.subsidies.grantAmount',
    0
  )

  const totalAdmissionFeesAmountIncludingTax = get(
    orderPreview,
    'admissionFees.amountIncludingTax',
    0
  )

  const targetTypeTotalModifiers = orderModifiers.filter((modifier) => modifier.isTargetTypeTotal)

  const shouldDisplayCartTotal =
    !isEmpty(fees) ||
    !isEmpty(targetTypeTotalModifiers) ||
    (minimumPrice !== 0 && totalCartPrice < minimumPrice) ||
    totalSubsidiesGrantAmount > 0 ||
    totalAdmissionFeesAmountIncludingTax > 0

  return (
    <React.Fragment>
      <Grid spacing={1} container className={classes.gridInverse}>
        <Grid item xs={12} md={type === Type.BIG ? 6 : 12}>
          {/* ------------------------------------------------------------ */}
          {/* total prices                                   */}
          {/* ------------------------------------------------------------ */}

          {frontSettings.display.totalPrices && (
            <Grid container spacing={0} className="u-marginBottom10">
              {/* ------------------------------------------------------------ */}
              {/* cart total                                   */}
              {/* ------------------------------------------------------------ */}

              <Grid item xs={8}>
                {shouldDisplayCartTotal && (
                  <TypographyCustom type="164">{tr('order.menu.cart.total')}</TypographyCustom>
                )}

                {minimumPrice !== 0 && totalCartPrice < minimumPrice && (
                  <TypographyCustom
                    type="144"
                    className={classNames(classes.grey500, classes.italic, classes.fee)}
                  >
                    {tr('order.cart.minimum_order.text', { amount: minimumPriceFormatted })}

                    <ToggleContainer defaultOpen={false}>
                      {({ toggle, isOpen }) => (
                        <Tooltip
                          id="minimum-order-tooltip"
                          title={tr('order.cart.minimum_order.tooltip')}
                          placement="bottom"
                          // This trick is for mobile only
                          // The tooltip does not react when we simply click it (only long click)
                          // So we toggle it by clicking it
                          onClick={toggle}
                          open={isOpen}
                        >
                          <ActionInfoOutline className={classes.feeIcon} />
                        </Tooltip>
                      )}
                    </ToggleContainer>
                  </TypographyCustom>
                )}
              </Grid>

              {/* ------------------------------------------------------------ */}
              {/* cart total price                                  */}
              {/* ------------------------------------------------------------ */}

              <Grid item xs={4}>
                {shouldDisplayCartTotal && (
                  <TypographyCustom
                    component="div"
                    type="204"
                    align="right"
                    className="u-floatRight"
                  >
                    <Price value={totalCartPriceFormatted} />
                  </TypographyCustom>
                )}
              </Grid>
            </Grid>
          )}

          {/* ------------------------------------------------------------ */}
          {/* FEES                                                         */}
          {/* ------------------------------------------------------------ */}

          {orderProperties &&
            (fees || []).map((fee, i) => (
              <Grid key={`fee${i}`} container spacing={0} className="u-marginBottom10">
                <Grid item xs={8}>
                  <TypographyCustom type="164" className={classes.fee}>
                    {fee.nameFormatted}
                    {fee.publicDescriptionFormatted && (
                      <ToggleContainer>
                        {({ toggle, isOpen }) => (
                          <Tooltip
                            id={`feee${i}-tooltip`}
                            title={fee.publicDescriptionFormatted}
                            placement="bottom"
                            // This trick is for mobile only
                            // The tooltip does not react when we simply click it (only long click)
                            // So we toggle it by clicking it
                            onClick={toggle}
                            open={isOpen}
                          >
                            <ActionInfoOutline className={classes.feeIcon} />
                          </Tooltip>
                        )}
                      </ToggleContainer>
                    )}
                  </TypographyCustom>

                  {fee.hasMinimumPriceForFree && displayMinimumPricesForFree && (
                    <TypographyCustom
                      type="144"
                      className={classNames(classes.grey500, classes.italic)}
                    >
                      {tr('order.menu.cart.fees_for_free', {
                        amount: fee.minimumPriceForFreeFormatted,
                      })}
                    </TypographyCustom>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <TypographyCustom
                    component="div"
                    type="204"
                    align="right"
                    className="u-floatRight"
                  >
                    <Price value={fee.amountIncludingTaxFormatted} />
                  </TypographyCustom>
                </Grid>
              </Grid>
            ))}

          {frontSettings.display.totalPrices && (
            <Grid container spacing={0} className="u-marginTop10">
              {/* ------------------------------------------------------------ */}
              {/* admission fees                                               */}
              {/* ------------------------------------------------------------ */}

              {totalAdmissionFeesAmountIncludingTax > 0 && (
                <Fragment>
                  <Grid item xs={8} className={classes.subTotalWrapper}>
                    <TypographyCustom type="164" className={classes.feeLabel}>
                      {tr('order.cart.admission_fees.amount')}
                    </TypographyCustom>
                  </Grid>
                  <Grid item xs={4}>
                    <TypographyCustom
                      component="div"
                      type="204"
                      align="right"
                      className={classes.feePrice}
                    >
                      <Price value={orderPreview.admissionFees.amountIncludingTaxFormatted} />
                    </TypographyCustom>
                  </Grid>
                </Fragment>
              )}

              {/* ------------------------------------------------------------ */}
              {/* subsidies                                                    */}
              {/* ------------------------------------------------------------ */}

              {totalSubsidiesGrantAmount > 0 && (
                <Fragment>
                  <Grid item xs={8} className={classes.subTotalWrapper}>
                    <TypographyCustom type="164" className={classes.feeLabel}>
                      {tr('order.cart.subsidy.amount')}
                    </TypographyCustom>
                  </Grid>
                  <Grid item xs={4}>
                    <TypographyCustom
                      component="div"
                      type="204"
                      align="right"
                      className={classes.feePrice}
                    >
                      <Price
                        value={`-${orderPreviewVerifications.userAccount.subsidies.grantAmountFormatted}`}
                      />
                    </TypographyCustom>
                  </Grid>
                </Fragment>
              )}

              {/* ------------------------------------------------------------ */}
              {/* cart modifiers with a target type total                      */}
              {/* ------------------------------------------------------------ */}

              {targetTypeTotalModifiers.map((modifier) => (
                <Fragment key={modifier.id}>
                  <Grid item xs={8} className={classes.modifiers}>
                    <Tooltip title={modifier.description} placement="bottom-start">
                      <TypographyCustom type="164" skipColor>
                        {modifier.name}
                      </TypographyCustom>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4}>
                    <TypographyCustom
                      component="div"
                      type="204"
                      align="right"
                      className={classes.modifierTotalPrice}
                    >
                      <Price value={`-${modifier.action.valueFormatted}`} />
                    </TypographyCustom>
                  </Grid>
                </Fragment>
              ))}

              {/* ------------------------------------------------------------ */}
              {/* order mode label                                             */}
              {/* ------------------------------------------------------------ */}

              <Grid item xs={8}>
                <TypographyCustom
                  type="207"
                  className={classNames({
                    [classes.grey500]: isEmpty(itemList),
                  })}
                >
                  {orderProperties.isDelivery
                    ? tr('order.cart.total.delivery')
                    : orderProperties.isOnSite
                    ? tr('order.cart.total.on_site')
                    : tr('order.cart.total.take_away')}
                </TypographyCustom>
              </Grid>

              {/* ------------------------------------------------------------ */}
              {/* total cart price and fees                               */}
              {/* ------------------------------------------------------------ */}

              <Grid item xs={4}>
                <TypographyCustom
                  component="div"
                  type="247"
                  align="right"
                  className={classNames('u-floatRight', {
                    [classes.grey500]: isEmpty(itemList),
                  })}
                >
                  <Price value={totalCartPriceAndFeesFormatted} />
                </TypographyCustom>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* ------------------------------------------------------------ */}
        {/* order comment modification                                   */}
        {/* ------------------------------------------------------------ */}

        {type === Type.BIG && shouldDisplayComment && (
          <Grid item xs={12} md={5} className={classes.centerTextMobile}>
            <ToggleContainer defaultOpen={!isEmpty(comment)}>
              {({ toggle, isOpen }) =>
                !isOpen ? (
                  <TypographyCustom type="164" skipColor>
                    {({ className }) => (
                      <ButtonLink
                        className={classNames(className, classes.commentButton, {
                          [classes.bigButtonMargin]: isWidthDown('sm', width),
                          [classes.bigButtonMarginNeg]: isWidthUp('sm', width),
                        })}
                        onClick={() => {
                          EventManager.dispatch(EventManager.Events.ON_CART_ADD_COMMENT)
                          toggle()
                        }}
                      >
                        <CommunicationMessage />
                        <span className="u-marginLeft10">{tr('order.cart.send_message')}</span>
                      </ButtonLink>
                    )}
                  </TypographyCustom>
                ) : (
                  <div className={classes.commentContainer}>
                    <TextField
                      id="multiline-flexible"
                      multiline
                      fullWidth
                      rows="4"
                      placeholder={tr('order.cart.your_message')}
                      value={comment}
                      onChange={(e) => updateComment(e.target.value)}
                      className={classes.inputComment}
                      margin="normal"
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          maxLength: orderAppConfig.comments.global.maxLength,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TypographyCustom type="124" className={classes.commentCount} component="div">
                      {comment.length} / {orderAppConfig.comments.global.maxLength}
                    </TypographyCustom>
                  </div>
                )
              }
            </ToggleContainer>
          </Grid>
        )}

        {/* ------------------------------------------------------------ */}
        {/* Age check about alcohol                                      */}
        {/* ------------------------------------------------------------ */}

        {type === Type.BIG && containsAlcohol && frontSettings.display.ageCheckForAlcohol && (
          <Grid container spacing={0} className="u-marginTop10">
            <Grid item xs={12}>
              <BlockContainer type={BlockContainer.Type.WHITE}>
                <CustomTextsContainer>
                  {({ texts }) => (
                    <>
                      <TypographyCustom type="207">
                        {!isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__BANNER_TITLE)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__BANNER_TITLE
                          : tr('order.cart.age_check_for_alcohol.banner.title')}
                      </TypographyCustom>
                      <TypographyCustom type="204" className="u-marginTop10">
                        {!isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__BANNER_DESCRIPTION)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__BANNER_DESCRIPTION
                          : tr('order.cart.age_check_for_alcohol.banner.description')}
                      </TypographyCustom>
                    </>
                  )}
                </CustomTextsContainer>
              </BlockContainer>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* ------------------------------------------------------------ */}
      {/* post order error                                             */}
      {/* ------------------------------------------------------------ */}

      {!isNil(errorPostOrder) && !frontSettings.display.paymentPage && (
        <div className="u-marginTop30">
          <ErrorBlock
            error={errorPostOrder}
            mode={ErrorBlock.Mode.CUSTOM}
            customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
          />
        </div>
      )}

      {/* ------------------------------------------------------------ */}
      {/* submit cart button                                           */}
      {/* ------------------------------------------------------------ */}

      {enabledSubmitCart && (
        <Grid container spacing={0} className={classes.gridInverse}>
          <Grid item xs={12} md={type === Type.BIG ? 6 : 12}>
            {restaurant?.isStatusDisabled && (
              <TypographyCustom type="167" className={classes.restaurantUnavailable}>
                {tr('order.cart.restaurant_disabled')}
              </TypographyCustom>
            )}
            <TypographyCustom type="247">
              <Button
                size="large"
                disabled={
                  isEmpty(itemList) ||
                  (isNil(orderProperties.dueDate) && !orderProperties.isAsap) ||
                  orderProperties.isMissingTableNumber ||
                  (minimumPrice !== 0 && totalCartPrice < minimumPrice) ||
                  restaurant?.isStatusDisabled
                }
                className={classNames({
                  [classes.bigButtonMargin]:
                    frontSettings.display.totalPrices || isWidthDown('sm', width),
                })}
                classes={{ root: classes.bigButton }}
                label={
                  type === Type.BIG
                    ? frontSettings.display.paymentPage ||
                      !haveAuthCredentials ||
                      isEmpty(itemList) ||
                      orderProperties.isDelivery
                      ? tr('order.cart.confirm_order')
                      : tr('order.cart.confirm_post_order')
                    : appConfigPreset.isDigitalMenu
                    ? tr('order.menu.cart.submit_button_digital_menu')
                    : tr('order.menu.cart.submit_button')
                }
                onClick={onSubmitAction}
              />
            </TypographyCustom>
          </Grid>
        </Grid>
      )}

      {/* ------------------------------------------------------------ */}
      {/* TEXTS CUSTOMS                                                */}
      {/* ------------------------------------------------------------ */}

      <CustomTextsContainer>
        {({ texts }) =>
          !isNil(texts) &&
          (!isNil(texts.ORDER__CART__ON_SITE) ||
            !isNil(texts.ORDER__CART__TAKE_AWAY) ||
            !isNil(texts.ORDER__CART__DELIVERY)) && (
            <Typography variant="caption" component="div" className={classes.cartCaption}>
              {orderProperties.isOnSite && texts.ORDER__CART__ON_SITE}
              {orderProperties.isTakeAway && texts.ORDER__CART__TAKE_AWAY}
              {orderProperties.isDelivery && texts.ORDER__CART__DELIVERY}
            </Typography>
          )
        }
      </CustomTextsContainer>
    </React.Fragment>
  )
}

CartFooterView.defaultProps = {
  enabledSubmitCart: true,

  displayMinimumPricesForFree: true,
}

CartFooterView.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  itemList: PropTypes.array.isRequired,
  enabledSubmitCart: PropTypes.bool,

  displayMinimumPricesForFree: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  haveAuthCredentials: haveAuthCredentials(state),
  orderPreview: getOrderPreview(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
})

const CartFooter = compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    updateComment,
  })
)(CartFooterView)

CartFooter.Type = Type
CartFooter.displayName = 'CartFooter'

export default CartFooter
