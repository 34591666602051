import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Grid from 'pmt-ui/Grid'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BlockContainer from '../../../components/BlockContainer'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  block: {
    padding: `${theme.spacing(20)}px ${theme.spacing(2)}px`,
  },
  loadingBlock: {
    background: 'transparent',
  },
  wording: {
    marginBottom: theme.spacing(10),
  },
})

const View = ({ classes }) => (
  <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <BlockContainer classes={{ root: classes.block }} mobileType={BlockContainer.Type.GREY}>
          <TypographyCustom type={204} align="center" className={classes.wording}>
            {tr('order.payment.verification')}
          </TypographyCustom>
          <LoadingBlock show classes={{ loadingBlock: classes.loadingBlock }} />
        </BlockContainer>
      </Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(View)
