import React from 'react'
import isNil from 'lodash/isNil'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { PspRegisterUserCardType } from 'pmt-modules/creditCard/constants'
import { getOrderData, getOrderProperties } from 'pmt-modules/orderPlugin/selectors'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import Logger from 'pmt-utils/logger'
import BlockContainer from 'app/components/BlockContainer'

const styles = (theme) => ({
  blockContainer: {
    width: '100%',
    height: '100%',
  },
})

@withRestaurant
@withUserMe
class PaymentFailurePage extends React.Component {
  shouldComponentUpdate(nextProps) {
    // we wait for the restaurant to be loaded
    if (nextProps.restaurant !== this.props.restaurant || nextProps.user !== this.props.user) {
      return true
    }
    return false
  }
  componentDidUpdate(prevProps) {
    const props = this.props
    if (
      !isNil(props.restaurant) &&
      !isNil(props.user) &&
      (props.user !== prevProps.user || props.restaurant !== prevProps.restaurant)
    ) {
      const { restaurant } = props
      const pspRegisterUserCardType = restaurant.pspRegisterUserCardType

      // transaction id, for example cpb91ac0dff7869135ff9600f3b1a4130d
      // const pid = getQueryParam('pid')
      // TODO: add query param actionType ? paiement / card registration

      switch (pspRegisterUserCardType) {
        case PspRegisterUserCardType.WEB_REDIRECT:
          props.redirectTo(getRoute('ORDER__PAYMENT'))
          break

        default:
          Logger.error('Payment', 'pspRegisterUserCardType mismatch', {
            pspRegisterUserCardType,
            extra: this.props,
          })
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <BlockContainer className={classes.blockContainer} type={BlockContainer.Type.WHITE}>
        <LoadingBlock show />
      </BlockContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  // errorPostPspDatas: getPostPspDatasError(state),

  // postPspDatasIsFetching: getPostPspDatasIsFetching(state),

  orderData: getOrderData(state),

  orderProperties: getOrderProperties(state),
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
  }),
  withStyles(styles)
)(PaymentFailurePage)
