import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import compose from 'recompose/compose'

import LoyaltyIcon from '@material-ui/icons/Loyalty'

import { tr } from 'pmt-modules/i18n'
import { EventManager } from 'pmt-modules/event'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { deleteFromCart, duplicateItemOnCart, editItem, removeFromCart } from 'pmt-modules/cart'
import { getOrderPreview } from 'pmt-modules/orderPreview'
import AlertButton from 'pmt-ui/AlertButton'
import isNil from 'lodash/isNil'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import Type from './Type'
import {
  getOrderProperties,
  saveAlcoholApproval,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'

import { withStyles } from 'pmt-ui/styles'
import Price from 'pmt-ui/Price'
import Tooltip from 'pmt-ui/Tooltip'
import { TypographyCustom } from 'pmt-ui/Typography'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ContentRemove from 'pmt-ui/svg-icons/content/remove'
import Trash from 'pmt-ui/svg-icons/pmt/Trash'
import CartIcon from 'pmt-ui/svg-icons/pmt/CartIcon'

import { existsOnArray } from 'pmt-utils/array'

import Chip from '../Chip'
import CartModifierTag from '../CartModifier/CartModifierTag'

import ProductContent from './components/ProductContent'
import { containsAlcohol } from 'pmt-modules/cart'

const styles = theme => ({
  // common styles for both small and big cart
  iconContainer: {
    display: 'inline-block',
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
  iconContainerPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,

    // transition with cartContentIconPrimary
    // https://stackoverflow.com/questions/18376815/trigger-animation-on-element-click-in-pure-css
    '&:not(:active)': {
      transition: 'background-color 400ms step-end',
    },

    '&:active, &:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconContainerTrash: {
    border: `1px solid ${theme.pmt.colors.grey500}`,

    // transition with cartContentIconPrimary
    // https://stackoverflow.com/questions/18376815/trigger-animation-on-element-click-in-pure-css
    '&:not(:active)': {
      transition: 'background-color 400ms, border-color 400ms step-end',
    },

    '&:active, &:hover': {
      backgroundColor: theme.pmt.colors.red800,
      borderColor: theme.pmt.colors.red800,
    },
  },
  cartContentIcon: {
    width: 22,
    height: 22,
    padding: 4,
  },
  cartContentIconPrimary: {
    color: theme.palette.primary.main,

    '&:not(:active)': {
      transition: 'color 400ms step-end',
    },

    '&:active, &:hover': {
      color: 'white',
    },
  },
  cartContentIconTrash: {
    color: theme.pmt.colors.grey500,

    '&:not(:active)': {
      transition: 'color 400ms step-end',
    },

    '&:active, &:hover': {
      color: 'white',
    },
  },
  cartContentText: {
    width: 26,
  },
  deleteSection: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  deleteIconContainer: {
    height: 24,
    lineHeight: '24px',
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  divider: {
    width: '100%',
    height: 0,
    borderBottom: `1px solid ${theme.pmt.colors.grey300}`,
  },
  cartIcon: {
    display: 'block',
    width: 80,
    height: 80,
    margin: '0 auto',
    marginBottom: 20,
  },

  menuProductOption: {
    marginLeft: theme.spacing(1),
  },

  // styles for big type
  bigQuantityGrid: {
    float: 'left',
    width: 74,
  },
  bigNameAndOptionsGrid: {
    float: 'left',
    paddingLeft: 30,
    width: 'calc(100% - ( 74px + 105px ) )',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - ( 74px + 75px ) )',
    },
    lineHeight: '21px',
  },
  bigPriceGrid: {
    float: 'left',
    width: 105,
    [theme.breakpoints.down('sm')]: {
      width: 75,
    },
  },
  bigNameFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  bigTotalPriceFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  bigMathPriceFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  bigMenuProductFont: {
    lineHeight: '20px',
  },

  // styles for small type
  smallQuantityGrid: {
    float: 'left',
    width: 74,
  },
  smallNameAndOptionsGrid: {
    float: 'left',
    paddingLeft: 10,
    width: 'calc(100% - ( 74px + 85px ) )',
    marginTop: 3,
  },
  smallPriceGrid: {
    float: 'left',
    width: 85,
  },
  smallMenuProductFont: {
    lineHeight: '18px',
  },
  smallMathPriceFont: {
    paddingTop: 5,
  },
  cartModifierContainer: {
    overflow: 'hidden',
  },
  cartModifierChip: {
    float: 'left',
    fontSize: 12,
    padding: `0 ${theme.spacing(0.5)}px`,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 4,
  },
  cartModifierUnavailable: {
    color: theme.palette.action.disabled,
    borderColor: theme.palette.action.disabled,
  },
  modifiers: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  modifier: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  loyalty: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
})

const ItemQuantity = ({
  classes,
  item,
  enabledQuantityChange,
  deleteFromCart,
  removeFromCart,
  duplicateItemOnCart,
  alcoholApprovalAnswer,
  saveAlcoholApproval,
  frontSettings,
}) => (
  <div className={`u-alignMiddle`}>
    {enabledQuantityChange && (
      <React.Fragment>
        {item.quantity === 1 ? (
          <span className={classNames(classes.iconContainer, classes.iconContainerTrash)}>
            <Trash
              viewBox="0 0 512 512"
              className={classNames(
                'u-alignBlock',
                'u-cursorPointer',
                classes.cartContentIcon,
                classes.cartContentIconTrash
              )}
              onClick={() => {
                deleteFromCart(item)
              }}
            />
          </span>
        ) : (
          <span className={classNames(classes.iconContainer, classes.iconContainerPrimary)}>
            <ContentRemove
              className={classNames(
                'u-alignBlock',
                'u-cursorPointer',
                classes.cartContentIcon,
                classes.cartContentIconPrimary
              )}
              onClick={() => {
                removeFromCart(item)
              }}
            />
          </span>
        )}
      </React.Fragment>
    )}
    <TypographyCustom
      component="span"
      type="164"
      align="center"
      className={classNames('u-alignBlock', classes.cartContentText)}
    >
      {item.quantity}
    </TypographyCustom>
    {enabledQuantityChange && (
      <span className={classNames(classes.iconContainer, classes.iconContainerPrimary)}>
        {frontSettings.display.ageCheckForAlcohol &&
        containsAlcohol([item]) &&
        !alcoholApprovalAnswer ? (
          <CustomTextsContainer>
            {({ texts }) => (
              <AlertButton
                button={
                  <ContentAdd
                    className={classNames(
                      'u-alignBlock',
                      'u-cursorPointer',
                      classes.cartContentIcon,
                      classes.cartContentIconPrimary
                    )}
                  />
                }
                onAgree={e => {
                  e.stopPropagation()
                  saveAlcoholApproval()
                  duplicateItemOnCart(item)
                }}
                alertTitle={
                  !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                    ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                    : tr('order.cart.age_check_for_alcohol.dialog.title')
                }
                alertContent={
                  !isNil(texts) &&
                  !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                    ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                    : tr('order.cart.age_check_for_alcohol.dialog.description')
                }
                okLabel={
                  !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                    ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                    : tr('order.cart.age_check_for_alcohol.dialog.ok')
                }
                cancelLabel={
                  !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                    ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                    : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                }
              />
            )}
          </CustomTextsContainer>
        ) : (
          <ContentAdd
            className={classNames(
              'u-alignBlock',
              'u-cursorPointer',
              classes.cartContentIcon,
              classes.cartContentIconPrimary
            )}
            onClick={() => {
              duplicateItemOnCart(item)
            }}
          />
        )}
      </span>
    )}
  </div>
)
ItemQuantity.propTypes = {
  classes: PropTypes.object.isRequired,
  enabledQuantityChange: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  duplicateItemOnCart: PropTypes.func.isRequired,
  alcoholApprovalAnswer: PropTypes.bool.isRequired,
  saveAlcoholApproval: PropTypes.func.isRequired,
}

const ItemNameAndOptions = ({
  classes,
  type,
  item,
  onEditItem,
  enabledClickableItems,
  modifiersFromCart,
}) => {
  let hasComplexModifier = false
  forEach(item.modifierIds, modifierId => {
    if (existsOnArray(modifiersFromCart, ({ id, isComplex }) => id === modifierId && isComplex)) {
      hasComplexModifier = true
    }
  })

  return (
    <React.Fragment>
      <TypographyCustom
        type={type === Type.BIG ? '204' : '164'}
        className={classNames(classes[`${type}NameFont`], {
          'u-cursorPointer': enabledClickableItems,
        })}
        onClick={enabledClickableItems && onEditItem}
      >
        {hasComplexModifier && <LoyaltyIcon className={classes.loyalty} />}
        {item.name}
      </TypographyCustom>
      {item.hasCartModifier && (
        <div className={classes.cartModifierContainer}>
          {item.cartModifiers.list.map((cartModifier, index) => {
            // we disabled "cartModifiers from catalog" that are NOT really applied by the API
            // eg. a discount can be displayed on the Catalog item, but not applied if this discount is not cumulative and another discount applied first

            // item.cardModifiers and item.hasCartModifier come from the catalogProduct
            // whereas item.modifierIds comes from the API orderProduct
            const disabled = !item.modifierIds?.includes(cartModifier.id)

            return (
              <CartModifierTag
                cartModifier={cartModifier}
                chipElement={
                  <Chip
                    key={index}
                    label={cartModifier.tag}
                    classes={{
                      root: classNames(classes.cartModifierChip, {
                        [classes.cartModifierUnavailable]: disabled,
                      }),
                    }}
                  />
                }
                key={index}
                tooltipMsg={disabled ? tr('order.cart.cart_modifier.not_applied') : ''}
              />
            )
          })}
        </div>
      )}
      <div
        className={classNames({
          'u-cursorPointer': enabledClickableItems,
        })}
        onClick={enabledClickableItems && onEditItem}
      >
        {item.isProduct && <ProductContent fromMenu={false} product={item} type={type} />}

        {item.isMenu && (
          /* menu products */
          <TypographyCustom
            component="div"
            type={type === Type.BIG ? '164' : '144'}
            className={classNames(classes[`${type}OptionFont`], classes.grey500)}
          >
            {item.selectedProducts.map((product, index) => (
              <React.Fragment key={index}>
                <span className={classes[`${type}MenuProductFont`]}>{product.name}</span>
                <ProductContent fromMenu product={product} type={type} />
              </React.Fragment>
            ))}
          </TypographyCustom>
        )}
      </div>
    </React.Fragment>
  )
}
ItemNameAndOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

const ItemPrice = ({ classes, type, item }) => (
  <div className="u-floatRight">
    <TypographyCustom
      component="div"
      type={type === Type.BIG ? '244' : '204'}
      align="right"
      className={classes[`${type}TotalPriceFont`]}
    >
      <Price value={item.totalPriceWithQuantityFormatted} />
    </TypographyCustom>

    {item.quantity > 1 && (
      <TypographyCustom
        type={type === Type.BIG ? '164' : '124'}
        align="right"
        className={classNames(classes[`${type}MathPriceFont`], classes.grey500)}
      >
        {item.quantity}x{item.totalPriceFormatted}
      </TypographyCustom>
    )}
  </div>
)
ItemPrice.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

@withRestaurant
class CartItemListView extends React.PureComponent {
  render() {
    const {
      restaurant,
      classes,
      type,
      itemList,
      enabledQuantityChange,
      enabledClickableItems,
      deleteFromCart,
      removeFromCart,
      duplicateItemOnCart,
      editItem,
      fromRoute,
      appConfigPreset,
      orderPreview,
      orderModifiers,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    // we don't want to display prices for preset other than order plugin
    // https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
    const shouldDisplayPrices = appConfigPreset.isPluginOrder

    const modifiers = orderModifiers || orderPreview?.modifiers || []

    return (
      <React.Fragment>
        {isEmpty(itemList) ? (
          <div className="u-textAlignCenter u-paddingBottom60 u-paddingTop60">
            <CartIcon className={classNames(classes.grey500, classes.cartIcon)} />
            <TypographyCustom
              type="167"
              component="span"
              align="center"
              className={classNames(classes.grey500, 'u-marginTop10')}
            >
              {tr('order.menu.cart.not_hungry')}
              <br />
              {tr('order.menu.cart.empty')}
            </TypographyCustom>
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.modifiers}>
              {modifiers.map(
                modifier =>
                  (modifier.isComplex || !modifier.isAutomatic) && (
                    <Tooltip
                      key={modifier.id}
                      title={modifier.description}
                      placement="bottom-start"
                    >
                      <TypographyCustom type="167" className={classes.modifier}>
                        <LoyaltyIcon className={classes.loyalty} />
                        {modifier.name}
                      </TypographyCustom>
                    </Tooltip>
                  )
              )}
            </div>
            {itemList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index !== 0 && type === Type.BIG && <div className={classes.divider} />}
                  <div className="u-overflowHidden u-paddingTop20 u-paddingBottom20">
                    <div className={classes[`${type}QuantityGrid`]}>
                      <ItemQuantity
                        classes={classes}
                        type={type}
                        item={item}
                        deleteFromCart={item => {
                          deleteFromCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DELETE, {
                            item,
                            restaurant,
                          })
                        }}
                        removeFromCart={item => {
                          removeFromCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DECREASE, {
                            item,
                          })
                        }}
                        duplicateItemOnCart={item => {
                          duplicateItemOnCart(item)
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_INCREASE, {
                            item,
                          })
                        }}
                        enabledQuantityChange={enabledQuantityChange}
                        saveAlcoholApproval={saveAlcoholApproval}
                        alcoholApprovalAnswer={orderProperties.alcoholApprovalAnswer}
                        frontSettings={frontSettings}
                      />
                    </div>
                    <div className={classes[`${type}NameAndOptionsGrid`]}>
                      <ItemNameAndOptions
                        classes={classes}
                        modifiersFromCart={modifiers}
                        type={type}
                        item={item}
                        onEditItem={() => {
                          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DETAIL, {
                            item,
                          })
                          editItem(item, {
                            restaurantId: restaurant.id,
                            fromRoute,
                          })
                        }}
                        enabledClickableItems={enabledClickableItems}
                      />
                    </div>
                    {shouldDisplayPrices && (
                      <div className={classes[`${type}PriceGrid`]}>
                        <ItemPrice classes={classes} type={type} item={item} />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

CartItemListView.defaultProps = {
  enabledQuantityChange: true,
  enabledClickableItems: true,
}

CartItemListView.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  itemList: PropTypes.array.isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  enabledQuantityChange: PropTypes.bool,
  enabledClickableItems: PropTypes.bool,
  duplicateItemOnCart: PropTypes.func.isRequired,
  /**
   * the route (RoutingEnum) from where this component is displayed.
   */
  fromRoute: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  orderPreview: getOrderPreview(state),
  orderProperties: getOrderProperties(state),
  frontSettings: getAppConfigFrontSettings(state),
})

const CartItemList = compose(
  withStyles(styles),
  connect(mapStateToProps, {
    duplicateItemOnCart,
    removeFromCart,
    deleteFromCart,
    editItem,
    saveAlcoholApproval,
  })
)(CartItemListView)

CartItemList.Type = Type

export default CartItemList
