import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Add from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import InfoOutlineIcon from 'pmt-ui/svg-icons/action/info-outline'
import WheatIcon from 'pmt-ui/svg-icons/food/wheat'
import Hidden from 'pmt-ui/Hidden'
import Price from 'pmt-ui/Price'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'

import Button, { ButtonLink } from '../../../components/Button'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import Chip from '../../../components/Chip'
import TipWithIcon from '../../../components/TipWithIcon'

import SubmitBtnText from './SubmitBtnText'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import AlertButton from 'pmt-ui/AlertButton'
import isNil from 'lodash/isNil'
const styles = (theme) => ({
  spaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignItems: {
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
    },
  },
  image: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: 150,
  },
  mediaRestaurantLogoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    height: '150px',
    width: '100%',
  },
  mediaRestaurantLogo: {
    height: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogoContent: {
    display: 'flex',
    alignItems: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    height: '150px',
    width: '100%',
  },
  mediaPlaceholderLogo: {
    height: '80px',
    width: '100%',
    opacity: 0.3,
  },
  name: {
    clear: 'both',
    color: theme.palette.primary.main,
    paddingRight: 24,
  },
  allergen: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
    marginLeft: `-${theme.spacing(1)}px`,
    '& svg': {
      marginRight: `0 !important`,
    },
    textDecoration: 'underline',
  },
  tipAndButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  tipWithIcon: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
      textAlign: 'left',
      marginBottom: theme.spacing(2),
    },
  },
  submitButtonWithText: {
    float: 'right',
  },
  priceContainer: {
    marginTop: theme.spacing(2),
  },
  price: {
    float: 'left',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      margin: 0,
      width: '100%',
    },
  },
  cartModifierContainer: {
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  cartModifierChip: {
    float: 'left',
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 4,
  },
})

const Header = ({
  appConfigPreset,
  fromDialog,
  orderProduct,
  isMenuMode,
  isEditMode,
  restaurantLogo,
  onSubmit,
  classes,
  showDietaryInfoDialog,
  orderProperties,
  saveAlcoholApproval,
  frontSettings,
}) => {
  const btnDisabled = !orderProduct.isValid || !orderProduct.available
  return (
    <React.Fragment>
      <Grid spacing={2} container className={classes.root}>
        {/** PICTURE AREA
         * on desktop we always display this Grid item
         * on mobile, we display it only if there is an image, to avoid an empty block with padding
         */}
        <Hidden smDown={!orderProduct.hasImage}>
          <Grid item xs={12} md={fromDialog ? 2 : 3}>
            {orderProduct.hasImage ? (
              <img className={classes.image} src={orderProduct.image} alt={orderProduct.name} />
            ) : !isEmpty(restaurantLogo) ? (
              <div className={classes.mediaRestaurantLogoContent}>
                <img
                  className={classes.mediaRestaurantLogo}
                  src={restaurantLogo}
                  alt={orderProduct.name}
                />
              </div>
            ) : (
              <div className={classes.mediaPlaceholderLogoContent}>
                <PlaceholderLogo className={classes.mediaPlaceholderLogo} />
              </div>
            )}
          </Grid>
        </Hidden>

        {/* TITLE AREA */}
        <Grid item xs={12} md={fromDialog ? 6 : 5} className={classes.spaceBetween}>
          {/* Name */}
          <TypographyCustom type="247" className={classes.name}>
            {orderProduct.name}
            <div className="u-marginTop10">
              <TypographyCustom type="164" component="p">
                {orderProduct.description}
              </TypographyCustom>
            </div>
          </TypographyCustom>
          {orderProduct.hasAllergens && (
            <ButtonLink
              onClick={showDietaryInfoDialog}
              className={classes.allergen}
              icon={<WheatIcon />}
              label={tr('order.dietary_info.title')}
              color="default"
            />
          )}
          {/* Price */}
          {(!isMenuMode || orderProduct.additionalPrice !== 0) && (
            <TypographyCustom type="247" className={classes.priceContainer}>
              <Price
                value={
                  isMenuMode ? orderProduct.additionalPriceFormatted : orderProduct.priceFormatted
                }
                hasCartModifier={orderProduct.hasCartModifier}
                baseValue={orderProduct.hasCartModifier ? orderProduct.basePriceFormatted : null}
                classes={{ root: classes.price }}
              />
              {orderProduct.hasCartModifier && (
                <div className={classes.cartModifierContainer}>
                  {orderProduct.cartModifiers.list.map((cartModifier, index) => (
                    <CartModifierTag
                      cartModifier={cartModifier}
                      chipElement={
                        <Chip
                          key={index}
                          label={cartModifier.tag}
                          classes={{ root: classes.cartModifierChip }}
                        />
                      }
                      key={index}
                    />
                  ))}
                </div>
              )}
            </TypographyCustom>
          )}
        </Grid>

        {/* RIGHT AREA */}
        <Hidden smDown={!orderProduct.hasOptions || !orderProduct.hasMandatoryOptions}>
          <Grid
            item
            xs={12}
            md={4}
            className={classNames(classes.tipAndButtonContainer, classes.alignItems)}
          >
            <TypographyCustom type="164">
              <TipWithIcon
                classes={{
                  root: classNames(classes.tipWithIcon, 'u-marginTop10', {
                    'u-hide': orderProduct.isValid,
                  }),
                }}
                icon={<InfoOutlineIcon />}
                label={
                  isMenuMode ? (
                    <span>{tr('order.product.options.select_menu')}</span>
                  ) : (
                    <span>{tr('order.product.options.select_cart')}</span>
                  )
                }
              />
            </TypographyCustom>
            <Hidden smDown>
              {frontSettings.display.ageCheckForAlcohol &&
              orderProduct.containsAlcohol &&
              !orderProperties.alcoholApprovalAnswer ? (
                <CustomTextsContainer>
                  {({ texts }) => (
                    <AlertButton
                      alertTitle={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                          : tr('order.cart.age_check_for_alcohol.dialog.title')
                      }
                      alertContent={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                          : tr('order.cart.age_check_for_alcohol.dialog.description')
                      }
                      okLabel={
                        !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                          : tr('order.cart.age_check_for_alcohol.dialog.ok')
                      }
                      cancelLabel={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                          : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                      }
                      button={<Button />}
                      disabled={btnDisabled}
                      onAgree={(e) => {
                        e.stopPropagation()
                        saveAlcoholApproval()
                        onSubmit(e)
                      }}
                      size="large"
                      icon={!isEditMode && <Add />}
                      className={classes.submitButtonWithText}
                    >
                      <SubmitBtnText
                        appConfigPreset={appConfigPreset}
                        orderProduct={orderProduct}
                        isMenuMode={isMenuMode}
                        isEditMode={isEditMode}
                      />
                    </AlertButton>
                  )}
                </CustomTextsContainer>
              ) : (
                <Button
                  disabled={btnDisabled}
                  onClick={onSubmit}
                  size="large"
                  icon={!isEditMode && <Add />}
                  className={classes.submitButtonWithText}
                >
                  <SubmitBtnText
                    appConfigPreset={appConfigPreset}
                    orderProduct={orderProduct}
                    isMenuMode={isMenuMode}
                    isEditMode={isEditMode}
                  />
                </Button>
              )}
            </Hidden>
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  )
}

export default withStyles(styles)(Header)
