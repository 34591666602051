import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { EventManager } from 'pmt-modules/event'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { TypographyCustom } from 'pmt-ui/Typography'

const IrlPayment = ({ setPaymentMethod, orderProperties, orderAppConfig, classes }) => (
  <FormControlLabel
    onClick={() => {
      EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
        paymentMode: PaymentMethodsAllowed.IRL,
      })
      setPaymentMethod(PaymentMethodsAllowed.IRL)
    }}
    control={
      <Radio
        color="primary"
        name="paymentOnSite"
        checked={orderProperties.payment.isIrlMethod}
        value="paymentOnSite"
      />
    }
    label={
      <TypographyCustom type={orderProperties.payment.isIrlMethod ? '167' : '164'}>
        {orderProperties.isDelivery
          ? tr('order.payment.cash.label.delivery')
          : orderProperties.isOnSite && orderAppConfig.hasTableNumber
            ? tr('order.payment.cash.label.on_site')
            : tr('order.payment.cash.label.take_away')}
      </TypographyCustom>
    }
  />
)

export default IrlPayment
