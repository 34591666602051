import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import isNil from 'lodash/isNil'

import { getAppConfig } from 'pmt-modules/appConfig'
import { authenticateUser, getAuthError, isFetchingAuth } from 'pmt-modules/auth'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { registerAsIncognito } from 'pmt-modules/registration'
import { isFetchingUserMe } from 'pmt-modules/userMe'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LabelDivider from 'pmt-ui/LabelDivider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button, { ButtonLink } from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  switchView: {
    clear: 'both',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  signUpButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  connectButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class AuthenticationChoiceForm extends React.PureComponent {
  render() {
    const {
      classes,
      authError,
      isFetchingAuth,
      isFetchingUserMe,
      isFetchingOrderPost,
      orderPostError,
      onToggleView,
      allowIncognito,
    } = this.props

    return (
      <React.Fragment>
        <LoadingBlockWrapper show={isFetchingAuth || isFetchingUserMe || isFetchingOrderPost} />

        <ErrorBlock
          error={authError || orderPostError}
          mode={ErrorBlock.Mode.CUSTOM}
          customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
        />

        <TypographyCustom className="u-marginTop20" type="284">
          {tr('order.login.register')}
        </TypographyCustom>

        <div className={classes.switchView}>
          <Button
            className={classes.signUpButton}
            size="large"
            onClick={() => onToggleView(LoginViews.REGISTER)}
            label={tr('order.login.create_account')}
          />
          {allowIncognito && (
            <React.Fragment>
              <div className={classes.allowIncognitoButton}>
                <CustomTextsContainer>
                  {({ texts }) => (
                    <ButtonLink
                      component="a"
                      onClick={(e) => {
                        e.stopPropagation()
                        onToggleView(LoginViews.INCOGNITO)
                      }}
                      label={
                        !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                          ? texts.ORDER__INCOGNITO__LINK
                          : tr('global.register.continue_as_incognito.from_NORMAL.link')
                      }
                    />
                  )}
                </CustomTextsContainer>
              </div>
            </React.Fragment>
          )}
        </div>

        <LabelDivider label={tr('order.login.or')} className="u-marginTop50" />

        <TypographyCustom className="u-marginTop30" type="284">
          {tr('order.login.already_customer')}
        </TypographyCustom>

        <div className={classes.switchView}>
          <Button
            className={classes.connectButton}
            variant="outlined"
            size="large"
            onClick={() => onToggleView(LoginViews.LOGIN)}
            label={tr('order.login.connect_button')}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  isFetchingAuth: isFetchingAuth(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingUserMe: isFetchingUserMe(state),
  orderPostError: getErrorOrderPost(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    authenticateUser,
    registerAsIncognito,
  })
)(AuthenticationChoiceForm)
