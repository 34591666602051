export default {
  // for key we use the `ENV` from our config
  DEV: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-2',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev',
        }
      },
       */

      // GA4
      {
        trackingId: 'G-L9YBTZ64Q0',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev_ga4',
        },
      },
    ],
    /*
      options.nonce	string Optional Used for Content Security Policy (CSP) more
      options.testMode	boolean Default false
      options.gaOptions	object Optional Reference
      options.gtagOptions	object Optional
      options.legacyDimensionMetric	boolean Default true
      
      */
    options: {
      gtagOptions: { debug_mode: true },
    },
  },
  PQA: {
    identifiers: [],
  },
  PREPROD: {
    identifiers: [
      // UA (old)
      /*
      {
        trackingId: 'UA-105853425-3',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod',
        },
      },
       */

      // GA4
      {
        trackingId: 'G-W6X2SYE3D1',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod_ga4',
        },
      },
    ],
    options: {},
  },
  PROD: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-1',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod',
        },
      },
       */

      // GA4
      {
        trackingId: 'G-62L7Z7VPB0',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod_ga4',
        },
      },
    ],
    options: {},
  },
}
