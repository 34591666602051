// import manually the language files we need
// TODO: do this dynamically
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/es'

export default {
  defaultLocale: 'en',
  locales: ['fr', 'en', 'de', 'it', 'es'],
  calendar: false, // true if we use flatpickr
}
