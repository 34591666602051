import React, { Fragment } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  allergens: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  allergen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 1, 0, 0),

    '& > svg': {
      fontSize: 16,
    },
  },
  subRow: {
    backgroundColor: theme.pmt.colors.greyBackground,
  },
  rowTextPrimary: {
    fontWeight: 'bold',
  },
}))

/**
 * @specs N/A
 */
const DietaryInfoDialog = ({ item, allergens }) => {
  const classes = useStyles()

  const renderItem = ({ item, allergens, depth = 1 }) =>
    item.hasAllergens && (
      <Fragment>
        <List>
          {/* item */}
          {renderRow({ item, allergens, depth })}

          {(item.hasOptionWithAllergens || item.hasPartWithAllergens) && <Divider />}

          {/* options */}
          {(item.options || []).map(
            option =>
              option.hasAllergens &&
              (option.values && (
                <Fragment>
                  <Divider />
                  <List disablePadding>
                    <ListItem disableRipple className={classes.subRow}>
                      <ListItemText primary={option.name} />
                    </ListItem>
                    <List disablePadding dense>
                      {(option.values || []).map(v =>
                        renderRow({ allergens, item: v, depth: depth + 2 })
                      )}
                    </List>
                  </List>
                </Fragment>
              ))
          )}

          {/* parts */}
          {item.parts &&
            (item.parts || []).map(
              part =>
                part.hasAllergens && (
                  <Fragment>
                    <Divider />
                    <List key={`parts-${part.id}`} disablePadding dense>
                      <ListItem disableRipple className={classes.subRow}>
                        <ListItemText primary={part.name} />
                      </ListItem>
                      {(part.products || []).map(product =>
                        renderItem({ allergens, item: product, depth: depth + 1 })
                      )}

                      {/* part categories */}
                      {renderPartCategory({
                        classes,
                        categories: part.categories,
                        depth: depth + 1,
                      })}
                    </List>
                  </Fragment>
                )
            )}
        </List>
        <Divider />
      </Fragment>
    )

  const renderPartCategory = ({ categories, depth = 2 }) =>
    categories &&
    categories.map(category => (
      <Fragment>
        {(category.products || []).map(item => renderItem({ allergens, item, depth }))}
        {(category.categories || []).map(subCategory =>
          renderPartCategory({ categories: subCategory.categories, depth })
        )}
      </Fragment>
    ))

  const renderRow = ({ allergens, item, depth }) =>
    !isEmpty(get(item, 'dietaryInfo.allergens', [])) && (
      <ListItem style={{ paddingLeft: 4 * depth * 2 }}>
        <ListItemText
          key={item.id}
          classes={{ primary: classes.rowTextPrimary }}
          primary={item.name}
          secondary={
            <div className={classes.allergens}>
              {allergens.map(
                a =>
                  (item?.dietaryInfo.allergens || []).indexOf(a.value) >= 0 && (
                    <div className={classes.allergen}>
                      {a.icon} {a.label}
                    </div>
                  )
              )}
            </div>
          }
        />
      </ListItem>
    )

  return renderItem({ item, allergens })
}

export default DietaryInfoDialog
