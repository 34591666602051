import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import { StickyContainer } from 'pmt-ui/Sticky'
import { TypographyCustom } from 'pmt-ui/Typography'
import LoadingBlock from 'pmt-ui/LoadingBlock'

import Header from './components/Header'
import PartsView from './components/PartsView'
import LeftArea from './components/LeftArea'
import Actions from './components/LeftArea/Actions'

import BannerView from '../../components/BannerView'
import BlockContainer from '../../components/BlockContainer'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  blockContainer: {
    width: '100%',
    padding: 0,
  },
  body: {
    backgroundColor: theme.pmt.colors.greyBackground,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      padding: `0 0 ${theme.spacing(4)}px 0`,
    },
  },
  // only on mobile
  composeYourMenu: {
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    fontSize: 14,
  },
  textFooter: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    whiteSpace: 'pre-line', // to display \n as new lines
    lineHeight: '1.5',
    '&> a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
})

/**
 * https://app.zeplin.io/project/5a68b6a766a4c4334877beda/screen/5a699c8a74384ff7e7596062
 */
const View = ({
  appConfigPreset,
  classes,
  orderMenu,
  restaurant,
  isEditMode,
  isFromCart,
  onSelectOrderProduct,
  onUnselectOrderProduct,
  onEditOrderProduct,
  onAddToCartFromShortcut,
  onRedirectToPreviousPage,
  onReturnToPreviousPartCategory,
  onSelectCategory,
  onSubmit,
  isFetchingAppConfig,
  isFetchingCatalog,
  isFetchingRestaurant,
  catalog,
  showDietaryInfoDialog,
  menu,
}) => (
  <div className={classes.root}>
    <BlockContainer type={BlockContainer.Type.WHITE} classes={{ root: classes.blockContainer }}>
      {isFetchingAppConfig ||
      isFetchingCatalog ||
      isFetchingRestaurant ||
      isNil(catalog) ||
      !orderMenu ? (
        <LoadingBlock
          show
          classes={{
            loadingBlock: classNames('u-padding20', classes.loadingBlock),
          }}
        />
      ) : (
        <React.Fragment>
          <BannerView onRedirectToPreviousPage={onRedirectToPreviousPage} isFromCart={isFromCart} />
          <Header
            appConfigPreset={appConfigPreset}
            orderMenu={orderMenu}
            restaurantLogo={restaurant.logo}
            isFromCart={isFromCart}
            isEditMode={isEditMode}
            onSubmit={(orderMenu) => {
              EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_TOP, {
                item: orderMenu,
              })
              onSubmit(orderMenu)
            }}
            showDietaryInfoDialog={showDietaryInfoDialog}
          />
          <StickyContainer>
            <Grid spacing={2} container className={classes.body}>
              <Hidden smDown>
                <Grid item md={3}>
                  <LeftArea
                    appConfigPreset={appConfigPreset}
                    orderMenu={orderMenu}
                    isEditMode={isEditMode}
                    onSubmit={(orderMenu) => {
                      EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_STICKY, {
                        item: orderMenu,
                      })
                      onSubmit(orderMenu)
                    }}
                  />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item sm={12}>
                  <div className={classes.composeYourMenu}>{tr('order.menu.side_menu.title')}</div>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={9}>
                <PartsView
                  orderMenu={orderMenu}
                  restaurant={restaurant}
                  onSelectOrderProduct={onSelectOrderProduct}
                  onUnselectOrderProduct={onUnselectOrderProduct}
                  onEditOrderProduct={onEditOrderProduct}
                  onAddToCartFromShortcut={onAddToCartFromShortcut}
                  onSelectCategory={onSelectCategory}
                  onReturnToPreviousPartCategory={onReturnToPreviousPartCategory}
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={12} md={9}>
                  <Actions
                    appConfigPreset={appConfigPreset}
                    orderMenu={orderMenu}
                    onSubmit={(orderMenu) => {
                      EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_BOTTOM, {
                        item: orderMenu,
                      })
                      onSubmit(orderMenu)
                    }}
                    isEditMode={isEditMode}
                    forMobile
                  />
                </Grid>
              </Hidden>
              <CustomTextsContainer>
                {({ texts }) =>
                  !isNil(texts) &&
                  !isNil(texts.ORDER__CATALOG__MENU__FOOTER) && (
                    <TypographyCustom
                      type="124"
                      className={classNames(
                        'custom-css-ORDER__CATALOG__MENU__FOOTER custom-css-ORDER__CATALOG__MENU__FOOTER-on-menu',
                        classes.textFooter
                      )}
                      html={texts.ORDER__CATALOG__MENU__FOOTER} // we allow html so they can put links
                    />
                  )
                }
              </CustomTextsContainer>
            </Grid>
          </StickyContainer>
        </React.Fragment>
      )}
    </BlockContainer>
  </div>
)

export default withStyles(styles)(View)
