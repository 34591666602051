import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'

import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'
import { CardItem } from 'pmt-ui/Card'
import { CardItemOrder } from 'pmt-ui/Card/components/CardItem'
import Snackbar from 'pmt-ui/Snackbar'
import DoneIcon from 'pmt-ui/svg-icons/action/done'
import AlertButton from 'pmt-ui/AlertButton'
import isNil from 'lodash/isNil'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import Button from '../../../../components/Button'

const styles = (theme) => {
  const cardItemDefaultStyles = CardItemOrder.defaultStyles(theme)
  return {
    mediaInactive: {
      ...cardItemDefaultStyles.mediaInactive,
    },
    mediaPlaceholder: {
      backgroundColor: theme.pmt.colors.itemPlaceholderBackgroundSecondary,
    },
  }
}

function onBtnClick(
  product,
  onUnselectOrderProduct,
  isSelectable,
  orderMenu,
  restaurant,
  onSelectOrderProduct,
  onAddToCartFromShortcut
) {
  if (product.available) {
    if (product.quantity > 0) {
      onUnselectOrderProduct()
    } else if (isSelectable) {
      if (product.isComplexItem) {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ITEM_DETAIL, {
          item: product,
          menu: orderMenu,
          restaurant,
        })
        if (product.isProduct) {
          onSelectOrderProduct()
        }
      } else {
        onAddToCartFromShortcut()
      }
    }
  }
}

class ProductItem extends React.PureComponent {
  handleOnClickDisabledOption = () => {
    this.props.updateUI({ openedSnackbar: true })
  }

  handleClose = () => {
    this.props.updateUI({ openedSnackbar: false })
  }

  render() {
    const {
      product,
      orderMenu,
      restaurant,
      // true if the product can't be selected
      // (part hasReachedMaxQuantity)
      isSelectable,
      classes,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onEditOrderProduct,
      onAddToCartFromShortcut,
      ui,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    const isSelectableAndDisabled = product.available && !isSelectable && product.quantity === 0

    const btnLabel = tr(
      product.quantity === 0
        ? 'order.menu.select'
        : product.totalPrice > 0
        ? 'order.menu.selected_with_price_with_plus'
        : 'order.menu.selected',
      {
        price: product.totalPriceFormatted,
      }
    )
    return (
      <React.Fragment>
        <CardItem
          type={<CardItemOrder />}
          item={product}
          classes={{
            media: classNames({}),
            mediaPlaceholder: classes.mediaPlaceholder,
          }}
          description={product.description}
          image={product.image}
          restaurantLogo={restaurant.logo}
          name={product.name}
          price={product.additionalPrice !== 0 ? `+${product.additionalPriceFormatted}` : null}
          isAvailable={product.available}
          isSelectableAndDisabled={isSelectableAndDisabled}
          isCategory={product.isCategory}
          onClickCard={() => {
            if (isSelectableAndDisabled) {
              this.handleOnClickDisabledOption()
            } else if (product.available) {
              EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ITEM_DETAIL, {
                item: product,
                menu: orderMenu,
                restaurant,
              })
              if (product.quantity > 0) {
                onEditOrderProduct()
              } else if (isSelectable) {
                onSelectOrderProduct()
              }
            }
          }}
          buttonAdd={
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (isSelectableAndDisabled) {
                  this.handleOnClickDisabledOption()
                }
              }}
            >
              {frontSettings.display.ageCheckForAlcohol &&
              product.containsAlcohol &&
              !orderProperties.alcoholApprovalAnswer ? (
                <CustomTextsContainer>
                  {({ texts }) => (
                    <AlertButton
                      alertTitle={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                          : tr('order.cart.age_check_for_alcohol.dialog.title')
                      }
                      alertContent={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                          : tr('order.cart.age_check_for_alcohol.dialog.description')
                      }
                      okLabel={
                        !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                          : tr('order.cart.age_check_for_alcohol.dialog.ok')
                      }
                      cancelLabel={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                          : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                      }
                      button={<Button />}
                      label={btnLabel}
                      onAgree={(e) => {
                        e.stopPropagation()
                        saveAlcoholApproval()
                        onBtnClick(
                          product,
                          onUnselectOrderProduct,
                          isSelectable,
                          orderMenu,
                          restaurant,
                          onSelectOrderProduct,
                          onAddToCartFromShortcut
                        )
                      }}
                      size="small"
                      variant={product.quantity === 0 ? 'outlined' : 'contained'}
                      disabled={isSelectableAndDisabled}
                      icon={product.quantity > 0 && <DoneIcon />}
                    />
                  )}
                </CustomTextsContainer>
              ) : (
                <Button
                  size="small"
                  variant={product.quantity === 0 ? 'outlined' : 'contained'}
                  disabled={isSelectableAndDisabled}
                  icon={product.quantity > 0 && <DoneIcon />}
                  onClick={(e) => {
                    e.stopPropagation()
                    onBtnClick(
                      product,
                      onUnselectOrderProduct,
                      isSelectable,
                      orderMenu,
                      restaurant,
                      onSelectOrderProduct,
                      onAddToCartFromShortcut
                    )
                  }}
                  label={btnLabel}
                />
              )}
            </div>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={ui.openedSnackbar}
          autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{tr('global.menu.product.disabled')}</span>}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  ui({
    state: {
      openedSnackbar: false,
    },
  }),
  withStyles(styles)
)(ProductItem)
