import React from 'react'
import PropTypes from 'prop-types'
import EventListener from 'react-event-listener'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import Hidden from 'pmt-ui/Hidden'
import { withStyles } from 'pmt-ui/styles'
import ArrowRightIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-right'
import ArrowLeftIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-left'

const styles = theme => ({
  root: {
    width: '100%',
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    float: 'left',
    width: `calc(100% - ${theme.spacing(10)}px)`,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing(6)}px)`,
    },
  },
  elementContainer: {
    position: 'absolute',
    height: '100%',
    transition: 'left 0.2s',
  },
  tab: {
    position: 'relative',
    float: 'left',
    width: 'auto',
    maxWidth: 182,
    padding: `0 ${theme.spacing(3)}px`,
    height: '100%',
    lineHeight: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    whiteSpace: 'normal',
    cursor: 'pointer',
  },
  borderBottom: {
    position: 'absolute',
    width: '100%',
    height: 3,
    bottom: 0,
    right: 0,
    left: 0,
    background: theme.palette.primary.main,
  },
  arrowContainer: {
    float: 'left',
    height: '100%',
    width: theme.spacing(5),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(3),
    },
  },
  arrow: {
    width: '100%',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    width: 40,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
    },
  },
})

class Carousel extends React.Component {
  state = {
    initialSelection: false,
    // the div that contains the elements
    containerMeta: {
      // containes datas of the div (width, height, left, right from viewport)
      boundingClientRect: null,
      // the left value applied to the container (used to "scroll")
      left: 0,
    },
    // used to display or not the arrows icons
    scrollButtons: {
      showLeft: false,
      showRight: false,
    },
    // used to handle the swipe movement (for mobile)
    swipeDirection: {
      start: 0,
      end: 0,
      left: false,
      right: false,
      // minimum width to trigger the functions that handle the swipe gesture
      minimum: 50,
    },
    // number of elements in carousel
    numberOfElement: null,
    // index of the selected element
    selectedElementIndex: null,
    // addition of element's width to have the total width
    totalWidth: null,
    initialRender: true,
  }

  elements = []

  componentDidMount() {
    this.setState({
      numberOfElement: this.props.elements.length,
    })
    this.updateElementsWidth()
    this.updateContainerBoundingClientRect()
  }

  componentWillReceiveProps(nextProps) {
    // we reset the elements array if we receive a new array of elements on props
    if (!isEmpty(this.elements) && !isEqual(this.props.elements, nextProps.elements)) {
      this.elements = []
    }

    if (nextProps.selectedElement.id !== this.props.selectedElement.id) {
      this.handleOnClickElement(this.getIndexOfElement(nextProps.selectedElement))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      this.updateElementsWidth()
      this.updateSelectedElement()
      this.updateScrollButtonState()
    }

    if (!this.state.initialSelection) {
      this.handleOnClickElement(this.getIndexOfElement(this.props.selectedElement))
      this.setState({
        initialSelection: true,
      })
    }
  }

  getMetaContainerWidth = () =>
    this.state.containerMeta.boundingClientRect
      ? Math.floor(this.state.containerMeta.boundingClientRect.width)
      : 0

  getWidthToElement = indexElement => {
    let width = 0
    if (this.state.elements) {
      this.state.elements.forEach((elementWidth, index) => {
        if (index < indexElement) {
          width += elementWidth
        }
      })
    }

    return width
  }

  getIndexOfElement = element => {
    return this.props.elements.map(elementFromProps => elementFromProps.id).indexOf(element.id)
  }

  updateSelectedElement = () => {
    const selectedElementIndex = this.props.elements
      .map(element => element.id)
      .indexOf(this.props.selectedElement.id)

    this.setState({
      selectedElementIndex,
    })
  }

  updateContainerBoundingClientRect = () => {
    if (!isNil(this.container)) {
      this.setState({
        containerMeta: {
          ...this.state.containerMeta,
          boundingClientRect: this.container.getBoundingClientRect(),
        },
      })
    }
  }

  updateElementsWidth = () => {
    let elementsWidth = []
    let totalWidth = 0

    this.elements.forEach(element => {
      if (!isNil(element)) {
        let elementMeta = element.getBoundingClientRect()
        elementsWidth.push(elementMeta.width)
        totalWidth += elementMeta.width
      }
    })

    this.setState({
      elements: elementsWidth,
      // we add 1 pixel to be sur that the last element is well displayed
      totalWidth: Math.floor(totalWidth + 3),
    })
  }

  updateContainerLeftValue = (left, negative = false, override = false) => {
    let leftTemp = negative ? -1 * left : left
    leftTemp = override ? leftTemp : this.state.containerMeta.left + leftTemp

    this.setState({
      containerMeta: {
        ...this.state.containerMeta,
        left: Math.floor(leftTemp),
      },
    })
  }

  updateScrollButtonState = () => {
    let showLeft = false
    let showRight = false

    const { containerMeta, totalWidth } = this.state

    if (containerMeta.left > 0) {
      showLeft = true
    }

    const totalLeft = containerMeta.left > 0 ? containerMeta.left : 0
    if (totalLeft + this.getMetaContainerWidth() < totalWidth) {
      showRight = true
    }

    this.setState({
      scrollButtons: {
        showLeft,
        showRight,
      },
    })
  }

  setFocusedElementBackInView = () => {
    const widthToSelectedElement = this.getWidthToElement(this.state.selectedElementIndex)
    let leftTemp = 0
    let applyLeft = false

    // if the focused element is behind the container view (left)
    if (widthToSelectedElement < this.state.containerMeta.left) {
      leftTemp = widthToSelectedElement
      applyLeft = true
      // if the focused element is after the container view (right)
    } else if (
      widthToSelectedElement + this.state.elements[this.state.selectedElementIndex] >
      this.getMetaContainerWidth() + this.state.containerMeta.left
    ) {
      leftTemp =
        this.state.containerMeta.left +
        (this.state.totalWidth - (this.state.containerMeta.left + this.getMetaContainerWidth()))
      applyLeft = true
    }

    // if the user is changing the size of container
    // we should lower or raise the left value applied to the container so we can keep displaying the selected one
    if (applyLeft) {
      this.setState({
        containerMeta: {
          ...this.state.containerMeta,
          left: leftTemp > 0 ? leftTemp : 0,
        },
      })
    }
  }

  // this function is used to fill the blank (in case the user raise the width of viewport)
  updateContainerLeftPosition = () => {
    if (this.getMetaContainerWidth() > this.state.totalWidth - this.state.containerMeta.left) {
      const leftTemp =
        this.state.containerMeta.left -
        Math.abs(
          this.state.totalWidth - (this.state.containerMeta.left + this.getMetaContainerWidth())
        )
      this.setState({
        containerMeta: {
          ...this.state.containerMeta,
          left: leftTemp > 0 ? leftTemp : 0,
        },
      })

      return true
    }
    return false
  }

  handleResize = debounce(() => {
    this.updateContainerBoundingClientRect()
    if (!this.updateContainerLeftPosition()) {
      this.setFocusedElementBackInView()
    }
    this.updateScrollButtonState()
  }, this.props.debounceCounter)

  moveTabsScroll = (restToScroll, containerWidth, multiplier) => {
    if (restToScroll < containerWidth) {
      this.updateContainerLeftValue(multiplier * restToScroll)
    } else {
      this.updateContainerLeftValue(multiplier * containerWidth)
    }
  }

  handleLeftScrollClick = () => {
    if (this.container) {
      const { containerMeta } = this.state

      const restToScroll = containerMeta.left
      const containerWidth = this.getMetaContainerWidth()

      this.moveTabsScroll(restToScroll, containerWidth, -1)
    }
  }

  handleRightScrollClick = () => {
    if (this.container) {
      const { containerMeta, totalWidth } = this.state

      const containerElementLength = totalWidth
      const containerWidth = this.getMetaContainerWidth()
      const restToScroll = containerElementLength - (containerMeta.left + containerWidth)

      this.moveTabsScroll(restToScroll, containerWidth, 1)
    }
  }

  handleOnClickElement = index => {
    const leftPart = this.getWidthToElement(index)
    const rightPart = this.getWidthToElement(index + 1)
    const centerPart = (rightPart - leftPart) / 2 + leftPart

    const metaContainerWidth = this.getMetaContainerWidth()
    const leftValue = centerPart - metaContainerWidth / 2
    const maxLeftValue = this.state.totalWidth - metaContainerWidth

    if (metaContainerWidth > 0) {
      if (leftValue >= 0 && leftValue <= maxLeftValue) {
        this.updateContainerLeftValue(leftValue, false, true)
      } else {
        if (leftValue < 0) {
          this.updateContainerLeftValue(0, false, true)
        } else {
          this.updateContainerLeftValue(maxLeftValue, false, true)
        }
      }
    }
  }

  handleTouchStart = e => {
    const touch = e.touches[0]
    if (!isUndefined(touch)) {
      this.setState({
        swipeDirection: {
          ...this.state.swipeDirection,
          start: touch.clientX,
        },
      })
    }
  }

  handleTouchEnd = e => {
    const touch = e.changedTouches[0]
    if (!isUndefined(touch)) {
      this.setState({
        swipeDirection: {
          ...this.state.swipeDirection,
          end: touch.clientX,
        },
      })

      const difference = Math.abs(this.state.swipeDirection.start - touch.clientX)
      if (difference > this.state.swipeDirection.minimum) {
        // swipe to the left (wants to see what is on the right)
        if (this.state.swipeDirection.start > touch.clientX) {
          this.handleRightScrollClick()
          // swipe to the right (wants to see what is on the left)
        } else {
          this.handleLeftScrollClick()
        }
      }
    }
  }

  render() {
    const { elements, selectedElement, getLabel, getValue, onChange, classes } = this.props

    return (
      <div className={classes.root}>
        <EventListener target="window" onResize={this.handleResize} />
        <div className={classes.arrowContainer}>
          {this.state.scrollButtons.showLeft && (
            <div className={classes.arrow} onClick={this.handleLeftScrollClick}>
              <ArrowLeftIcon className={classes.icon} />
            </div>
          )}
        </div>
        <div
          className={classes.container}
          ref={node => {
            this.container = node
          }}
        >
          <div
            className={classes.elementContainer}
            style={{
              width: `${this.state.totalWidth}px`,
              left: `-${this.state.containerMeta.left}px`,
            }}
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
          >
            {elements.map((element, index) => (
              <div
                key={index}
                onClick={() => {
                  this.handleOnClickElement(index)
                  onChange(getValue(element))
                }}
                className={classes.tab}
                ref={node => {
                  this.elements[index] = node
                }}
              >
                {getLabel(element)}
                <Hidden xsUp={selectedElement.id !== element.id}>
                  <div className={classes.borderBottom} />
                </Hidden>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.arrowContainer}>
          {this.state.scrollButtons.showRight && (
            <div className={classes.arrow} onClick={this.handleRightScrollClick}>
              <ArrowRightIcon className={classes.icon} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

Carousel.defaultProps = {
  debounceCounter: 166,
}

Carousel.propTypes = {
  // this array must contains object that has an "id" property
  elements: PropTypes.array,
  getLabel: PropTypes.func,
  getValue: PropTypes.func,
  onChange: PropTypes.func,
  selectedElement: PropTypes.object,
  classes: PropTypes.object,
  // the time between we throw the handle resize's function
  debounceCounter: PropTypes.number,
}

export default withStyles(styles)(Carousel)
