import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { tr } from 'pmt-modules/i18n'
import isNil from 'lodash/isNil'

import { authenticateUser, getAuthError, isFetchingAuth } from 'pmt-modules/auth'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { getRegisterError, isFetchingRegister, registerAsIncognito } from 'pmt-modules/registration'
import { isFetchingUserMe } from 'pmt-modules/userMe'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import Ask from 'pmt-ui/svg-icons/action/ask'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LabelDivider from 'pmt-ui/LabelDivider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import { FormType, withForm } from 'pmt-modules/form'
import { IncognitoFormView } from 'pmt-modules/registration/forms/incognito'
import { AuthMode } from 'pmt-modules/auth/constants'

import Button, { ButtonLink } from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(4),
    float: 'right',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  backLink: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  buttonsGrid: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  onSmallScreenOnly: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  onBigScreenOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      signUpButton: {
        marginTop: theme.spacing(4),
        float: 'right',
      },
    },
  },
  caption: {
    display: 'flex',
    color: theme.pmt.status.inactive,
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  captionTitle: {
    marginBottom: theme.spacing(1),
  },
  iconAsk: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  signUpButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      float: 'right',
    },
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  connectButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class IncognitoForm extends React.PureComponent {
  handleKeepIncognito = () => {
    this.props.registerAsIncognito(this.props.formData)
  }

  render() {
    const {
      classes,
      authError,
      formIsValid,
      isFetchingRegister,
      isFetchingAuth,
      isFetchingUserMe,
      isFetchingOrderPost,
      orderPostError,
      registerError,
      onToggleView,
      cguLabel,
      authMode,
      customTexts,
    } = this.props

    let title = ''
    let btnLabel = ''
    let captionTitle = ''
    let captionText = ''
    // default labels
    if (authMode === AuthMode.NORMAL) {
      title = tr('global.register.continue_as_incognito.from_NORMAL.title')
      btnLabel = tr('global.register.continue_as_incognito.from_NORMAL.btnLabel')
    } else if (authMode === AuthMode.LIGHT) {
      title = tr('global.register.continue_as_incognito.from_LIGHT.title')
      btnLabel = tr('global.register.continue_as_incognito.from_LIGHT.btnLabel')
      captionTitle = tr('global.register.continue_as_incognito.from_LIGHT.captionTitle')
      captionText = tr('global.register.continue_as_incognito.from_LIGHT.captionText')
    } else {
      title = tr('global.register.continue_as_incognito.from_INCOGNITO_ONLY.title')
      btnLabel = tr('global.register.continue_as_incognito.from_INCOGNITO_ONLY.btnLabel')
      captionTitle = tr('global.register.continue_as_incognito.from_INCOGNITO_ONLY.captionTitle')
      captionText = tr('global.register.continue_as_incognito.from_INCOGNITO_ONLY.captionText')
    }
    // override with custom texts
    if (!isNil(customTexts)) {
      if (!isNil(customTexts.ORDER__INCOGNITO__TITLE)) {
        title = customTexts.ORDER__INCOGNITO__TITLE
      }
      if (!isNil(customTexts.ORDER__INCOGNITO__SUBMIT_BUTTON)) {
        btnLabel = customTexts.ORDER__INCOGNITO__SUBMIT_BUTTON
      }
      if (!isNil(customTexts.ORDER__INCOGNITO__CAPTION_TITLE)) {
        captionTitle = customTexts.ORDER__INCOGNITO__CAPTION_TITLE
      }
      if (!isNil(customTexts.ORDER__INCOGNITO__CAPTION_TEXT)) {
        captionText = customTexts.ORDER__INCOGNITO__CAPTION_TEXT
      }
    }

    const withCaption = captionTitle || captionText

    return (
      <React.Fragment>
        {title && (
          <Grid item xs={12} md={5} lg={5}>
            <TypographyCustom className={classes.title} type="284">
              {title}
            </TypographyCustom>
          </Grid>
        )}

        <LoadingBlockWrapper
          show={isFetchingRegister || isFetchingAuth || isFetchingUserMe || isFetchingOrderPost}
        />

        <Grid spacing={2} container>
          {withCaption && (
            <>
              <Grid item md={5}>
                <div className={classes.caption}>
                  <Hidden smDown>
                    <Ask className={classes.iconAsk} />
                  </Hidden>
                  <div className={classes.captionWrapper}>
                    {captionTitle && (
                      <TypographyCustom type="167" className={classes.captionTitle} skipColor>
                        {captionTitle}
                      </TypographyCustom>
                    )}
                    {captionText && (
                      <TypographyCustom type="144" skipColor>
                        {captionText}
                      </TypographyCustom>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item md={1} />
            </>
          )}

          <Grid item xs={12} md={5} lg={5}>
            {!isNil(orderPostError || registerError || authError) && (
              <ErrorBlock
                error={orderPostError || registerError || authError}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />
            )}

            <Grid container>
              <Grid item md={11}>
                <IncognitoFormView cguLabel={cguLabel} />
              </Grid>

              <Grid container className={classes.buttonsGrid}>
                <Grid item lg={4}>
                  <Button
                    disabled={!formIsValid}
                    classes={{ root: classes.button }}
                    size="large"
                    onClick={() => this.handleKeepIncognito()}
                    label={btnLabel}
                  />
                </Grid>

                {/* we display the buttons/link to go back to LOGIN/REGISTER  */}

                {authMode === AuthMode.NORMAL && (
                  <>
                    <Hidden smUp>
                      {/* on mobile / small screens, we display big buttons to go to LOGIN or REGISTER views */}

                      <LabelDivider label={tr('order.login.or')} className="u-marginTop20" />

                      <Button
                        className={classes.signUpButton}
                        variant="outlined"
                        size="large"
                        onClick={() => onToggleView(LoginViews.REGISTER)}
                        label={tr('order.login.create_account')}
                      />

                      <LabelDivider label={tr('order.login.or')} className="u-marginTop20" />

                      <Button
                        className={classes.connectButton}
                        variant="outlined"
                        size="large"
                        onClick={() => onToggleView(LoginViews.LOGIN)}
                        label={tr('order.login.connect_button')}
                      />
                    </Hidden>

                    <Hidden smDown>
                      {/* on desktop / big screen, we display a tiny link to go back to LOGIN/REGISTER view */}
                      <Grid item lg={8}>
                        <div className={classes.backLink}>
                          <ButtonLink
                            component="a"
                            onClick={(e) => {
                              e.stopPropagation()
                              onToggleView(LoginViews.CHOICE_TO_DO)
                            }}
                            label={tr('order.login.login_or_register')}
                          />
                        </div>
                      </Grid>
                    </Hidden>
                  </>
                )}

                {authMode === AuthMode.LIGHT && (
                  <>
                    {/* we display a tiny link to go back to USER_LIGHT view */}
                    <div className={classes.backLink}>
                      <ButtonLink
                        component="a"
                        onClick={(e) => {
                          e.stopPropagation()
                          onToggleView(LoginViews.USER_LIGHT)
                        }}
                        label={tr('order.login.back_to_user_light')}
                      />
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  isFetchingAuth: isFetchingAuth(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingUserMe: isFetchingUserMe(state),
  isFetchingRegister: isFetchingRegister(state),
  orderPostError: getErrorOrderPost(state),
  registerError: getRegisterError(state),
})

export default compose(
  withStyles(styles),
  withForm(FormType.INCOGNITO),
  connect(mapStateToProps, {
    authenticateUser,
    registerAsIncognito,
  })
)(IncognitoForm)
