import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'

import { AuthMode } from 'pmt-modules/auth/constants'
import { getIncognitoCookie } from 'pmt-modules/auth/utils'
import { AppConfigContainer } from 'pmt-modules/appConfig'
import RoutingContainer from 'pmt-modules/routing/components/RoutingContainer'
import { withUserMe } from 'pmt-modules/userMe'

import { TypographyCustom } from 'pmt-ui/Typography'
import { makeStyles } from 'pmt-ui/styles'
import NavigationArrowBack from 'pmt-ui/svg-icons/navigation/arrow-back'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import Address from './components/Address'
import BlockContainer from '../BlockContainer'
import ChangeButton from './components/ChangeButton'
import DueDate from './components/DueDate'
import TableNumber from './components/TableNumber'
import OrderModeSelect from './components/OrderModeSelect'
import OrderModeText from './components/OrderModeText'
import User from './components/User'
import UserLight from './components/UserLight'
import Incognito from './components/Incognito'

const useStyles = makeStyles((theme) => ({
  hiddenHeader: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-2),
    },
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      boxShadow: theme.shadows[2],
      zIndex: 1,
    },
  },
  topLevel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    height: 40,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'normal',
    minWidth: 0,
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  modeText: {
    height: 24,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },
  separator: {
    margin: `0 ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: `-${theme.spacing(0.5)}px`,
    marginRight: `${theme.spacing(1)}px`,
    textAlign: 'center',
    color: theme.palette.primary.main,
    float: 'left',
    cursor: 'pointer',
  },
  changeButton: {
    marginLeft: theme.spacing(2),
  },
  logoArea: {
    float: 'left',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: 32,
    },
  },
  restaurantLogo: {
    maxWidth: 100,
    maxHeight: 70,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 32,
    },
  },
  user: {
    flex: 1,
  },
  dueDate: {
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
}))

function ConditionalUserBlock(props) {
  if (getIncognitoCookie()) {
    // we allow the user to remove its incognito mode
    // so he will be able to connect as real user if he wants afterwards
    return (
      <Incognito
        classes={{
          root: props.classes.user,
        }}
      />
    )
  } else if (props.authenticationMode === AuthMode.NORMAL || !isNil(props.me)) {
    return (
      <User
        classes={{
          root: props.classes.user,
        }}
      />
    )
  } else if (props.authenticationMode === AuthMode.LIGHT) {
    return (
      <UserLight
        classes={{
          root: props.classes.user,
        }}
      />
    )
  }
  return null
}

@withUserMe
class HeaderView extends React.Component {
  render() {
    const {
      appConfig,
      frontAppConfig,
      backLink,
      classes,
      displayAddress,
      changeButton,
      displayDueDate,
      displayTableNumber,
      displayOrderMode,
      displayOrderModeSelect,
      displayRestaurantLogo,
      displayUser,
      displayTitle,
      // here to handle 2 layout on the same page
      orderProperties,
      computeAddress,
      restaurant,
      width,
      isFetchingMe,
      me,
    } = this.props

    if (isFetchingMe) {
      return null
    }

    return (
      <RoutingContainer>
        {({ redirectTo }) => (
          <BlockContainer type={BlockContainer.Type.WHITE} className={classes.root}>
            <div className={classes.topLevel}>
              {/* Back */}
              {backLink && (
                <div
                  className={classes.backArea}
                  onClick={() => (isFunction(backLink) ? backLink() : redirectTo(backLink))}
                >
                  <NavigationArrowBack />
                </div>
              )}

              {displayRestaurantLogo &&
                restaurant &&
                !isNil(restaurant.logo) &&
                (frontAppConfig.theme.logo.display ? (
                  <div className={classes.logoArea}>
                    <img
                      src={`${restaurant.logo}`}
                      className={classes.restaurantLogo}
                      alt={`${restaurant.name}`}
                    />
                  </div>
                ) : null)}

              {(displayTitle ||
                displayOrderModeSelect ||
                displayOrderMode ||
                displayAddress ||
                changeButton) && (
                <div className={classes.leftSide}>
                  {/* TITLE */}
                  {displayTitle && (
                    <TypographyCustom type="287" className={classes.title}>
                      {tr('order.global.header.title')}
                    </TypographyCustom>
                  )}

                  {orderProperties && (
                    <div>
                      {/*
                    We display the mode in select format if props
                  */}
                      {displayOrderModeSelect && (
                        <OrderModeSelect orderProperties={orderProperties} appConfig={appConfig} />
                      )}
                      {/*
                    We display the mode in text format if props
                  */}
                      {displayOrderMode && (
                        <OrderModeText
                          classes={!backLink ? { root: classes.modeText } : {}}
                          orderProperties={orderProperties}
                        />
                      )}

                      {displayOrderMode && displayAddress && (
                        <TypographyCustom
                          component="span"
                          type={isWidthUp('sm', width) ? 164 : 144}
                          className={classes.separator}
                        >
                          -
                        </TypographyCustom>
                      )}

                      {displayAddress && (
                        <Address
                          orderProperties={orderProperties}
                          restaurant={restaurant}
                          computeAddress={computeAddress}
                        />
                      )}

                      {changeButton && (
                        <ChangeButton
                          classes={{
                            root: classes.changeButton,
                          }}
                          orderProperties={orderProperties}
                          onClick={() =>
                            isFunction(changeButton) ? changeButton() : redirectTo(changeButton)
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )}

              {displayUser && (
                <ConditionalUserBlock
                  authenticationMode={appConfig.authentication.mode}
                  me={me}
                  classes={classes}
                />
              )}
            </div>
            {orderProperties && (
              <React.Fragment>
                {orderProperties.isOnSite &&
                  frontAppConfig.hasTableNumber &&
                  displayTableNumber && (
                    <TableNumber
                      classes={{ root: classes.dueDate }}
                      orderProperties={orderProperties}
                    />
                  )}
                {(!orderProperties.isOnSite || !frontAppConfig.hasTableNumber) &&
                  displayDueDate && (
                    <DueDate
                      classes={{ root: classes.dueDate }}
                      orderProperties={orderProperties}
                    />
                  )}
              </React.Fragment>
            )}
          </BlockContainer>
        )}
      </RoutingContainer>
    )
  }
}

const Header = ({ displayHeader, restaurant, ...otherProps }) => {
  const classes = useStyles()

  return (
    <AppConfigContainer>
      {({ appConfig, appConfigPreset, frontAppConfig }) => {
        const shouldHideHeader = !displayHeader || !appConfigPreset.isPluginOrder

        return (
          <div className={classNames({ [classes.hiddenHeader]: shouldHideHeader })}>
            {!shouldHideHeader && (
              <HeaderView
                appConfig={appConfig}
                classes={classes}
                frontAppConfig={frontAppConfig}
                restaurant={restaurant}
                {...otherProps}
              />
            )}
          </div>
        )
      }}
    </AppConfigContainer>
  )
}

Header.defaultProps = {
  displayHeader: true,
  displayRestaurantLogo: true,
  displayTitle: false,
  backLink: null,
  displayOrderModeSelect: false,
  displayOrderMode: false,
  displayAddress: false,
  changeButton: null,
  displayDueDate: false,
  displayTableNumber: false,
  displayUser: false,
}

Header.propTypes = {
  displayHeader: PropTypes.bool,
  displayRestaurantLogo: PropTypes.bool,
  displayTitle: PropTypes.bool,
  displayOrderModeSelect: PropTypes.bool,
  displayOrderMode: PropTypes.bool,
  displayAddress: PropTypes.bool,
  changeButton: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
  displayDueDate: PropTypes.bool,
  displayUser: PropTypes.bool,
  backLink: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
}

export default withWidth()(Header)
