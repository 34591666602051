import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { getCartData } from 'pmt-modules/cart'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'

import Hidden from 'pmt-ui/Hidden'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    background: theme.palette.primary.main,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: theme.shadows[3],
    zIndex: theme.zIndex.stickyButton,
  },
})

//https://zpl.io/aRNPw7E
const MobileCartButton = ({
  appConfigPreset,
  classes,
  cartData,
  onRedirectToCart,
  frontSettings,
}) => (
  <Hidden mdUp smDown={!cartData.hasItems}>
    <div className={classes.root} onClick={onRedirectToCart}>
      <TypographyCustom type="204" align="center" skipColor>
        {/*
          display a different label for digital menu
          https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
         */}
        {appConfigPreset.isDigitalMenu
          ? tr('order.menu.cart.submit_button_mobile_digital_menu')
          : tr('order.menu.cart.submit_button_mobile')}
        {frontSettings.display.totalPrices && ` (${cartData.totalCartPriceFormatted})`}
      </TypographyCustom>
    </div>
  </Hidden>
)

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  cartData: getCartData(state),
})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {}
  )
)(MobileCartButton)
