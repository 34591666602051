import isNil from 'lodash/isNil'

export const getFormattedAddressFromAddressComponents = address => {
  if (isNil(address)){
    return null
  }

  if (isNil(address.street)) {
    return ''
  }

  let addressFormatted = `${address.street}, ${address.postCode} ${address.city}`
  if (!isNil(address.country)) {
    addressFormatted += `, ${address.country}`
  }

  return addressFormatted
}

export const withFormattedAddress = address => 
({
  ...address,
  formattedAddress: getFormattedAddressFromAddressComponents(address)
})
