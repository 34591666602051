import React from 'react'
import { connect } from 'react-redux'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import { getRoute, redirectTo } from 'pmt-modules/routing'
import { getAppConfig, getFrontSettings } from 'pmt-modules/appConfig'
import { saveRawAccessToken } from 'pmt-modules/auth'
import { OrderMode } from 'pmt-modules/order'
import { selectMode, setRestaurantId } from 'pmt-modules/orderFront'
import {
  setGeolocationComputeAddress,
  setGeolocationAddress,
  setGeolocationCoordinates,
} from 'pmt-modules/geolocation'
import { DEFAULT_GEOLOCATION_ID } from 'pmt-modules/geolocation/constants'
import { getAddressElementsFromFormattedAddress } from 'pmt-utils/google'

import {
  setTableNumber,
  disableTableNumberModification,
  setPosCheckId,
  setFrom,
  setDueDateAsap,
  setConfigurationError,
} from '../../actions'
import { fromApp } from '../../constants'
import { getOrderProperties } from '../../selectors'

class OrderPluginUrlCheckerContainer extends React.Component {
  constructor(props) {
    super(props)

    this.mode = null
    this.restaurantId = null
    this.address = null
    this.isAsap = null

    const {
      appConfig,
      verifyRestaurantId,
      verifyMode,
      verifyLatLng,
      verifyAddress,
      verifyIsAsap,
      verifyTableNumber,
      verifyFrom,
    } = props

    if (!appConfig) {
      // do not check if we don't have an appConfig provided
      // when the appToken is invalid, there is no appConfig retrieved, but we need to render
      return
    }

    if (verifyMode) {
      this.checkMode()
    }

    this.checkAccessToken()

    if (verifyLatLng) {
      this.checkLatLng()
    }

    if (verifyAddress) {
      this.checkAddress()
    }

    if (verifyRestaurantId) {
      this.checkRestaurantId()
    }

    if (verifyIsAsap) {
      this.checkIsAsap()
    }

    if (verifyTableNumber) {
      this.checkTableNumber()
    }

    if (verifyFrom) {
      this.checkFrom()
    }
  }

  setLocalState = obj => {
    this.setState(obj)
  }

  checkMode = () => {
    const {
      location,
      appConfig,
      orderPluginProperties,
      selectMode,
      redirectTo,
      setConfigurationError,
    } = this.props

    // if the mode set in URL is not in the appConfig
    if (
      !isUndefined(location.query.mode) &&
      appConfig.order.modes.indexOf(parseInt(location.query.mode, 10)) === -1
    ) {
      setConfigurationError(
        "Le mode de commande renseigné dans l'URL n'est pas disponible dans votre configuration"
      )
      return false
    }

    const modeInQuery = isNil(location.query.mode) ? NaN : parseInt(location.query.mode, 10)

    // if only one mode is in appConfig, we preselect it no matter what's in the URL
    if (appConfig.order && appConfig.order.modes.length === 1) {
      selectMode(appConfig.order.modes[0])
      this.mode = appConfig.order.modes[0]

      // if the user is on the modes page, we redirect on store locator
      if (location.pathname === getRoute('ORDER__MODE').path) {
        redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }

      // if one mode is in query URL, we set this mode as selected
    } else if (
      (isNil(orderPluginProperties.mode) || orderPluginProperties.mode !== modeInQuery) &&
      !isNaN(modeInQuery)
    ) {
      selectMode(modeInQuery)
      this.mode = modeInQuery

      // if the user is on the modes page, we redirect on store locator
      if (location.pathname === getRoute('ORDER__MODE').path) {
        redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }

      // if nothing is filled in query URL and no selected mode, we redirect on mode page
    } else if (isNil(orderPluginProperties.mode)) {
      this.setState({
        renderOrderPluginAppConfig: false,
      })
      redirectTo(getRoute('ORDER__MODE'))
    }
  }

  checkRestaurantId = () => {
    const {
      location,
      setRestaurantId,
      setConfigurationError,
      params,
      orderPluginProperties,
    } = this.props

    // if its format isn't ok
    if (
      !isUndefined(location.query.restaurantId) &&
      isNaN(parseInt(location.query.restaurantId, 10))
    ) {
      setConfigurationError("Le format de l'ID du restaurant renseigné est erroné")

      return false
    } else {
      const restaurantId = !isUndefined(location.query.restaurantId)
        ? String(parseInt(location.query.restaurantId, 10))
        : !isUndefined(params) && !isUndefined(params.restaurantId)
          ? String(parseInt(params.restaurantId, 10))
          : null

      this.restaurantId = restaurantId

      if (
        isNil(orderPluginProperties.restaurantId) ||
        orderPluginProperties.restaurantId !== restaurantId
      ) {
        setRestaurantId(restaurantId)
      }
    }
  }

  checkLatLng = () => {
    const {
      location,
      orderPluginProperties,
      setConfigurationError,
      setGeolocationCoordinates,
    } = this.props

    if (!isUndefined(location.query.latitude) && !isUndefined(location.query.longitude)) {
      const latInQuery = parseFloat(location.query.latitude)
      const lngInQuery = parseFloat(location.query.longitude)

      if (isNaN(latInQuery) || isNaN(lngInQuery)) {
        setConfigurationError('Le format des latitudes/longitudes renseignées est erroné')
        return
      }

      // if latitude and longitude are set correctly in URL
      if (
        (isNil(orderPluginProperties.lat) || orderPluginProperties.lat !== latInQuery) &&
        (isNil(orderPluginProperties.lng) || orderPluginProperties.lng !== lngInQuery)
      ) {
        setGeolocationCoordinates(DEFAULT_GEOLOCATION_ID, latInQuery, lngInQuery)
      }
    }
  }

  checkAddress = () => {
    const { location, setGeolocationAddress, setGeolocationComputeAddress } = this.props

    if (!isUndefined(location.query.address)) {
      const addressInQuery = isNil(location.query.address)
        ? null
        : location.query.address.split('+').join(' ')

      // if latitude and longitude are set correctly in URL
      if (!isNil(addressInQuery)) {
        setGeolocationAddress(DEFAULT_GEOLOCATION_ID, decodeURIComponent(addressInQuery))
        const formattedAddress = decodeURIComponent(addressInQuery)
        const computeAddress = getAddressElementsFromFormattedAddress(formattedAddress)
        setGeolocationComputeAddress(DEFAULT_GEOLOCATION_ID, computeAddress)

        this.address = decodeURIComponent(addressInQuery)

        return true
      }
    }
  }

  checkIsAsap = () => {
    const { location, setDueDateAsap, setConfigurationError, redirectTo } = this.props

    // if a restaurantId is set in the URL and its format is ok
    if (!isNil(this.mode) && !isNil(this.restaurantId)) {
      const queryIsAsap = location.query.isAsap || null

      // if isAsap is set correctly in URL
      if (!isNil(queryIsAsap)) {
        if (queryIsAsap === 'true') {
          setDueDateAsap()
          this.isAsap = true

          // if the user is on the modes page, we redirect on store locator
          if (
            location.pathname === getRoute('ORDER__MODE').path ||
            location.pathname === getRoute('ORDER__STORE_LOCATOR').path
          ) {
            redirectTo(getRoute('ORDER__CATALOG'), {
              restaurantId: this.restaurantId,
            })
          }

          // if the format is wrong
        } else {
          setConfigurationError('Le format de isAsap renseigné est erroné')
          return false
        }
      }
    }
  }

  checkTableNumber = () => {
    const {
      location,
      orderPluginProperties,
      setTableNumber,
      disableTableNumberModification,
      setPosCheckId,
      redirectTo,
    } = this.props
    let makeRedirect = false
    const tableNumberInQuery = location.query.tableNumber
    const posCheckIdInQuery = location.query.posCheckId

    // if a table number is set in the URL and its format is ok
    if (
      (isNil(orderPluginProperties.tableNumber) ||
        orderPluginProperties.tableNumber !== tableNumberInQuery) &&
      !isNil(tableNumberInQuery)
    ) {
      makeRedirect = true
      setTableNumber(tableNumberInQuery)
      disableTableNumberModification()
    }

    // if a posCheckId is set in the URL and its format is ok
    if (
      (isNil(orderPluginProperties.posCheckId) ||
        orderPluginProperties.posCheckId !== posCheckIdInQuery) &&
      !isNil(posCheckIdInQuery)
    ) {
      makeRedirect = true
      setPosCheckId(posCheckIdInQuery)
      disableTableNumberModification()
    }

    if (
      makeRedirect &&
      (location.pathname === getRoute('ORDER__MODE').path ||
        location.pathname === getRoute('ORDER__STORE_LOCATOR').path) &&
      !isNil(this.mode) &&
      parseInt(this.mode, 10) === OrderMode.ON_SITE &&
      !isNil(this.restaurantId)
    ) {
      redirectTo(getRoute('ORDER__CATALOG'), {
        restaurantId: this.restaurantId,
      })
    }
  }

  checkFrom = () => {
    const { location, setFrom } = this.props
    const from = location.query.from

    if (!isNil(from)) {
      setFrom(from)
      this.from = from
      if (from === fromApp.WEBAPP) {
        disableTableNumberModification()
      }
    }
  }

  // when an accessToken is passed as query param, we store it
  checkAccessToken = () => {
    const { location, saveRawAccessToken } = this.props
    const accessToken = location.query.accessToken

    if (!isNil(accessToken)) {
      saveRawAccessToken({
        access_token: accessToken,
        token_type: 'bearer',
      })
    }
  }

  render() {
    const { children, ...otherProps } = this.props

    if (isFunction(children)) {
      return children({
        ...otherProps,
      })
    }

    return React.cloneElement(children, {
      ...otherProps,
    })
  }
}

OrderPluginUrlCheckerContainer.defaultProps = {
  verifyRestaurantId: false,
  verifyMode: false,
  verifyLatLng: false,
  verifyAddress: false,
  verifyIsAsap: false,
  verifyTableNumber: false,
  verifyFrom: false,
}

const mapStateToProps = (state, props) => ({
  appConfig: getAppConfig(state),
  frontAppConfig: getFrontSettings(state),
  orderPluginProperties: getOrderProperties(state),
})

export default connect(
  mapStateToProps,
  {
    redirectTo,
    saveRawAccessToken,
    selectMode,
    setDueDateAsap,
    setConfigurationError,
    setFrom,
    setGeolocationAddress,
    setGeolocationComputeAddress,
    setGeolocationCoordinates,
    setPosCheckId,
    setRestaurantId,
    setTableNumber,
    disableTableNumberModification,
  }
)(OrderPluginUrlCheckerContainer)
