import React from 'react'
import PropTypes from 'prop-types'

import Button from 'pmt-ui/Button'
import { withStyles, withTheme } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    textTransform: 'initial',
    fontWeight: 700,
    lineHeight: 1,
  },
  disabled: {},
  label: {
    textAlign: 'center',
    lineHeight: '20px',
    textTransform: 'initial',
  },
  sizeSmall: {
    fontSize: theme.typography.pxToRem(14),
  },
  sizeLarge: {
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  outlinedPrimary: {
    background: theme.pmt.colors.white,
  },
})

// ButtonCustom - used for main action for order plugin
// created to format style applied in one file
// and not everywhere a primary button has to be set
const ButtonCustom = ({ children, classes, color, label, icon, variant, theme, ...otherProps }) => (
  <Button variant={variant} color={color} classes={classes} {...otherProps}>
    {icon &&
      React.cloneElement(icon, {
        style: {
          marginRight:
            label || children
              ? otherProps.size === 'small'
                ? theme.spacing(0.5)
                : theme.spacing(1)
              : 0,
          height: otherProps.size === 'small' ? 16 : 24,
          width: otherProps.size === 'small' ? 16 : 24,
        },
      })}
    {children || label}
  </Button>
)

ButtonCustom.defaultProps = {
  color: 'primary',
  variant: 'contained',
}

ButtonCustom.propTypes = {
  color: PropTypes.string,
  // same as 'children'
  label: PropTypes.string,
  // same as 'label'
  children: PropTypes.any,
}

export default withStyles(styles)(withTheme(ButtonCustom))
