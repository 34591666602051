import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import NavigationChevronLeft from 'pmt-ui/svg-icons/navigation/chevron-left'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    padding: '0 !important',
  },
  backLink: {
    marginLeft: theme.spacing(1) * -1.5,
    height: 26,
    lineHeight: '26px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  icon: {
    float: 'left',
    width: 26,
    height: 26,
  },
  hr: {
    borderTop: `1px solid ${theme.pmt.colors.grey500}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  categoryContainer: {
    height: 160,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  category: {
    overflow: 'hidden',
    minHeight: 28,
    maxHeight: 84,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '26px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '20px',
      minHeight: 23,
      maxHeight: 51,
      '-webkit-line-clamp': 2,
    },
  },
})

const ParentCategoryCard = ({ category, parentCategory, isMobile, onClick, classes }) => (
  <Card className={classes.root}>
    <CardContent className={classes.content}>
      <TypographyCustom
        type="167"
        className={classes.backLink}
        onClick={e => {
          e.stopPropagation()
          onClick(parentCategory)
        }}
      >
        <NavigationChevronLeft className={classes.icon} /> Retour
      </TypographyCustom>
      <hr className={classes.hr} />
      <div className={classes.categoryContainer}>
        <TypographyCustom type={isMobile ? '207' : '247'} className={classes.category}>
          {category.name}
        </TypographyCustom>
      </div>
    </CardContent>
  </Card>
)

export default withStyles(styles)(ParentCategoryCard)
