import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatUserAddressList } from './format'

const getUserAddress = state => state.entities.userAddress
const getUserAddressListOnProps = (state, props) => props.userAddressList
const getOriginOnProps = (state, props) => props.origin
const getRangeOnProps = (state, props) => props.range

/**
 * Required props:
 */
export const isFetchingUserAddressList = createSelector([getUserAddress], userAddress => {
  return userAddress.getIn(['list', 'isFetching'], false)
})

export const getUserAddressList = createSelector([getUserAddress], userAddress => {
  const userAddressList = userAddress.getIn(['list', 'data'], null)

  if (!isNil(userAddressList)) {
    return userAddressList.toJS()
  }

  return null
})

export const makeGetUserAddressListInRange = () =>
  createSelector(
    [getUserAddressListOnProps, getOriginOnProps, getRangeOnProps],
    (userAddressList, origin, range) => {
      if (!isNil(userAddressList) && !isNil(origin) && !isNil(range)) {
        const userAddressListFormatted = formatUserAddressList(userAddressList, {
          origin,
          range,
        })

        return userAddressListFormatted.filter(address => address.isInRange)
      }

      return null
    }
  )

export const makeGetUserAddressListOutOfRange = () =>
  createSelector(
    [getUserAddressListOnProps, getOriginOnProps, getRangeOnProps],
    (userAddressList, origin, range) => {
      if (!isNil(userAddressList) && !isNil(origin) && !isNil(range)) {
        const userAddressListFormatted = formatUserAddressList(userAddressList, {
          origin,
          range,
        })

        return userAddressListFormatted.filter(address => !address.isInRange)
      }

      return null
    }
  )

export const isFetchingUserAddressPost = createSelector([getUserAddress], userAddress =>
  userAddress.getIn(['post', 'isFetching'], false)
)

export const getCreatedUserAddress = createSelector([getUserAddress], userAddress =>
  userAddress.getIn(['post', 'data'], null)
)

export const isFetchingUserAddressEdit = createSelector([getUserAddress], userAddress =>
  userAddress.getIn(['edit', 'isFetching'], false)
)

export const isFetchingUserAddressDelete = createSelector([getUserAddress], userAddress =>
  userAddress.getIn(['delete', 'isFetching'], false)
)
