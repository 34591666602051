import React, { Fragment } from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Table, { TableBody, TableCell, TableHead, TableRow } from 'pmt-ui/Table'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  allergen: {
    color: theme.pmt.colors.grey97,
  },
  allergenActive: {
    color: theme.palette.primary.main,
  },
  tableCell: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    width: 90,
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
      width: 55,
      minWidth: 55,
    },
  },
  cellRow: {
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
    },
  },
}))

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.pmt.colors.greyBackground,
    fontWeight: 'bold',
  },
  body: {
    backgroundColor: theme.pmt.colors.greyBackground,
    fontSize: 12,
    minWidth: 300,
  },
}))(TableCell)

/**
 * @specs N/A
 */
const DietaryInfoDialog = ({ item, allergens }) => {
  const classes = useStyles()

  const renderItem = ({ item, allergens, depth = 2 }) => (
    <Fragment>
      {/* item */}
      {renderRow({ item, allergens, depth })}

      {/* options */}
      {(item.options || []).map(
        option =>
          option.hasAllergens && (
            <Fragment>
              {option.values && (
                <Fragment>
                  <TableRow style={{ height: 36 }}>
                    <CustomTableCell
                      component="th"
                      scope="row"
                      colSpan={allergens.length + 1}
                      style={{
                        paddingLeft: 4 * (depth + 1) * 3,
                      }}
                    >
                      {option.name}
                    </CustomTableCell>
                  </TableRow>
                  {(option.values || []).map(v =>
                    renderRow({ allergens, item: v, depth: depth + 2 })
                  )}
                </Fragment>
              )}
            </Fragment>
          )
      )}

      {/* parts */}
      {item.parts &&
        (item.parts || []).map(
          part =>
            part.hasAllergens && (
              <Fragment>
                <TableRow key={`parts-${part.id}`} style={{ height: 36 }}>
                  <CustomTableCell component="th" scope="row" colSpan={allergens.length + 1}>
                    {part.name}
                  </CustomTableCell>
                </TableRow>
                {(part.products || []).map(product =>
                  renderItem({ allergens, item: product, depth: depth + 1 })
                )}

                {/* part categories */}
                {renderPartCategory({
                  classes,
                  categories: part.categories,
                  depth: depth + 1,
                })}
              </Fragment>
            )
        )}
    </Fragment>
  )

  const renderPartCategory = ({ categories, depth = 2 }) =>
    categories &&
    categories.map(category => (
      <Fragment>
        {(category.products || []).map(item => renderItem({ allergens, item, depth }))}
        {(category.categories || []).map(subCategory =>
          renderPartCategory({ categories: subCategory.categories, depth })
        )}
      </Fragment>
    ))

  const renderRow = ({ allergens, item, depth = 2 }) =>
    !isEmpty(get(item, 'dietaryInfo.allergens', [])) && (
      <TableRow key={item.id}>
        <TableCell
          component="th"
          scope="row"
          className={classes.cellRow}
          style={{ paddingLeft: 4 * depth * 3 }}
        >
          {item.name}
        </TableCell>
        {allergens.map(a => (
          <TableCell key={`${item.id}-${a.value}`} classes={{ root: classes.tableCell }}>
            <div
              className={clsx(classes.allergen, {
                [classes.allergenActive]:
                  item.dietaryInfo && (item.dietaryInfo.allergens || []).indexOf(a.value) >= 0,
              })}
            >
              {a.icon}
            </div>
          </TableCell>
        ))}
      </TableRow>
    )

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <CustomTableCell />
          {allergens.map(a => (
            <CustomTableCell classes={{ root: classes.tableCell }}>{a.label}</CustomTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{renderItem({ item, allergens, classes })}</TableBody>
    </Table>
  )
}

export default DietaryInfoDialog
