import React from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import TextField from 'pmt-ui/TextField'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  formContainer: {},
  inputStyles: {
    width: '100%',
  },
  inputStreet: {},
  inputPostCode: {},
  inputCity: {},
  inputCountry: {},
  inputComplement: {},
  inputName: {},
})

class AddUserAddressFormView extends React.Component {
  render() {
    const { classes, formData, formErrors, onChange, labels, placeholders } = this.props

    return (
      <div className={classes.formContainer}>
        <TextField
          id="street"
          type="text"
          className={clsx(classes.inputStyles, classes.inputStreet)}
          label={!isUndefined(labels.street) ? labels.street : 'Rue'}
          placeholder={!isUndefined(placeholders.street) ? placeholders.street : 'Rue'}
          value={formData.street || ''}
          error={formData.street !== null && !isEmpty(formErrors.street.message)}
          onChange={event => onChange({ ...formData, street: event.target.value })}
        />
        <TextField
          id="postCode"
          type="text"
          className={clsx(classes.inputStyles, classes.inputPostCode)}
          label={!isUndefined(labels.postCode) ? labels.postCode : 'Code postal'}
          placeholder={!isUndefined(placeholders.postCode) ? placeholders.postCode : 'Code postal'}
          value={formData.postCode || ''}
          error={formData.postCode !== null && !isEmpty(formErrors.postCode.message)}
          onChange={event => onChange({ ...formData, postCode: event.target.value })}
        />
        <TextField
          id="city"
          type="text"
          className={clsx(classes.inputStyles, classes.inputCity)}
          label={!isUndefined(labels.city) ? labels.city : 'Ville'}
          placeholder={!isUndefined(placeholders.city) ? placeholders.city : 'Ville'}
          value={formData.city || ''}
          error={formData.city !== null && !isEmpty(formErrors.city.message)}
          onChange={event => onChange({ ...formData, city: event.target.value })}
        />
        <TextField
          id="country"
          type="text"
          className={clsx(classes.inputStyles, classes.inputCountry)}
          label={!isUndefined(labels.country) ? labels.country : 'Pays'}
          placeholder={!isUndefined(placeholders.country) ? placeholders.country : 'Pays'}
          value={formData.country || ''}
          error={formData.country !== null && !isEmpty(formErrors.country.message)}
          onChange={event => onChange({ ...formData, country: event.target.value })}
        />
        <TextField
          id="complement"
          type="text"
          className={clsx(classes.inputStyles, classes.inputComplement)}
          label={!isUndefined(labels.complement) ? labels.complement : 'Complément'}
          placeholder={
            !isUndefined(placeholders.complement) ? placeholders.complement : 'Complément'
          }
          value={formData.complement || ''}
          error={formData.complement !== null && !isEmpty(formErrors.complement.message)}
          onChange={event => onChange({ ...formData, complement: event.target.value })}
        />
        <TextField
          id="name"
          type="text"
          className={clsx(classes.inputStyles, classes.inputName)}
          label={!isUndefined(labels.name) ? labels.name : 'Nom'}
          placeholder={!isUndefined(placeholders.name) ? placeholders.name : 'Nom'}
          value={formData.name || ''}
          error={formData.name !== null && !isEmpty(formErrors.name.message)}
          onChange={event => onChange({ ...formData, name: event.target.value })}
        />
      </div>
    )
  }
}

AddUserAddressFormView.defaultProps = {
  labels: {},
  placeholders: {},
}

export default withStyles(styles)(AddUserAddressFormView)
