import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let HardwareKeyboardArrowLeft = props => {
  const { slideCount, currentSlide, ...svgProps } = props

  return (
    <SvgIcon {...svgProps}>
      <path d="M14.71,15.88L10.83,12l3.88-3.88c0.39-0.39,0.39-1.02,0-1.41l0,0c-0.39-0.39-1.02-0.39-1.41,0l-4.59,4.59 c-0.39,0.39-0.39,1.02,0,1.41l4.59,4.59c0.39,0.39,1.02,0.39,1.41,0l0,0C15.09,16.91,15.1,16.27,14.71,15.88z" />
    </SvgIcon>
  )
}
HardwareKeyboardArrowLeft = pure(HardwareKeyboardArrowLeft)
HardwareKeyboardArrowLeft.displayName = 'HardwareKeyboardArrowLeft'
HardwareKeyboardArrowLeft.pmtName = 'SvgIcon'

export default HardwareKeyboardArrowLeft
