import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'
import moment from 'moment'
import classNames from 'classnames'

import Hidden from '@material-ui/core/Hidden'

import ui from 'pmt-modules/reduxUi'
import { EventManager } from 'pmt-modules/event'
import { getForDay, isSlotInOpeningHours, getOpeningHours } from 'pmt-modules/orderSettings'
import { selectSlot, selectDay, setDueDateAsap, resetDueDate } from 'pmt-modules/orderPlugin'

import {
  getMs,
  isSameDay,
  getHourAndMinuteFromMs,
  getMsFromHour,
  getMsFromMinute,
  getPmtDayIndex,
} from 'pmt-utils/date'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { Radio } from 'pmt-ui/Radio'
import Select from 'pmt-ui/Select'
import { MenuItem } from 'pmt-ui/Menu'
import HardwareKeyboardArrowDown from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'
import HardwareKeyboardArrowUp from 'pmt-ui/svg-icons/hardware/keyboard-arrow-up'

import Button, { ButtonLink } from '../Button'
import BlockContainer from '../BlockContainer'
import styles from './styles'

const Type = {
  BIG: 'big',
  SMALL: 'small',
}

class Slots extends React.Component {
  constructor(props) {
    super(props)
    props.updateUI({
      showSlots: props.showSlots,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { orderProperties, ui, updateUI } = nextProps

    // if there's no correct due date
    if (
      !orderProperties.isAsap &&
      (isNil(orderProperties.slot) || isNil(orderProperties.day)) &&
      !ui.showSlots
    ) {
      updateUI({
        showSlots: true,
      })
    }
  }

  toggleSlotShow = () => {
    EventManager.dispatch(EventManager.Events.ON_CHANGE_SLOT)
    this.props.updateUI({
      showSlots: !this.props.ui.showSlots,
    })
  }

  renderSelect(day) {
    const { type, orderProperties, selectSlot } = this.props

    return (
      <Select
        onChange={event => {
          selectSlot(event.target.value, getMs(day.date))
        }}
        inputProps={{
          name: tr('order.slots.schedules'),
        }}
        value={isNil(orderProperties.slot) ? day.slots[0].value : orderProperties.slot}
        renderValue={value => {
          const hourData = getHourAndMinuteFromMs(value)
          let slotValue = hourData.hour < 10 ? `0${hourData.hour}` : `${hourData.hour}`
          slotValue +=
            hourData.minute < 10 ? `    :    0${hourData.minute}` : `    :    ${hourData.minute}`
          return (
            <TypographyCustom
              component="span"
              type={type === Type.BIG ? '164' : '144'}
              align="center"
            >
              {slotValue}
            </TypographyCustom>
          )
        }}
      >
        {day.slots.map((slot, index) => {
          let slotValue =
            slot.hourData.hour < 10 ? `0${slot.hourData.hour}` : `${slot.hourData.hour}`
          slotValue +=
            slot.hourData.minute < 10
              ? `    :    0${slot.hourData.minute}`
              : `    :    ${slot.hourData.minute}`
          return (
            <MenuItem key={index} value={slot.value}>
              <TypographyCustom
                component="span"
                type={type === Type.BIG ? '164' : '144'}
                align="center"
              >
                {slotValue}
              </TypographyCustom>
            </MenuItem>
          )
        })}
      </Select>
    )
  }

  renderDay(day, index, isSelectedDay) {
    const {
      classes,
      type,
      orderProperties,
      selectDay,
      selectSlot,
      setDueDateAsap,
      openingHours,
      hideSubmit = true,
      onSubmit,
    } = this.props

    let dateLabel = day.date.format('dddd D MMMM')
    let isInOpeningHours = false
    if (day.isToday) {
      dateLabel = tr('order.global.today')

      // the API constants for day index is one below the returned value from moment
      // TODO: refactor / optimize this
      const now = moment()
      const weekdayIndex = getPmtDayIndex(now)
      const openingHoursForNow = getForDay(openingHours, weekdayIndex)
      isInOpeningHours = isSlotInOpeningHours(
        openingHoursForNow,
        getMsFromHour(parseInt(now.format('HH'), 10)) +
          getMsFromMinute(parseInt(now.format('mm'), 10)),
        orderProperties.delay
      )
    }
    dateLabel = `${dateLabel.charAt(0).toUpperCase()}${dateLabel.slice(1)}`

    return (
      <div key={index} className={classes.date}>
        <TypographyCustom
          component="p"
          type={isSelectedDay ? '167' : '164'}
          className={classes[`${type}DateLabel`]}
          onClick={() => {
            if (orderProperties.day !== getMs(day.date)) {
              selectDay(getMs(day.date))
              resetDueDate(null)
            }
          }}
        >
          {dateLabel}
          {isSelectedDay ? (
            <HardwareKeyboardArrowUp
              className={classNames(classes.dateIcon, classes[`${type}DateIcon`])}
            />
          ) : (
            <HardwareKeyboardArrowDown
              className={classNames(classes.dateIcon, classes[`${type}DateIcon`])}
            />
          )}
        </TypographyCustom>
        <div
          className={
            isSelectedDay
              ? classNames(classes.slotContainer, classes[`${type}SlotContainerSelected`])
              : classes.slotContainer
          }
        >
          {day.isToday && isInOpeningHours && orderProperties.canOrderUsingAsap && (
            <div
              className={classes.slotRow}
              onClick={() => {
                EventManager.dispatch(EventManager.Events.ON_SET_DUE_DATE_ASAP, {
                  mode: orderProperties.mode,
                })
                setDueDateAsap()
              }}
            >
              <Radio
                color="primary"
                name="asap"
                className={classNames('u-floatLeft', classes[`${type}RadioButton`])}
                checked={orderProperties.isAsap}
                value="asap"
              />
              <TypographyCustom
                component="p"
                type={type === Type.BIG ? '164' : '144'}
                className={
                  orderProperties.isAsap
                    ? classNames(classes.colorPrimary, classes[`${type}RadioButtonLabel`])
                    : classes[`${type}RadioButtonLabel`]
                }
              >
                {orderProperties.hasDelayOrDeliveryTime
                  ? tr('order.store_locator.slot.as_soon_as_possible', {
                      delay: orderProperties.delayAndDeliveryFormattedAsMin,
                    })
                  : tr('order.global.now')}
              </TypographyCustom>
            </div>
          )}
          <div
            className={classNames(classes.slotRow, {
              'u-marginTop10': type === Type.SMALL,
            })}
          >
            <Radio
              color="primary"
              name="dueDate"
              className={classNames('u-floatLeft', classes[`${type}RadioButton`])}
              checked={!isNil(orderProperties.slot) && !orderProperties.isAsap}
              value="not_asap"
              onClick={() => {
                if (
                  orderProperties.day !== getMs(day.date) ||
                  orderProperties.isAsap ||
                  isNil(orderProperties.slot)
                ) {
                  EventManager.dispatch(EventManager.Events.ON_SET_DUE_DATE_SLOT, {
                    mode: orderProperties.mode,
                  })
                  selectSlot(day.slots[0].value, getMs(day.date))
                }
              }}
            />
            <TypographyCustom
              type={type === Type.BIG ? '164' : '144'}
              component="p"
              className={
                !isNil(orderProperties.slot) && !orderProperties.isAsap
                  ? classNames(classes.colorPrimary, classes[`${type}RadioButtonLabel`])
                  : classes[`${type}RadioButtonLabel`]
              }
              onClick={() => {
                if (
                  orderProperties.day !== getMs(day.date) ||
                  orderProperties.isAsap ||
                  isNil(orderProperties.slot)
                ) {
                  EventManager.dispatch(EventManager.Events.ON_SET_DUE_DATE_SLOT, {
                    mode: orderProperties.mode,
                  })
                  selectSlot(day.slots[0].value, getMs(day.date))
                }
              }}
            >
              {day.isToday && isInOpeningHours && orderProperties.canOrderUsingAsap
                ? tr('order.slots.or_choose_schedule')
                : tr('order.slots.choose_schedule')}
            </TypographyCustom>
            <div className="u-paddingBottom5 u-paddingLeft50">{this.renderSelect(day)}</div>
          </div>
          <Hidden smDown mdUp={hideSubmit}>
            <Button
              size="large"
              disabled={orderProperties.isMissingSlot || orderProperties.isMissingTableNumber}
              classes={{ root: classes.bigButton }}
              onClick={onSubmit}
              label={tr('order.global.continue')}
            />
          </Hidden>
        </div>
      </div>
    )
  }

  render() {
    const {
      classes,
      ui,
      type,
      dueDateClassnameColor,
      modificationEnabled,
      orderProperties,
    } = this.props

    return (
      <div className={classes.root}>
        {ui.showSlots && !isNil(orderProperties.slots) ? (
          <BlockContainer type={BlockContainer.Type.WHITE} mobileType={BlockContainer.Type.WHITE}>
            {orderProperties.slots.map((day, index) => {
              const isSelected = !isNil(orderProperties.day)
                ? isSameDay(orderProperties.day, day.date)
                : index === 0
              return this.renderDay(day, index, isSelected)
            })}
            {type === Type.SMALL && (
              <React.Fragment>
                {isNil(orderProperties.dueDate) && !orderProperties.isAsap && (
                  <p className={classNames('u-marginTop20', classes.errorMessage)}>
                    {tr('order.error_message.slot_missing')}
                  </p>
                )}
                <Button
                  classes={{ root: 'u-marginTop10' }}
                  variant={type === Type.SMALL ? 'outlined' : 'contained'}
                  disabled={isNil(orderProperties.dueDate) && !orderProperties.isAsap}
                  onClick={this.toggleSlotShow}
                  label={tr('order.global.close')}
                />
              </React.Fragment>
            )}
          </BlockContainer>
        ) : (
          <div className={classes.dueDateLabel}>
            {orderProperties.isAsap ? (
              <div className="u-floatLeft">
                {orderProperties.hasDelayOrDeliveryTime ? (
                  <TypographyCustom className={dueDateClassnameColor} component="p" type="167">
                    {tr('order.store_locator.slot.as_soon_as_possible', {
                      delay: orderProperties.delayAndDeliveryFormattedAsMin,
                    })}
                  </TypographyCustom>
                ) : (
                  <TypographyCustom className={dueDateClassnameColor} component="p" type="167">
                    {tr('order.global.now')}
                  </TypographyCustom>
                )}
              </div>
            ) : (
              !isNil(orderProperties.dueDate) && (
                <TypographyCustom className={dueDateClassnameColor} component="p" type="167">
                  {orderProperties.dueDateFormatted}
                </TypographyCustom>
              )
            )}
            {modificationEnabled && (
              <ButtonLink
                classes={{ root: classNames('u-marginLeft10', dueDateClassnameColor) }}
                onClick={this.toggleSlotShow}
                label={tr('order.global.modify')}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

Slots.defaultProps = {
  dueDateClassnameColor: '',
  showSlots: false,
  modificationEnabled: true,
  onSelectSlot: null,
}

const mapStateToProps = (state, props) => ({
  openingHours: getOpeningHours(state),
})

const SlotsContainer = compose(
  withStyles(styles),
  ui({
    state: {
      showSlots: false,
    },
  }),
  connect(mapStateToProps, {
    selectDay,
    selectSlot,
    setDueDateAsap,
    resetDueDate,
  })
)(Slots)

SlotsContainer.Type = Type

export default SlotsContainer
