import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getCategoryFromCatalog } from 'pmt-modules/catalog/utils'
import { makeGetFirstSelectableCategory } from 'pmt-modules/catalog/selectors'

import { getOrderProperties } from '../../selectors'

/**
 * @specs N/A
 *
 * A HOC that returns the selected category from store
 *
 */
class SelectedCategoryContainer extends React.Component {
  state = {
    selectedCategoryFound: null,
  }

  // Here we need to define the category to be displayed.
  // If the user changes the restaurant id in url, we need to select the right category
  // even if a previous one on the previous restaurant was already selected.
  // If we don't find a category from catalog we the selected one, we select the first selectable category.
  componentWillReceiveProps(nextProps) {
    const { catalog, firstSelectableCategory, orderPluginProperties } = nextProps

    if (
      !isNil(this.props.orderPluginProperties.selectedCategory) ||
      (isNil(this.props.orderPluginProperties.selectedCategory) &&
        !isNil(orderPluginProperties.selectedCategory))
    ) {
      if (!isNil(catalog) && !isNil(orderPluginProperties.selectedCategory)) {
        const selectedCategoryFound = getCategoryFromCatalog(
          catalog,
          orderPluginProperties.selectedCategory.id,
          orderPluginProperties.parentCategories
        )

        this.setState({
          selectedCategoryFound: selectedCategoryFound || firstSelectableCategory,
        })
      }
    }
  }

  render() {
    const {
      children,
      catalog,
      parentCategories,
      orderPluginProperties,
      firstSelectableCategory,
      ...otherProps
    } = this.props

    return children({
      catalog,
      selectedCategory:
        this.state.selectedCategoryFound ||
        orderPluginProperties.selectedCategory ||
        firstSelectableCategory,
      parentCategories: orderPluginProperties.parentCategories,
      isSubCategory: !isEmpty(parentCategories),
      ...otherProps,
    })
  }
}

SelectedCategoryContainer.propTypes = {
  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  const getFirstSelectableCategory = makeGetFirstSelectableCategory()

  return {
    orderPluginProperties: getOrderProperties(state),
    firstSelectableCategory: getFirstSelectableCategory(state, props),
  }
}

export default connect(
  mapStateToProps,
  {}
)(SelectedCategoryContainer)
