import { tr } from 'pmt-modules/i18n'
/**
 * Contains the text to put on the submit buttons
 */
const SubmitBtnText = ({ appConfigPreset, orderProduct, isMenuMode, isEditMode }) => {
  let wording = isEditMode
    ? 'order.catalog.update'
    : isMenuMode
      ? 'order.catalog.add_to_menu'
      : 'order.catalog.add_to_cart'

  if (!isMenuMode || orderProduct.totalPrice > 0) {
    wording += '_with_price'

    if (isMenuMode) {
      wording += '_with_plus'
    }
  }

  if (appConfigPreset.isDigitalMenu) {
    wording = 'order.catalog.add_to_cart_digital_menu'
  }

  return tr(wording, {
    price: orderProduct.totalPriceFormatted,
  })
}

export default SubmitBtnText
