import React, { PureComponent } from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import HardwareKeyboardArrowDown from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'
import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'

import BlockContainer from '../BlockContainer'

const styles = theme => ({
  root: {},
  blockTitle: {
    height: 32,
    lineHeight: '32px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  blockTitleIcon: {
    float: 'right',
    width: 32,
    height: 32,
    color: theme.palette.primary.main,
    transition: 'transform 0.1s ease-in',
  },
  blockTitleIconOpened: {
    transform: 'rotate(-180deg)',
  },
  content: {
    marginBottom: theme.spacing(3),
  },
})

class FlexBlock extends PureComponent {
  constructor(props) {
    super(props)

    if (isWidthUp('lg', props.width)) {
      props.updateUI({
        opened: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    // if the user resize his screen (marginal case but who knows)
    if (isWidthUp('lg', nextProps.width) && !isWidthUp('lg', this.props.width)) {
      nextProps.updateUI({
        opened: true,
      })
    }
  }

  render() {
    const { title, content, opened, handleOnClick, classes, width, ui, updateUI } = this.props

    return (
      <BlockContainer
        className={classNames('u-relative u-overflowHidden', classes.root)}
        type={BlockContainer.Type.WHITE}
      >
        <TypographyCustom
          type={isWidthDown('sm', width) ? '144' : '164'}
          className={classes.blockTitle}
          onClick={() => {
            handleOnClick()

            // handle apart the desktop case
            if (isWidthUp('lg', width)) {
              updateUI({
                opened: !ui.opened,
              })
            }
          }}
        >
          {title}
          <HardwareKeyboardArrowDown
            className={classNames(classes.blockTitleIcon, {
              [classes.blockTitleIconOpened]:
                (!isWidthUp('lg', width) && opened) || (isWidthUp('lg', width) && ui.opened),
            })}
          />
        </TypographyCustom>
        {((!isWidthUp('lg', width) && opened) || (isWidthUp('lg', width) && ui.opened)) && (
          <div className={classes.content}>{content}</div>
        )}
      </BlockContainer>
    )
  }
}

BlockContainer.defaultProps = {
  opened: true,
  onSelect: () => {},
  handleOnClick: () => {},
}

BlockContainer.propTypes = {
  opened: PropTypes.bool,
  onSelect: PropTypes.func,
  handleOnClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.func,
}

export default compose(
  ui({
    state: {
      opened: true,
    },
  }),
  withWidth(),
  withStyles(styles)
)(FlexBlock)
