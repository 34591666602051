import { tr } from 'pmt-modules/i18n'
import React, { Component } from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { Sticky } from 'pmt-ui/Sticky'
import Button from 'pmt-ui/Button'
import { goToAnchor, removeHash, configureAnchors } from 'pmt-ui/ScrollableAnchor'

import BlockContainer from '../../../../components/BlockContainer'

configureAnchors({ scrollDuration: 300 })

const styles = theme => ({
  title: {
    height: 50,
    lineHeight: '50px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  navigationList: {
    padding: 0,
    marginLeft: 0,
  },
  navigationElement: {
    listStyleType: 'none',
    padding: `${theme.spacing(1.5)}px 0`,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  hr: {
    borderTop: `1px solid ${theme.pmt.colors.grey97}`,
    margin: `${theme.spacing(3)}px 0`,
  },
  buttonDisconnect: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0`,
    textTransform: 'initial',
  },
  isSticky: {
    marginTop: theme.spacing(3),
    ...theme.pmt.appConfig.accountMenuSticky,
  },
})

class LeftBlock extends Component {
  constructor(props) {
    super(props)

    /**
     * set custom offset for anchors
     * see: https://paymytable.atlassian.net/browse/PP-1002
     * example: -130
     */
    configureAnchors({
      offset: props.frontAppConfig.theme.customCss.accountAnchorsOffset || 0,
      keepLastAnchorHash: true,
    })
  }

  handleAnchorClick = anchor => {
    const hash = window.location.hash
    // we remove the hash only if we want to scroll to the same hash
    if (hash.substring(1) === anchor) {
      removeHash()
    }

    // scroll to the anchor, without saving that hash update in history (false)
    goToAnchor(anchor, false)
  }

  render() {
    const { appConfig, blockType, handleLogout, classes } = this.props

    return (
      <Sticky>
        {({ isSticky, style }) => (
          <div style={style}>
            <BlockContainer
              className={classNames('u-relative u-overflowHidden', {
                [classes.isSticky]: isSticky,
              })}
              type={BlockContainer.Type.WHITE}
            >
              <TypographyCustom type="164" className={classes.title}>
                {tr('order.account.left_block.title')}
              </TypographyCustom>
              <div className={classes.content}>
                <nav>
                  <ul className={classes.navigationList}>
                    <li
                      className={classes.navigationElement}
                      onClick={() => {
                        this.handleAnchorClick(blockType.PROFILE_FORM)
                      }}
                    >
                      <TypographyCustom type="164" skipColor>
                        {tr('order.account.profile_form.title')}
                      </TypographyCustom>
                    </li>
                    {/*
                    <li
                      className={classes.navigationElement}
                      onClick={() => {
                        this.handleAnchorClick(blockType.DIETARY_INFO)
                      }}
                    >
                      <TypographyCustom type="164" skipColor>
                        {tr('order.account.dietary_info.title')}
                      </TypographyCustom>
                    </li>
                    */}
                    {/*
                    <li
                      className={classes.navigationElement}
                      onClick={() => {
                        this.handleAnchorClick(blockType.PAYMENT_METHODS)
                      }}
                    >
                      <TypographyCustom type="164" skipColor>
                        {tr('order.account.payment_methods.title')}
                      </TypographyCustom>
                    </li>
                    */}
                    {appConfig.order.modesFormatted.hasDelivery && (
                      <li
                        className={classes.navigationElement}
                        onClick={() => {
                          this.handleAnchorClick(blockType.DELIVERY_ADDRESS)
                        }}
                      >
                        <TypographyCustom type="164" skipColor>
                          {tr('order.account.delivery_address.title')}
                        </TypographyCustom>
                      </li>
                    )}
                    {/* <li
                      className={classes.navigationElement}
                      onClick={() => {
                        this.handleAnchorClick(blockType.PAST_ORDERS)
                      }}
                    >
                      <TypographyCustom type="164" skipColor>
                        {tr('order.account.past_orders.title')}
                      </TypographyCustom>
                    </li> */}
                  </ul>
                </nav>
                <hr className={classes.hr} />
                <div className="u-textAlignCenter">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonDisconnect}
                    onClick={handleLogout}
                  >
                    <TypographyCustom type="164" skipColor>
                      {tr('order.global.logout')}
                    </TypographyCustom>
                  </Button>
                </div>
              </div>
            </BlockContainer>
          </div>
        )}
      </Sticky>
    )
  }
}

export default withStyles(styles)(LeftBlock)
