import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import AvReplay from 'pmt-ui/svg-icons/av/replay'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import { ButtonLink } from '../../Button'

const styles = theme => ({
  root: {},
})

const ChangeButton = ({ classes, onClick, orderProperties, width }) => (
  <div className={classes.root}>
    <TypographyCustom type={isWidthUp('sm', width) ? 164 : 144} component="div">
      <ButtonLink
        classes={{ root: classes.changeButton }}
        disabled={isNil(orderProperties.mode)}
        onClick={() => {
          EventManager.dispatch(EventManager.Events.ON_HEADER_BUTTON_CHANGE, {
            mode: orderProperties.mode,
          })
          onClick()
        }}
        icon={<AvReplay />}
        label={tr('order.global.change')}
      />
    </TypographyCustom>
  </div>
)

export default compose(withWidth(), withStyles(styles))(ChangeButton)
