import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { tr } from 'pmt-modules/i18n'
import { EventManager } from 'pmt-modules/event'
import { EPaymentMode, PspRegisterUserCardType } from 'pmt-modules/creditCard'

import { getPublicUrl } from 'pmt-modules/environment'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { getRoute } from 'pmt-modules/routing'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import Add from 'pmt-ui/svg-icons/content/add'
import Delete from 'pmt-ui/svg-icons/action/delete'

import { getAbsolutePath } from 'pmt-utils/url'

import BlockContainer from '../../../components/BlockContainer'
import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'

import AddCreditCardFormView from './AddCreditCardFormView'

const styles = (theme) => ({
  creditCardContainerPadding: {
    padding: theme.spacing(2),
  },
  cvv: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left !important',
    },
  },
  nameContainer: {
    width: 190,
  },
  name: {
    marginTop: 0,
  },
  iconDeleteContainer: {
    height: 48,
    lineHeight: '48px',
    paddingTop: 11,
  },
  iconCreditCard: {
    height: 40,
    lineHeight: '40px',
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  lineHeight53: {
    lineHeight: '53px',
  },
  payWithNewCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
})

class CreditCardPayment extends React.Component {
  constructor(props) {
    super(props)

    if (
      props.creditCards &&
      props.creditCards.length === 1 &&
      props.restaurant.hasPaymentWithCardPrint
    ) {
      props.onToggleCreditCard(true, props.creditCards[0])
    }

    // user has no credit card and payment method is already selected (ie. only available)
    if (props.isEmptyCreditCards && props.orderProperties.payment.isCreditCardMethod) {
      this.openCreditCardForm()
    }
  }

  componentWillReceiveProps(nextProps) {
    // user selects credit card method but has no credit cards yet
    if (
      !this.props.orderProperties.payment.isCreditCardMethod &&
      nextProps.orderProperties.payment.isCreditCardMethod &&
      nextProps.isEmptyCreditCards
    ) {
      this.openCreditCardForm()
    }

    // user already had at least one credit card and click on adding a new one
    // or delete his cards, list becomes empty so we want to display form
    if (
      (isNil(this.props.datasPostCreditCardRegistrationType) &&
        !isNil(nextProps.datasPostCreditCardRegistrationType) &&
        !nextProps.ui.showCreditCardForm) ||
      (!this.props.isEmptyCreditCards && nextProps.isEmptyCreditCards)
    ) {
      if (
        !this.props.isFetchingPostCreditCardRegistrationType &&
        !nextProps.isFetchingPostCreditCardRegistrationType
      ) {
        this.openCreditCardForm()
      }
      nextProps.updateUI({ showCreditCardForm: true })
    }

    // hide credit card form after a success full POST
    if (
      this.props.postPspDatasIsFetching &&
      !nextProps.postPspDatasIsFetching &&
      !isNil(nextProps.postPspDatasCard) &&
      nextProps.ui.showCreditCardForm
    ) {
      nextProps.updateUI({ showCreditCardForm: false })
    }

    if (
      nextProps.restaurant.hasPaymentWithCardPrint &&
      !this.props.creditCards &&
      nextProps.creditCards &&
      nextProps.creditCards.length === 1
    ) {
      nextProps.onToggleCreditCard(true, nextProps.creditCards[0])
      nextProps.updateUI({ showCreditCardForm: false })
    }
  }

  openCreditCardForm = (shouldResetCards = false) => {
    const { restaurant, user, updateUI, postUserCreditCardRegistrationType } = this.props
    const { pspRegisterUserCardType } = restaurant

    if (!restaurant.hasPaymentWithCardPrint) {
      return
    }

    if (shouldResetCards) {
      this.props.onResetUserCard()
      this.props.resetUserCreditCardToPspSend()
    }

    if (pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES) {
      postUserCreditCardRegistrationType(user.id, restaurant.id, undefined, {
        urlSuccess: getAbsolutePath(getRoute('ORDER__PAYMENT__SUCCESS')),
        urlFailure: getAbsolutePath(getRoute('ORDER__PAYMENT__FAILURE')),
        urlCancel: getAbsolutePath(getRoute('ORDER__PAYMENT__CANCEL')),
      })
    } else {
      updateUI({ showCreditCardForm: true })
    }
  }

  render() {
    const {
      isFetching,
      isEmptyCreditCards,
      isOnlyOneCreditCard,
      selectedCreditCard,
      creditCards,
      onToggleCreditCard,
      onClickDeleteCreditCard,
      setPaymentMethod,
      // datasPostCreditCardRegistrationType,
      isFetchingPostCreditCardRegistrationType,
      // statusSendUserCreditCardToPsp,
      user,
      restaurant,
      restaurantsGroup,
      amountToPay,
      ui,
      updateUI,
      orderProperties,
      classes,
      canRegisterCardWithName,
    } = this.props

    const { pspRegisterUserCardType } = restaurant

    return (
      <React.Fragment>
        <FormControlLabel
          onClick={() => {
            EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
              paymentMode: PaymentMethodsAllowed.CREDIT_CARD,
            })
            setPaymentMethod(PaymentMethodsAllowed.CREDIT_CARD)
          }}
          control={
            <Radio
              color="primary"
              name="paymentCreditCard"
              checked={orderProperties.payment.isCreditCardMethod}
              value="paymentCreditCard"
            />
          }
          label={
            <TypographyCustom type={orderProperties.payment.isCreditCardMethod ? '167' : '164'}>
              {restaurant.isPspSaferpay && tr('order.payment.online')}
              {!restaurant.isPspSaferpay && tr('order.payment.credit_card')}
            </TypographyCustom>
          }
        />

        {restaurant.hasPaymentWithCardPrint && orderProperties.payment.isCreditCardMethod && (
          <div className="u-marginTop10">
            <div>
              {isFetching ? (
                <LoadingBlock show />
              ) : (
                <React.Fragment>
                  {!isEmptyCreditCards && (
                    <React.Fragment>
                      {!isNil(creditCards) &&
                        creditCards.map((creditCard, index) => (
                          <BlockContainer
                            key={index}
                            type={BlockContainer.Type.GREY}
                            className="u-overflowHidden u-marginTop5"
                            classes={{
                              padding: classes.creditCardContainerPadding,
                            }}
                            onClick={
                              !isOnlyOneCreditCard
                                ? () => {
                                    const toBeSelected =
                                      !selectedCreditCard ||
                                      (selectedCreditCard &&
                                        creditCard.id !== selectedCreditCard.id)
                                    onToggleCreditCard(toBeSelected, creditCard)
                                    updateUI({
                                      showCreditCardForm: false,
                                    })
                                  }
                                : null
                            }
                          >
                            <div className="u-floatLeft">
                              <TypographyCustom type="127" className={classes.grey500}>
                                {creditCard.name}
                              </TypographyCustom>
                              <TypographyCustom type="147" className={'u-marginTop10'}>
                                {creditCard.number}
                              </TypographyCustom>
                              <TypographyCustom type="127" className={'u-marginTop5'}>
                                {tr('order.payment.credit_card.expire', {
                                  date: creditCard.expirationDateFormatted,
                                })}
                              </TypographyCustom>
                            </div>
                            <div
                              className={classNames(
                                classes.iconCreditCard,
                                'u-floatLeft u-marginLeft10'
                              )}
                            >
                              {creditCard.payMode === EPaymentMode.VISA && (
                                <img
                                  src={`${getPublicUrl()}/img/ic_VISA.png`}
                                  width="40"
                                  alt="VISA"
                                />
                              )}
                              {creditCard.payMode === EPaymentMode.MASTERCARD && (
                                <img
                                  src={`${getPublicUrl()}/img/ic_MASTERCARD.png`}
                                  width="40"
                                  alt="MASTERCARD"
                                />
                              )}
                            </div>
                            <div
                              className={classNames(classes.iconDeleteContainer, 'u-floatRight')}
                              onClick={() => {
                                onClickDeleteCreditCard(creditCard)
                              }}
                            >
                              <Delete className={classes.colorPrimary} />
                            </div>
                            <div className={classNames(classes.lineHeight53, 'u-floatRight')}>
                              <Checkbox
                                color="primary"
                                checked={
                                  selectedCreditCard && selectedCreditCard.id === creditCard.id
                                }
                                disabled={isOnlyOneCreditCard}
                              />
                            </div>
                          </BlockContainer>
                        ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {!ui.showCreditCardForm &&
                ((pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES &&
                  !isFetchingPostCreditCardRegistrationType) ||
                  (!isEmptyCreditCards &&
                    pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES)) && (
                  <div className="u-marginTop20">
                    <TypographyCustom type="204" align="center">
                      <Button
                        icon={<Add />}
                        label={tr('order.payment.add_new_credit_card')}
                        onClick={() => this.openCreditCardForm(true)}
                      />
                    </TypographyCustom>
                  </div>
                )}
            </div>

            {restaurant.hasPaymentWithCardPrint &&
              pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES && (
                <form className="u-marginTop30">
                  {(ui.showCreditCardForm || (isEmptyCreditCards && !isFetching)) && (
                    <React.Fragment>
                      {!isEmptyCreditCards && (
                        <TypographyCustom type="167" className={classes.payWithNewCard}>
                          {tr('order.payment.pay_with_new_card')}
                          <Button
                            variant="outlined"
                            label={tr('order.global.cancel')}
                            classes={{ root: 'u-floatRight' }}
                            onClick={() => {
                              if (isOnlyOneCreditCard) {
                                onToggleCreditCard(true, creditCards[0])
                              }
                              updateUI({ showCreditCardForm: false })
                            }}
                          />
                        </TypographyCustom>
                      )}
                      <AddCreditCardFormView
                        updateUI={updateUI}
                        formClasses={{
                          cvv: classes.cvv,
                          nameContainer: classes.nameContainer,
                          name: classes.name,
                        }}
                        restaurantId={restaurant.id}
                        tosUrl={restaurantsGroup?.userSettings?.tosUrl}
                        userId={user.id}
                        amountToPay={amountToPay}
                        withName={canRegisterCardWithName}
                      />
                    </React.Fragment>
                  )}
                </form>
              )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CreditCardPayment)
