import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import Card from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import HardwareKeyboardArrowRight from 'pmt-ui/svg-icons/hardware/keyboard-arrow-right'

import Button from '../Button'

const styles = theme => ({
  root: {
    cursor: 'pointer',
    overflow: 'initial',
  },
  imageContainer: {
    position: 'relative',
    height: 161,
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      float: 'left',
    },
  },
  image: {
    width: 110,
    height: 110,
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 40,
    },
  },
  placeholder: {
    opacity: 0.3,
  },
  textContainer: {
    position: 'relative',
    height: 130,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      height: 66,
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  name: {
    float: 'left',
    height: 35,
    width: '100%',
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 30px)',
      height: 'auto',
      minHeight: 16,
      maxHeight: 35,
      marginTop: 0,
      padding: 0,
    },
  },
  categoryIcon: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
  },
  buttonSee: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: 'calc(50% - 34px)',
    marginTop: theme.spacing(1),
  },
  unavailableLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.6)',
  },
  unavailableText: {
    color: theme.pmt.colors.white,
  },
  unavailableOpacity: {
    opacity: 0.6,
  },
  unavailableLabel: {
    position: 'absolute',
    top: -theme.spacing(1),
    right: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    color: theme.pmt.colors.white,
    background: theme.pmt.colors.darkGrey,
    borderRadius: 3,
  },
})

const CategoryCard = ({ category, restaurant, isMobile, onClick, classes }) => (
  <Card
    className={classes.root}
    onClick={e => {
      if (category.available) {
        onClick(e)
      }
    }}
  >
    {!isMobile && (
      <div className={classes.imageContainer}>
        {!isEmpty(category.image) ? (
          <img src={category.image} alt={category.name} className={classes.image} />
        ) : (
          <img
            src={restaurant.logo}
            alt={category.name}
            className={classNames(classes.image, classes.placeholder)}
          />
        )}
        {!category.available && (
          <div className={classes.unavailableLayout}>
            <TypographyCustom type="167" className={classes.unavailableText}>
              {tr('order.catalog.unavailable')}
            </TypographyCustom>
          </div>
        )}
      </div>
    )}
    <div
      className={classNames(classes.textContainer, {
        [classes.unavailableOpacity]: !category.available,
      })}
    >
      {!category.available &&
        isMobile && (
          <TypographyCustom type="127" className={classes.unavailableLabel}>
            {tr('order.catalog.unavailable')}
          </TypographyCustom>
        )}
      <TypographyCustom type="167" align={isMobile ? 'left' : 'center'} className={classes.name}>
        {category.name}
      </TypographyCustom>
      {isMobile ? (
        <span className={`${classes.categoryIcon} u-floatRight`}>
          <HardwareKeyboardArrowRight className={classes.categoryIcon} viewBox="0 0 17 24" />
        </span>
      ) : (
        <Button
          variant="outlined"
          label={tr('order.global.see')}
          className={classes.buttonSee}
          disabled={!category.available}
        />
      )}
    </div>
  </Card>
)

export default withStyles(styles)(CategoryCard)
