import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { redirectTo, goBack } from 'pmt-modules/routing'
import { logout } from 'pmt-modules/auth/actions'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import AccountCircle from 'pmt-ui/svg-icons/action/account-circle'
import ArrowDropDown from 'pmt-ui/svg-icons/navigation/arrow-drop-down'
import Menu, { MenuItem } from 'pmt-ui/Menu'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import { getIncognitoCookie } from 'pmt-modules/auth/utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.primary.main,
  },
  login: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
})

class Incognito extends React.Component {
  state = {
    anchorEl: null,
  }

  handleLogout = () => {
    this.props.logout()
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { classes, incognito, width, authenticatedUser } = this.props

    return (
      <div className={classes.root}>
        {incognito ? (
          <React.Fragment>
            <div
              className={classes.wrapper}
              aria-owns={anchorEl ? 'user-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <AccountCircle className={classes.icon} />
              {isWidthUp('sm', width) && (
                <TypographyCustom type="164" skipColor component="span">
                  {[
                      authenticatedUser.firstName,
                      authenticatedUser.lastName,
                      authenticatedUser.phone,
                  ]
                    .filter(Boolean)
                    .join(' ') || tr('order.global.incognito_header_label')}
                </TypographyCustom>
              )}
              <ArrowDropDown />
            </div>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              anchorReference="anchorEl"
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={this.handleLogout}>{tr('order.global.forget')}</MenuItem>
            </Menu>
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  incognito: getIncognitoCookie(),
  authenticatedUser: getAuthenticatedUser(state),
})

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, {
    redirectTo,
    goBack,
    logout,
  })
)(Incognito)
