import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from 'pmt-ui/ButtonBase'
import Hidden from 'pmt-ui/Hidden'
import { withStyles } from 'pmt-ui/styles'
import HardwareKeyboardArrowRight from 'pmt-ui/svg-icons/hardware/keyboard-arrow-right'

const styles = theme => ({
  root: {
    width: 180,
    height: 180,
    display: 'flex',
    fontSize: 20,
    flexDirection: 'column',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.pmt.colors.white,
    // this syntax is coming from here
    // http://cssinjs.org/jss-nested/?v=v6.0.1#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
    // https://github.com/mui-org/material-ui/blob/v1-beta/src/Button/Button.js#L84
    '&$keyboardFocused': {
      boxShadow: theme.shadows[6],
    },
    '&:active': {
      boxShadow: theme.shadows[8],
    },
    '&$disabled': {
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.text.divider,
    },
    '&:hover': {
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[300],
      },
      '&$disabled': {
        backgroundColor: theme.palette.text.divider,
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      height: 68,
      flexDirection: 'row',
      padding: theme.spacing(2),
    },
  },
  keyboardFocused: {},
  disabled: {
    color: theme.palette.action.disabled,
  },
  icon: {
    width: 90,
    height: 90,
    marginTop: -theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      width: 40,
      height: 40,
      marginTop: 0,
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      marginLeft: theme.spacing(2),
      height: 40,
      lineHeight: '40px',
      fontSize: 16,
    },
  },
  floatingArrow: {
    float: 'right',
    width: 40,
    height: 40,
  },
})

const ButtonSquare = ({ classes, label, icon, onClick }) => (
  <ButtonBase className={classes.root} onClick={onClick}>
    {React.cloneElement(icon, {
      className: classes.icon,
    })}
    <span className={classes.label}>{label}</span>
    <Hidden smUp>
      <HardwareKeyboardArrowRight className={classes.floatingArrow} />
    </Hidden>
  </ButtonBase>
)

ButtonSquare.propTypes = {
  colors: PropTypes.object,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
}

export default withStyles(styles)(ButtonSquare)
