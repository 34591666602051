import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let HardwareKeyboardArrowRight = props => {
  const { slideCount, currentSlide, ...svgProps } = props

  return (
    <SvgIcon {...svgProps}>
      <path d="M9.29,15.88L13.17,12L9.29,8.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0l4.59,4.59 c0.39,0.39,0.39,1.02,0,1.41l-4.59,4.59c-0.39,0.39-1.02,0.39-1.41,0l0,0C8.91,16.91,8.9,16.27,9.29,15.88z" />
    </SvgIcon>
  )
}
HardwareKeyboardArrowRight = pure(HardwareKeyboardArrowRight)
HardwareKeyboardArrowRight.displayName = 'HardwareKeyboardArrowRight'
HardwareKeyboardArrowRight.pmtName = 'SvgIcon'

export default HardwareKeyboardArrowRight
