import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'

import Button from 'pmt-ui/Button'
import Dialog, { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Slide from 'pmt-ui/transitions/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

/**
 * HOC func. Display an alert dialog, with a title and an optionnal message
 * The children must be a function that will receive:
 * - openAlertDialog: function
 */
class AlertDialog extends React.Component {
  state = {
    open: false,
  }

  handleClickOpen = e => {
    if (e) {
      e.stopPropagation()
    }
    this.setState({ open: true })
  }

  handleClose = e => {
    if (e) {
      e.stopPropagation()
    }
    this.setState({ open: false })
  }

  handleAgree = e => {
    if (e) {
      e.stopPropagation()
    }
    this.handleClose(e)
    this.props.onAgree(e)
  }

  handleDisagree = e => {
    if (e) {
      e.stopPropagation()
    }
    this.handleClose(e)
    this.props.onDisagree && this.props.onDisagree(e)
  }

  render() {
    const {
      children,
      title,
      content,
      dialogProps,
      cancelLabel = tr('global.cancel'),
      okLabel = tr('global.validate'),
    } = this.props

    return (
      <React.Fragment>
        {children({
          openAlertDialog: this.handleClickOpen,
        })}
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          {...dialogProps}
        >
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="primary">
              {cancelLabel}
            </Button>
            <Button onClick={this.handleAgree} variant="contained" color="primary">
              {okLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

AlertDialog.propTypes = {
  /**
   * callaback called when the user agree
   */
  onAgree: PropTypes.func.isRequired,
  /**
   * callaback called when the user disagree
   * optionnal
   */
  onDisagree: PropTypes.func,
  /**
   * Title of the dialog
   */
  title: PropTypes.string.isRequired,
  /**
   * Content of the dialog
   * optionnal
   */
  content: PropTypes.any,
  /**
   * the rest of the view.
   * Must be a function: ({ openAlertDialog }) => {}
   */
  children: PropTypes.func.isRequired,
}

export default AlertDialog
