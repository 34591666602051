import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import compose from 'recompose/compose'

import { EventManager } from 'pmt-modules/event'
import { setSelectedCategory, setParentCategories } from 'pmt-modules/orderPlugin'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import Hidden from 'pmt-ui/Hidden'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import Carousel from 'pmt-ui/Carousel'

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  categoryCarousel: {
    background: theme.pmt.colors.white,
    borderBottom: `1px solid ${theme.pmt.colors.greyBackground}`,
  },
  categoryName: {
    width: '100%',
    minHeight: 17,
    maxHeight: 38,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textAlign: 'center',
  },
  tabRoot: {
    height: 72,
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
})

class Categories extends React.PureComponent {
  handleCategoryChange = value => {
    const category = this.props.categories.filter(category => category.id === value).pop()
    EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
      category,
    })

    this.props.setParentCategories([])
    this.props.redirectTo(getRoute('ORDER__CATEGORY'), {
      restaurantId: this.props.restaurant.id,
      categoryId: category.id,
    })
  }

  render() {
    const { categories, classes, selectedCategory, parentCategories } = this.props

    return (
      <Hidden smDown={!isEmpty(parentCategories)}>
        <div className={classes.categoryCarousel}>
          {!isNil(selectedCategory) && (
            <Carousel
              elements={categories}
              getLabel={category => (
                <TypographyCustom
                  type={
                    !isNil(selectedCategory) && selectedCategory.id === category.id ? '167' : '164'
                  }
                  className={classes.categoryName}
                >
                  {category.name}
                </TypographyCustom>
              )}
              getValue={category => category.id}
              selectedElement={selectedCategory}
              onChange={this.handleCategoryChange}
              classes={{ root: classes.tabRoot }}
            />
          )}
        </div>
      </Hidden>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      setSelectedCategory,
      setParentCategories,
      redirectTo,
    }
  )
)(Categories)
