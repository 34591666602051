import { tr } from 'pmt-modules/i18n'
import React, { useEffect } from 'react'
import compose from 'recompose/compose'

import classNames from 'classnames'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Divider from 'pmt-ui/Divider'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import { Categories, ItemList, SideInformation } from './components'
import BlockContainer from '../../components/BlockContainer'
import CategoriesBreadcrumb from './components/CategoriesBreadcrumb'
import CategoryMobileHeader from './components/CategoryMobileHeader'
import MobileCartButton from './components/MobileCartButton'
import MessageBlock from '../../components/MessageBlock'
import { ButtonLink } from '../../components/Button'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'

import TableNumberInput from 'app/components/TableNumberInput'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(2),
    },
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  loadingBlock: {
    [theme.breakpoints.down('sm')]: {
      background: theme.pmt.colors.greyBackground,
    },
  },
  catalogError: {
    textAlign: 'center',
    '&> button': {
      marginTop: theme.spacing(3),
    },
  },
  textFooter: {
    marginTop: theme.spacing(5),
    color: theme.pmt.colors.grey600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    whiteSpace: 'pre-line', // to display \n as new lines
    lineHeight: '1.5',
    '&> a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
})

const MobileCatalogTableNumberForm = ({
  orderProperties,
  classes,
  ui,
  updateUI,
  enableModifyTableNumber,
}) => {
  useEffect(() => {
    if (orderProperties.isMissingTableNumber) {
      // this init the form
      updateUI({ showTableNumberForm: true })
    } else if (!enableModifyTableNumber) {
      // when tableNumber is automatically set after fetching the Check with posCheckId
      updateUI({ showTableNumberForm: false })
    }
  }, [orderProperties.isMissingTableNumber, enableModifyTableNumber, updateUI])

  return (
    <Grid item xs={12} md={6} lg={5}>
      <BlockContainer type={BlockContainer.Type.WHITE} className={classes.root}>
        <div>
          <CustomTextsContainer>
            {({ texts }) => (
              <TypographyCustom type={247}>
                {!isNil(texts) && !isNil(texts.ORDER__GLOBAL__WHICH_TABLE_NUMBER)
                  ? texts.ORDER__GLOBAL__WHICH_TABLE_NUMBER
                  : tr('order.store_locator.table_number')}
              </TypographyCustom>
            )}
          </CustomTextsContainer>
          <TableNumberInput
            tableNumber={orderProperties.tableNumber}
            type={TableNumberInput.Type.SMALL}
            handleCloseInput={() => {
              updateUI({ showTableNumberForm: false })
            }}
            tableNumberInputButtonLabel={tr('order.global.continue')}
          />
          <CustomTextsContainer>
            {({ texts }) => (
              <TypographyCustom
                type="164"
                className={classNames('u-marginTop30 u-marginBottom30', classes.grey500)}
              >
                {!isNil(texts) && !isNil(texts.ORDER__GLOBAL__TABLE_NUMBER_HELPER)
                  ? texts.ORDER__GLOBAL__TABLE_NUMBER_HELPER
                  : tr('order.store_locator.table_number.helper')}
              </TypographyCustom>
            )}
          </CustomTextsContainer>
        </div>
      </BlockContainer>
    </Grid>
  )
}

const View = ({
  appConfigPreset,
  classes,
  isFetchingCatalog,
  isFetchingRestaurant,
  catalog,
  catalogError,
  restaurantError,
  restaurant,
  route,
  onRedirectToCart,
  selectedCategory,
  parentCategories,
  isSubCategory,
  from,
  orderProperties,
  handleRedirectToStoreLocator,
  width,
  ui,
  updateUI,
  enableModifyTableNumber,
  itemListFromCart,
}) => {
  if (
    isWidthDown('sm', width) &&
    (orderProperties.isMissingTableNumber || ui.showTableNumberForm)
  ) {
    return (
      <MobileCatalogTableNumberForm
        orderProperties={orderProperties}
        classes={classes}
        ui={ui}
        updateUI={updateUI}
        enableModifyTableNumber={enableModifyTableNumber}
      />
    )
  } else {
    return (
      <div className={classes.root}>
        <Grid spacing={2} container>
          <Grid item container direction="column" spacing={0} xs={12} md={7} lg={8} wrap="nowrap">
            {!isFetchingRestaurant && restaurant && !isFetchingCatalog && catalog && (
              <React.Fragment>
                {selectedCategory ? (
                  <Categories
                    catalog={catalog}
                    categories={catalog.categories}
                    restaurant={restaurant}
                    selectedCategory={selectedCategory}
                    parentCategories={parentCategories}
                  />
                ) : (
                  <Categories
                    categories={catalog.categories}
                    restaurant={restaurant}
                    selectedCategory={null}
                  />
                )}
              </React.Fragment>
            )}
            <BlockContainer
              className="u-flex1"
              type={BlockContainer.Type.WHITE}
              mobileType={BlockContainer.Type.GREY}
              mobilePadding={isSubCategory ? 0 : '10px 0'}
            >
              {(isFetchingRestaurant || isNil(restaurant) || isFetchingCatalog || isNil(catalog)) &&
              isNil(restaurantError) &&
              isNil(catalogError) ? (
                <LoadingBlock
                  show
                  classes={{
                    loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
                  }}
                />
              ) : isNil(restaurant) && !isFetchingRestaurant && restaurantError ? (
                <ErrorBlock
                  error={restaurantError}
                  mode={ErrorBlock.Mode.CUSTOM}
                  customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                />
              ) : isNil(catalog) && !isFetchingCatalog && catalogError ? (
                <div className={classes.catalogError}>
                  <ErrorBlock
                    error={catalogError}
                    mode={ErrorBlock.Mode.CUSTOM}
                    customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                  />
                  <ButtonLink onClick={handleRedirectToStoreLocator}>
                    {tr('order.global.choose_restaurant')}
                  </ButtonLink>
                </div>
              ) : selectedCategory ? (
                <React.Fragment>
                  <CategoryMobileHeader
                    selectedCategory={selectedCategory}
                    parentCategories={parentCategories}
                    restaurant={restaurant}
                  />
                  <div className={classes.content}>
                    <CategoriesBreadcrumb
                      selectedCategory={selectedCategory}
                      parentCategories={parentCategories}
                      restaurant={restaurant}
                    />
                    <ItemList
                      items={selectedCategory.items}
                      subCategories={selectedCategory.categories}
                      catalog={catalog}
                      restaurant={restaurant}
                      selectedCategory={selectedCategory}
                      parentCategories={parentCategories}
                      itemListFromCart={itemListFromCart}
                    />
                  </div>
                  <CustomTextsContainer>
                    {({ texts }) =>
                      !isNil(texts) &&
                      !isNil(texts.ORDER__CATALOG__MENU__FOOTER) && (
                        <TypographyCustom
                          type="124"
                          className={classNames(
                            'custom-css-ORDER__CATALOG__MENU__FOOTER custom-css-ORDER__CATALOG__MENU__FOOTER-on-catalog',
                            classes.textFooter
                          )}
                          html={texts.ORDER__CATALOG__MENU__FOOTER} // we allow html so they can put links
                        />
                      )
                    }
                  </CustomTextsContainer>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <div className="u-marginTop60 u-marginBottom60 u-textAlignCenter">
                    <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                    <TypographyCustom type="207" align="center" className={classes.grey500}>
                      {tr('order.menu.no_item_in_selected_category')}
                    </TypographyCustom>
                  </div>
                </React.Fragment>
              )}
            </BlockContainer>
          </Grid>

          {!isFetchingRestaurant && restaurant && (
            // price formatting is depending on Restaurant (because of the currency),
            // so we don't display it until the restaurant is loaded
            <React.Fragment>
              <Hidden smDown>
                <Grid item md={5} lg={4}>
                  <SideInformation
                    orderProperties={orderProperties}
                    restaurant={restaurant}
                    enableModifyTableNumber={enableModifyTableNumber}
                    // cartModifiers={catalog ? catalog.cartModifiers : []}
                    onRedirectToCart={onRedirectToCart}
                    route={route}
                  />
                </Grid>
              </Hidden>
              <MobileCartButton
                appConfigPreset={appConfigPreset}
                onRedirectToCart={onRedirectToCart}
              />
            </React.Fragment>
          )}
        </Grid>
      </div>
    )
  }
}

export default compose(withStyles(styles), withWidth())(View)
