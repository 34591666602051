import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { ProfileFormView } from 'pmt-modules/user/forms/profile'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withForm, FormType } from 'pmt-modules/form'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'

import Button from '../../../components/Button'
import BlockContainer from '../../../components/BlockContainer'
import MessageBlock from '../../../components/MessageBlock'
import SideSummary from '../../../components/SideSummary'
import Breadcrumb from '../../../components/Breadcrumb'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  hello: {
    marginBottom: theme.spacing(2),
  },
  info: {
    color: theme.palette.primary.light,
    background: theme.pmt.colors.grey100,
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submit: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  gridInverse: {
    flexDirection: 'row-reverse',
  },
  height100: {
    height: '100%',
  },
})

const breadcrumbElements = [
  {
    name: 'CATALOG',
    active: true,
    hiddenMobile: true,
  },
  {
    name: 'CART',
    active: true,
  },
  {
    name: 'INFORMATIONS',
    active: true,
    current: true,
  },
  {
    name: 'DELIVERY_ADDRESS',
  },
  {
    name: 'PAYMENT',
  },
]

const View = ({
  classes,
  formData,
  formErrors,
  formIsValid,
  onChange,
  frontSettings,
  itemListFromCart,
  orderProperties,
  restaurant,
  hasPhoneRequired,
  isFetching,
  errors,
  onSubmit,
  user,
}) => (
  <div className={classes.root}>
    <Grid container spacing={0} className={classes.gridInverse}>
      <Grid item xs={12} md={4} lg={3} className={'u-relative'}>
        {isNil(orderProperties.mode) || isNil(restaurant) ? (
          <LoadingBlockWrapper show />
        ) : (
          <SideSummary
            orderProperties={orderProperties}
            restaurant={restaurant}
            cartResumeEnabled
            dueDateModificationEnabled={false}
          />
        )}
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <BlockContainer
          className={classNames(classes.height100, 'u-relative')}
          type={BlockContainer.Type.WHITE}
        >
          {!isNil(user) ? (
            <React.Fragment>
              <Breadcrumb className="u-marginBottom30" elements={breadcrumbElements} />
              <LoadingBlockWrapper show={isFetching} />

              <ErrorBlock
                error={errors}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />

              <TypographyCustom type="284" className={classes.hello}>
                {tr('order.profile.hello_name', {
                  name: user.firstName,
                })}
              </TypographyCustom>

              {hasPhoneRequired() &&
                isEmpty(user.phone) && (
                  <div className={classes.info}>
                    <MessageBlock
                      type={MessageBlock.Type.INFO}
                      text={tr('order.profile.missing_phone')}
                    />
                  </div>
                )}

              <form
                onSubmit={e => {
                  e.preventDefault()
                  onSubmit(formData)
                }}
              >
                <ProfileFormView
                  formData={formData}
                  formErrors={formErrors}
                  onChange={onChange}
                  defaultData={user}
                />
                <div className={classes.submitContainer}>
                  <Button
                    size="large"
                    type="submit"
                    label={tr(
                      frontSettings.display.paymentPage || isEmpty(itemListFromCart)
                        ? 'order.profile.submit'
                        : 'order.profile.submit_and_order'
                    )}
                    classes={{ root: classes.submit }}
                    disabled={!formIsValid || isFetching}
                  />
                </div>
              </form>
            </React.Fragment>
          ) : (
            <LoadingBlockWrapper show />
          )}
        </BlockContainer>
      </Grid>
    </Grid>
  </div>
)

export default compose(
  withForm(FormType.USER_PROFILE),
  withStyles(styles)
)(View)
