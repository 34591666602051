import React from 'react'

import AlertDialog from 'pmt-ui/AlertDialog/AlertDialog'
import Button from 'pmt-ui/Button'

const AlertButton = ({
  onAgree,
  onDisagree,
  alertTitle,
  alertContent,
  okLabel,
  cancelLabel,
  children,
  label,
  button = <Button />,
  ...buttonOtherProps
}) => (
  <AlertDialog
    onAgree={onAgree}
    onDisagree={onDisagree}
    title={alertTitle}
    content={alertContent}
    okLabel={okLabel}
    cancelLabel={cancelLabel}
  >
    {({ openAlertDialog }) =>
      React.cloneElement(
        button,
        {
          onClick: e => {
            e.stopPropagation()
            openAlertDialog(e)
          },
          onSelect: e => {
            // for checkboxes (with <OptionValue/>)
            e.stopPropagation()
            openAlertDialog(e)
          },

          ...buttonOtherProps,
        },
        <div>{children || label}</div>
      )
    }
  </AlertDialog>
)

export default AlertButton
