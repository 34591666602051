import React from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'

import { getFrontSettings } from 'pmt-modules/appConfig'

import {
  fetchRestaurantList,
  fetchRestaurantListByGeoPt,
  isFetchingRestaurantList,
  getRestaurantListError,
} from 'pmt-modules/restaurant'

import {
  getOrderProperties,
  makeGetOrderRestaurantListData,
} from 'pmt-modules/orderFront/selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant list and pass it to the children
 *
 */
class OrderRestaurantListContainer extends React.PureComponent {
  componentDidMount() {
    if (this.props.cancelIP) {
      return
    }

    this.loadRestaurantList(this.props)
  }

  componentWillReceiveProps(nextProps) {
    const { mode } = this.props

    if (!isNil(mode) && !isNil(nextProps.mode) && mode !== nextProps.mode) {
      this.loadRestaurantList(nextProps)
    }
  }

  loadRestaurantList = props => {
    const limit = this.props.appConfigFrontSettings.restaurants.list.limit

    if (!props.isDelivery && isNil(props?.lat) && isNil(props?.lng)) {
      this.props.fetchRestaurantList({
        restaurantId: props.orderFrontProperties.restaurantId || null,
        useIP: isNil(props.orderFrontProperties.restaurantId),
        limit,
      })
    } else if (!isNil(props?.lat) && !isNil(props?.lng)) {
      this.props.fetchRestaurantListByGeoPt(props.lat, props.lng, { limit })
    }
  }

  render() {
    const {
      children,
      isDelivery,
      deliveryMaxRange,
      orderFrontProperties,
      ...otherProps
    } = this.props

    return children({
      loadRestaurantList: location => {
        this.loadRestaurantList(location)
      },
      ...otherProps,
    })
  }
}

OrderRestaurantListContainer.defaultProps = {
  cancelIP: false,
  geoPt: false,
  lat: null,
  lng: null,
}

OrderRestaurantListContainer.propTypes = {
  fetchRestaurantList: PropTypes.func.isRequired,
  fetchRestaurantListByGeoPt: PropTypes.func.isRequired,
  isFetchingRestaurantList: PropTypes.bool,
  cancelIP: PropTypes.bool,
}

const makeMapStateToProps = () => {
  const getOrderRestaurantListData = makeGetOrderRestaurantListData()

  return (state, props) => ({
    // TODO: this housld not be in this module
    orderFrontProperties: getOrderProperties(state),
    restaurantListData: getOrderRestaurantListData(state, props),
    isFetchingRestaurantList: isFetchingRestaurantList(state),
    restaurantListError: getRestaurantListError(state),

    appConfigFrontSettings: getFrontSettings(state),
  })
}

export default connect(
  makeMapStateToProps,
  {
    fetchRestaurantList,
    fetchRestaurantListByGeoPt,
  }
)(OrderRestaurantListContainer)
