import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

import { EventManager } from 'pmt-modules/event'
import { setParentCategories } from 'pmt-modules/orderPlugin'
import { openMenu } from 'pmt-modules/orderMenu'
import { openProduct } from 'pmt-modules/orderProduct'
import { addToCartFromShortcut } from 'pmt-modules/cart'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import Item from './Item'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
})

class ItemList extends React.PureComponent {
  render() {
    const {
      classes,
      items,
      subCategories,
      catalog,
      restaurant,
      openMenu,
      openProduct,
      addToCartFromShortcut,
      parentCategories,
      setParentCategories,
      selectedCategory,
      redirectTo,
      itemListFromCart,
    } = this.props

    return (
      <Grid spacing={2} container className={classes.root}>
        {!isEmpty(subCategories) && (
          <Grid item xs={12} className="u-marginBottom20">
            <Grid spacing={2} container>
              {subCategories.map((item) => (
                <Grid key={`item-${item.id}`} item xs={12} sm={6} md={6} lg={4}>
                  <Item
                    item={item}
                    isCategory
                    catalog={catalog}
                    restaurant={restaurant}
                    onClickCard={(category) => {
                      // update parentCategories breadcrumb.
                      const newParentCategories = isArray(parentCategories) ? parentCategories : []
                      if (!selectedCategory.isRootCategory === true) {
                        newParentCategories.push(selectedCategory)
                      }
                      EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
                        category,
                      })

                      setParentCategories(newParentCategories)
                      redirectTo(getRoute('ORDER__CATEGORY'), {
                        restaurantId: restaurant.id,
                        categoryId: category.id,
                      })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid spacing={2} container>
            {items.map((item) => (
              <Grid key={`item-${item.id}`} item xs={12} sm={6} md={6} lg={4}>
                <Item
                  item={item}
                  catalog={catalog}
                  restaurant={restaurant}
                  openMenu={openMenu}
                  openProduct={openProduct}
                  addToCartFromShortcut={addToCartFromShortcut}
                  selectedCategory={selectedCategory}
                  alreadySelectedQuantity={
                    // sum of the "quantity" of all items having the same id as the current item
                    // eg. for a menu, we sum the quantities, whatever the choosen options

                    // we add the comparison of names too, just in case a menu and a product have the same id
                    itemListFromCart.reduce((total, i) => {
                      if (i.id === item.id && i.name === item.name) {
                        return total + i.quantity
                      } else {
                        return total
                      }
                    }, 0)
                  }
                  onClickCard={() => {
                    if (item.available) {
                      if (item.isProduct) {
                        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                          item,
                          category: selectedCategory,
                          restaurant,
                        })
                        openProduct(item, {
                          restaurantId: restaurant.id,
                          showDialog: false,
                        })
                      } else if (item.isMenu) {
                        EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
                          item,
                          category: selectedCategory,
                          restaurant,
                        })
                        openMenu(item, {
                          restaurantId: restaurant.id,
                        })
                      }
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setParentCategories,
    addToCartFromShortcut,
    openMenu,
    openProduct,
    redirectTo,
  })
)(ItemList)
