import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import {
  forgotPassword,
  resetForgotPasswordData,
  getForgotPasswordData,
  getForgotPasswordError,
  getForgotPasswordIsFetching,
} from 'pmt-modules/forgotPassword'
import { FormType, withForm } from 'pmt-modules/form'
import { OrderPluginUrlCheckerContainer, withOrderProperties } from 'pmt-modules/orderPlugin'
import { redirectTo } from 'pmt-modules/routing'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import OrderPage from '../../components/OrderPage'

import View from './View'

/**
 * This page is used by not-authenticated users when they forgot their password,
 * or by authenticated users when they want to reset their password
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withScrollToTop()
class ForgotPasswordPage extends React.PureComponent {
  componentWillUnmount() {
    this.props.resetForgotPasswordData()
  }

  handleSubmit = () => {
    EventManager.dispatch(EventManager.Events.ON_FORGOT_PASSWORD_SUBMIT)

    const credentials = this.props.formData
    this.props.forgotPassword(credentials.username)
  }

  render() {
    const {
      location,
      forgotPasswordData,
      forgotPasswordError,
      formIsValid,
      orderProperties,
      restaurant,
      isFetching,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location}>
          <View
            isFetching={isFetching}
            forgotPasswordData={forgotPasswordData}
            forgotPasswordError={forgotPasswordError}
            formIsValid={formIsValid}
            onSubmit={this.handleSubmit}
          />
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: getForgotPasswordIsFetching(state),
  forgotPasswordData: getForgotPasswordData(state),
  forgotPasswordError: getForgotPasswordError(state),
})

export default withForm(FormType.FORGOT_PASSWORD)(
  compose(
    connect(mapStateToProps, {
      redirectTo,
      forgotPassword,
      resetForgotPasswordData,
    }),
    withWidth()
  )(ForgotPasswordPage)
)
