import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { TypographyCustom } from 'pmt-ui/Typography'
import Card, { CardContent } from 'pmt-ui/Card'

const GeneralDatasCard = ({
  orderProperties,
  dueDate,
  truncOrderId,
  orderAppConfig,
  restaurant,
  classes,
}) => (
  <Card className={classNames('u-marginBottom20', classes.maxWidthContainer)}>
    <CardContent className={classes.cardContent}>
      <div className="u-marginBottom15">
        <TypographyCustom type="164" component="p" className="u-marginBottom4 u-textAlignCenter">
          {tr('order.confirm.order_number')}
        </TypographyCustom>
        <TypographyCustom
          type="284"
          component="p"
          className="u-marginBottom10 u-textAlignCenter"
          style={{ fontSize: '70px' }}
        >
          {truncOrderId}
        </TypographyCustom>
        <TypographyCustom type="164" component="p">
          {orderProperties.isDelivery ? (
            tr('order.global.delivery')
          ) : orderProperties.isOnSite ? (
            <React.Fragment>
              {tr('order.global.on_site')}
              {orderAppConfig.hasTableNumber && (
                <Fragment>
                  &nbsp;-&nbsp;
                  {tr('order.global.on_site_table_number', {
                    tableNumber: orderProperties.tableNumber,
                  })}
                </Fragment>
              )}
            </React.Fragment>
          ) : (
            tr('order.global.take_away')
          )}
        </TypographyCustom>
        {orderProperties.isDelivery && (
          <React.Fragment>
            <TypographyCustom type="144" component="p" className={classes.grey500}>
              {orderProperties.deliveryAddress.formattedAddress}
            </TypographyCustom>
            {!isNil(orderProperties.deliveryAddress.complement) && (
              <TypographyCustom
                type="144"
                component="p"
                className={classNames(classes.grey500, classes.italic)}
              >
                {orderProperties.deliveryAddress.complement}
              </TypographyCustom>
            )}
          </React.Fragment>
        )}
      </div>
      {!orderProperties.isOnSite || !orderAppConfig.hasTableNumber ? (
        <React.Fragment>
          <div className="u-marginBottom15">
            <TypographyCustom type="164" component="p">
              {restaurant.name}
            </TypographyCustom>
            {!orderProperties.isDelivery && (
              <TypographyCustom
                type="144"
                component="p"
                className={classNames(classes.grey500, 'u-marginTop5')}
              >
                {restaurant.address.street}{' '}
                {`${restaurant.address.postCode} ${restaurant.address.city}`}
              </TypographyCustom>
            )}
          </div>
          <TypographyCustom type="164" component="p">
            {dueDate}
          </TypographyCustom>
        </React.Fragment>
      ) : (
        <CustomTextsContainer>
          {({ texts }) => (
            <TypographyCustom type="144" component="p" className={classes.grey500}>
              {!isNil(texts) && !isNil(texts.ORDER__CONFIRM__ON_SITE_INFORMATION)
                ? texts.ORDER__CONFIRM__ON_SITE_INFORMATION
                : tr('order.confirm.on_site.information')}
            </TypographyCustom>
          )}
        </CustomTextsContainer>
      )}
    </CardContent>
  </Card>
)

export default GeneralDatasCard
