import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'

import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

const styles = theme => ({
  addressArea: {
    color: theme.pmt.colors.grey500,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      ...theme.pmt.mixins.textEllipsis,
    },
  },
  restaurantName: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  address: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
})

const Address = ({ classes, orderProperties, restaurant, width }) => (
  <GeolocationContainer>
    {({ geolocationComputeAddress }) => (
      <div className={classes.addressArea}>
        {restaurant && (
          <TypographyCustom
            component="span"
            type={isWidthUp('sm', width) ? 164 : 144}
            className={classes.restaurantName}
          >
            {restaurant.name}
          </TypographyCustom>
        )}
        <TypographyCustom
          skipColor
          component="span"
          type={isWidthUp('sm', width) ? 164 : 144}
          className={classes.address}
        >
          {orderProperties.isDelivery && geolocationComputeAddress ? (
            <React.Fragment>
              {tr('order.header.delivery_catch_phrase')}
              {geolocationComputeAddress.formattedAddress}
            </React.Fragment>
          ) : (
            restaurant && (
              <React.Fragment>
                {restaurant.address.street}
                ,&nbsp;
                {restaurant.address.postCode}
                &nbsp;
                {restaurant.address.city}
              </React.Fragment>
            )
          )}
        </TypographyCustom>
      </div>
    )}
  </GeolocationContainer>
)

export default compose(
  withWidth(),
  withStyles(styles)
)(Address)
