import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { withAppConfig } from 'pmt-modules/appConfig'
import { logout, haveAuthCredentials } from 'pmt-modules/auth'
import { getItemListFromCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { FormType, withForms } from 'pmt-modules/form'
import { OrderPluginUrlCheckerContainer, getOrderProperties } from 'pmt-modules/orderPlugin'
import { fieldsType } from 'pmt-modules/registration/forms/register/constants'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import {
  withUserMe,
  updateProfile,
  isFetchingPutUserMe,
  getErrorputUserMe,
} from 'pmt-modules/userMe'
import withRestaurantsGroupMine from 'pmt-modules/restaurantsGroup/components/withRestaurantsGroup'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../../components/OrderPage'

import View from './View'

@withAppConfig
@withRestaurant
@withRestaurantsGroupMine()
@withUserMe
@withScrollToTop()
class UserAccountPage extends React.PureComponent {
  state = {
    submittedForm: null,
  }

  constructor(props) {
    super(props)

    // user doesn't have credentials, go back to login
    if (!this.props.haveAuthCredentials) {
      this.props.redirectTo(getRoute('LOGIN'))
    }
  }

  componentWillReceiveProps(nextProps) {
    // user doesn't have credentials, go back to login
    if (this.props.haveAuthCredentials && !nextProps.haveAuthCredentials) {
      this.props.redirectTo(getRoute('LOGIN'))
    }
  }

  hasPhoneRequired = () =>
    find(
      this.props.userAppConfig.registration.form.fields,
      ({ required, type }) => required && type === fieldsType.PHONE
    )

  handleGoBack = () => {
    const { restaurant, itemListFromCart, redirectTo } = this.props

    if (!isNil(restaurant) || !isEmpty(itemListFromCart)) {
      redirectTo(getRoute('ORDER__CATALOG'), {
        restaurantId: restaurant.id,
      })
    } else {
      redirectTo(getRoute('ORDER__MODE'))
    }
  }

  handleLogout = () => {
    this.props.logout()
    this.props.redirectTo(getRoute('LOGIN'))
  }

  handleSubmit = ({ formData, formType }) => {
    EventManager.dispatch(EventManager.Events.ON_PROFILE_SUBMIT)

    this.setState({
      submittedForm: formType,
    })

    this.props.updateProfile({
      id: this.props.user.id,
      ...this.props.user,
      ...formData,
    })
  }

  render() {
    const {
      appConfig,
      frontAppConfig,
      isFetchingMe,
      isFetchingPutUserMe,
      isFetchingRestaurantsGroup,
      restaurantsGroup,
      itemListFromCart,
      location,
      me,
      orderProperties,
      restaurant,
      updateUserError,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location}>
          <View
            appConfig={appConfig}
            frontAppConfig={frontAppConfig}
            itemListFromCart={itemListFromCart}
            hasPhoneRequired={this.hasPhoneRequired}
            orderProperties={orderProperties}
            restaurantsGroup={restaurantsGroup}
            isFetching={isFetchingMe || isFetchingRestaurantsGroup}
            isFetchingPutUserMe={isFetchingPutUserMe}
            onSubmit={this.handleSubmit}
            user={me}
            errors={updateUserError}
            handleGoBack={this.handleGoBack}
            handleLogout={this.handleLogout}
            submittedForm={this.state.submittedForm}
          />
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = state => ({
  haveAuthCredentials: haveAuthCredentials(state),
  isFetchingPutUserMe: isFetchingPutUserMe(state),
  itemListFromCart: getItemListFromCart(state),
  orderProperties: getOrderProperties(state),
  updateUserError: getErrorputUserMe(state),
})

export default withForms([FormType.USER_PROFILE, FormType.USER_PROFILE_DIETARY])(
  compose(
    connect(
      mapStateToProps,
      {
        updateProfile,
        logout,
        redirectTo,
      }
    ),
    withWidth()
  )(UserAccountPage)
)
