import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { getAuth } from 'pmt-modules/auth'
import { redirectTo, getRoute, generatePathWithParams } from 'pmt-modules/routing'
import { getAppConfig } from 'pmt-modules/appConfig/selectors'
import { AuthMode } from 'pmt-modules/auth/constants'
import { getUserMe } from 'pmt-modules/userMe'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { getAppConfigFrontSettings, getOrderProperties } from 'pmt-modules/orderPlugin'
import { getAppTexts } from 'pmt-modules/appConfig/selectors'

import { replaceSpaceWithUnbreakableUnicodeSpace } from 'pmt-utils/string'

import Hidden from 'pmt-ui/Hidden'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: 7,
  },
  element: {
    margin: `0 ${theme.spacing(0.5)}px`,
    textTransform: 'uppercase',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  current: {
    paddingBottom: theme.spacing(0.5),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  divider: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
})

export const getBreadcrumbElements = (
  { orderProperties, restaurant, user, auth, frontSettings, customTexts, appConfig },
  elements
) => {
  const BreadcrumbElements = [
    {
      name: 'CATALOG',
      getDatas: ({ restaurant }) => ({
        title: tr('order.global.catalog'),
        route: getRoute('ORDER__CATALOG'),
        routeParams: {
          restaurantId: restaurant.id,
        },
      }),
      validateFunc: ({ restaurant }) => !isNil(restaurant),
    },
    {
      name: 'CART',
      getDatas: () => ({
        title: tr('order.global.cart'),
        route: getRoute('ORDER__CART'),
      }),
      validateFunc: ({ restaurant }) => !isNil(restaurant),
    },
    {
      name: 'INFORMATIONS',
      getDatas: ({ userId, appConfig }) => ({
        title:
          appConfig.authentication.mode === AuthMode.INCOGNITO_ONLY
            ? tr('global.register.continue_as_incognito.from_INCOGNITO_ONLY.title')
            : !isNil(userId) || appConfig.authentication.mode === AuthMode.LIGHT
            ? !isNil(customTexts) && !isNil(customTexts.ORDER__GLOBAL__YOUR_INFORMATIONS)
              ? customTexts.ORDER__GLOBAL__YOUR_INFORMATIONS
              : tr('order.global.your_informations')
            : tr('order.global.login'),
        route: !isNil(userId) ? getRoute('USER_PROFILE') : getRoute('LOGIN'),
        routeParams: {
          userId,
        },
      }),
    },
    {
      name: 'DELIVERY_ADDRESS',
      getDatas: () => ({
        title: tr('order.global.delivery_short'),
        route: getRoute('ORDER__DELIVERY_ADDRESS'),
      }),
      validateFunc: ({ orderProperties }) => orderProperties.isDelivery,
    },
    {
      name: 'PAYMENT',
      getDatas: () => ({
        title: tr('order.global.payment'),
        route: getRoute('ORDER__PAYMENT'),
      }),
      validateFunc: ({ frontSettings }) =>
        frontSettings.display.paymentPage && !isNil(restaurant) && !isNil(orderProperties.mode),
    },
  ]

  let breadcrumbElements = []

  const userId = (!isNil(auth) && !isNil(user) && user.id) || null

  elements.forEach((element) => {
    BreadcrumbElements.forEach((breadcrumbElement) => {
      if (
        element.name === breadcrumbElement.name &&
        (!breadcrumbElement.validateFunc ||
          breadcrumbElement.validateFunc({ orderProperties, restaurant, userId, frontSettings }))
      ) {
        let breadcrumbElementDatas = breadcrumbElement.getDatas({
          orderProperties,
          restaurant,
          userId,
          active: element.active,
          appConfig,
        })
        breadcrumbElementDatas.hiddenMobile = element.hiddenMobile || false
        breadcrumbElementDatas.current = element.current || false
        breadcrumbElementDatas.active = element.active || false

        breadcrumbElements.push(breadcrumbElementDatas)
      }
    })
  })

  return breadcrumbElements
}

@withRestaurant
class Breadcrumb extends React.PureComponent {
  handleRedirection = (route, params) => {
    EventManager.dispatch(EventManager.Events.ON_BREADCRUMB_LINK, {
      link: generatePathWithParams(route, params),
    })
    this.props.redirectTo(route, params)
  }

  render() {
    const {
      elements,
      className,
      classes,
      orderProperties,
      restaurant,
      user,
      auth,
      frontSettings,
      customTexts,
      appConfig,
    } = this.props
    const breadcrumbElements = getBreadcrumbElements(
      { orderProperties, restaurant, user, auth, frontSettings, customTexts, appConfig },
      elements
    )
    const elementsSize = breadcrumbElements.length - 1

    return (
      <div className={classNames(classes.root, className)}>
        {breadcrumbElements.map((element, index) => {
          element.title = replaceSpaceWithUnbreakableUnicodeSpace(element.title)

          return (
            // the property hiddenMobile, given on element, is used to hide element on mobile
            // this is used on cart resume page, to hide the catalog link on mobile (replaced by header)
            <Hidden smDown={element.hiddenMobile} key={index}>
              <div key={index} className="u-floatLeft u-marginTop5">
                <TypographyCustom
                  component="span"
                  type="144"
                  className={classNames(classes.element, {
                    [classes.grey500]: !element.active,
                    [classes.current]: element.current,
                  })}
                  onClick={
                    element.active && element.route
                      ? () => {
                          this.handleRedirection(element.route, element.routeParams)
                        }
                      : null
                  }
                >
                  {element.title}
                </TypographyCustom>
                {index !== elementsSize && (
                  <TypographyCustom component="span" type="144" className={classes.divider}>
                    /
                  </TypographyCustom>
                )}
              </div>
            </Hidden>
          )
        })}
      </div>
    )
  }
}

Breadcrumb.propTypes = {
  elements: PropTypes.array.isRequired,
  className: PropTypes.string,
}

const mapStateToProps = (state, props) => ({
  auth: getAuth(),
  frontSettings: getAppConfigFrontSettings(state),
  user: getUserMe(state),
  orderProperties: getOrderProperties(state),
  customTexts: getAppTexts(state),
  appConfig: getAppConfig(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    redirectTo,
  })
)(Breadcrumb)
