import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { hideDeleteCartDialog } from 'pmt-modules/cart'
import { goBackToStoreLocator } from 'pmt-modules/orderPlugin'

import { TypographyCustom } from 'pmt-ui/Typography'
import { DialogContent } from 'pmt-ui/Dialog'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import { withStyles } from 'pmt-ui/styles'

import Button from '../Button'

const styles = theme => ({
  dialogContent: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginTop: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  bigButtonMobile: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
})

const DeleteCart = ({
  entry,
  item,
  padding,
  hideDeleteCartDialog,
  goBackToStoreLocator,
  classes,
  ...props
}) => (
  <DialogContent classes={{ root: classes.dialogContent }}>
    <div className={classes.closeArea} onClick={hideDeleteCartDialog}>
      <CloseIcon className={classes.closeBtn} />
    </div>
    <TypographyCustom type="247" component="p" className={classes.title}>
      {tr('order.dialog.cart_delete.title')}
    </TypographyCustom>
    <TypographyCustom type="164" component="p">
      {tr('order.dialog.cart_delete.info')}
    </TypographyCustom>
    <br />
    <TypographyCustom type="164" component="p" className="u-marginBottom10">
      {tr('order.dialog.cart_delete.question')}
    </TypographyCustom>
    <Button
      size="large"
      label={tr('order.global.delete')}
      className={classNames('u-floatRight u-marginLeft20', classes.bigButtonMobile)}
      onClick={() => {
        goBackToStoreLocator()
        hideDeleteCartDialog()
      }}
    />
    <Button
      variant="outlined"
      size="large"
      label={tr('order.global.no_cancel')}
      className={classNames('u-floatRight', classes.bigButtonMobile)}
      onClick={hideDeleteCartDialog}
    />
  </DialogContent>
)

DeleteCart.DialogRootProps = {}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default compose(
  connect(
    mapStateToProps,
    {
      goBackToStoreLocator,
      hideDeleteCartDialog,
    }
  ),
  withStyles(styles)
)(DeleteCart)
