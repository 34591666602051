import React from 'react'
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { setCurrentPart } from 'pmt-modules/orderMenu'
import {
  getPartSelectedCategory,
  getPartParentCategory,
  getCategoryFromPart,
  resetPartParentCategories,
  resetPartSelectedCategory,
} from 'pmt-modules/orderMenu'

import {
  getOrderProperties,
  saveAlcoholApproval,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'

import classNames from 'classnames'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import { TypographyCustom } from 'pmt-ui/Typography'
import { Sticky, StickyContainer } from 'pmt-ui/Sticky'
import ScrollableAnchor, { goToAnchor, configureAnchors } from 'pmt-ui/ScrollableAnchor'
import ArrowDownIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'
import ArrowUpIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-up'
import ContentClear from 'pmt-ui/svg-icons/content/clear'

import CategoryCard from '../../../../components/CategoryCard'
import ParentCategoryCard from '../../../../components/ParentCategoryCard'

import PartQuantity from '../PartQuantity'
import Item from './Item'

const styles = (theme) => ({
  part: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      borderTop: `1px solid ${theme.pmt.colors.grey500}`,
      '&>:first-child': {
        paddingTop: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {},
  },
  scrollableSection: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  partTitle: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  partTitleSticky: {
    [theme.breakpoints.up('md')]: {
      height: 50,
    },
    backgroundColor: 'white',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    zIndex: 500,
    ...theme.pmt.appConfig.menuPartTitleSticky,
  },
  partName: {
    ...theme.pmt.mixins.textEllipsis,
    float: 'left',
    marginRight: theme.spacing(3),
    minHeight: 25,
    [theme.breakpoints.down('sm')]: {
      minHeight: 21,
    },
  },
  partQuantityQuantityTip: {
    paddingTop: 2,
    color: theme.pmt.colors.grey500,
    ...theme.pmt.mixins.textEllipsis,
  },
  headerFirstLine: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  headerFirstLineSticky: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  partTitleNbProducts: {
    color: theme.pmt.colors.grey500,
    marginLeft: theme.spacing(1),
  },
  mobileArrow: {
    float: 'right',
    marginRight: theme.spacing(1),
    width: 30,
    height: 30,
  },
  partContent: {
    padding: theme.spacing(1),
  },
  mobileDescription: {
    width: '100%',
    borderBottom: 'solid 1px #979797',
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mobileDescriptionSticky: {
    margin: 0,
    border: 'none',
  },
  selectedProductsContainer: {
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
  },
  selectedProductContainer: {
    display: 'inline-block',
    maxWidth: '33.3333334%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'table',
      maxWidth: '100%',
    },
  },
  selectedProduct: {
    overflow: 'hidden',
    marginLeft: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.pmt.colors.white,
    borderRadius: 4,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  selectedProductName: {
    float: 'left',
    maxWidth: 'calc(100% - 40px)',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    lineHeight: '20px',
    height: 20,
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(2),
  },
  selectedProductIcon: {
    float: 'right',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
})

// https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js
class PartView extends React.Component {
  //
  // We need to track the scrolling position of the PartView, to know if it is currenctly display.
  // Some parts could be display on the screen altogether: we take the part that is the closest
  // from the top of the screen.
  // We listen for the scroll listener, and deduce in `isTop` is the part is the one that should be
  // set as `currentPart`.
  //

  static DEBOUNCE_TIMEOUT = 300

  constructor(props) {
    super(props)

    configureAnchors({ keepLastAnchorHash: true })

    if (
      props.part.products.length === 1 &&
      !props.part.products[0].isComplexItem &&
      isEmpty(props.part.categories) &&
      props.part.mandatory
    ) {
      props.onAddToCartFromShortcut(props.part, props.part, props.part.products[0])
      props.onOpen(false)
    }
  }

  componentWillReceiveProps(nextProps) {
    // if the part has reached its max,
    // we scroll to the next part (if possible)
    if (nextProps.part.hasReachedMaxQuantity) {
      const isMobile = isWidthDown('sm', nextProps.width)

      if (
        !this.props.part.hasReachedMaxQuantity &&
        !isNil(nextProps.part.nextPart) &&
        this.props.part.nbChosenValues !== nextProps.part.nbChosenValues
      ) {
        if (!isMobile) {
          if (!isNil(nextProps.part.nextPart.anchor)) {
            goToAnchor(nextProps.part.nextPart.anchor, true)
          }
        } else {
          nextProps.onOpen(false)
        }

        this.props.resetPartParentCategories()
        this.props.resetPartSelectedCategory()

        return
      }

      if (
        isMobile &&
        this.props.isOpen &&
        !this.props.hasReachedMaxQuantity &&
        this.props.part.nbChosenValues !== nextProps.part.nbChosenValues
      ) {
        this.props.resetPartParentCategories()
        this.props.resetPartSelectedCategory()

        nextProps.onOpen(false)
      }
    }
  }

  render() {
    const {
      part,
      isOpen,
      onOpen,
      width,
      restaurant,
      classes,
      orderMenu,
      partParentCategory,
      partSelectedCategory,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onEditOrderProduct,
      onAddToCartFromShortcut,
      onSelectCategory,
      onReturnToPreviousPartCategory,
      resetPartParentCategories,
      resetPartSelectedCategory,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    let partCategory = part
    let categoryFromPart = null
    if (!isNil(partSelectedCategory)) {
      categoryFromPart = getCategoryFromPart(part, partSelectedCategory)
      partCategory = !isNil(categoryFromPart) ? categoryFromPart : part
    }

    const isMobile = isWidthDown('sm', width)
    const isMobileSmall = isWidthDown('xs', width)

    return (
      <StickyContainer
        className={classes.part}
        ref={(ref) => {
          this.rootRef = ref
        }}
      >
        <ScrollableAnchor id={part.anchor}>
          {/* div required for ScrollableAnchor. we need the anchor to be a parent of the
            sticky, otherwise, the anchor position taken is the sticky position, not the
            original position
            */}
          <div className={classes.scrollableSection}>
            <Sticky>
              {({ isSticky, style }) => {
                // on mobile we don't want to stick a not-opened part header
                const shouldStick = isSticky && (!isMobile || isOpen)

                return (
                  <div
                    style={
                      shouldStick
                        ? {
                            ...style,
                            // override on mobile
                            ...(isMobile
                              ? {
                                  // override style
                                  // keep sticky part on border of the screen
                                  right: 0,
                                  left: 0,
                                  width: '100%',
                                  // set same padding on sticky
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                }
                              : {}),
                          }
                        : null
                    }
                    className={classNames(classes.partTitle, {
                      [classes.partTitleSticky]: shouldStick,
                    })}
                    onClick={() => {
                      onOpen()
                      resetPartParentCategories()
                      resetPartSelectedCategory()
                    }}
                  >
                    <div
                      className={classNames(classes.headerFirstLine, {
                        [classes.headerFirstLineSticky]: isSticky,
                      })}
                    >
                      <TypographyCustom className={classes.partName} type={isMobile ? 204 : 244}>
                        {part.name}
                      </TypographyCustom>
                      <Hidden smDown>
                        <PartQuantity
                          part={part}
                          typographyType={144}
                          classes={{
                            quantityTip: classNames(
                              classes.partQuantityQuantityTip,
                              'u-marginLeft5'
                            ),
                          }}
                        />
                      </Hidden>

                      <Hidden mdUp>
                        {isMobile && this.props.isOpen ? (
                          <ArrowUpIcon className={classes.mobileArrow} />
                        ) : (
                          <ArrowDownIcon className={classes.mobileArrow} />
                        )}
                      </Hidden>
                    </div>

                    <Hidden mdUp>
                      <div
                        className={classNames(classes.mobileDescription, 'u-marginRight10', {
                          [classes.mobileDescriptionSticky]: shouldStick,
                        })}
                      >
                        <PartQuantity
                          part={part}
                          displaySelectedItems={!this.props.isOpen}
                          typographyType={144}
                          classes={{
                            quantityTip: classes.partQuantityQuantityTip,
                          }}
                        />
                      </div>
                    </Hidden>
                  </div>
                )
              }}
            </Sticky>
            {/* in mobile, we hide the content of the parts that are not opened */}
            <Hidden mdDown={!isOpen && isMobile}>
              <div className={classes.selectedProductsContainer}>
                {!isEmpty(part.selectedProducts) &&
                  part.selectedProducts.map((selectedProduct) => (
                    <div
                      key={`selectedProduct-${selectedProduct.partCategory.id}-${selectedProduct.id}`}
                      className={classes.selectedProductContainer}
                    >
                      <div className={classes.selectedProduct}>
                        <TypographyCustom
                          type={isMobile ? '144' : '164'}
                          className={classes.selectedProductName}
                        >
                          {selectedProduct.name}
                          {selectedProduct.totalPrice > 0 && (
                            <React.Fragment>
                              {' '}
                              ({`+${selectedProduct.totalPriceFormatted}`})
                            </React.Fragment>
                          )}
                        </TypographyCustom>
                        <ContentClear
                          className={classes.selectedProductIcon}
                          onClick={(e) => {
                            e.stopPropagation()
                            onUnselectOrderProduct(
                              part,
                              selectedProduct.partCategory,
                              selectedProduct
                            )
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <Grid spacing={2} container className={classes.partContent}>
                {!isNil(categoryFromPart) && !isEmpty(partParentCategory) && (
                  <Grid
                    key={`partParentCategory-${partParentCategory.id}`}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                  >
                    <ParentCategoryCard
                      category={partCategory}
                      parentCategory={partParentCategory}
                      isMobile={isMobileSmall}
                      onClick={(parentCategory) => {
                        onReturnToPreviousPartCategory(parentCategory)
                      }}
                    />
                  </Grid>
                )}
                {!isNil(partCategory.categories) &&
                  partCategory.categories.map((category) => (
                    <Grid key={`category-${category.id}`} item xs={12} sm={6} md={6} lg={4}>
                      <CategoryCard
                        category={category}
                        restaurant={restaurant}
                        isMobile={isMobileSmall}
                        onClick={() => onSelectCategory(category, partCategory)}
                      />
                    </Grid>
                  ))}
                {!isNil(partCategory.products) &&
                  partCategory.products.map((item) => (
                    <Grid key={`item-${item.id}`} item xs={12} sm={6} md={6} lg={4}>
                      <Item
                        orderMenu={orderMenu}
                        item={item}
                        restaurant={restaurant}
                        partHasReachedMaxQuantity={part.hasReachedMaxQuantity}
                        onEditOrderProduct={() => onEditOrderProduct(part, partCategory, item)}
                        onUnselectOrderProduct={() =>
                          onUnselectOrderProduct(part, partCategory, item)
                        }
                        onSelectOrderProduct={() => onSelectOrderProduct(part, partCategory, item)}
                        onAddToCartFromShortcut={() =>
                          onAddToCartFromShortcut(part, partCategory, item)
                        }
                        orderProperties={orderProperties}
                        saveAlcoholApproval={saveAlcoholApproval}
                        frontSettings={frontSettings}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Hidden>
          </div>
        </ScrollableAnchor>
      </StickyContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  partSelectedCategory: getPartSelectedCategory(state),
  partParentCategory: getPartParentCategory(state),
  orderProperties: getOrderProperties(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    resetPartParentCategories,
    resetPartSelectedCategory,
    setCurrentPart,
    saveAlcoholApproval,
  }),
  withWidth(),
  withStyles(styles)
)(PartView)
