import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Type from '../Type'

import Box from 'pmt-ui/Box'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import IconLabel from 'pmt-ui/IconLabel'
import CommentOutlineIcon from 'pmt-ui/svg-icons/communication/comment-outline'

const styles = (theme) => ({
  optionsFromMenu: {
    marginLeft: theme.spacing(1),
  },

  commentFromMenu: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  grey500: {
    color: theme.pmt.colors.grey500,
  },

  commentIcon: {
    width: 18,
  },

  commentLabel: {},

  productComment: {
    marginTop: theme.spacing(1),
    fontStyle: 'italic',
  },

  bigOptionFont: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    lineHeight: '22px',
  },
  smallOptionFont: {
    lineHeight: '18px',
  },
})

const ProductContent = ({ product, fromMenu, type, classes }) => (
  <Box mt={0.5}>
    {/* product options */}
    <TypographyCustom
      component="div"
      type={type === Type.BIG ? '164' : '144'}
      className={classNames(classes[`${type}OptionFont`], classes.grey500, {
        [classes.optionsFromMenu]: fromMenu,
      })}
    >
      {product.optionsSelected.map((option) =>
        option.values.map(
          (value) =>
            value.quantity > 0 &&
            isEmpty(value.unavailabilityReasons) && <div key={value.id}>{value.name}</div>
        )
      )}
    </TypographyCustom>

    {/* reclaim later */}
    {product.reclaimLater && (
      <TypographyCustom
        component="div"
        type={type === Type.BIG ? '164' : '144'}
        className={classNames(classes[`${type}OptionFont`], classes.grey500, {
          [classes.optionsFromMenu]: fromMenu,
        })}
      >
        {tr('order.product.reclaimLater.label')}
      </TypographyCustom>
    )}

    {/* Product comment */}
    {product.comment && (
      <TypographyCustom
        type={type === Type.BIG ? '164' : '144'}
        component="div"
        className={classNames(
          classes[`${type}OptionFont`],
          classes.grey500,
          classes.productComment,
          {
            [classes.commentFromMenu]: fromMenu,
          }
        )}
      >
        <IconLabel
          spacing={1}
          classes={{
            label: classes.commentLabel,
          }}
          icon={<CommentOutlineIcon className={classes.commentIcon} />}
          label={product.comment}
        />
      </TypographyCustom>
    )}
  </Box>
)

export default withStyles(styles)(ProductContent)
