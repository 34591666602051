import Logger from 'pmt-utils/logger'
import each from 'lodash/each'
import concat from 'lodash/concat'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import isNil from 'lodash/isNil'

import { DAYS } from 'pmt-modules/api/constants'

import { MINUTE_AS_MS, getMinuteFromMs } from 'pmt-utils/date'

/**
 * /!\ do not use moment to retrieve the day value, use getPmtDayIndex, since the given day by moment
 * is not the same according to the locale (fr / en).
 */
export const getForDay = (daysOfWeek, day) => {
  if (isNil(daysOfWeek)) {
    return []
  }

  switch (day) {
    case DAYS.MONDAY:
      return daysOfWeek.monday
    case DAYS.TUESDAY:
      return daysOfWeek.tuesday
    case DAYS.WEDNESDAY:
      return daysOfWeek.wednesday
    case DAYS.THURSDAY:
      return daysOfWeek.thursday
    case DAYS.FRIDAY:
      return daysOfWeek.friday
    case DAYS.SATURDAY:
      return daysOfWeek.saturday
    case DAYS.SUNDAY:
      return daysOfWeek.sunday
    default:
      Logger.error('No day of the week has been found for day ', day)
      return []
  }
}

/**
 * Warning: moment.js week start at sunday, not monday
 */
export const generateHoursForDay = (daysOfWeek, dayIndex, deliveryTime = 0) => {
  const openingHoursForDay = getForDay(daysOfWeek, dayIndex)
  let hours = []

  each(openingHoursForDay, openingHour => {
    // add delay when is delivery in order to be able to
    // deliver after delay + opening and after delay + closing hour
    let intervals = generateIntervals({
      opening: openingHour.opening + deliveryTime,
      closing: openingHour.closing + deliveryTime,
    })

    hours = concat(hours, intervals)
  })

  // delete doublons
  let finalHours = uniq(hours)

  // sort by date
  finalHours = sortBy(finalHours, slot => slot)

  finalHours = finalHours.map(hour => ({
    value: hour,
  }))

  return finalHours
}

const getNextInterval = time => {
  const intervals = [0, 15, 30, 45, 60]

  const minutesFromTime = getMinuteFromMs(time)

  let nextInterval = 0
  // go from opening to next valid intervals
  for (let i = 0; i < intervals.length; i++) {
    if (minutesFromTime > intervals[i] && minutesFromTime <= intervals[i + 1]) {
      nextInterval = intervals[i + 1]
      break
    }
  }

  return time + (nextInterval - minutesFromTime) * MINUTE_AS_MS
}

export const generateIntervals = (openingHour, minuteInterval = 15) => {
  const intervalAsMs = minuteInterval * MINUTE_AS_MS

  // start by adding opening time which might not be fall on an interval
  const hours = [openingHour.opening]

  // get the next interval
  const nextInterval = getNextInterval(openingHour.opening)
  hours.push(nextInterval)

  // generate all intervals until closing
  for (let i = nextInterval; i < openingHour.closing; i += intervalAsMs) {
    hours.push(i)
  }

  // finall, add closing time which might not be fall on an interval
  hours.push(openingHour.closing)

  return hours
}

export const isSlotInOpeningHours = (openingHours, slot, delay) => {
  let isInOpeningHours = false

  openingHours.every(hour => {
    if (slot >= hour.opening && slot + delay <= hour.closing) {
      isInOpeningHours = true
      return false
    }

    return true
  })

  return isInOpeningHours
}

export const getNextClosingHour = (hoursInMs, openingHours) => {
  let closingHour = null

  openingHours.every(hour => {
    if (hoursInMs >= hour.opening && hoursInMs <= hour.closing) {
      closingHour = hour.closing
      return false
    }

    return true
  })

  if (isNil(closingHour)) {
    return openingHours?.[openingHours.length - 1]?.closing || false
  }

  return closingHour
}
