import React from 'react'

import clsx from 'clsx'

import compose from 'recompose/compose'

import Hidden from 'pmt-ui/Hidden'
import { withStyles } from 'pmt-ui/styles'
import ArrowLeftIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-left'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: theme.pmt.header.mobile.height,
    padding: `${theme.pmt.header.mobile.verticalPadding}px ${
      theme.pmt.header.mobile.horizontalPadding
    }px`,
    lineHeight: `${theme.pmt.header.mobile.contentHeight}px`,
    boxShadow: '0 2px 4px 0 rgba(74, 74, 74, 0.77)',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 50px)',
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 40,
    height: theme.pmt.header.mobile.height,
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  backIcon: {
    width: 32,
    height: 32,
  },
  formControl: {
    minWidth: 40,
  },
  icon: {
    width: 16,
    height: 16,
  },
  separator: {
    width: 2,
    height: theme.pmt.header.mobile.contentHeight,
    background: theme.pmt.colors.grey300,
    marginRight: theme.spacing(1),
  },
})

//
// This component display a mobile header, using the theme.pmt.header data
//
const MobileHeader = ({
  children,
  withBack,
  onBack,
  classes,
  // on some cases, we want to hide the header
  hiddenMobile = false,
}) => (
  // hide the header when we are on mobile and are on the root category, since we display
  // the categories slider
  <Hidden mdUp smDown={hiddenMobile}>
    <div className={clsx(classes.root)}>
      {withBack && (
        <div className={classes.backArea} onClick={onBack}>
          <ArrowLeftIcon className={classes.backIcon} />
        </div>
      )}

      <div className={classes.separator} />

      <div className={classes.content}>
        {children}

        {/* TODO: restaurant logo */}
      </div>
    </div>
  </Hidden>
)

export default compose(withStyles(styles))(MobileHeader)
