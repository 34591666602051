import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

const DueDate = ({ classes, orderProperties, width }) => (
  <div className={classes.root}>
    <TypographyCustom type={isWidthUp('sm', width) ? 164 : 144} component="div" skipColor>
      {orderProperties.isAsap
        ? orderProperties.hasDelayOrDeliveryTime
          ? tr('order.store_locator.slot.as_soon_as_possible', {
              delay: orderProperties.delayAndDeliveryFormattedAsMin,
            })
          : tr('order.global.now')
        : orderProperties.dueDateFormatted}
    </TypographyCustom>
  </div>
)

export default withWidth()(DueDate)
