import moment from 'moment'

import { appConfigReducer } from 'pmt-modules/appConfig'
import { authAppReducer } from 'pmt-modules/authApp'
import { authReducer } from 'pmt-modules/auth'
import { authenticatedUserReducer } from 'pmt-modules/authenticatedUser'
import { authProReducer } from 'pmt-modules/authPro'
import { cartReducer } from 'pmt-modules/cart'
import { cartDiffReducer } from 'pmt-modules/cartDiff'
import { catalogReducer } from 'pmt-modules/catalog'
import { customTextsReducer } from 'pmt-modules/customTexts'
import {
  userCreditCardsReducer,
  userSelectedCreditCardsReducer,
  deleteUserCreditCardReducer,

  // new process credit card v2
  postUserCreditCardRegistrationTypeReducer,
  sendUserCreditCardToPspReducer,
  postPspDatasReducer,
} from 'pmt-modules/creditCard'
import { forgotPasswordReducer } from 'pmt-modules/forgotPassword'
import { geolocationReducer } from 'pmt-modules/geolocation'
import { meReducer, putUserMeReducer } from 'pmt-modules/userMe'
import { userAddressReducer } from 'pmt-modules/userAddress'
import { orderPluginReducer } from 'pmt-modules/orderPlugin'
import { orderPreviewReducer } from 'pmt-modules/orderPreview'
import { orderSettingsReducer } from 'pmt-modules/orderSettings'
import { registrationReducer } from 'pmt-modules/registration'
import { restaurantsGroupReducer } from 'pmt-modules/restaurantsGroup'
import { restaurantReducer } from 'pmt-modules/restaurant'
import { orderFrontReducer } from 'pmt-modules/orderFront'
import { orderProductReducer } from 'pmt-modules/orderProduct'
import { orderMenuReducer } from 'pmt-modules/orderMenu'
import { orderPostReducer } from 'pmt-modules/orderPost'
import { suggestionReducer } from 'pmt-modules/suggestion'
import { userOrdersReducer } from 'pmt-modules/userOrders'
import { appSecurityReducer } from 'pmt-modules/appSecurity'
import { userUserAccountsReducer } from 'pmt-modules/userAccount'
import { checkReducer } from 'pmt-modules/payment'
import { fetchPaymentPdfLinkReducer } from 'pmt-modules/payment/pdf'

const persistExpiresAt = moment().add(60, 'minutes')

const persistOptions = { persist: true, persistExpiresAt }

cartReducer.options = { ...persistOptions }
customTextsReducer.options = { ...persistOptions }
geolocationReducer.options = { ...persistOptions }
orderFrontReducer.options = { ...persistOptions }
orderPluginReducer.options = { ...persistOptions }
// for 3DSecure we need to persist the orderPost data
orderPreviewReducer.options = { ...persistOptions }
orderPostReducer.options = { ...persistOptions }
orderSettingsReducer.options = { ...persistOptions }
userUserAccountsReducer.options = { ...persistOptions }

export default {
  appConfig: appConfigReducer,
  appSecurity: appSecurityReducer,
  auth: authReducer,
  authenticatedUser: authenticatedUserReducer,
  authApp: authAppReducer,
  authPro: authProReducer,
  cart: cartReducer,
  cartDiff: cartDiffReducer,
  catalog: catalogReducer,
  check: checkReducer,
  customTexts: customTextsReducer,
  deleteUserCreditCard: deleteUserCreditCardReducer,
  forgotPassword: forgotPasswordReducer,
  geolocation: geolocationReducer,
  fetchPaymentPdfLink: fetchPaymentPdfLinkReducer,
  orderFront: orderFrontReducer,
  orderMenu: orderMenuReducer,
  orderPlugin: orderPluginReducer,
  orderPost: orderPostReducer,
  orderPreview: orderPreviewReducer,
  orderProduct: orderProductReducer,
  orderSettings: orderSettingsReducer,
  postPspDatas: postPspDatasReducer,
  postUserCreditCardRegistrationType: postUserCreditCardRegistrationTypeReducer,
  putUserMe: putUserMeReducer,
  registration: registrationReducer,
  restaurant: restaurantReducer,
  restaurantsGroup: restaurantsGroupReducer,
  sendUserCreditCardToPsp: sendUserCreditCardToPspReducer,
  suggestion: suggestionReducer,
  userAddress: userAddressReducer,
  userCreditCards: userCreditCardsReducer,
  userMe: meReducer,
  userOrders: userOrdersReducer,
  userSelectedCreditCards: userSelectedCreditCardsReducer,
  userAccounts: userUserAccountsReducer,
}
