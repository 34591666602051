import { tr } from 'pmt-modules/i18n'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { ProfileFormView } from 'pmt-modules/user/forms/profile'
import { withForm, FormType } from 'pmt-modules/form'

import { withStyles } from 'pmt-ui/styles'
import ScrollableAnchor from 'pmt-ui/ScrollableAnchor'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import { cleanForApi } from 'pmt-utils/phone'

import MessageBlock from '../../../../components/MessageBlock'
import Button from '../../../../components/Button'
import FlexBlock from '../../../../components/FlexBlock'

const styles = theme => ({
  info: {
    color: theme.palette.primary.light,
    background: theme.pmt.colors.grey100,
    padding: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
  },
  submit: {
    float: 'right',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
})

class ProfileForm extends Component {
  state = {
    userFormDatas: {},
  }

  getUserDatas = user => ({
    email: user.email,
    firstName: user.firstName,
    formType: FormType.USER_PROFILE,
    lastName: user.lastName,
    phone: user.phone,
    marketing: {
      allowContact: user.marketing.allowContact,
    },
  })

  constructor(props) {
    super(props)

    this.state.userFormDatas = props.formData
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!isNil(nextProps.user) && this.props.user.id !== nextProps.user.id) ||
      (!isEqual(nextProps.formData, this.getUserDatas(nextProps.user)) &&
        this.props.isFetchingPutUserMe &&
        !nextProps.isFetchingPutUserMe)
    ) {
      this.setState({
        userFormDatas: {
          ...this.state.userFormDatas,
          ...this.getUserDatas(nextProps.user),
        },
      })
    }

    if (!isEqual(this.props.formData, nextProps.formData)) {
      this.setState({
        userFormDatas: nextProps.formData,
      })
    }

    if (this.props.isFetchingPutUserMe && !nextProps.isFetchingPutUserMe && nextProps.formIsValid) {
      nextProps.updateUI({
        enableSubmit: false,
      })
    }
  }

  handleOnChangeFormDatas = formData => {
    if (!isEqual(formData, { ...this.getUserDatas(this.props.user) })) {
      if (!this.props.ui.enableSubmit) {
        this.props.updateUI({
          enableSubmit: true,
        })
      }
    } else if (this.props.ui.enableSubmit) {
      this.props.updateUI({
        enableSubmit: false,
      })
    }
  }

  render() {
    const {
      classes,
      formErrors,
      onChange,
      hasPhoneRequired,
      formData,
      formType,
      frontAppConfig,
      forgottenPasswordLink,
      onSubmit,
      user,
      itemListFromCart,
      formIsValid,
      isFetchingPutUserMe,
      handleOnClickBlock,
      opened,
      anchor,
      ui,
      submittedForm,
    } = this.props

    return (
      <ScrollableAnchor id={anchor}>
        <div>
          <FlexBlock
            isFlex
            classes={{ root: 'u-marginBottom25' }}
            title={tr('order.account.profile_form.title')}
            content={
              <React.Fragment>
                <LoadingBlockWrapper show={isFetchingPutUserMe && submittedForm === formType} />
                {hasPhoneRequired() &&
                  isEmpty(user.phone) && (
                    <div className={classes.info}>
                      <MessageBlock
                        type={MessageBlock.Type.INFO}
                        text={tr('order.profile.missing_phone')}
                      />
                    </div>
                  )}
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    onSubmit({
                      formData: {
                        ...formData,
                        phone: cleanForApi(formData.phone),
                      },
                      formType,
                    })
                  }}
                >
                  <ProfileFormView
                    formData={this.state.userFormDatas}
                    formErrors={formErrors}
                    onChange={onChange}
                    forgottenPasswordLink={forgottenPasswordLink}
                    handleOnChangeFormDatas={this.handleOnChangeFormDatas}
                  />
                  <Button
                    size="large"
                    type="submit"
                    label={tr(
                      frontAppConfig.display.paymentPage || isEmpty(itemListFromCart)
                        ? 'order.profile.personnal_informations.submit'
                        : 'order.profile.submit_and_order'
                    )}
                    classes={{ root: classes.submit }}
                    disabled={
                      !formIsValid ||
                      (isFetchingPutUserMe && submittedForm === formType) ||
                      !ui.enableSubmit
                    }
                  />
                </form>
              </React.Fragment>
            }
            handleOnClick={handleOnClickBlock}
            opened={opened}
          />
        </div>
      </ScrollableAnchor>
    )
  }
}

export default compose(
  ui({
    state: {
      enableSubmit: false,
    },
  }),
  withForm(FormType.USER_PROFILE),
  withStyles(styles)
)(ProfileForm)
