import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import { transformCartForApi } from 'pmt-modules/orderPreview/utils/transformForApi/transformCartForApi'

import { formatPriceWithCurrency, getRoundAmountNumber } from 'pmt-utils/currency'

import { formatItemList, formatItemListForOrderPreview } from './format'

const getCart = state => state.entities.cart

export const getItemListFromCart = createSelector([getCart], cartData => {
  let itemList = cartData.get('itemList')

  return !isNil(itemList) ? formatItemList(itemList.toJS()) : []
})

// force reformat, so it will apply the current currency
export const forceGetItemListFromCart = state => {
  const cartData = state.entities.cart
  let itemList = cartData.get('itemList')
  return !isNil(itemList) ? formatItemList(itemList.toJS()) : []
}

export const getItemListFromCartForOrderPreview = createSelector([getCart], cartData => {
  let itemList = cartData.get('itemList')

  return !isNil(itemList) ? formatItemListForOrderPreview(itemList.toJS()) : []
})

/**
 * Returns the item list transformed for the API.
 */
export const getItemListForApi = createSelector([getItemListFromCartForOrderPreview], itemList => {
  return transformCartForApi({
    itemList,
  })
})

export function containsAlcohol(itemList) {
  const productContainsAlcohol = p =>
    p.containsAlcohol ||
    (p.optionsSelected || []).some(option => option.values.some(v => v.containsAlcohol))

  if (itemList.filter(p => p.isProduct).some(productContainsAlcohol)) {
    return true
  }
  if (
    itemList
      .filter(p => p.isMenu)
      .some(m => m.parts.some(part => part.selectedProducts.some(productContainsAlcohol)))
  ) {
    return true
  }

  return false
}

/**
 * Return cart data formatted
 */
export const getCartData = createSelector(
  [forceGetItemListFromCart, getCart],
  (itemList, cartData) => {
    const data = {}

    // -- total cartPrice
    let totalCartPrice = 0

    itemList.forEach(item => {
      totalCartPrice += item.totalPriceWithQuantity
    })

    data.totalCartPrice = getRoundAmountNumber(totalCartPrice)
    data.totalCartPriceFormatted = formatPriceWithCurrency(totalCartPrice)

    // -- total item list
    data.totalItemList = getRoundAmountNumber(
      itemList.reduce((totalPrice, item) => (totalPrice += item.totalPriceWithQuantity), 0)
    )
    data.totalItemListFormatteed = formatPriceWithCurrency(data.totalItemList)

    // -- hasItems
    data.hasItems = !isEmpty(itemList)

    data.comment = cartData.get('comment')
    // --

    data.minimumPrice = cartData.get('minimumPrice', 0)
    data.minimumPriceFormatted = formatPriceWithCurrency(data.minimumPrice)

    data.modificationDate = cartData.get('modificationDate')

    data.comment = cartData.get('comment')

    data.containsAlcohol = containsAlcohol(itemList)

    return data
  }
)

export const getSuspendedDatas = createSelector([getCart], cartData => {
  const suspendedItem = cartData.get('suspendedItem', null)
  const suspendedActionType = cartData.get('suspendedActionType', null)

  if (isNil(suspendedItem)) {
    return null
  }

  return { suspendedItem: suspendedItem.toJS(), suspendedActionType }
})
