import { createAction } from '../redux'

import { getRoute, createRedirectToAction } from 'pmt-modules/routing'

import { ModalType, createShowDialogAction, createHideDialogAction } from 'pmt-modules/dialog'

export const OrderPluginAction = {
  SET_FROM: 'ORDER_PLUGIN::SET_FROM',
  SELECT_DAY: 'ORDER_PLUGIN::SELECT_DAY',
  SELECT_SLOT: 'ORDER_PLUGIN::SELECT_SLOT',
  RESET_DAY_AND_SLOT: 'ORDER_PLUGIN::RESET_DAY_AND_SLOT',
  RESET_DUE_DATE: 'ORDER_PLUGIN::RESET_DUE_DATE',
  SET_DUE_DATE_ASAP: 'ORDER_PLUGIN::SET_DUE_DATE_ASAP',
  SET_TABLE_NUMBER: 'ORDER_PLUGIN::SET_TABLE_NUMBER',
  DISABLE_TABLE_NUMBER_MODIFICATION: 'ORDER_PLUGIN::DISABLE_TABLE_NUMBER_MODIFICATION',
  SET_POS_CHECK_ID: 'ORDER_PLUGIN::SET_POS_CHECK_ID',
  SET_DELIVERY_ADDRESS: 'ORDER_PLUGIN::SET_DELIVERY_ADDRESS',
  RESET_DELIVERY_ADDRESS: 'ORDER_PLUGIN::RESET_DELIVERY_ADDRESS',
  SET_DELIVERY_ADDRESS_ERROR: 'ORDER_PLUGIN::SET_DELIVERY_ADDRESS_ERROR',
  SET_SELECTED_CATEGORY: 'ORDER_PLUGIN::SET_SELECTED_CATEGORY',
  SET_FIRST_SELECTED_CATEGORY: 'ORDER_PLUGIN::SET_FIRST_SELECTED_CATEGORY',
  RESET_SELECTED_CATEGORY: 'ORDER_PLUGIN::RESET_SELECTED_CATEGORY',
  SET_PARENT_CATEGORIES: 'ORDER_PLUGIN::SET_PARENT_CATEGORIES',
  SET_CONFIGURATION_ERROR: 'ORDER_PLUGIN::SET_CONFIGURATION_ERROR',
  DISPLAY_UPSELLING: 'ORDER_PLUGIN::DISPLAY_UPSELLING',
  BACK_TO_STORE_LOCATOR: 'ORDER_PLUGIN::BACK_TO_STORE_LOCATOR',
  RESET: 'ORDER_PLUGIN::RESET',
  POST_ORDER_PREVIEW_AND_POST_ORDER_IF_SUCCEEDED:
    'ORDER_PLUGIN::POST_ORDER_PREVIEW_AND_POST_ORDER_IF_SUCCEEDED',
  ALCOHOL_ACCEPT: 'ALCOHOL_APPROVAL::ACCEPT', // register the fact that the user said he's authorized to buy alcohol
  ALCOHOL_RESET: 'ALCOHOL_APPROVAL::RESET', // forget the fact that the user said he's authorized to buy alcohol
}

export const setApiConsumer = apiConsumer =>
  createAction(OrderPluginAction.SET_API_CONSUMER, {
    apiConsumer,
  })

/**
 * @param {string} date - the date
 */
export const selectDay = date =>
  createAction(OrderPluginAction.SELECT_DAY, {
    date,
  })

/**
 * Reset the selected slot.
 *
 * @param {string} slot - the selected slot
 * @param {number} day - optional, to override the selected day.
 */
export const selectSlot = (slot, day = null) =>
  createAction(OrderPluginAction.SELECT_SLOT, {
    slot,
    day,
  })

export const resetDueDate = () => createAction(OrderPluginAction.RESET_DUE_DATE, {})

export const setDueDateAsap = () => createAction(OrderPluginAction.SET_DUE_DATE_ASAP, {})

/**
 * Reset the selected day and slot's day used to generate the dueDate and the slot.
 *
 * TODO: should we modify the setFrontOrderDueDate middleware to reset the due date when this action
 * is made? For now we call resetDayAndSlot and resetDueDate() on the UnavailableDueDateDialog. We
 * fear to introduct side effect if we do this modification.
 */
export const resetDayAndSlot = () => createAction(OrderPluginAction.RESET_DAY_AND_SLOT, {})

/**
 * @param {string} tableNumber - the selected tableNumber
 */
export const setTableNumber = tableNumber =>
  createAction(OrderPluginAction.SET_TABLE_NUMBER, {
    tableNumber,
  })

export const disableTableNumberModification = () =>
  createAction(OrderPluginAction.DISABLE_TABLE_NUMBER_MODIFICATION)

/**
 * @param {string} posCheckId - the selected posCheckId
 */
export const setPosCheckId = posCheckId =>
  createAction(OrderPluginAction.SET_POS_CHECK_ID, {
    posCheckId,
  })

/**
 * e.g. this is set when a customer comes from webapp
 */
export const setFrom = from => createAction(OrderPluginAction.SET_FROM, { from })

/**
 * @param {string} tableNumber - the selected tableNumber
 */
export const setDeliveryAddress = (id, name, complement, street, postCode, city, country) =>
  createAction(OrderPluginAction.SET_DELIVERY_ADDRESS, {
    id,
    name,
    complement,
    street,
    postCode,
    city,
    country,
  })

export const resetDeliveryAddress = () => createAction(OrderPluginAction.RESET_DELIVERY_ADDRESS)

export const setDeliveryAddressError = value =>
  createAction(OrderPluginAction.SET_DELIVERY_ADDRESS_ERROR, {
    value,
  })

/**
 *
 * @param {*} category
 */
export const setSelectedCategory = category =>
  createAction(OrderPluginAction.SET_SELECTED_CATEGORY, {
    category,
  })

/**
 *
 * @param {*} firstSelectedCategory
 */
export const setFirstSelectedCategory = firstSelectedCategory =>
  createAction(OrderPluginAction.SET_FIRST_SELECTED_CATEGORY, {
    firstSelectedCategory,
  })

/**
 *
 * @param {*} category
 */
export const resetSelectedCategory = category =>
  createAction(OrderPluginAction.RESET_SELECTED_CATEGORY, {})

/**
 *
 * @param {*} parentCategories
 */
export const setParentCategories = parentCategories =>
  createAction(OrderPluginAction.SET_PARENT_CATEGORIES, {
    parentCategories,
  })

/**
 * @param {string} configurationError - text displayed in global layout / blocks order plugin
 */
export const setConfigurationError = configurationError =>
  createAction(OrderPluginAction.SET_CONFIGURATION_ERROR, {
    configurationError,
  })

export const goBackToStoreLocator = () => createAction(OrderPluginAction.BACK_TO_STORE_LOCATOR, {})

export const redirectToCatalogPage = restaurantId =>
  createRedirectToAction(getRoute('ORDER__CATALOG'), {
    restaurantId,
  })

export const redirectToCart = () => createRedirectToAction(getRoute('ORDER__CART'), {})

export const resetOrderPlugin = () => createAction(OrderPluginAction.RESET)

/**
 * @param {*} product
 * @param {*} options
 *  - restaurantId: required since it is need by withMenu used on the ProductDialog page.
 *
 * Handle by the middleware of the `openProduct` action
 */
export const showProductDialog = (product, options) =>
  createShowDialogAction(ModalType.ORDER_PLUGIN_PRODUCT_VIEW, {
    product,
    viewOptions: options,
    // put in the root for withMenu
    restaurantId: options.restaurantId,
  })

export const hideProductDialog = () => createHideDialogAction(ModalType.ORDER_PLUGIN_PRODUCT_VIEW)

export const showUnavailableDueDateDialog = () =>
  createShowDialogAction(ModalType.UNAVAILABLE_DUE_DATE)

export const hideUnavailableDueDateDialog = () =>
  createHideDialogAction(ModalType.UNAVAILABLE_DUE_DATE)

export const showOrderModeDisabledDialog = () =>
  createShowDialogAction(ModalType.ORDER_MODE_DISABLED)

export const hideOrderModeDisabledDialog = () =>
  createHideDialogAction(ModalType.ORDER_MODE_DISABLED)

export const showRestaurantDisabledDialog = () =>
  createShowDialogAction(ModalType.RESTAURANT_DISABLED)

export const hideRestaurantDisabledDialog = () =>
  createHideDialogAction(ModalType.RESTAURANT_DISABLED)

export const showRestaurantClosedDialog = (orderContext, restaurant) =>
  createShowDialogAction(ModalType.RESTAURANT_CLOSED, {
    orderContext,
    restaurant,
  })

export const hideRestaurantClosedDialog = () => createHideDialogAction(ModalType.RESTAURANT_CLOSED)

export const showTableNotFoundDialog = restaurantId =>
  createShowDialogAction(ModalType.TABLE_NOT_FOUND, {
    restaurantId,
  })

export const hideTableNotFoundDialog = () => createHideDialogAction(ModalType.TABLE_NOT_FOUND)

export const postOrderPreviewAndPostOrderIfSucceeded = () =>
  createAction(OrderPluginAction.POST_ORDER_PREVIEW_AND_POST_ORDER_IF_SUCCEEDED, {})

export const saveAlcoholApproval = () => createAction(OrderPluginAction.ALCOHOL_ACCEPT, {})

export const forgetAlcoholApproval = () => createAction(OrderPluginAction.ALCOHOL_RESET, {})
