import { tr } from 'pmt-modules/i18n'
import React from 'react'

import isNil from 'lodash/isNil'
import classNames from 'classnames'

import OrderRestaurantContextContainer from 'pmt-modules/orderRestaurantContext/container/OrderRestaurantContextContainer'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Hidden from 'pmt-ui/Hidden'
import Grid from 'pmt-ui/Grid'
import MapsPlace from 'pmt-ui/svg-icons/maps/place'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import TestChip from 'pmt-ui/TestChip'

const styles = (theme) => ({
  restaurantContainer: {
    position: 'relative',
    background: theme.pmt.colors.grey100,
    cursor: 'pointer',
    '&:first-child': {
      marginTop: 0,
    },
    '&:hover': {
      background: theme.pmt.colors.grey100,
    },
  },
  restaurantText: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    paddingRight: 0,
    transition: 'border 0.1s ease-in',
  },
  street: {
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      display: 'block',
    },
  },
  isSelected: {
    borderLeft: `4px solid ${theme.palette.primary.main} !important`,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
  },
  restaurantDistance: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    paddingLeft: 0,
    lineHeight: '74px',
  },
  mapsIcon: {
    height: 74,
    lineHeight: '74px',
  },
  opening: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    display: 'block',
    width: 8,
    height: 8,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
  },
  greenDot: {
    background: theme.pmt.colors.green500,
  },
  redDot: {
    background: theme.pmt.colors.red800,
  },
  greyDot: {
    background: theme.pmt.colors.grey500,
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  grey700: {
    color: theme.pmt.colors.grey700,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  textEllipsis: {
    ...theme.pmt.mixins.textEllipsis,
  },

  hr: {
    height: 0,
    width: `calc(100% - ${theme.spacing(4)}px)`,
    margin: '0 auto',
    borderTop: `1px solid ${theme.pmt.colors.grey500}`,
  },
})

const RestaurantRow = ({ restaurant, isSelected, onSelect, classes }) => {
  return (
    <OrderRestaurantContextContainer restaurantId={restaurant.id}>
      {({ orderContext }) => (
        <div>
          <Grid spacing={0} container className={classes.restaurantContainer} onClick={onSelect}>
            <Grid
              item
              xs={9}
              className={
                isSelected
                  ? `${classes.isSelected} ${classes.restaurantText}`
                  : classes.restaurantText
              }
            >
              <TypographyCustom
                type="204"
                className={classNames({
                  [classes.grey500]: !orderContext.canOrder,
                })}
              >
                {restaurant.name}
                {restaurant.testMode && (
                  <React.Fragment>
                    &nbsp;
                    <TestChip tooltipText={tr('global.restaurant.testMode.tooltip')} />
                  </React.Fragment>
                )}
              </TypographyCustom>
              {!isNil(restaurant.address) && (
                <div className="u-marginTop5">
                  <TypographyCustom
                    type="144"
                    className={classNames(classes.textEllipsis, {
                      [classes.grey500]: !orderContext.canOrder,
                    })}
                  >
                    <span className={classes.street}>
                      {restaurant.address.street}
                      &nbsp;
                    </span>
                    {restaurant.address.postCode} {restaurant.address.city}
                  </TypographyCustom>
                </div>
              )}
              <div className="u-marginTop5">
                <TypographyCustom
                  type="144"
                  className={classNames(classes.opening, {
                    [classes.grey500]: !orderContext.canOrder,
                  })}
                >
                  <span
                    className={classNames(classes.dot, {
                      [classes.greenDot]: orderContext.orderIsCurrentlyOpen,
                      [classes.redDot]: !orderContext.canOrder,
                      [classes.greyDot]:
                        !orderContext.orderIsCurrentlyOpen && orderContext.canPreOrder,
                    })}
                  />

                  <div>
                    {/*  */}

                    {orderContext.orderIsCurrentlyOpen && (
                      <React.Fragment>
                        {tr('order.store_locator.restaurant.open')}
                        <span className={classes.grey700}>
                          &nbsp;
                          {tr('order.store_locator.restaurant.open_order', {
                            hour: restaurant.orderSettings.currentOpeningHour.formattedClosing,
                          })}
                        </span>
                      </React.Fragment>
                    )}

                    {/*  */}
                    {!orderContext.canOrder && orderContext.nextOpening
                      ? orderContext.nextOpening.isCurrentDay
                        ? tr('order.store_locator.restaurant.closed_today_until', {
                            hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                          })
                        : tr('order.store_locator.restaurant.closed_until', {
                            day: orderContext.nextOpening.dayName,
                            hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                          })
                      : null}

                    {/*  */}
                    {!orderContext.orderIsCurrentlyOpen && orderContext.canPreOrder && (
                      <span className={classes.grey700}>
                        {orderContext.canPreOrderOnNextDaysButNotToday &&
                          tr('order.store_locator.restaurant.pre_order_for_day', {
                            day: orderContext.nextOpening.dayName,
                          })}

                        {orderContext.canPreOrderForLaterToday &&
                          tr('order.store_locator.restaurant.pre_order_for_today')}
                      </span>
                    )}

                    {/*  */}
                    {!orderContext.canOrder &&
                      !orderContext.nextOpening &&
                      tr('order.store_locator.restaurant.closed')}
                  </div>
                </TypographyCustom>
              </div>
            </Grid>
            {isSelected ? (
              <Grid item xs={3} className={classes.restaurantDistance}>
                <ActionDone className={classNames(classes.mapsIcon, classes.checkedIcon)} />
              </Grid>
            ) : (
              !isNil(restaurant.geoPt.distanceFormatted) && (
                <Grid item xs={3} className={classes.restaurantDistance}>
                  <Hidden smDown>
                    <MapsPlace className={classNames(classes.mapsIcon, classes.grey700)} />
                  </Hidden>
                  <TypographyCustom type="144" align="right" className={classes.grey700}>
                    {tr('order.store_locator.restaurant.at')} {restaurant.geoPt.distanceFormatted}
                  </TypographyCustom>
                </Grid>
              )
            )}
          </Grid>
          <hr className={classes.hr} />
        </div>
      )}
    </OrderRestaurantContextContainer>
  )
}

export default withStyles(styles)(RestaurantRow)
