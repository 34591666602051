import { tr } from 'pmt-modules/i18n'
import React from 'react'

import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import Add from 'pmt-ui/svg-icons/content/add'

import Button from '../../../../components/Button'

const styles = theme => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  mobileRoot: {
    padding: `0 ${theme.spacing(2)}px`,
  },
})

/**
 * Also used as bottom of the page on mobile: https://zpl.io/VxBkdkk. see View. We use the forMobile
 * prop set to true
 *
 */
const Actions = ({ appConfigPreset, classes, orderMenu, forMobile, isEditMode, onSubmit }) => (
  <div
    className={classNames(classes.root, {
      [classes.mobileRoot]: forMobile,
    })}
  >
    <Button
      disabled={!orderMenu.isValid}
      onClick={onSubmit}
      size="large"
      fullWidth
      icon={!isEditMode && <Add />}
      label={tr(
        !isEditMode
          ? appConfigPreset.isDigitalMenu
            ? 'order.catalog.add_to_cart_digital_menu'
            : 'order.catalog.add_to_cart_with_price'
          : 'order.catalog.update_with_price',
        {
          price: orderMenu.totalPriceFormatted,
        }
      )}
    />
  </div>
)

export default withStyles(styles)(Actions)
