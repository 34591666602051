import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hideDietaryInfoDialog } from 'pmt-modules/catalog'
import { getAllergensOptions } from 'pmt-modules/user/constants'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import CloseIcon from '@material-ui/icons/Close'

import { TypographyCustom } from 'pmt-ui/Typography'

import Button from 'app/components/Button'

import DesktopView from './DesktopView'
import MobileView from './MobileView'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 35px)',
    color: theme.palette.primary.main,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  dialogContent: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  dialogActions: {
    cursor: 'pointer',
    background: theme.pmt.colors.greyBackground,
    boxShadow: `0 0 4px 0 ${theme.pmt.colors.darkGrey}`,
    justifyContent: 'center',
    margin: 0,
    padding: `${theme.spacing(2)}px 0`,
    zIndex: 1,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
})

/**
 * @specs N/A
 */
class DietaryInfoDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    // set static var for better performance and clean allergens without icon
    this.allergens = getAllergensOptions().filter(
      a => props.item.formattedAllergens.indexOf(a.value) >= 0
    )
  }

  render() {
    const { classes, item, hideDietaryInfoDialog } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="287" skipColor className={classes.dialogTitle}>
            {tr('order.dietary_info.title')}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={hideDietaryInfoDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.wrapper}>
            <Hidden xsDown>
              <DesktopView allergens={this.allergens} item={item} />
            </Hidden>
            <Hidden smUp>
              <MobileView allergens={this.allergens} item={item} />
            </Hidden>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="outlined"
            label={tr('order.dietary_info.close')}
            onClick={hideDietaryInfoDialog}
          />
        </DialogActions>
      </React.Fragment>
    )
  }
}

DietaryInfoDialog.DialogStyles = theme => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = () => ({})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      hideDietaryInfoDialog,
    }
  )
)(DietaryInfoDialog)
