import ApiEndpoints from '../config/ApiEndpoints'

const UserAddressApi = {
  getList: userId => {
    return {
      endpoint: ApiEndpoints.USER_ADDRESSES,
      type: 'GET',
      params: {
        userId,
      },
    }
  },

  post: (userId, address) => {
    return {
      endpoint: ApiEndpoints.USER_ADDRESSES,
      type: 'POST',
      params: {
        userId,
      },
      body: address,
    }
  },

  edit: (userId, addressId, address) => {
    return {
      endpoint: ApiEndpoints.USER_ADDRESS,
      type: 'PUT',
      params: {
        userId,
        addressId,
      },
      body: address,
    }
  },

  delete: (userId, addressId) => {
    return {
      endpoint: ApiEndpoints.USER_ADDRESS,
      type: 'DELETE',
      params: {
        userId,
        addressId,
      },
    }
  },
}

export default UserAddressApi
