import React from 'react'
import classNames from 'classnames'

import isEmpty from 'lodash/isEmpty'
import GoogleMapsAutocomplete from 'pmt-modules/google/components/GoogleMapsAutocomplete'
import GoogleMapsReverseGeocoding from 'pmt-modules/google/components/GoogleMapsReverseGeocoding'

import TextField from 'pmt-ui/TextField'
import { withStyles } from 'pmt-ui/styles'
import ContentClear from 'pmt-ui/svg-icons/content/clear'

const styles = theme => ({
  clearIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.primary.main,
  },
  clearIcon: {
    float: 'right',
    height: 54,
    marginTop: 10,
    cursor: 'pointer',
  },
  geoSuggestBlock: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  geoSuggestionContainer: {},
  restaurantGoogleMap: {},
  geolocContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  geolocButton: {},
  textField: {
    width: '100%',
  },
  inputGeoloc: {
    width: 'calc(100% - 24px)',
    maxWidth: 'calc(100% - 24px)',
    height: 36,
    padding: 0,
    lineHeight: '36px',
    border: 'none',
    fontSize: 14,
    outline: 'none',
  },
  labelError: {
    display: 'block',
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.red800,
  },
})

const SearchAddress = ({
  inputClassName,
  placeholder,
  label,
  resetAddressCallback,
  selectLocationCallback,
  reverseGeocoding = false,
  reverseGeocodingSuccessCallback,
  geolocationId,
  geolocationAddress,
  geolocationComputeAddress,
  geolocationLatitude,
  geolocationLongitude,
  geolocationError,
  geolocationOrigin,
  countryRestriction,
  onChangeCallback,
  errorText,
  helperText,
  error, // aka addressLocationNotGood / isValidAddress
  classes,
}) => {
  const hasSearchedAddress = !isEmpty(geolocationComputeAddress)
  const shouldDisplayError = hasSearchedAddress && (error || geolocationError) && errorText

  return (
    <GoogleMapsReverseGeocoding
      geolocationId={geolocationId}
      geolocationLatitude={geolocationLatitude}
      geolocationLongitude={geolocationLongitude}
      geolocationOrigin={geolocationOrigin}
      geolocationComputeAddress={geolocationComputeAddress}
      reverseGeocoding={reverseGeocoding}
      successCallback={reverseGeocodingSuccessCallback}
    >
      <GoogleMapsAutocomplete
        reverseAddress={geolocationAddress}
        inputFieldId={geolocationId}
        handleSelectLocation={selectLocationCallback}
        countryRestriction={countryRestriction}
      >
        <div className={classes.geoSuggestBlock}>
          <div className={classes.geolocContainer}>
            <TextField
              id={geolocationId}
              type="text"
              className={classNames(classes.textField)}
              label={label}
              placeholder={placeholder}
              value={geolocationComputeAddress ? geolocationComputeAddress.formattedAddress : geolocationAddress || ''}
              InputProps={{
                classes: {
                  input: classNames(classes.inputGeoloc, inputClassName),
                },
              }}
              onChange={onChangeCallback}
              error={shouldDisplayError}
              helperText={shouldDisplayError ? errorText : helperText}
            />
            {(geolocationAddress || geolocationComputeAddress) && (
              <div className={classes.clearIconContainer}>
                <ContentClear
                  className={classes.clearIcon}
                  onClick={() => {
                    resetAddressCallback()
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </GoogleMapsAutocomplete>
    </GoogleMapsReverseGeocoding>
  )
}

SearchAddress.defaultProps = {
  label: '',
  placeholder: '',
}

export default withStyles(styles)(SearchAddress)
