import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let CommentOutline = props => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.125,10.625 L6.875,10.625 C6.53,10.625 6.25,10.905 6.25,11.25 C6.25,11.595 6.53,11.875 6.875,11.875 L13.125,11.875 C13.47,11.875 13.75,11.595 13.75,11.25 C13.75,10.905 13.47,10.625 13.125,10.625 Z M14.375,6.875 L5.625,6.875 C5.28,6.875 5,7.155 5,7.5 C5,7.845 5.28,8.125 5.625,8.125 L14.375,8.125 C14.72,8.125 15,7.845 15,7.5 C15,7.155 14.72,6.875 14.375,6.875 Z M10,0 C4.47751634,0 0,3.91748366 0,8.75 C0,11.5118954 1.46562092,13.9706209 3.75,15.5743791 L3.75,20 L8.13062092,17.3418627 C8.73689542,17.4418954 9.36062092,17.5 10,17.5 C15.5231373,17.5 20,13.5825163 20,8.75 C20,3.91748366 15.5231373,0 10,0 Z M10,16.25 C9.27,16.25 8.565,16.165 7.88748366,16.0206209 L4.94496732,17.7893464 L4.98434641,14.8906209 C2.72875817,13.5337582 1.25,11.2912745 1.25,8.75 C1.25,4.60813725 5.16748366,1.25 10,1.25 C14.8325163,1.25 18.75,4.60813725 18.75,8.75 C18.75,12.8918627 14.8325163,16.25 10,16.25 Z"
      id="Shape"
    />
  </SvgIcon>
)
CommentOutline = pure(CommentOutline)
CommentOutline.displayName = 'CommentOutline'
CommentOutline.muiName = 'SvgIcon'

export default CommentOutline
