import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { goToAnchor, removeHash, configureAnchors } from 'pmt-ui/ScrollableAnchor'
import { MuiStepper, MuiStep, MuiStepLabel, MuiStepIcon } from 'pmt-ui/Stepper'
import PartQuantity from '../PartQuantity'

// Offset all anchors by -40 to account for a fixed header
// and scroll more quickly than the default 400ms
// TODO: put on constructor ?
configureAnchors({ offset: -40, scrollDuration: 300 })

const styles = theme => ({
  // TODO: position static
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
  },
  title: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  partList: {
    marginTop: theme.spacing(2),
  },
  part: {
    display: 'block',
    cursor: 'pointer',
  },
  labelContainer: {
    width: 'calc(100% - 32px)',
  },
  partName: {},
  partQuantityQuantityTip: {
    paddingTop: 4,
    display: 'block',
    lineHeight: '14px',
  },
  stepperRoot: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      margin: '0 auto',
    },
  },
  stepIconInvalid: {
    border: `1px solid ${theme.pmt.colors.grey500}!important`,
    width: 24,
    height: 24,
    borderRadius: 90,
  },
})

const SideMenu = ({ orderMenu, classes }) => (
  <div className={classes.root}>
    <TypographyCustom type="144" className={classes.title}>
      {tr('order.menu.side_menu.title')}
    </TypographyCustom>

    <div className={classes.partList}>
      <MuiStepper activeStep={null} orientation="vertical" classes={{ root: classes.stepperRoot }}>
        {orderMenu.parts.map(part => (
          <MuiStep
            key={part.id}
            completed={part.isValid && part.nbChosenValues >= 1}
            // always false since there is nothing in the content
            active={false}
          >
            <MuiStepLabel
              icon={
                // see https://github.com/mui-org/material-ui/blob/v1-beta/src/Stepper/StepIcon.js
                // we want to render the default icon when the part is valid.
                // When it is not valid, the default display the index of the step, we want to use
                // our custom component instead
                <MuiStepIcon
                  icon={
                    part.isValid && part.nbChosenValues >= 1 ? (
                      0
                    ) : (
                      <div className={classes.stepIconInvalid} />
                    )
                  }
                  completed={part.isValid && part.nbChosenValues >= 1}
                />
              }
              className={classes.labelContainer}
            >
              <span
                key={part.id}
                className={classes.part}
                onClick={() => {
                  // trick: we have two parts, and scrolled to the last one
                  // but the first one is still a little bit visible (with its header sticky)
                  // the url hash correspond to the first part, and not the second one
                  // that is visually feeled as the currently displayed part.
                  // so when we click on the anchor to display the first part, it does not work,
                  // since the hash in url is the same as the anchor we want to scroll to.
                  // So we remove the hash before scrolling to the anchor if the url hash and the
                  // anchor are the same, otherwise it does not scroll at all.
                  // Problems with this trick: it display a little 'blink' on the url while we
                  // remove the hash / add a new one
                  const hash = window.location.hash // ex: #plat
                  const anchor = part.anchor // ex: plat
                  // we remove the hash only if we want to scroll to the same hash
                  if (hash.substring(1) === anchor) {
                    removeHash()
                  }

                  EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_BREADCRUMB, {
                    item: orderMenu,
                    part,
                  })
                  // scroll to the anchor, without saving that hash update in history (false)
                  goToAnchor(anchor, false)
                }}
              >
                <TypographyCustom type="167" component="span" className={classes.partName}>
                  {part.name}
                </TypographyCustom>

                <br />

                <PartQuantity
                  part={part}
                  typographyType={144}
                  displaySelectedItems
                  classes={{
                    quantityTip: classes.partQuantityQuantityTip,
                  }}
                />
              </span>
            </MuiStepLabel>
          </MuiStep>
        ))}
      </MuiStepper>
    </div>
  </div>
)

export default withStyles(styles)(SideMenu)
