import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import OrderRestaurantContextContainer from 'pmt-modules/orderRestaurantContext/container/OrderRestaurantContextContainer'

import Grid from 'pmt-ui/Grid'

import { withStyles } from 'pmt-ui/styles'
import Hidden from 'pmt-ui/Hidden'

import BlockContainer from 'app/components/BlockContainer'
import Button from 'app/components/Button'
import WhenContent from './WhenContent'

const styles = theme => ({
  root: {
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  stickyButton: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    marginTop: theme.spacing(1),
    borderRadius: 0,
    zIndex: theme.zIndex.stickyButton,
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  bigButton: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
})

class WhenSection extends React.PureComponent {
  render() {
    const {
      ui,
      orderProperties,
      restaurant,
      handleMenuRedirection,
      handleSetTableNumber,
      isFetchingRestaurantList,
      classes,
    } = this.props

    const isSubmitDisabled = orderProperties.isMissingSlot || orderProperties.isMissingTableNumber

    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        // this help us to handle the hidden parts of mobile display #responsive
        className={classNames('u-paddingTop0', {
          [classes.hideMobile]: !ui.displaySecondPart,
          [classes.showMobile]: ui.displaySecondPart,
        })}
      >
        <BlockContainer type={BlockContainer.Type.WHITE} className={classes.root}>
          <OrderRestaurantContextContainer
            restaurantId={restaurant && restaurant.id}
            withAvailabilities
          >
            {({ orderContext }) => (
              <React.Fragment>
                <WhenContent
                  restaurant={restaurant}
                  orderContext={orderContext}
                  orderProperties={orderProperties}
                  handleSetTableNumber={handleSetTableNumber}
                  handleMenuRedirection={handleMenuRedirection}
                  isFetchingRestaurantList={isFetchingRestaurantList}
                />

                {orderContext && orderContext.canOrder && orderContext.hasRestaurantAvailabilities && (
                  <React.Fragment>
                    <Hidden mdUp>
                      <Button
                        size="large"
                        disabled={isSubmitDisabled}
                        onClick={handleMenuRedirection}
                        classes={{ root: classes.stickyButton }}
                        label={tr('order.global.continue')}
                      />
                    </Hidden>
                    {/* Continue butotn for on site and delivery */}
                    <Hidden smDown mdUp={orderProperties.canOrderUsingSlots}>
                      <Button
                        size="large"
                        disabled={isSubmitDisabled}
                        classes={{ root: classes.bigButton }}
                        onClick={handleMenuRedirection}
                        label={tr('order.global.continue')}
                      />
                    </Hidden>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </OrderRestaurantContextContainer>
        </BlockContainer>
      </Grid>
    )
  }
}

export default withStyles(styles)(WhenSection)
