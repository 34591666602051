import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { OrderMode } from 'pmt-modules/order'
import { selectMode, setRestaurantId } from 'pmt-modules/orderFront'

import { MenuItem } from 'pmt-ui/Menu'
import { withStyles } from 'pmt-ui/styles'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'
import Select from 'pmt-ui/Select'
import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'

const styles = theme => ({
  selectMenu: {
    '&>span:first-child': {
      display: 'none',
    },
  },
  selectValue: {
    [theme.breakpoints.up('md')]: {
      float: 'left',
      lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '12px',
    },
  },
  orderIconSelect: {
    [theme.breakpoints.up('md')]: {
      float: 'left',
      height: 28,
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  orderIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      float: 'left',
      display: 'block',
      height: 36,
    },
  },
})

const OrderModeSelect = ({
  classes,
  orderProperties,
  appConfig,
  setRestaurantId,
  selectMode,
  width,
}) =>
  !isNil(orderProperties.mode) && (
    <Select
      onChange={event => {
        EventManager.dispatch(EventManager.Events.ON_HEADER_MODE_SELECT, {
          mode: event.target.value,
        })
        selectMode(event.target.value)
        setRestaurantId(null)
      }}
      classes={{ selectMenu: classes.selectMenu }}
      inputProps={{
        name: 'Mode',
      }}
      disableUnderline={isWidthDown('sm', width)}
      value={orderProperties.mode}
      renderValue={value => (
        <TypographyCustom
          component="p"
          type={isWidthUp('sm', width) ? 164 : 144}
          className="u-inline"
        >
          <IconOrderMode type={value} className={classes.orderIconSelect} />
          <span className={classNames(classes.selectValue)}>
            {orderProperties.isDelivery
              ? tr('order.global.delivery')
              : orderProperties.isOnSite
                ? tr('order.global.on_site')
                : tr('order.global.take_away')}
          </span>
        </TypographyCustom>
      )}
    >
      {appConfig.order.modes.map((mode, index) => (
        <MenuItem key={index} value={mode}>
          <IconOrderMode type={mode} className={classes.orderIcon} />
          <TypographyCustom
            component="span"
            type={isWidthUp('sm', width) ? 164 : 144}
            align="center"
          >
            {OrderMode.DELIVERY === mode
              ? tr('order.global.delivery')
              : OrderMode.ON_SITE === mode
                ? tr('order.global.on_site')
                : tr('order.global.take_away')}
          </TypographyCustom>
        </MenuItem>
      ))}
    </Select>
  )

const mapStateToProps = () => ({})

export default compose(
  withWidth(),
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      selectMode,
      setRestaurantId,
    }
  )
)(OrderModeSelect)
