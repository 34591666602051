import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionDone from 'pmt-ui/svg-icons/action/done'

const styles = theme => ({
  quantityTip: {
    color: theme.pmt.colors.grey500,
    fontStyle: 'italic',
  },
  quantityTipForValidPart: {
    // color: theme.palette.primary.main,
  },
  selectedItemsString: {
    color: theme.palette.primary.main,
    ...theme.pmt.mixins.textEllipsis,
    fontStyle: 'normal',
  },
  quantitySelected: {
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    marginRight: 2,
  },
  lineHeight25: {
    padding: '0 !important',
    display: 'block',
    float: 'left',
    lineHeight: '25px',
    height: 25,
  },
})

/*
  * - MIN to MAX where MIN >= 0 and MAX != MIN
  * - MIN == MAX
  *
  *
  * MIN to MAX where MIN >= 0 and MAX != MIN
  */
const PartQuantity = ({ part, displaySelectedItems, classes, typographyType }) => (
  <React.Fragment>
    {displaySelectedItems && part.nbChosenValues > 0 ? (
      <TypographyCustom
        component="div"
        type={typographyType}
        className={classes.selectedItemsString}
      >
        {part.selectedItemsString}
      </TypographyCustom>
    ) : part.nbChosenValues > 0 ? (
      <TypographyCustom component="div" type={typographyType}>
        <span className={classNames(classes.quantitySelected, classes.lineHeight25)}>
          {part.hasReachedMaxQuantity && <ActionDone className={'u-floatLeft u-marginRight5'} />}
          {part.min === 1 && part.max === 1
            ? tr('order.menu.part.quantity.product_selected')
            : tr('order.menu.part.quantity.product_selected_value', {
                smart_count: part.nbChosenValues,
              })}
        </span>
        {!part.hasReachedMaxQuantity && (
          <span className={classNames(classes.quantityTip, classes.lineHeight25)}>
            {part.min !== part.max
              ? tr('order.menu.part.quantity.on_maximum', { value: part.max })
              : tr('order.menu.part.quantity.on', { value: part.max })}
          </span>
        )}
      </TypographyCustom>
    ) : (
      <TypographyCustom
        component="div"
        type={typographyType}
        className={classNames(classes.quantityTip, {
          [classes.quantityTipForValidPart]: part.isValid && part.nbChosenValues >= 1,
        })}
      >
        {part.min >= 0 &&
          part.max !== part.min && (
            <span>
              {tr('order.menu.part.quantity.min_max_product_to_select.min', {
                min: part.min,
              })}
              {tr('order.menu.part.quantity.min_max_product_to_select.max', {
                smart_count: part.max,
              })}
            </span>
          )}

        {/* min equals max */}
        {part.min === part.max && (
          <span>
            {tr('order.menu.part.quantity.min_product_to_select', { smart_count: part.min })}
          </span>
        )}
      </TypographyCustom>
    )}
  </React.Fragment>
)

export default withStyles(styles)(PartQuantity)
