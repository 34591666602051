import { createSelector } from 'reselect'
import moment from 'moment'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { findOnArray } from 'pmt-utils/array'

import { getGeolocationCoordinates } from 'pmt-modules/geolocation/selectors'
import { OrderMode } from 'pmt-modules/order/constants'
import { getOrderProperties } from 'pmt-modules/orderPlugin'
import { getRestaurantAvailabilities } from 'pmt-modules/orderSettings/utils'
import { getMode } from 'pmt-modules/orderFront'

import { getAllRestaurants } from 'pmt-modules/restaurant/selectors'
import { formatRestaurant } from 'pmt-modules/restaurant/format'

import { getMaxTimeAheadAsDaysOnMode } from 'pmt-modules/orderSettings'

import { diff } from 'pmt-utils/date'

// TODO: rename to get..OnProps
const getRestaurantIdOnProps = (state, props) => props.restaurantId
const getModeOnProps = (state, props) => props.mode
const getWithAvailabilitiesOnProps = (state, props) => props.withAvailabilities

// TODO: replace makeGetRestaurantFromRestaurantList to makeGetRestaurant, but we do not have the formatted
// orderProperties this way yet.
const makeGetRestaurant = () =>
  createSelector(
    [getAllRestaurants, getRestaurantIdOnProps, getGeolocationCoordinates, getModeOnProps],
    (restaurantList, restaurantId, geolocationCoordinates, mode) => {
      const restaurant = findOnArray(restaurantList, restaurant => restaurant.id === restaurantId)

      return restaurant
        ? formatRestaurant(restaurant, {
            mode,
            longitude: geolocationCoordinates?.longitude,
            latitude: geolocationCoordinates?.latitude,
          })
        : null
    }
  )

export const makeGetOrderContext = () =>
  createSelector(
    [makeGetRestaurant(), getMode, getWithAvailabilitiesOnProps, getOrderProperties],
    (restaurant, mode, withAvailabilities, orderProperties) => {
      if (!restaurant) {
        return null
      }

      const isDelivery = mode === OrderMode.DELIVERY

      const maxTimeAheadAsDaysForMode = getMaxTimeAheadAsDaysOnMode(
        mode,
        restaurant.orderSettings.onSiteSettings,
        restaurant.orderSettings.takeAwaySettings,
        restaurant.orderSettings.deliverySettings
      )

      let daysBeforePreOrder = null
      if (restaurant.orderSettings.nextOpening) {
        // nextOpening.date is now.add(1, 'd').startOf('day')
        // we want to compare with today at midnight to ensure having full day gap
        daysBeforePreOrder = diff(
          moment().startOf('day'),
          restaurant.orderSettings.nextOpening.date
        )
      }

      const orderIsCurrentlyOpen =
        restaurant.orderSettings.isCurrentlyOpen && restaurant.isStatusEnabled

      const hasAsapFlow = orderProperties.canOrderUsingAsap
      const hasChooseSlotFlow = orderProperties.canOrderUsingSlots
      const hasPreOrderFlow = orderProperties.canOrderUsingSlots

      // can*: define if the flow is possible and the context make it available
      const canAsap = hasAsapFlow && orderIsCurrentlyOpen
      const canPreOrder =
        hasPreOrderFlow &&
        !isNil(daysBeforePreOrder) &&
        daysBeforePreOrder <= maxTimeAheadAsDaysForMode &&
        restaurant.isStatusEnabled

      const canOnlyAsap = hasAsapFlow && !hasChooseSlotFlow && !canPreOrder && canAsap

      //
      //
      //

      const willOpenLaterToday =
        !orderIsCurrentlyOpen && restaurant.orderSettings.willOpenLaterToday

      const nextOpening = restaurant.isStatusEnabled && restaurant.orderSettings.nextOpening

      const canOrder = canPreOrder || orderIsCurrentlyOpen

      const canPreOrderOnNextDaysButNotToday =
        !orderIsCurrentlyOpen && canPreOrder && nextOpening && !nextOpening.isCurrentDay

      let nextPreOrderOpening = null
      if (hasPreOrderFlow && !isNil(daysBeforePreOrder) && nextOpening && nextOpening.date) {
        nextPreOrderOpening = nextOpening.date.clone().subtract(daysBeforePreOrder, 'days')
      }

      const canPreOrderForLaterToday =
        canPreOrder &&
        nextOpening &&
        nextOpening.isCurrentDay &&
        nextOpening.openingHours.length > 0

      let restaurantAvailabilities = null
      if (withAvailabilities) {
        if (canPreOrder) {
          // TODO: orderClosing = : now + pre order limit in days - manufacture delay
          restaurantAvailabilities = get(
            getRestaurantAvailabilities(restaurant, mode),
            'availabilities',
            []
          )
        } else {
          // TODO: use this when optimizing slot generation
          // orderOpening = (opening courant (date now) || next opening) + manufacture delay
          // orderClosing = next closing - manufacture delay

          // TODO: use another method to optimize calculation between opening and closing
          restaurantAvailabilities = get(
            getRestaurantAvailabilities(restaurant, mode),
            'availabilities',
            []
          )
        }
      }

      // TODO:
      let addressIsInRestaurantDeliveryAddressRange = true
      if (isDelivery) {
        // calculate if the selected address is in the range of delivery for this restaurant
        // TODO:
        addressIsInRestaurantDeliveryAddressRange = false
      }

      return {
        hasAsapFlow,
        hasChooseSlotFlow,
        hasPreOrderFlow,
        canAsap,
        canPreOrder,
        canOnlyAsap,

        // orderOpening,
        // orderClosing,

        addressIsInRestaurantDeliveryAddressRange,

        canPreOrderOnNextDaysButNotToday,
        canPreOrderForLaterToday,

        orderIsCurrentlyOpen,
        canOrder,

        willOpenLaterToday,
        daysBeforePreOrder,
        maxTimeAheadAsDaysForMode,

        nextOpening,
        nextPreOrderOpening,

        restaurantAvailabilities,
        hasRestaurantAvailabilities: !isEmpty(restaurantAvailabilities),
      }
    }
  )
