import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let LockOutline = props => (
  <SvgIcon {...props}>
    <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ic_forgotpassword">
        <g id="Group-19" transform="translate(2.000000, 2.000000)">
          <path
            d="M8.05863004,14.0206186 C7.36583842,14.0206186 6.80412371,13.4591817 6.80412371,12.7660535 L6.80412371,9.29580219 C6.80412371,8.60297813 7.36583842,8.04123711 8.05863004,8.04123711 L12.0444627,8.04123711 C12.7372544,8.04123711 13.2989691,8.60297813 13.2989691,9.29580219 L13.2989691,12.7660535 C13.2989691,13.4591817 12.7372544,14.0206186 12.0444627,14.0206186 L8.05863004,14.0206186 Z"
            id="Fill-55"
            fill="#EFEFF4"
          />
          <path
            d="M11.9612828,14.0255277 L8.03871724,14.0255277 C7.35692232,14.0255277 6.80412371,13.4730026 6.80412371,12.7908764 L6.80412371,9.27588845 C6.80412371,8.5940616 7.35692232,8.04123711 8.03871724,8.04123711 L11.9612828,8.04123711 C12.6430777,8.04123711 13.1958763,8.5940616 13.1958763,9.27588845 L13.1958763,12.7908764 C13.1958763,13.4730026 12.6430777,14.0255277 11.9612828,14.0255277 Z"
            id="Stroke-56"
            stroke="#919CA7"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.9587629,8.04123711 L8.04123711,8.04123711 L8.04123711,6.19022722 C8.04123711,5.10600336 8.91834956,4.22680412 10,4.22680412 C11.0819544,4.22680412 11.9587629,5.10600336 11.9587629,6.19022722 L11.9587629,8.04123711 Z"
            id="Stroke-57"
            stroke="#919CA7"
            strokeWidth="0.5"
          />
          <path
            d="M10.4637276,11.1248728 C10.6569981,10.9799468 10.7840218,10.7489901 10.7840218,10.4862731 C10.7840218,10.0468697 10.4330353,9.69072165 10,9.69072165 C9.56696473,9.69072165 9.21567436,10.0468697 9.21567436,10.4862731 C9.21567436,10.7489901 9.34269804,10.9799468 9.53596852,11.1248728 L9.17525773,12.4742268 L10.8247423,12.4742268 L10.4637276,11.1248728 Z"
            id="Fill-58"
            fill="#919CA7"
          />
          <path
            d="M10.8247423,12.4742268 L10.4637276,11.1248728 C10.6569981,10.9799468 10.7840218,10.7489901 10.7840218,10.4862731 C10.7840218,10.0468697 10.4330353,9.69072165 10,9.69072165 C9.56696473,9.69072165 9.21567436,10.0468697 9.21567436,10.4862731 C9.21567436,10.7489901 9.34269804,10.9799468 9.53596852,11.1248728 L9.17525773,12.4742268 L10.8247423,12.4742268 Z"
            id="Stroke-59"
          />
          <path
            d="M19.9407216,10.0070833 C19.9407216,15.495 15.4919716,19.94375 10.0044716,19.94375 C4.51655498,19.94375 0.0682216495,15.495 0.0682216495,10.0070833 C0.0682216495,4.51958333 4.51655498,0.0708333333 10.0044716,0.0708333333 C15.4919716,0.0708333333 19.9407216,4.51958333 19.9407216,10.0070833 Z"
            id="Stroke-60"
            stroke="#919CA7"
            strokeWidth="0.5"
            strokeDasharray="0.515463924407959,1.030927848815918"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)
LockOutline = pure(LockOutline)
LockOutline.displayName = 'LockOutline'
LockOutline.muiName = 'SvgIcon'

export default LockOutline
