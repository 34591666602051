import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchUserAddressList } from '../../actions'

import {
  getUserAddressList,
  makeGetUserAddressListInRange,
  makeGetUserAddressListOutOfRange,
  isFetchingUserAddressList,
} from '../../selectors'

/**
 * Container that fetch the address list for the given `userId` and pass it to the children.
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - userId, can be 'me'
 *
 */
class UserAddressListContainer extends React.Component {
  componentWillMount() {
    if (isNil(this.props.userAddressList)) {
      this.loadUserAddressList(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userId !== this.props.userId) {
      this.loadUserAddressList(nextProps)
    }
  }

  loadUserAddressList(props) {
    props.fetchUserAddressList(props.userId)
  }

  render() {
    const { children, ...otherProps } = this.props

    return children({
      ...otherProps,
    })
  }
}

UserAddressListContainer.propTypes = {
  fetchUserAddressList: PropTypes.func.isRequired,
  isFetchingUserAddressList: PropTypes.bool,
  userAddressList: PropTypes.array,
  userAddressListInRange: PropTypes.array,
  userAddressListOutOfRange: PropTypes.array,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  const userAddressList = getUserAddressList(state)
  const getUserAddressListInRange = makeGetUserAddressListInRange()
  const getUserAddressListOutOfRange = makeGetUserAddressListOutOfRange()

  return {
    userAddressList,
    userAddressListInRange: getUserAddressListInRange(state, { ...props, userAddressList }),
    userAddressListOutOfRange: getUserAddressListOutOfRange(state, { ...props, userAddressList }),
    isFetchingUserAddressList: isFetchingUserAddressList(state),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchUserAddressList,
  }
)(UserAddressListContainer)
