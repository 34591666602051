import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import InfoOutlineIcon from 'pmt-ui/svg-icons/action/info-outline'
import WheatIcon from 'pmt-ui/svg-icons/food/wheat'
import Price from 'pmt-ui/Price'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'

import Button, { ButtonLink } from '../../../components/Button'
import TipWithIcon from '../../../components/TipWithIcon'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  image: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: 150,
  },
  mediaRestaurantLogoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    height: '150px',
    width: '100%',
  },
  mediaRestaurantLogo: {
    height: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogoContent: {
    display: 'flex',
    alignItems: 'center',
    background: theme.pmt.colors.itemPlaceholderBackground,
    height: '150px',
    width: '100%',
  },
  mediaPlaceholderLogo: {
    height: '80px',
    width: '100%',
    opacity: 0.3,
  },
  nameArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    color: theme.palette.primary.light,
  },
  description: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  menuChip: {
    marginLeft: theme.spacing(1),
    marginTop: 3,
    minWidth: 40,
    padding: `2px 5px`,
    borderRadius: '8%',
    fontSize: 12,
    height: 18,
    lineHeight: '18px',
    fontWeight: 700,
    textAlign: 'center',
    backgroundColor: theme.pmt.colors.grey300,
    color: theme.pmt.colors.grey700,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  allergen: {
    marginTop: theme.spacing(1),
    justifyContent: 'flex-start',
    marginLeft: `-${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: `0 !important`,
    },
    textDecoration: 'underline',
  },
  infoArea: {
    [theme.breakpoints.up('md')]: {
      // align price to bottom
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
    },
  },
  addToCartTip: {
    color: theme.palette.primary.light,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingBottom: theme.spacing(1),
    },
  },
  addToCartTipIcon: {
    marginTop: -14,
  },
  addToCartTipText: {},
  basePrice: {
    display: 'block',
    textDecoration: 'line-through',
    color: theme.pmt.colors.grey300,
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  price: {
    float: 'left',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cartModifierChip: {
    float: 'left',
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 4,
  },
  tipWithIcon: {
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      maxWidth: 320,
      float: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
      textAlign: 'left',
    },
  },
  submitBtn: {
    marginBottom: theme.spacing(1),
  },
  mobileAdd: {
    // same as Product#Header
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
})

const HeaderView = ({
  appConfigPreset,
  orderMenu,
  restaurantLogo,
  isEditMode,
  classes,
  onSubmit,
  showDietaryInfoDialog,
}) => {
  return (
    <Grid spacing={2} container className={classes.root}>
      {/** PICTURE AREA
       * on desktop we always display this Grid item
       * on mobile, we display it only if there is an image, to avoid an empty block with padding
       */}
      <Hidden smDown={!orderMenu.hasImage}>
        <Grid item xs={12} md={3}>
          {orderMenu.hasImage ? (
            <img className={classes.image} src={orderMenu.image} alt={orderMenu.name} />
          ) : !isEmpty(restaurantLogo) ? (
            <div className={classes.mediaRestaurantLogoContent}>
              <img
                className={classes.mediaRestaurantLogo}
                src={restaurantLogo}
                alt={orderMenu.name}
              />
            </div>
          ) : (
            <div className={classes.mediaPlaceholderLogoContent}>
              <PlaceholderLogo className={classes.mediaPlaceholderLogo} />
            </div>
          )}
        </Grid>
      </Hidden>

      {/* INFO AREA */}
      <Grid item xs={12} md={5} className={classes.infoArea}>
        <div>
          <div className={classes.nameArea}>
            {/* name */}
            <TypographyCustom type="247" component="h1" className={classes.name}>
              {orderMenu.name}

              <span className={classes.menuChip}>MENU</span>
            </TypographyCustom>
          </div>

          {/* description */}
          <TypographyCustom type="164" component="h2" className={classes.description}>
            {orderMenu.description}
          </TypographyCustom>
          {orderMenu.hasAllergens && (
            <ButtonLink
              onClick={showDietaryInfoDialog}
              className={classes.allergen}
              icon={<WheatIcon />}
              label={tr('order.dietary_info.title')}
              color="default"
            />
          )}
        </div>
        {/* Price */}
        <TypographyCustom type="247" className={classes.priceContainer}>
          <Price
            value={orderMenu.priceFormatted}
            hasCartModifier={orderMenu.hasCartModifier}
            baseValue={orderMenu.hasCartModifier ? orderMenu.basePriceFormatted : null}
            classes={{ root: classes.price }}
          />
          {orderMenu.hasCartModifier &&
            orderMenu.cartModifiers.list.map((cartModifier, index) => (
              <CartModifierTag
                cartModifier={cartModifier}
                chipElement={
                  <Chip
                    key={index}
                    label={cartModifier.tag}
                    classes={{ root: classes.cartModifierChip }}
                  />
                }
                key={index}
              />
            ))}
        </TypographyCustom>
      </Grid>

      {/* ACTIONS AREA */}
      <Grid item xs={12} md={4} className={classes.actionArea}>
        <TypographyCustom
          type="164"
          className={classNames({
            'u-invisible': orderMenu.isValid,
          })}
        >
          <TipWithIcon
            icon={<InfoOutlineIcon />}
            label={tr('order.menu.products.select_cart')}
            classes={{
              root: classes.tipWithIcon,
            }}
          />
        </TypographyCustom>

        <Hidden smDown>
          <Button
            disabled={!orderMenu.isValid || !orderMenu.available}
            onClick={onSubmit}
            size="large"
            classes={{ root: classes.submitBtn }}
          >
            {tr(
              appConfigPreset.isDigitalMenu
                ? isEditMode
                  ? 'order.catalog.update'
                  : 'order.catalog.add_to_cart_digital_menu'
                : isEditMode
                  ? 'order.catalog.update_with_price'
                  : 'order.catalog.add_to_cart_with_price',
              {
                price: orderMenu.totalPriceFormatted,
              }
            )}
          </Button>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HeaderView)
