import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { EventManager } from 'pmt-modules/event'
import { addOrderMenuToCart, addOrderProductToCart, addToCartFromShortcut } from 'pmt-modules/cart'
import {
  getOrderProperties,
  saveAlcoholApproval,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'
import { hideUpsellingDialog } from 'pmt-modules/catalog'
import { openProduct } from 'pmt-modules/orderProduct'
import { openMenu } from 'pmt-modules/orderMenu'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import { CardItem } from 'pmt-ui/Card'
import { CardItemOrder } from 'pmt-ui/Card/components/CardItem'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import StateMachine from 'pmt-ui/StateMachine'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import AlertButton from 'pmt-ui/AlertButton'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import isNil from 'lodash/isNil'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    color: theme.palette.primary.main,
    width: 'calc(100% - 40px)',
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  dialogActions: {
    cursor: 'pointer',
    background: theme.pmt.colors.greyBackground,
    boxShadow: `0 0 4px 0 ${theme.pmt.colors.darkGrey}`,
    justifyContent: 'center',
    margin: 0,
    padding: `${theme.spacing(2)}px 0`,
    zIndex: 1,
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  cartModifierChip: {
    display: 'table',
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 4,
  },
  cartModifierUnavailable: {
    opacity: 0.2,
  },
})

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

/**
 * @specs N/A
 */
@withRestaurant
class UpsellingDialog extends React.Component {
  handleOpenItem = (item, options) => {
    const { hideUpsellingDialog, openMenu, openProduct } = this.props

    hideUpsellingDialog()

    if (item.isProduct) {
      openProduct(item, options)
    } else if (item.isMenu) {
      openMenu(item, options)
    }
  }

  hideUpsellingDialog = () => {
    this.props.hideUpsellingDialog()
  }

  processClickAddBtn = (item, restaurant, transitionTo, orderProperties, addToCartFromShortcut) => {
    if (item.available) {
      this.hideUpsellingDialog()

      if (item.isComplexItem) {
        this.handleOpenItem(item, {
          restaurantId: restaurant.id,
        })
      } else {
        transitionTo(State.ADDING_TO_CART)
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          dueDate: orderProperties.dueDate,
          item,
          restaurant,
        })
        addToCartFromShortcut(item)
      }
    }
  }

  render() {
    const {
      classes,
      addToCartFromShortcut,
      orderProperties,
      restaurant,
      upsellingCategory,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="287" skipColor className={classes.dialogTitle}>
            {upsellingCategory.description}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={this.hideUpsellingDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid spacing={2} container className={classes.container}>
            {upsellingCategory.items.map(
              item =>
                item.available && (
                  <Grid key={`item-${item.id}`} item xs={12} md={4}>
                    <StateMachine
                      states={State}
                      default={State.NORMAL}
                      transitions={[
                        {
                          state: State.ADDING_TO_CART,
                          to: State.NORMAL,
                          duration: 1000,
                        },
                      ]}
                    >
                      {({ currentState, transitionTo }) => {
                        const addBtnIcon =
                          currentState === State.NORMAL ? <ContentAdd /> : <ActionDone />
                        const addBtnLabel = tr(
                          currentState === State.NORMAL
                            ? 'order.catalog.add'
                            : 'order.catalog.added'
                        )
                        return (
                          <CardItem
                            type={<CardItemOrder />}
                            classes={{ title: classes.title }}
                            // give item even if it is not used, to simplify debug on react dev tools
                            item={item}
                            description={item.description}
                            image={item.image}
                            restaurantLogo={restaurant.logo}
                            name={item.name}
                            price={item.priceFormatted}
                            isAvailable={item.available}
                            isCategory={item.isCategory}
                            hasCartModifier={item.hasCartModifier}
                            basePrice={item.hasCartModifier ? item.basePriceFormatted : null}
                            cartModifierTag={(cartModifier, index) => (
                              <CartModifierTag
                                cartModifier={cartModifier}
                                chipElement={
                                  <Chip
                                    key={index}
                                    label={cartModifier.tag}
                                    noBorder
                                    classes={{
                                      root: classNames(classes.cartModifierChip, {
                                        [classes.cartModifierUnavailable]: !item.available,
                                      }),
                                    }}
                                  />
                                }
                                key={index}
                                overEventsEnabled={false}
                              />
                            )}
                            onClickCard={() => {
                              if (item.available) {
                                EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                                  item,
                                  restaurant,
                                  category: {
                                    name: 'Upselling',
                                  },
                                })
                                this.hideUpsellingDialog()
                                this.handleOpenItem(item, {
                                  restaurantId: restaurant.id,
                                })
                              }
                            }}
                            buttonAdd={
                              frontSettings.display.ageCheckForAlcohol &&
                              item.isProduct &&
                              !item.isComplexItem &&
                              item.containsAlcohol &&
                              !orderProperties.alcoholApprovalAnswer ? (
                                <CustomTextsContainer>
                                  {({ texts }) => (
                                    <AlertButton
                                      button={<Button />}
                                      variant="outlined"
                                      size="small"
                                      icon={addBtnIcon}
                                      label={addBtnLabel}
                                      onAgree={e => {
                                        e.stopPropagation()
                                        saveAlcoholApproval()
                                        this.processClickAddBtn(
                                          item,
                                          restaurant,
                                          transitionTo,
                                          orderProperties,
                                          addToCartFromShortcut
                                        )
                                      }}
                                      alertTitle={
                                        !isNil(texts) &&
                                        !isNil(
                                          texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                                        )
                                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                                          : tr('order.cart.age_check_for_alcohol.dialog.title')
                                      }
                                      alertContent={
                                        !isNil(texts) &&
                                        !isNil(
                                          texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                                        )
                                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                                          : tr(
                                              'order.cart.age_check_for_alcohol.dialog.description'
                                            )
                                      }
                                      okLabel={
                                        !isNil(texts) &&
                                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                                          : tr('order.cart.age_check_for_alcohol.dialog.ok')
                                      }
                                      cancelLabel={
                                        !isNil(texts) &&
                                        !isNil(
                                          texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                                        )
                                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                                          : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                                      }
                                    />
                                  )}
                                </CustomTextsContainer>
                              ) : (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  icon={addBtnIcon}
                                  label={addBtnLabel}
                                  onClick={e => {
                                    e.stopPropagation()

                                    this.processClickAddBtn(
                                      item,
                                      restaurant,
                                      transitionTo,
                                      orderProperties,
                                      addToCartFromShortcut
                                    )
                                  }}
                                />
                              )
                            }
                          />
                        )
                      }}
                    </StateMachine>
                  </Grid>
                )
            )}
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="outlined"
            label={tr('order.suggestions.no_thanks')}
            onClick={this.hideUpsellingDialog}
          />
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
UpsellingDialog.DialogRootProps = {
  // we don't want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

UpsellingDialog.DialogStyles = theme => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    hideUpsellingDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
    saveAlcoholApproval,
  })
)(UpsellingDialog)
