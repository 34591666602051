import React from 'react'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'

import CategoryContainer from './CategoryContainer'

import { getQueryParam } from 'pmt-utils/url'

const getCategoryIdOnProps = props => {
  const queryParam = getQueryParam('categoryId')
  if (!isEmpty(queryParam)) {
    return queryParam
  }
  if (!isNil(props.routeParams) && !isEmpty(props.routeParams.categoryId)) {
    return props.routeParams.categoryId
  }
  if (!isNil(props.params) && !isEmpty(props.params.categoryId)) {
    return props.params.categoryId
  }
  return isUndefined(props.categoryId) ? null : props.categoryId
}

/**
 * Add the RestaurantContainer as HOC for the given component
 * Requirements
 * - categoryId in props or routeParams
 */
const withCategory = CategoryWrappedComponent => {
  return props => (
    <CategoryContainer
      CategoryWrappedComponent={CategoryWrappedComponent}
      categoryId={getCategoryIdOnProps(props)}
      {...props}
    />
  )
}

export default withCategory
