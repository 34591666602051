import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import ArrowLeftIcon from 'pmt-ui/svg-icons/hardware/keyboard-arrow-left'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
})

const BannerView = ({ classes, isFromCart, returnLabel = false, onRedirectToPreviousPage }) => (
  <div className={classes.root} onClick={onRedirectToPreviousPage}>
    <TypographyCustom type="164" skipColor>
      <span className={classes.content}>
        <ArrowLeftIcon />
        {returnLabel
          ? returnLabel
          : !isFromCart
            ? tr('order.global.back_to_catalog')
            : tr('order.global.back_to_cart')}
      </span>
    </TypographyCustom>
  </div>
)

export default withStyles(styles)(BannerView)
