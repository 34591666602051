import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Card, { CardContent, CardMedia } from 'pmt-ui/Card'
import Price from 'pmt-ui/Price'
import Divider from 'pmt-ui/Divider'
import { TypographyCustom } from 'pmt-ui/Typography'
import HardwareKeyboardArrowRight from 'pmt-ui/svg-icons/hardware/keyboard-arrow-right'
import PlaceholderLogo from 'pmt-ui/svg-icons/maps/restaurant'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

const styles = theme => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
  },
  content: {
    padding: `${theme.spacing(2)}px`,
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  contentProductAndMenu: {
    // make all cards to have the same size
    height: 130,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 160,
    opacity: 1,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  mediaPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.pmt.colors.itemPlaceholderBackground,
  },
  mediaRestaurantLogo: {
    // https://paymytable.atlassian.net/browse/PP-158
    // maxHeight: '80px',
    width: '120px',
    opacity: 0.3,
  },
  mediaPlaceholderLogo: {
    // https://paymytable.atlassian.net/browse/PP-158
    height: '80px',
    width: '80px',
    opacity: 0.3,
  },
  categoryContent: {
    height: 72,
  },
  categoryMedia: {
    display: 'block',
    float: 'left',
    width: 60,
    height: 40,
    marginRight: theme.spacing(3),
  },
  categoryNameContainer: {
    position: 'relative',
    float: 'left',
    width: 'calc(100% - 110px)',
    height: 40,
  },
  categoryName: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    float: 'left',
    minHeight: 18,
    maxHeight: 40,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '18px',
    color: theme.palette.primary.main,
  },
  categoryIcon: {
    height: 40,
    lineHeight: '40px',
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    minHeight: 17,
    maxHeight: 35,
    padding: `0 0 ${theme.spacing(0.25)}px`,
    color: theme.palette.primary.main,
  },
  iconCategory: {
    lineHeight: '76px',
  },
  itemInfo: {
    height: 55,
    overflow: 'hidden',
  },
  description: {
    maxHeight: 29,
    lineHeight: '14px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  unavailable: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unavailableWhite: {
    background: theme.pmt.overlay.background,
  },
  unavailableBlack: {
    background: theme.pmt.overlay.backgroundBlack,
  },
  unavailableCategory: {
    overflow: 'initial',
    opacity: 0.6,
    cursor: 'default',
  },
  unavailableLabel: {
    position: 'absolute',
    top: -theme.spacing(1),
    right: theme.spacing(2),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    color: theme.pmt.colors.white,
    background: theme.pmt.colors.darkGrey,
    borderRadius: 3,
  },
  white: {
    color: theme.pmt.colors.white,
  },
  priceAndButtonContainer: {
    // since we make all cards to have the same size, we need the bottom of the card to be
    // absolute to the bottom of the card
    display: 'flex',
    flexDirection: 'initial',
    verticalAlign: 'middle',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  extraInfos: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: `100%`,
    padding: theme.spacing(2, 2, 0),
  },
  extraInfosWithoutPlaceholder: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  menuChip: {
    padding: theme.spacing(1),
    borderRadius: '3px',
    fontSize: 10,
    fontWeight: 700,
    textAlign: 'center',
    backgroundColor: theme.pmt.colors.grey300,
    color: theme.pmt.colors.grey700,
    textTransform: 'uppercase',
  },
  cartModifierContainer: {
    left: theme.spacing(1),
  },
  grey300: {
    color: theme.pmt.colors.grey300,
  },
})

const CardItemOrder = ({
  classes,
  image,
  name,
  item,
  description,
  price,
  hasCartModifier,
  basePrice,
  cartModifierTag,
  isCategory,
  isSelectableAndDisabled,
  onClickCard,
  displayAddButtonIcon,
  buttonAdd,
  buttonElement,
  restaurantLogo,
  isAvailable,
  ui,
  updateUI,
  width,
  ...props
}) => {
  const disableAdd = item.disableAdd
  // we don't want to display the placeholder on mobile (i.e. xs)
  // see: https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
  const isPlaceholderHidden = !isWidthUp('sm', width) && !image

  const ItemExtraDisplay = ({ hasPlaceholder = false }) => (
    <React.Fragment>
      {disableAdd && (
        <div className={clsx(classes.unavailable, classes.unavailableBlack)}>
          <TypographyCustom align="center" type="167" className={classes.white} skipColor>
            {tr('order.catalog.unavailable')}
          </TypographyCustom>
        </div>
      )}
      {(item.isMenu || item.hasCartModifier) && (
        <div
          className={clsx(classes.extraInfos, {
            [classes.extraInfosWithoutPlaceholder]: !hasPlaceholder,
          })}
        >
          {item.hasCartModifier && (
            <div className={classes.cartModifierContainer}>
              {item.cartModifiers.list.map((cartModifier, index) => (
                <React.Fragment key={index}>{cartModifierTag(cartModifier, index)}</React.Fragment>
              ))}
            </div>
          )}
          {item.isMenu && (
            <span className={classes.menuChip}>{tr('global.card_item.menu_chip')}</span>
          )}
        </div>
      )}
    </React.Fragment>
  )

  return isCategory ? (
    <Card
      className={clsx(classes.root, {
        [classes.unavailableCategory]: disableAdd,
      })}
      onClick={!disableAdd && onClickCard}
    >
      <CardContent className={clsx(classes.content, classes.categoryContent)}>
        {image && (
          <span
            className={classes.categoryMedia}
            style={{
              background: `url('${image}') no-repeat center center`,
              backgroundSize: 'cover',
            }}
          />
        )}
        {!isAvailable && (
          <TypographyCustom type="127" className={classes.unavailableLabel}>
            {tr('order.catalog.unavailable')}
          </TypographyCustom>
        )}
        <div className={classes.categoryNameContainer}>
          <TypographyCustom type="167" className={classes.categoryName}>
            {name}
          </TypographyCustom>
        </div>
        <span className={`${classes.categoryIcon} u-floatRight`}>
          <HardwareKeyboardArrowRight className={classes.categoryIcon} viewBox="0 0 17 24" />
        </span>
      </CardContent>
    </Card>
  ) : (
    <Card className={classes.root}>
      {!isPlaceholderHidden && (
        <React.Fragment>
          <div className="u-relative">
            {image ? (
              <CardMedia
                className={classes.media}
                image={image}
                title={name}
                onClick={onClickCard}
              />
            ) : (
              // default image
              // see https://paymytable.atlassian.net/browse/PP-158
              <div className={clsx(classes.media, classes.mediaPlaceholder)} onClick={onClickCard}>
                {restaurantLogo ? (
                  <img
                    className={classes.mediaRestaurantLogo}
                    src={restaurantLogo}
                    alt="restauant logo"
                  />
                ) : (
                  <PlaceholderLogo className={classes.mediaPlaceholderLogo} />
                )}
              </div>
            )}
            <ItemExtraDisplay hasPlaceholder />
          </div>
          <Divider />
        </React.Fragment>
      )}
      <CardContent
        className={clsx(classes.content, classes.contentProductAndMenu)}
        onClick={onClickCard}
      >
        {isPlaceholderHidden && (
          <div>
            <ItemExtraDisplay />
          </div>
        )}
        <div className={classes.itemInfo}>
          {name && (
            <TypographyCustom
              align="left"
              type="167"
              className={`${classes.title} u-marginBottom5`}
            >
              {name}
            </TypographyCustom>
          )}
          {description && (
            <TypographyCustom type="144" className={classes.description} onClick={onClickCard}>
              {description}
            </TypographyCustom>
          )}
        </div>
        <div className={classes.priceAndButtonContainer}>
          <div className={classes.price} onClick={onClickCard}>
            {price && (
              <TypographyCustom component="div" type="204">
                {({ className }) => (
                  <Price
                    value={price}
                    hasCartModifier={hasCartModifier}
                    baseValue={basePrice}
                    className={className}
                  />
                )}
              </TypographyCustom>
            )}
          </div>
          {/* debug: we enable adding locked items, display it is locked for the tests */}
          {!disableAdd && !isAvailable && <span>LOCKED</span>}
          {/* -- BUTTON ADD */}
          {!disableAdd && buttonAdd ? buttonAdd : null}
          {/* -- */}
        </div>
        {(disableAdd || isSelectableAndDisabled) && (
          <div className={clsx(classes.unavailable, classes.unavailableWhite)} />
        )}
      </CardContent>
    </Card>
  )
}

CardItemOrder.propTypes = {
  title: PropTypes.string,

  // description of item, not set if category
  description: PropTypes.string,

  // set product/menu availability
  isAvailable: PropTypes.bool,

  // used in menu part to know if product is not selectable anymore
  isSelectableAndDisabled: PropTypes.bool,

  buttonAdd: PropTypes.any,
}

CardItemOrder.defaultProps = {
  isAvailable: true,
  isSelectableAndDisabled: false,

  buttonAdd: null,
}

const CardItemOrderToExport = withWidth()(withStyles(styles)(CardItemOrder))

// add default styles to be used by parents
CardItemOrderToExport.defaultStyles = theme => ({
  mediaInactive: {
    backgroundSize: 'contain',
    opacity: 0.1,
  },
})

export default CardItemOrderToExport
