import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'

import Divider from 'pmt-ui/Divider'
import Grid from 'pmt-ui/Grid'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'

import BlockContainer from '../../components/BlockContainer'
import MessageBlock from '../../components/MessageBlock'
import { ButtonLink } from '../../components/Button'
import BannerView from '../../components/BannerView'

import { default as ViewProduct } from './components/View'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  rootContent: {
    padding: `${theme.spacing(4)}px !important`,
  },
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  loadingBlock: {
    [theme.breakpoints.down('sm')]: {
      background: theme.pmt.colors.greyBackground,
    },
  },
  catalogError: {
    textAlign: 'center',
    '&> button': {
      marginTop: theme.spacing(3),
    },
  },
})

const View = ({
  classes,
  appConfigPreset,
  orderAppConfig,
  orderProduct,
  isMenuMode,
  isEditMode,
  onSubmit,
  onClose,
  onSelectOptionValue,
  onUnselectOptionValue,
  isFetchingRestaurant,
  isFetchingCatalog,
  catalog,
  catalogError,
  restaurant,
  selectedCategory,
  handleRedirectToStoreLocator,
  showDietaryInfoDialog,
  setReclaimLater,
  orderProperties,
  saveAlcoholApproval,
  frontSettings,
}) => (
  <div className={classes.root}>
    <Grid spacing={2} container>
      <Grid item container direction="column" spacing={0} xs={12} wrap="nowrap">
        <BannerView onRedirectToPreviousPage={onClose} isFromCart={false} />
        <BlockContainer className="u-flex1" type={BlockContainer.Type.WHITE} disablePadding>
          {isFetchingRestaurant || !restaurant || isFetchingCatalog || !orderProduct ? (
            <LoadingBlock
              show
              classes={{
                loadingBlock: classNames('u-marginTop20 u-marginBottom20', classes.loadingBlock),
              }}
            />
          ) : !catalog ? (
            <div className={classes.catalogError}>
              <ErrorBlock
                error={catalogError}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />
              <ButtonLink onClick={handleRedirectToStoreLocator}>
                {tr('order.global.choose_restaurant')}
              </ButtonLink>
            </div>
          ) : selectedCategory ? (
            <div className={classes.content}>
              <ViewProduct
                appConfigPreset={appConfigPreset}
                orderAppConfig={orderAppConfig}
                fromDialog={false}
                orderProduct={orderProduct}
                isMenuMode={isMenuMode}
                isEditMode={isEditMode}
                onSelectOptionValue={onSelectOptionValue}
                onUnselectOptionValue={onUnselectOptionValue}
                onSubmit={onSubmit}
                onClose={onClose}
                restaurant={restaurant}
                classes={{ root: classes.rootContent }}
                showDietaryInfoDialog={showDietaryInfoDialog}
                setReclaimLater={setReclaimLater}
                orderProperties={orderProperties}
                saveAlcoholApproval={saveAlcoholApproval}
                frontSettings={frontSettings}
              />
            </div>
          ) : (
            <React.Fragment>
              <Divider className={classes.divider} />
              <div className="u-marginTop60 u-marginBottom60 u-textAlignCenter">
                <ActionInfoOutline className={classNames(classes.iconInfo, classes.grey500)} />
                <TypographyCustom type="207" align="center" className={classes.grey500}>
                  {tr('order.menu.no_item_in_selected_category')}
                </TypographyCustom>
              </div>
            </React.Fragment>
          )}
        </BlockContainer>
      </Grid>
    </Grid>
  </div>
)

export default withStyles(styles)(View)
