import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { isFetchingAuth } from 'pmt-modules/auth'
import { EventManager } from 'pmt-modules/event'
import { FormType, withForm } from 'pmt-modules/form'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { getAppConfig } from 'pmt-modules/appConfig'
import { RegisterFormView } from 'pmt-modules/registration/forms/register'
import {
  getRegisterError,
  isFetchingRegister,
  registerAsIncognito,
  registerUser,
} from 'pmt-modules/registration'
import { isFetchingUserMe } from 'pmt-modules/userMe'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Typography, { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'

import { cleanForApi } from 'pmt-utils/phone'
import LabelDivider from 'pmt-ui/LabelDivider'
import Grid from 'pmt-ui/Grid'

import Button, { ButtonLink } from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(4),
    float: 'right',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    float: 'right',
    padding: 10,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  buttonsGrid: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  link: {
    display: 'inline',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: -2,
  },
  switchView: {
    clear: 'both',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  subheader: {
    color: theme.pmt.colors.grey500,
    marginBottom: theme.spacing(2),
  },
  connectButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
})

class RegisterForm extends React.PureComponent {
  handleSubmitRegistration = () => {
    const { formData, cartItemList, restaurant, clientId, clientSecret, registerUser } = this.props

    EventManager.dispatch(EventManager.Events.ON_REGISTER_SUBMIT_REGISTER, {
      items: cartItemList,
      restaurant,
    })

    registerUser(
      {
        ...formData,
        phone: cleanForApi(formData.phone),
      },
      {
        clientId,
        clientSecret,
      }
    )
  }

  render() {
    const {
      cartItemList,
      classes,
      formIsValid,
      frontSettings,
      isFetchingRegister,
      isFetchingAuth,
      isFetchingUserMe,
      onToggleView,
      isFetchingOrderPost,
      orderPostError,
      orderProperties,
      registerError,
      cguLabel,
      allowIncognito,
    } = this.props

    return (
      <React.Fragment>
        <TypographyCustom className="u-marginBottom20" type="284">
          {tr('order.login.register')}
        </TypographyCustom>
        <CustomTextsContainer>
          {({ texts }) =>
            !isNil(texts) &&
            !isNil(texts.ORDER__REGISTER__SUBHEADER) && (
              <Typography variant="subtitle1" className={classes.subheader}>
                {texts.ORDER__REGISTER__SUBHEADER}
              </Typography>
            )
          }
        </CustomTextsContainer>

        <LoadingBlockWrapper
          show={isFetchingRegister || isFetchingAuth || isFetchingUserMe || isFetchingOrderPost}
        />

        <ErrorBlock
          error={registerError || orderPostError}
          mode={ErrorBlock.Mode.CUSTOM}
          customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault()
            this.handleSubmitRegistration()
          }}
        >
          <RegisterFormView cguLabel={cguLabel} />

          <Grid container className={classes.buttonsGrid}>
            <Grid item lg={4}>
              <Button
                size="large"
                type="submit"
                label={tr(
                  frontSettings.display.paymentPage ||
                    isEmpty(cartItemList) ||
                    orderProperties.isDelivery
                    ? 'order.login.register_button'
                    : 'order.login.register_button_and_order'
                )}
                disabled={
                  !formIsValid ||
                  isFetchingRegister ||
                  isFetchingAuth ||
                  isFetchingUserMe ||
                  isFetchingOrderPost
                }
                classes={{ root: classes.button }}
              />
            </Grid>
            {allowIncognito && (
              <Grid item lg={8}>
                <div className={classes.allowIncognitoButton}>
                  <CustomTextsContainer>
                    {({ texts }) => (
                      <ButtonLink
                        component="a"
                        onClick={(e) => {
                          e.stopPropagation()
                          onToggleView(LoginViews.INCOGNITO)
                        }}
                        label={
                          !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                            ? texts.ORDER__INCOGNITO__LINK
                            : tr('global.register.continue_as_incognito.from_NORMAL.link')
                        }
                      />
                    )}
                  </CustomTextsContainer>
                </div>
              </Grid>
            )}
          </Grid>
        </form>

        <div className={classes.switchView}>
          <LabelDivider label={tr('order.login.or')} />
          <Button
            className={classes.connectButton}
            variant="outlined"
            size="large"
            onClick={() => onToggleView(LoginViews.LOGIN)}
            label={tr('order.login.connect_button')}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingUserMe: isFetchingUserMe(state),
  isFetchingAuth: isFetchingAuth(state),
  isFetchingRegister: isFetchingRegister(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  registerError: getRegisterError(state),
  orderPostError: getErrorOrderPost(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  withForm(FormType.REGISTER),
  connect(mapStateToProps, {
    registerUser,
    registerAsIncognito,
  })
)(RegisterForm)
