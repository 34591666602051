import React from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import { GOOGLE_PLACE_CHANGED_EVENT } from '../constants'

class GoogleMapsAutocomplete extends React.Component {
  state = {
    autocomplete: null,
  }

  componentWillUpdate(nextProps, nextState) {
    if (!isUndefined(window.google) && isNil(nextState.autocomplete)) {
      this.setupAutocomplete()
    }
  }

  setupAutocomplete = () => {
    const input = document.getElementById(this.props.inputFieldId)
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      // https://developers.google.com/maps/documentation/javascript/places-autocomplete#restrict-predictions-to-a-specific-country
      // if restricting, we can restrict the autocomplete search to a specific set of up to five countries.

      // configured on apiConsumer
      // ex: ['FR','RE'] for France and Reunion. Null or empty means no restriction
      componentRestrictions: { country: this.props.countryRestriction}, 
   })

    // prevent submitting form when selecting address with "enter" key
    window.google.maps.event.addDomListener(input, 'keydown', e => {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    autocomplete.addListener(GOOGLE_PLACE_CHANGED_EVENT, () => {
      this.setState({
        address: null,
      })
      this.props.handleSelectLocation(autocomplete)
    })

    this.setState({
      autocomplete: autocomplete,
    })
  }

  render() {
    const { children } = this.props

    return children
  }
}

GoogleMapsAutocomplete.defaultProps = {
  reverseAddress: null,
}

GoogleMapsAutocomplete.propTypes = {
  inputFieldId: PropTypes.string.isRequired,
  handleSelectLocation: PropTypes.func.isRequired,
}

export default GoogleMapsAutocomplete
