import React from 'react'
import { tr } from 'pmt-modules/i18n'

import PhoneIcon from '@material-ui/icons/Phone'
import { TypographyCustom } from 'pmt-ui/Typography'
import Clock from 'pmt-ui/svg-icons/device/clock'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from 'pmt-ui/styles'
import { hideRestaurantClosedDialog } from 'pmt-modules/orderPlugin'
import Button from 'app/components/Button'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const styles = (theme) => ({
  iconInfo: {
    width: 30,
    height: 30,
    marginBottom: theme.spacing(1),
    color: theme.pmt.colors.grey500,
  },
  root: {
    width: '100%',
    display: 'flex',
    color: theme.pmt.colors.grey500,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  closedContent: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.pmt.colors.grey500,
    lineHeight: '1.3',
  },
  phoneNumber: {
    marginTop: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,

    '& > svg': {
      marginRight: theme.spacing(),
    },
  },
})

const ClosedStoreDialog = ({ restaurant, orderContext, classes, hideRestaurantClosedDialog }) => (
  <div className={classes.root}>
    <DialogContent>
      <DialogContentText>
        <Clock className={classes.iconInfo} />
        <TypographyCustom type="207" className={classes.closedContent}>
          {tr('order.catalog.restaurant_closed.dialog.currently_closed')}
        </TypographyCustom>

        {orderContext.nextOpening ? (
          <TypographyCustom type="207" className={classes.closedContent}>
            {orderContext.nextOpening.isCurrentDay
              ? tr('order.catalog.restaurant_closed.dialog.closed_today_until', {
                  hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                })
              : tr('order.catalog.restaurant_closed.dialog.closed_until', {
                  day: orderContext.nextOpening.dayName,
                  hour: orderContext.nextOpening.nextOpeningHour.formattedOpening,
                })}
          </TypographyCustom>
        ) : (
          restaurant.phonePub && (
            <TypographyCustom type="204" className={classes.closedContent}>
              {tr('order.catalog.restaurant_closed.dialog.contact_phone')}
              <a href={`tel:${restaurant.phonePub}`}>
                <div className={classes.phoneNumber}>
                  <PhoneIcon />
                  {restaurant.phonePub}
                </div>
              </a>
            </TypographyCustom>
          )
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        label={tr('order.catalog.restaurant_closed.dialog.close')}
        onClick={() => {
          window.location.reload()
        }}
      />
    </DialogActions>
  </div>
)

// Dialog root properties
ClosedStoreDialog.DialogRootProps = {
  // we don't want the user to be able to quit the dialog
  // (only the Refresh button will allow to go out if the Order is becoming available)
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { hideRestaurantClosedDialog })
)(ClosedStoreDialog)
