import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import {
  getIsFetchingPostCreditCardRegistrationType,
  getErrorPostCreditCardRegistrationType,
  getSendUserCreditCardToPspError,
  getPostPspDatasError,
  postUserCreditCardRegistrationType,
  resetUserCreditCardRegistrationTypeError,
} from 'pmt-modules/creditCard'
import CreditCardFormView from 'pmt-modules/creditCard/components/CreditCardFormView'
import { getErrorOrderPost } from 'pmt-modules/orderPost'
import { FormType, resetFormWithDefaultData, withForm } from 'pmt-modules/form'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import MessageBlock from 'app/components/MessageBlock'
import Button from 'app/components/Button'

const styles = (theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(3),
  },
  cgv: {
    marginBottom: theme.spacing(1),
    color: theme.pmt.colors.grey97,
  },
  link: {
    textDecoration: 'underline',
  },
})

class AddCreditCardFormView extends React.PureComponent {
  constructor(props) {
    super(props)

    props.resetFormWithDefaultData(FormType.CREDIT_CARD)
  }

  componentWillUnmount() {
    this.props.updateUI({
      showCreditCardForm: false,
    })
    this.props.resetUserCreditCardRegistrationTypeError()
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!isNil(nextProps.errorPostCreditCardRegistrationType) &&
        isNil(nextProps.errorPostCreditCardRegistrationType)) ||
      (!isNil(nextProps.errorSendUserCreditCardToPsp) &&
        isNil(nextProps.errorSendUserCreditCardToPsp)) ||
      (!isNil(nextProps.errorPostPspDatas) && isNil(nextProps.errorPostPspDatas)) ||
      (!isNil(nextProps.errorPostOrder) && isNil(nextProps.errorPostOrder))
    ) {
      nextProps.updateUI({
        showCreditCardForm: true,
      })
    }
  }

  handleSubmitCreditCard = (e) => {
    e.preventDefault()

    const {
      userId,
      restaurantId,
      formData,
      resetUserCreditCardRegistrationTypeError,
      postUserCreditCardRegistrationType,
    } = this.props

    resetUserCreditCardRegistrationTypeError()

    postUserCreditCardRegistrationType(userId, restaurantId, { creditCard: formData })
  }

  render() {
    const {
      isFetchingPostCreditCardRegistrationType,
      errorPostCreditCardRegistrationType,
      errorSendUserCreditCardToPsp,
      errorPostPspDatas,
      errorPostOrder,
      formClasses,
      padding,
      amountToPay,
      tosUrl,
      formIsValid,
      classes,
      withName = true,
    } = this.props

    return (
      <div className="u-relative" style={{ padding: padding }}>
        <CreditCardFormView withName={withName} withOptionnalRegister classes={formClasses} />
        {(!isNil(errorPostCreditCardRegistrationType) ||
          !isNil(errorSendUserCreditCardToPsp) ||
          !isNil(errorPostPspDatas) ||
          !isNil(errorPostOrder)) && (
          <div className="u-marginTop30">
            <ErrorBlock
              error={
                errorPostCreditCardRegistrationType ||
                errorSendUserCreditCardToPsp ||
                errorPostPspDatas ||
                errorPostOrder
              }
              mode={ErrorBlock.Mode.CUSTOM}
              customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
            />
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Grid spacing={2} container>
            {!isEmpty(tosUrl) && (
              <Grid item xs={12}>
                <TypographyCustom
                  type="144"
                  className={classes.cgv}
                  dangerouslySetInnerHTML={{
                    __html: tr('order.payment.cgv', {
                      linkBegin: `<a class="${classes.link}" href="${tosUrl}" target="_blank" rel="noopener noreferrer">`,
                      linkEnd: '</a>',
                    }),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                size="large"
                disabled={!formIsValid || isFetchingPostCreditCardRegistrationType}
                classes={{ root: 'u-sizeFullWidth' }}
                label={tr('order.payment.pay_amount', { amount: amountToPay })}
                onClick={this.handleSubmitCreditCard}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),

  errorPostCreditCardRegistrationType: getErrorPostCreditCardRegistrationType(state),
  errorSendUserCreditCardToPsp: getSendUserCreditCardToPspError(state),
  errorPostPspDatas: getPostPspDatasError(state),
  errorPostOrder: getErrorOrderPost(state),
})

export default compose(
  withStyles(styles),
  withForm(FormType.CREDIT_CARD),
  connect(mapStateToProps, {
    postUserCreditCardRegistrationType,
    resetUserCreditCardRegistrationTypeError,
    resetFormWithDefaultData,
  })
)(AddCreditCardFormView)
