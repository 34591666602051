import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { hideTableNotFoundDialog, setTableNumber } from 'pmt-modules/orderPlugin'
import RoutingContainer from 'pmt-modules/routing/components/RoutingContainer'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'app/components/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const TableNotFoundDialog = ({ hideTableNotFoundDialog, setTableNumber, restaurantId }) => {
  const classes = useStyles()

  return (
    <RoutingContainer>
      {({ redirectTo, getRoute }) => (
        <div className={classes.root}>
          <DialogTitle>{tr('order.catalog.table_not_found.dialog.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {tr(`order.catalog.table_not_found.dialog.content`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              label={tr('order.catalog.table_not_found.dialog.close')}
              onClick={() => {
                setTableNumber(null)
                hideTableNotFoundDialog()
                // go back to where the user can enter a new table number ( = the catalog page)
                redirectTo(getRoute('ORDER__CATALOG'), {
                  restaurantId: restaurantId,
                })
              }}
            />
          </DialogActions>
        </div>
      )}
    </RoutingContainer>
  )
}

// Dialog root properties
TableNotFoundDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { hideTableNotFoundDialog, setTableNumber })(
  TableNotFoundDialog
)
