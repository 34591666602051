import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import Diet from '../../constants/Diet'

// in DEV environment, prefill form with the following data
const devData = {}

const defaultData = {
  allergens: [],
  diet: Diet.NONE,
}

export default createForm(FormType.USER_PROFILE_DIETARY, {}, defaultData, devData)
