import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import last from 'lodash/last'

import { getOrderProperties } from 'pmt-modules/orderPlugin/selectors'
import {
  setSelectedCategory,
  setFirstSelectedCategory,
  setParentCategories,
} from 'pmt-modules/orderPlugin/actions'
import { makeGetCategory, makeGetFirstSelectableCategory } from 'pmt-modules/catalog/selectors'
import { getOrderProduct } from 'pmt-modules/orderProduct/selectors'

class CategoryContainer extends React.PureComponent {
  componentWillMount() {
    const { category, orderPluginProperties, setSelectedCategory, setParentCategories } = this.props

    if (!isNil(category)) {
      setSelectedCategory(category)
      setParentCategories(orderPluginProperties.parentCategories)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      category,
      orderPluginProperties,
      setParentCategories,
      setSelectedCategory,
      setFirstSelectedCategory,
    } = this.props

    if (
      (!isNil(category) &&
        !isNil(nextProps.category) &&
        (nextProps.category.id !== category.id || !isEqual(category, nextProps.category))) ||
      (isNil(category) && !isNil(nextProps.category))
    ) {
      setSelectedCategory(nextProps.category)

      // if the previous parent category is the same as the next category selected
      // we deduct that the user hit the back button of web browser
      // we remove the last parent category
      if (
        !isNil(orderPluginProperties.parentCategories) &&
        !isEmpty(orderPluginProperties.parentCategories)
      ) {
        const lastParentCategory = last(orderPluginProperties.parentCategories)
        if (lastParentCategory.id === nextProps.category.id) {
          const newParentCategories = orderPluginProperties.parentCategories.slice(0, -1)
          setParentCategories(newParentCategories)
        }
      }
    } else if (
      isNil(category) &&
      isNil(nextProps.category) &&
      !isNil(nextProps.firstSelectableCategory) &&
      isNil(nextProps.orderPluginProperties.selectedCategory)
    ) {
      setSelectedCategory(nextProps.firstSelectableCategory)
      setFirstSelectedCategory(nextProps.firstSelectableCategory)
      setParentCategories([])
      // we check that we're not displaying a product or leaving one
      // the first 2 conditions are to check when we're going from /catalog/:categoryId to /catalog
    } else if (
      !isNil(category) &&
      isNil(nextProps.category) &&
      !isNil(orderPluginProperties.firstSelectedCategory) &&
      !isNil(nextProps.orderPluginProperties.selectedCategory)
    ) {
      setSelectedCategory(orderPluginProperties.firstSelectedCategory)
      setParentCategories([])
    }
  }

  render() {
    const { CategoryWrappedComponent, categoryId, ...otherProps } = this.props
    return <CategoryWrappedComponent categoryId={categoryId} {...otherProps} />
  }
}

CategoryContainer.propTypes = {
  categoryId: PropTypes.string,
}
const mapStateToProps = (state, props) => {
  const getCategory = makeGetCategory()
  const getFirstSelectableCategory = makeGetFirstSelectableCategory()
  const orderPluginProperties = getOrderProperties(state)

  return {
    category: getCategory(state, {
      ...props,
      parentCategories: orderPluginProperties.parentCategories,
    }),
    firstSelectableCategory: getFirstSelectableCategory(state, props),
    orderPluginProperties,
    orderProduct: getOrderProduct(state),
  }
}

export default connect(
  mapStateToProps,
  {
    setSelectedCategory,
    setFirstSelectedCategory,
    setParentCategories,
  }
)(CategoryContainer)
