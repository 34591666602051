import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import OptionView from './OptionView'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
})

const OptionsView = ({
  orderProduct,
  classes,
  onSelectOptionValue,
  onUnselectOptionValue,
  orderProperties,
  saveAlcoholApproval,
  frontSettings,
}) => (
  <div className={classes.root}>
    {orderProduct.options.map((option) => (
      <OptionView
        key={option.id}
        option={option}
        onSelectOptionValue={onSelectOptionValue}
        onUnselectOptionValue={onUnselectOptionValue}
        orderProperties={orderProperties}
        saveAlcoholApproval={saveAlcoholApproval}
        frontSettings={frontSettings}
      />
    ))}
  </div>
)

export default withStyles(styles)(OptionsView)
