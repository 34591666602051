import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import { isFetchingUserAddressEdit } from 'pmt-modules/userAddress'
import { getFormattedAddressFromAddressComponents } from 'pmt-utils/address'

import ImageEdit from 'pmt-ui/svg-icons/image/edit'
import Delete from 'pmt-ui/svg-icons/action/delete'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import { Radio } from 'pmt-ui/Radio'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import EditorModeEdit from 'pmt-ui/svg-icons/editor/mode-edit'

import AddressEditForm from './AddressEditForm'
import { ButtonLink } from '../Button'

const styles = theme => ({
  card: {
    padding: `${theme.spacing(0.5)}px 0`,
    minWidth: 240,
  },
  cardDisabled: {
    background: theme.pmt.colors.grey100,
    border: `1px solid ${theme.pmt.colors.grey300}`,
    boxShadow: 'none',
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardContentLabel: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  cardContentClassicClosed: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px 0 !important`,
  },
  cardContentClassicOpened: {
    overflow: 'hidden',
    padding: `0 !important`,
  },
  cardContentAccount: {
    overflow: 'hidden',
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  onClickZone: {
    float: 'left',
    overflow: 'hidden',
    width: '100%',
    cursor: 'pointer',
  },
  onClickZoneClosed: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 48px)',
  },
  actionContainer: {
    float: 'left',
    width: 48,
    height: 48,
    textAlign: 'center',
    cursor: 'pointer',
  },
  textContainerClassic: {
    overflow: 'hidden',
    display: '-webkit-box',
    float: 'left',
    width: 'calc(100% - 48px * 2)',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  label: {
    marginBottom: theme.spacing(1),
    color: theme.pmt.colors.grey91,
    textTransform: 'uppercase',
  },
  address: {
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
  complement: {
    marginBottom: theme.spacing(0.25),
    fontStyle: 'italic',
  },
  textContainerNew: {
    height: 48,
    lineHeight: '48px',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  closeButton: {
    float: 'right',
    marginRight: theme.spacing(2),
  },
  editIcon: {
    color: theme.palette.primary.main,
    marginTop: 12,
  },
  editFormInputStyles: {
    marginTop: theme.spacing(1),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  actionsContainer: {
    float: 'left',
    marginTop: theme.spacing(2),
  },
  geolocButton: {
    marginTop: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
  buttonRoot: {
    width: '100%',
  },
  buttonLabel: {
    fontSize: 14,
  },
})

const Type = {
  CLASSIC: 'CLASSIC',
  LABEL: 'LABEL',
  ACCOUNT: 'ACCOUNT',
}

class AddressElement extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.address, this.props.address)) {
      this.props.handleEditActionSuccess()
    }
  }

  render() {
    const {
      classes,
      className,
      type,
      label,
      labelEdit,
      address,
      selected,
      disabled,
      onSelect,
      handleEditAction,
      handleDeleteAction,
      showEditAddressForm,
      isFetchingUserAddressEdit,
      user,
      onClose,
    } = this.props

    return (
      <Card
        className={classNames(
          classes.card,
          {
            [classes.cardDisabled]: disabled,
            [classes.cardSelected]: selected,
          },
          className
        )}
      >
        <CardContent
          className={classNames({
            [classes.cardContentLabel]: type === Address.Type.LABEL,
            [classes.cardContentClassicOpened]:
              type === Address.Type.CLASSIC && showEditAddressForm,
            [classes.cardContentClassicClosed]:
              type === Address.Type.CLASSIC && !showEditAddressForm,
            [classes.cardContentAccount]: type === Address.Type.ACCOUNT,
          })}
        >
          {isFetchingUserAddressEdit && showEditAddressForm ? (
            <LoadingBlock show />
          ) : (
            <React.Fragment>
              <div
                className={classNames(classes.onClickZone, {
                  [classes.onClickZoneClosed]: !showEditAddressForm,
                })}
              >
                {type === Address.Type.CLASSIC && (
                  <Radio
                    color="primary"
                    name="select_address"
                    className={'u-floatLeft'}
                    checked={selected}
                    disabled={disabled}
                    value="select_address"
                    onClick={onSelect}
                  />
                )}
                <React.Fragment>
                  {type === Address.Type.CLASSIC || type === Address.Type.ACCOUNT ? (
                    <React.Fragment>
                      {!showEditAddressForm ? (
                        <div
                          className={classNames({
                            [classes.textContainerClassic]: type === Address.Type.CLASSIC,
                          })}
                          onClick={onSelect}
                        >
                          <TypographyCustom component="p" type={'107'} className={classes.label}>
                            {label}
                          </TypographyCustom>
                          <TypographyCustom component="p" type={'164'} className={classes.address}>
                            {getFormattedAddressFromAddressComponents(address)}
                          </TypographyCustom>
                        </div>
                      ) : (
                        <React.Fragment>
                          <TypographyCustom
                            component="p"
                            type="164"
                            className={classNames(classes.textContainerNew, 'u-floatLeft')}
                          >
                            {labelEdit}
                          </TypographyCustom>
                          <ButtonLink
                            label={tr('order.global.close')}
                            classes={{
                              root: classNames(classes.textContainerNew, classes.closeButton),
                            }}
                            onClick={onClose}
                          />
                          <AddressEditForm
                            user={user}
                            address={address}
                            defaultData={address}
                            classes={classes}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <TypographyCustom
                      component="p"
                      type="164"
                      className={classNames(classes.textContainerNew, 'u-floatLeft')}
                      onClick={onSelect}
                    >
                      {showEditAddressForm ? labelEdit : label}
                    </TypographyCustom>
                  )}
                </React.Fragment>
              </div>

              {type === Address.Type.CLASSIC && !showEditAddressForm && (
                <div className={classes.actionContainer} onClick={handleEditAction}>
                  <EditorModeEdit className={classes.editIcon} />
                </div>
              )}

              {type === Address.Type.ACCOUNT && !showEditAddressForm && (
                <Grid container alignItems="center" className={classes.actionsContainer}>
                  <Grid item xs={6}>
                    <ButtonLink
                      classes={{ root: classes.buttonRoot }}
                      label={tr('order.global.modify')}
                      icon={<ImageEdit />}
                      onClick={handleEditAction}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonLink
                      classes={{ root: classes.buttonRoot }}
                      label={tr('order.global.delete')}
                      icon={<Delete />}
                      onClick={handleDeleteAction}
                    />
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    )
  }
}

AddressElement.defaultProps = {
  selected: false,
  disabled: false,
  onSelect: () => {},
  handleEditAction: () => {},
  handleDeleteAction: () => {},
  type: Type.CLASSIC,
  label: '',
  labelEdit: '',
}

AddressElement.propTypes = {
  label: PropTypes.string.isRequired,
  address: PropTypes.object,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
  isFetchingUserAddressEdit: isFetchingUserAddressEdit(state),
})

const Address = compose(connect(mapStateToProps, {}), withStyles(styles))(AddressElement)

Address.Type = Type

export default Address
