import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import { showDietaryInfoDialog, withCatalog } from 'pmt-modules/catalog'
import SelectedMenuContainer from 'pmt-modules/orderMenu/components/SelectedMenuContainer'
import {
  selectMenuProduct,
  unselectMenuProduct,
  addProductItemToMenu,
  editMenuProduct,
  getViewOptions,
  setPartParentCategories,
  resetPartParentCategories,
  setPartSelectedCategory,
  resetPartSelectedCategory,
  getPartParentCategories,
  getParentCategoriesLeft,
} from 'pmt-modules/orderMenu'
import { displayDeleteCartDialog } from 'pmt-modules/dialog'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import {
  SelectedCategoryContainer,
  OrderPluginUrlCheckerContainer,
  redirectToCatalogPage,
  redirectToCart,
  withOrderProperties,
} from 'pmt-modules/orderPlugin'
import {
  getItemListFromCart,
  addOrderMenuToCart,
  updateOrderMenuOnCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import { removeHash } from 'pmt-ui/ScrollableAnchor'
import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../components/OrderPage'

import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withCatalog
@withScrollToTop()
class MenuPage extends React.Component {
  componentWillUnmount() {
    removeHash()
  }

  resetPartCategory = () => {
    this.props.resetPartParentCategories()
    this.props.resetPartSelectedCategory()
  }

  handleAddProductFromShortcut = (part, partCategory, productItem, menu, category) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_PRODUCT, {
      dueDate: this.props.orderProperties.dueDate,
      category,
      item: productItem,
      menu,
      restaurant: this.props.restaurant,
    })

    this.props.addProductItemToMenu(part, partCategory, productItem, options)
  }

  handleSelectOrderProduct = (part, partCategory, productItem, menu, selectedCategory) => {
    const options = {
      restaurantId: this.props.restaurant.id,
      menu,
      selectedCategory,
    }

    this.props.selectMenuProduct(part, partCategory, productItem, options)
  }

  handleUnselectOrderProduct = (part, partCategory, productItem) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    this.props.unselectMenuProduct(part, partCategory, productItem, options)
  }

  handleEditOrderProduct = (part, partCategory, orderProduct) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    this.props.editMenuProduct(part, partCategory, orderProduct, options)
  }

  handleSelectCategory = (category, parentCategory) => {
    const { partParentCategories, setPartParentCategories, setPartSelectedCategory } = this.props

    setPartSelectedCategory({ id: category.id })
    partParentCategories.push({
      id: parentCategory.id,
      name: parentCategory.name,
      categories: parentCategory.categories,
      products: parentCategory.products,
    })
    setPartParentCategories(partParentCategories)
  }

  handleReturnToPreviousPartCategory = (parentCategory) => {
    const { setPartParentCategories, setPartSelectedCategory } = this.props
    const partParentCategories = getParentCategoriesLeft(
      parentCategory,
      this.props.partParentCategories
    )

    setPartParentCategories(partParentCategories)
    setPartSelectedCategory({ id: parentCategory.id })
  }

  handleOnSubmit = (orderMenu, category) => {
    const {
      viewOptions,
      restaurant,
      updateOrderMenuOnCart,
      addOrderMenuToCart,
      cartSuspendedDatas,
      orderProperties,
      setSuspendedDatas,
    } = this.props

    if (orderMenu.isValid) {
      if (!viewOptions.isEditMode) {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
          category,
          dueDate: orderProperties.dueDate,
          item: orderMenu,
          restaurant: restaurant,
        })
      }

      if (viewOptions.isEditMode) {
        updateOrderMenuOnCart(orderMenu)
        this.handleRedirectToPreviousPage()
      } else {
        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }
        addOrderMenuToCart(orderMenu)
        this.handleRedirectToCatalogPage()
      }
    }
  }

  handleRedirectToStoreLocator = () => {
    this.resetPartCategory()

    this.props.itemListFromCart.length > 0
      ? this.props.displayDeleteCartDialog()
      : this.props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
  }

  handleRedirectToPreviousPage = () => {
    this.resetPartCategory()

    if (this.props.isFromCart) {
      this.handleRedirectToCartPage()
    } else {
      this.handleRedirectToCatalogPage()
    }
  }

  handleRedirectToCatalogPage = () => {
    this.resetPartCategory()
    removeHash()

    this.props.redirectToCatalogPage(this.props.restaurant.id)
  }

  handleRedirectToCartPage = () => {
    removeHash()
    this.props.redirectToCart()
  }

  render() {
    const {
      appConfigPreset,
      catalog,
      isFetchingCatalog,
      location,
      restaurant,
      isFetchingRestaurant,
      orderProperties,
      showDietaryInfoDialog,
      viewOptions,
      width,
    } = this.props

    let isEditMode = false
    if (!isNil(viewOptions)) {
      isEditMode = viewOptions.isEditMode
    }

    return (
      <SelectedCategoryContainer catalog={catalog}>
        {({ selectedCategory }) => (
          <OrderPage
            headerProps={{
              // hide the header when there is a selected children category, since we display
              // the category header
              displayHeader: isWidthUp('md', width),
              displayRestaurantName: true,
              displayTitle: isWidthUp('md', width),
              displayOrderMode: true,
              displayAddress: true,
              changeButton: isWidthUp('md', width) && this.handleRedirectToStoreLocator,
              displayDueDate: isWidthDown('sm', width),
              displayTableNumber: isWidthDown('sm', width),
              displayUser: true,
            }}
            orderProperties={orderProperties}
            restaurant={restaurant}
            location={location}
          >
            <OrderPluginUrlCheckerContainer location={location} verifyMode>
              {({ isFetchingAppConfig, appConfig }) => (
                <SelectedMenuContainer
                  catalog={catalog}
                  selectedCategory={selectedCategory}
                  menuId={this.props.params.menuId}
                >
                  {({ selectedMenu, orderMenu }) => (
                    <SendEventContainer
                      send={
                        orderProperties &&
                        !isNil(orderProperties.restaurant) &&
                        !isNil(selectedCategory)
                      }
                      event={EventManager.Events.ON_PAGE_LOADED}
                      eventProps={() => ({
                        category: selectedCategory,
                        dueDate: orderProperties.dueDate,
                        item: orderMenu,
                        restaurant: orderProperties.restaurant,
                      })}
                    >
                      <View
                        appConfigPreset={appConfigPreset}
                        menu={selectedMenu}
                        orderMenu={orderMenu}
                        restaurant={restaurant}
                        isEditMode={isEditMode}
                        isFromCart={this.props.isFromCart}
                        onRedirectToPreviousPage={this.handleRedirectToPreviousPage}
                        onSelectCategory={this.handleSelectCategory}
                        onSelectOrderProduct={(part, partCategory, productItem) =>
                          this.handleSelectOrderProduct(
                            part,
                            partCategory,
                            productItem,
                            orderMenu,
                            selectedCategory
                          )
                        }
                        onUnselectOrderProduct={(part, partCategory, productItem) =>
                          this.handleUnselectOrderProduct(part, partCategory, productItem)
                        }
                        onEditOrderProduct={this.handleEditOrderProduct}
                        onReturnToPreviousPartCategory={this.handleReturnToPreviousPartCategory}
                        onAddToCartFromShortcut={(part, partCategory, productItem) =>
                          this.handleAddProductFromShortcut(
                            part,
                            partCategory,
                            productItem,
                            orderMenu,
                            selectedCategory
                          )
                        }
                        onSubmit={() => this.handleOnSubmit(orderMenu, selectedCategory)}
                        isFetchingAppConfig={isFetchingAppConfig}
                        isFetchingCatalog={isFetchingCatalog}
                        isFetchingRestaurant={isFetchingRestaurant}
                        catalog={catalog}
                        showDietaryInfoDialog={() => showDietaryInfoDialog(selectedMenu)}
                      />
                    </SendEventContainer>
                  )}
                </SelectedMenuContainer>
              )}
            </OrderPluginUrlCheckerContainer>
          </OrderPage>
        )}
      </SelectedCategoryContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const viewOptions = getViewOptions(state)

  return {
    viewOptions,
    itemListFromCart: getItemListFromCart(state),
    cartSuspendedDatas: getSuspendedDatas(state),
    isFromCart:
      viewOptions &&
      viewOptions.fromRoute &&
      viewOptions.fromRoute.name === getRoute('ORDER__CART').name,
    partParentCategories: getPartParentCategories(state),
  }
}

export default compose(
  connect(mapStateToProps, {
    selectMenuProduct,
    unselectMenuProduct,
    addOrderMenuToCart,
    updateOrderMenuOnCart,
    addProductItemToMenu,
    editMenuProduct,
    getSuspendedDatas,
    setSuspendedDatas,
    redirectToCatalogPage,
    redirectToCart,
    displayDeleteCartDialog,
    redirectTo,
    setPartParentCategories,
    setPartSelectedCategory,
    resetPartParentCategories,
    resetPartSelectedCategory,
    showDietaryInfoDialog,
  }),
  withWidth()
)(MenuPage)
