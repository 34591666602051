import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { hideCartModifierDialog } from 'pmt-modules/dialog'

import { TypographyCustom } from 'pmt-ui/Typography'
import { DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import LocalOffer from 'pmt-ui/svg-icons/maps/local-offer'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  title: {
    clear: 'both',
    color: theme.palette.primary.main,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginTop: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  cartModifierContainer: {
    overflow: 'hidden',
  },
  cartModifierName: {
    float: 'left',
    height: 24,
    lineHeight: '24px',
    color: theme.palette.primary.main,
  },
  cartModifierIcon: {
    float: 'left',
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    transform: 'rotate(90deg)',
  },
  cartModifierDescription: {
    float: 'left',
    marginLeft: 40,
    marginTop: theme.spacing(1),
  },
})

class CartModifierDialog extends React.PureComponent {
  handleRemoveItemsFromCart = () => {
    this.props.hideCartModifierDialog()
  }

  render() {
    const { cartModifiers, hideCartModifierDialog, classes } = this.props

    return (
      <React.Fragment>
        <DialogTitle>
          <div className={classes.closeArea} onClick={hideCartModifierDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
          <TypographyCustom type="247" component="p" className={classes.title}>
            {tr('order.cartModifier.dialog.title')}
          </TypographyCustom>
        </DialogTitle>

        <DialogContent>
          {cartModifiers.map(cartModifier => (
            <div className="u-overflowHidden u-marginTop30">
              <div className={classNames(classes.cartModifierContainer)}>
                <LocalOffer className={classes.cartModifierIcon} />
                <TypographyCustom type="167" component="p" className={classes.cartModifierName}>
                  {cartModifier.name}
                </TypographyCustom>
              </div>
              <TypographyCustom
                type="124"
                component="p"
                className={classes.cartModifierDescription}
              >
                {cartModifier.description}
              </TypographyCustom>
            </div>
          ))}
        </DialogContent>
      </React.Fragment>
    )
  }
}

CartModifierDialog.DialogRootProps = {}

const mapStateToProps = (state, ownProps) => ({})

export default compose(
  connect(
    mapStateToProps,
    {
      hideCartModifierDialog,
    }
  ),
  withStyles(styles)
)(CartModifierDialog)
