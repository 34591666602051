import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getRoute, redirectTo } from 'pmt-modules/routing'
import { FormType, withForm } from 'pmt-modules/form'
import { getAppConfigFrontSettings, getOrderData, resetOrderPlugin } from 'pmt-modules/orderPlugin'
import { getAppConfig } from 'pmt-modules/appConfig'
import {
  getRegisterError,
  isFetchingRegister,
  registerAsIncognito,
  registerUserLight,
} from 'pmt-modules/registration'
import { getErrorOrderPost, isFetchingOrderPost } from 'pmt-modules/orderPost'
import { UserLightFormView } from 'pmt-modules/registration/forms/userLight'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import Ask from 'pmt-ui/svg-icons/action/ask'

import Button from '../../../components/Button'
import MessageBlock from '../../../components/MessageBlock'
import { LoginViews } from '../constants'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(4),
    float: 'right',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  allowIncognitoButton: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonsGrid: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 0,
    },
  },
  caption: {
    display: 'flex',
    color: theme.pmt.status.inactive,
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  captionTitle: {
    marginBottom: theme.spacing(1),
  },
  iconAsk: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
})

class UserLightForm extends React.PureComponent {
  constructor(props) {
    super(props)

    // SECURITY
    // on user light mode, login page is specifically made to post order
    // if the order data is null, it means that one mandatory information
    // to access this page is missing
    if (isNil(props.orderData)) {
      if (this.hasRedirectUriDefined()) {
        window.location.href = props.frontSettings.redirectUri
      } else {
        props.resetOrderPlugin()
        props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
      }
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleSubmitLogin = () => {
    this.props.registerUserLight(this.props.formData)
  }

  render() {
    const {
      classes,
      formIsValid,
      isFetchingOrder,
      isFetchingRegister,
      orderPostError,
      registerError,
      allowIncognito,
      cguLabel,
      onToggleView,
      customTexts: texts,
    } = this.props

    const captionTitle =
      !isNil(texts) && !isNil(texts.ORDER__USERLIGHT__CAPTION_TITLE)
        ? texts.ORDER__USERLIGHT__CAPTION_TITLE
        : tr('order.login.user_light.caption.title')

    const captionText =
      !isNil(texts) && !isNil(texts.ORDER__USERLIGHT__CAPTION_TEXT)
        ? texts.ORDER__USERLIGHT__CAPTION_TEXT
        : tr('order.login.user_light.caption.text')

    return (
      <React.Fragment>
        <TypographyCustom className={classes.title} type="284">
          {!isNil(texts) && !isNil(texts.ORDER__GLOBAL__YOUR_INFORMATIONS)
            ? texts.ORDER__GLOBAL__YOUR_INFORMATIONS
            : tr('order.global.your_informations')}
        </TypographyCustom>

        <LoadingBlockWrapper show={isFetchingOrder || isFetchingRegister} />

        <Grid spacing={2} container>
          <Grid item md={5}>
            <div className={classes.caption}>
              <Hidden smDown>
                <Ask className={classes.iconAsk} />
              </Hidden>
              <div className={classes.captionWrapper}>
                {captionTitle && (
                  <TypographyCustom type="167" className={classes.captionTitle} skipColor>
                    {captionTitle}
                  </TypographyCustom>
                )}
                {captionText && (
                  <TypographyCustom type="144" skipColor>
                    {captionText}
                  </TypographyCustom>
                )}

                {allowIncognito && (
                  <div className={classes.allowIncognitoButton}>
                    <Button
                      size="small" // small height
                      className="u-sizeFull" // large width
                      onClick={(e) => {
                        e.stopPropagation()
                        onToggleView(LoginViews.INCOGNITO)
                      }}
                      label={
                        !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                          ? texts.ORDER__INCOGNITO__LINK
                          : tr('global.register.continue_as_incognito.from_LIGHT.link')
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </Grid>

          <Grid item md={1} />

          <Grid item xs={12} md={5} lg={5}>
            {!isNil(orderPostError || registerError) && (
              <ErrorBlock
                error={orderPostError || registerError}
                mode={ErrorBlock.Mode.CUSTOM}
                customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
              />
            )}

            <form
              onSubmit={(e) => {
                e.preventDefault()
                this.handleSubmitLogin()
              }}
            >
              <Grid container>
                <Grid item md={11}>
                  <UserLightFormView cguLabel={cguLabel} />
                </Grid>

                <Grid container className={classes.buttonsGrid}>
                  <Grid item lg={4}>
                    <Button
                      size="large"
                      type="submit"
                      label={tr('order.login.user_light.button')}
                      disabled={!formIsValid}
                      classes={{ root: classes.button }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingOrder: isFetchingOrderPost(state),
  isFetchingRegister: isFetchingRegister(state),
  orderData: getOrderData(state),
  orderPostError: getErrorOrderPost(state),
  registerError: getRegisterError(state),
  allowIncognito: getAppConfig(state).authentication.allowIncognito,
})

export default compose(
  withStyles(styles),
  withForm(FormType.USER_LIGHT),
  connect(mapStateToProps, {
    registerUserLight,
    registerAsIncognito,
    redirectTo,
    resetOrderPlugin,
  })
)(UserLightForm)
