// TODO: rename to DialogType to be consistant
export const ModalType = {
  /**
   * Props:
   * - entry: the entry to share
   * - item: the item (nullable)
   */
  PAYMENT_CHOOSE_ENTRY_DIVIDER: 'PAYMENT_CHOOSE_ENTRY_DIVIDER',

  PAYMENT_GENEROUS_PAYMENT: 'PAYMENT_GENEROUS_PAYMENT',

  /**
   * Props:
   * - error
   */
  ERROR: 'ERROR',

  /**
   * Props:
   */
  DELETE_CART: 'DELETE_CART',

  /**
   * Props:
   * - productId
   */
  ORDER_PLUGIN_PRODUCT_VIEW: 'ORDER_PLUGIN_PRODUCT_VIEW',

  /**
   * Show the dialog containing all items that will be deleted when changing schedule
   */
  CART_DIFF_ITEMS: 'CART_DIFF_ITEMS',

  /**
   * Props:
   * - company
   */
  ADD_ADMISSION_FEE_RULE: 'ADD_ADMISSION_FEE_RULE',

  /**
   * Props:
   * - company
   */
  ADD_SUBSIDY_RULE: 'ADD_SUBSIDY_RULE',

  /**
   *
   */
  SUGGESTION_VIEW: 'SUGGESTION_VIEW',

  /**
   *
   */
  UPSELLING_VIEW: 'UPSELLING_VIEW',

  /**
   *
   */
  CART_MODIFIER_VIEW: 'CART_MODIFIER_VIEW',

  /**
   *
   */
  PRODUCT_VIEW: 'PRODUCT_VIEW',

  /**
   *
   */
  LOOSE_CART_VIEW: 'LOOSE_CART_VIEW',

  /**
   *
   */
  INACTIVITY: 'INACTIVITY',

  /**
   *
   */
  PRINTER_DISABLED: 'PRINTER_DISABLED',

  /**
   *
   */
  POST_ORDER_ERROR_BACK_HOME: 'POST_ORDER_ERROR_BACK_HOME',

  /**
   *
   */
  DIETARY_INFO: 'DIETARY_INFO',

  /**
   *
   */
  RESTAURANTS_GROUP_SELECTION: 'RESTAURANTS_GROUP_SELECTION',

  /**
   *
   */
  UNAVAILABLE_DUE_DATE: 'UNAVAILABLE_DUE_DATE',

  /**
   *
   */
  ORDER_MODE_DISABLED: 'ORDER_MODE_DISABLED',

  /**
   *
   */
  RESTAURANT_DISABLED: 'RESTAURANT_DISABLED',

  /**
   *
   */
  RESTAURANT_CLOSED: 'RESTAURANT_CLOSED',

  /**
   *
   */
  TABLE_NOT_FOUND: 'TABLE_NOT_FOUND',
}

export default ModalType
