import { AsyncPaymentMiddlewares } from 'pmt-modules/asyncPayment'
import { appConfigMiddlewares } from 'pmt-modules/appConfig'
import { appSecurityMiddlewares } from 'pmt-modules/appSecurity'
import { authMiddlewares } from 'pmt-modules/auth'
import { cartDiffMiddlewares } from 'pmt-modules/cartDiff'
import { cartMiddlewares } from 'pmt-modules/cart'
import { creditCardMiddlewares } from 'pmt-modules/creditCard'
import { dialogMiddlewares } from 'pmt-modules/dialog'
import { orderFrontMiddlewares } from 'pmt-modules/orderFront'
import { orderMenuMiddlewares } from 'pmt-modules/orderMenu'
import { orderPluginMiddlewares } from 'pmt-modules/orderPlugin'
import { orderPreviewMiddlewares } from 'pmt-modules/orderPreview'
import { orderSettingsMiddlewares } from 'pmt-modules/orderSettings'
import { registrationMiddlewares } from 'pmt-modules/registration'

export default [
  ...AsyncPaymentMiddlewares,
  ...appConfigMiddlewares,
  ...appSecurityMiddlewares,
  ...authMiddlewares,
  ...cartDiffMiddlewares,
  ...cartMiddlewares,
  ...creditCardMiddlewares,
  ...dialogMiddlewares,
  ...orderFrontMiddlewares,
  ...orderMenuMiddlewares,
  ...orderPluginMiddlewares,
  ...orderPreviewMiddlewares,
  ...orderSettingsMiddlewares,
  ...registrationMiddlewares,
]
