import React from 'react'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    flex: 1,
  },
})

const PageContent = ({ children, classes }) => <div className={classes.root}>{children}</div>
export default withStyles(styles)(PageContent)
