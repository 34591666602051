import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import ui from 'pmt-modules/reduxUi'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import {
  getAppConfigFrontSettings,
  getOrderData,
  setDeliveryAddress,
  resetDeliveryAddress,
} from 'pmt-modules/orderPlugin'
import { setPaymentMethod } from 'pmt-modules/orderFront'
import { isFetchingOrderPost, postOrder } from 'pmt-modules/orderPost'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { UserAddressListContainer, isFetchingUserAddressPost } from 'pmt-modules/userAddress'

import LoadingBlock, { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Hidden from 'pmt-ui/Hidden'
import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BlockContainer from '../../../components/BlockContainer'
import Breadcrumb from '../../../components/Breadcrumb'
import Button from '../../../components/Button'

import RegisteredAddresses from './RegisteredAddresses'
import NewAddress from './NewAddress'

const styles = theme => ({
  subtitle: {
    color: theme.pmt.colors.grey500,
    marginBottom: theme.spacing(2),
  },
  geolocButton: {
    marginTop: theme.spacing(1),
  },
  registered_addresses: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  subContainerWidth: {
    maxWidth: 450,
  },
  stickyButton: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    borderRadius: 0,
    marginTop: theme.spacing(1),
    zIndex: theme.zIndex.stickyButton,
  },
  submitButton: {
    float: 'right',
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bigButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  errorMessage: {
    color: theme.pmt.status.error,
  },
  textField: {
    marginTop: theme.spacing(3),
  },
  checkbox_label: {
    float: 'left',
    height: 48,
    lineHeight: '48px',
  },
  italic: {
    fontStyle: 'italic',
  },
  hidden: {
    display: 'none',
  },
  height100: {
    height: '100%',
  },
})

const breadcrumbElements = [
  {
    name: 'CATALOG',
    active: true,
    hiddenMobile: true,
  },
  {
    name: 'CART',
    active: true,
  },
  {
    name: 'INFORMATIONS',
    active: true,
  },
  {
    name: 'DELIVERY_ADDRESS',
    active: true,
    current: true,
  },
  {
    name: 'PAYMENT',
  },
]

class DeliveryBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addressToEdit: null,
    }
  }

  handleOnEditAddress = addressToEdit => {
    this.setState({
      addressToEdit,
    })

    this.props.updateUI({
      showAddressForm: false,
    })
  }

  handleCloseEditAddress = () => {
    this.setState({
      addressToEdit: null,
    })
  }

  onValidateSelectedAddress = () => {
    const { frontSettings, orderData, postOrder, redirectTo, setPaymentMethod } = this.props

    EventManager.dispatch(EventManager.Events.ON_DELIVERY_ADDRESS_CONFIRM)

    /**
     * when skipping payment page, we set payment method as IRL
     * and redirect to delivery address on delivery mode
     * otherwise, we post order directly
     */
    if (frontSettings.display.paymentPage) {
      redirectTo(getRoute('ORDER__PAYMENT'))
    } else {
      setPaymentMethod(PaymentMethodsAllowed.IRL) // this doesn't change the orderData we use for postOrder, so the API receive paymentMethod=null
      postOrder(orderData)
    }
  }

  render() {
    const {
      classes,
      frontSettings,
      isFetchingOrderPost,
      isFetchingUserAddressPost,
      orderProperties,
      restaurant,
      user,
      isFetchingUser,
      ui,
      updateUI,
      setDeliveryAddress,
      resetDeliveryAddress,
    } = this.props

    return (
      <BlockContainer
        classes={{ root: classNames(classes.height100, 'u-overflowHidden u-relative') }}
      >
        {isFetchingUser || isNil(user) ? (
          <LoadingBlock show />
        ) : (
          <React.Fragment>
            <LoadingBlockWrapper show={isFetchingOrderPost || isFetchingUserAddressPost} />

            <Breadcrumb className="u-marginBottom30" elements={breadcrumbElements} />
            <TypographyCustom type="284" component="h2" className="u-marginBottom20">
              {tr('order.addresses.title', { name: user.firstName })}
            </TypographyCustom>
            <UserAddressListContainer
              userId={user.id}
              origin={!isNil(restaurant) ? restaurant.geoPt : null}
              range={
                !isNil(get(restaurant, 'orderSettings.deliverySettings', null))
                  ? restaurant.orderSettings.deliverySettings.maxRangeInMeters / 1000
                  : null
              }
            >
              {({
                isFetchingUserAddressList,
                userAddressListInRange,
                userAddressListOutOfRange,
              }) => {
                if (
                  !isNil(userAddressListInRange) &&
                  userAddressListInRange.length === 0 &&
                  !ui.showAddressForm
                ) {
                  updateUI({
                    showAddressForm: true,
                  })
                }

                return (
                  <Grid spacing={2} container>
                    {((!isNil(userAddressListInRange) && userAddressListInRange.length > 0) ||
                      (!isNil(userAddressListOutOfRange) && userAddressListOutOfRange.length > 0) ||
                      isFetchingUserAddressList) && (
                      <Grid item xs={12} sm={6}>
                        <RegisteredAddresses
                          selectedDeliveryAddress={orderProperties.deliveryAddress}
                          isFetchingUserAddressList={isFetchingUserAddressList}
                          userAddressListInRange={userAddressListInRange}
                          userAddressListOutOfRange={userAddressListOutOfRange}
                          user={user}
                          classes={classes}
                          onEditAddress={this.handleOnEditAddress}
                          onCloseEditAddress={this.handleCloseEditAddress}
                          addressToEdit={this.state.addressToEdit}
                          onSubmit={this.onValidateSelectedAddress}
                          onResetDeliveryAddress={resetDeliveryAddress}
                          onSelectDeliveryAddress={address => {
                            setDeliveryAddress(
                              address.id,
                              address.name,
                              address.complement,
                              address.street,
                              address.postCode,
                              address.city,
                              address.country
                            )
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={
                        (!isNil(userAddressListInRange) && userAddressListInRange.length > 0) ||
                        (!isNil(userAddressListOutOfRange) &&
                          userAddressListOutOfRange.length > 0) ||
                        isFetchingUserAddressList
                          ? 6
                          : 12
                      }
                    >
                      <NewAddress
                        orderProperties={orderProperties}
                        restaurant={restaurant}
                        ui={ui}
                        updateUI={updateUI}
                        user={user}
                        classes={classes}
                      />

                      {/*
                        This button is here for the mobile rendering version
                        We want this button to appear on mobile to be at the bottom of everything, and if the new form is not opened
                      */}
                      {!ui.showAddressForm && (
                        <Hidden smUp xsDown={isNil(orderProperties.deliveryAddress)}>
                          <Button
                            size="large"
                            classes={{ root: classes.stickyButton }}
                            label={tr(
                              frontSettings.display.paymentPage
                                ? 'order.global.validate'
                                : 'order.global.validate_and_order'
                            )}
                            onClick={this.onValidateSelectedAddress}
                          />
                        </Hidden>
                      )}
                    </Grid>
                  </Grid>
                )
              }}
            </UserAddressListContainer>
          </React.Fragment>
        )}
      </BlockContainer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  isFetchingUserAddressPost: isFetchingUserAddressPost(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  orderData: getOrderData(state),
})

export default compose(
  connect(mapStateToProps, {
    setDeliveryAddress,
    resetDeliveryAddress,
    redirectTo,
    postOrder,
    setPaymentMethod,
  }),
  ui({
    state: {
      showAddressForm: false,
      addressLocationNotGood: false,
    },
  }),
  withStyles(styles)
)(DeliveryBlock)
