import concat from 'lodash/concat'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import {
  sendEvent,
  sendAddDeliveryInfo,
  sendRemoveFromCart,
  sendLogin,
  sendPaymentSubmitted,
  sendPaymentSucceeded,
  sendAddPaymentInfo,
  sendAddToCart,
  sendCartSubmitted,
  sendViewCart,
  sendViewItem,
} from 'pmt-modules/analytics'

const events = {
  ON_PAGE_LOADED: 'EVENT::GLOBAL::ON_PAGE_LOADED',

  // HEADER
  ON_HEADER_MODE_SELECT: 'EVENT::ORDER::HEADER::MODE_SELECT',
  ON_HEADER_BUTTON_CHANGE: 'EVENT::ORDER::HEADER::BUTTON_CHANGE',

  // BREADCRUMB
  ON_BREADCRUMB_LINK: 'EVENT::ORDER::BREADCRUMB::LINK',

  // MODE
  ON_MODE_SELECT: 'EVENT::ORDER::MODE::SELECT',

  // STORE_LOCATOR
  ON_STORE_LOCATOR_GEOLOCATION: 'EVENT::ORDER::STORE_LOCATOR::GEOLOCATION',
  ON_STORE_LOCATOR_RESTAURANT_SELECT: 'EVENT::ORDER::STORE_LOCATOR::RESTAURANT::SELECT',
  ON_STORE_LOCATOR_SUBMIT: 'EVENT::ORDER::STORE_LOCATOR::SUBMIT',

  // CATALOG
  ON_CATALOG_CATEGORY_SELECT: 'EVENT::ORDER::CATALOG::CATEGORY::SELECT',

  // CATALOG ITEM
  ON_CATALOG_ITEM_DETAIL: 'EVENT::ORDER::CATALOG::ITEM::DETAIL',
  ON_CATALOG_ITEM_ADD_PRODUCT: 'EVENT::ORDER::CATALOG::ITEM::ADD_PRODUCT',
  ON_CATALOG_ITEM_ADD_MENU: 'EVENT::ORDER::CATALOG::ITEM::ADD_MENU',

  // CATALOG MENU
  ON_CATALOG_MENU_ADD_PRODUCT: 'EVENT::ORDER::CATAOLOG:LMENU::ADD_PRODUCT',
  ON_CATALOG_MENU_BREADCRUMB: 'EVENT::ORDER::CATALOG::MENU::BREADCRUMB',
  ON_CATALOG_MENU_DETAIL: 'EVENT::ORDER::CATALOG::MENU::DETAIL',
  ON_CATALOG_MENU_ITEM_DETAIL: 'EVENT::ORDER::CATALOG::MENU::ITEM_DETAIL',
  ON_CATALOG_MENU_ADD_TOP: 'EVENT::ORDER::CATALOG::MENU::ADD_TOP',
  ON_CATALOG_MENU_ADD_BOTTOM: 'EVENT::ORDER::CATALOG::MENU::ADD_BOTTOM',
  ON_CATALOG_MENU_ADD_STICKY: 'EVENT::ORDER::CATALOG::MENU::ADD_STICKY',

  // CATALOG CART
  ON_CATALOG_CART_SUBMIT: 'EVENT::ORDER::CATALOG::CART::SUBMIT',

  // CART
  ON_CART_ITEM_DETAIL: 'EVENT::ORDER::CART::ITEM_DETAIL',
  ON_CART_ITEM_DELETE: 'EVENT::ORDER::CART::ITEM_DELETE',
  ON_CART_ITEM_INCREASE: 'EVENT::ORDER::CART::ITEM_INCREASE',
  ON_CART_ITEM_DECREASE: 'EVENT::ORDER::CART::ITEM_DECREASE',
  ON_CART_ADD_COMMENT: 'EVENT::ORDER::CART::ADD_COMMENT',
  ON_CART_SUBMIT: 'EVENT::ORDER::CART::SUBMIT',

  // REGISTER
  ON_REGISTER_SUBMIT_REGISTER: 'EVENT::ORDER::REGISTER::SUBMIT_REGISTER',

  // LOGIN
  ON_LOGIN_SUBMIT_LOGIN: 'EVENT::ORDER::LOGIN::SUBMIT_LOGIN',

  // FORGOT PASSWORD
  ON_FORGOT_PASSWORD_SUBMIT: 'EVENT::ORDER::FORGOT_PASSWORD::SUBMIT',

  // PROFILE
  ON_PROFILE_SUBMIT: 'EVENT::ORDER::PROFILE::SUBMIT',

  // DELIVERY ADDRESS
  ON_DELIVERY_ADDRESS_ADD_ADDRESS: 'EVENT::ORDER::DELIVERY_ADDRESS::ADD_ADDRESS',
  ON_DELIVERY_ADDRESS_ADD_GEOLOCATION: 'EVENT::ORDER::DELIVERY_ADDRESS::ADD_GEOLOCATION',
  ON_DELIVERY_ADDRESS_EDIT_ADDRESS: 'EVENT::ORDER::DELIVERY_ADDRESS::EDIT_ADDRESS',
  ON_DELIVERY_ADDRESS_EDIT_GEOLOCATION: 'EVENT::ORDER::DELIVERY_ADDRESS::EDIT_GEOLOCATION',
  ON_DELIVERY_ADDRESS_DELETE_ADDRESS: 'EVENT::ORDER::DELIVERY_ADDRESS::DELETE_ADDRESS',
  ON_DELIVERY_ADDRESS_CONFIRM: 'EVENT::ORDER::DELIVERY_ADDRESS::SUBMIT',

  // PAYMENT
  ON_PAYMENT_MODE_SELECT: 'EVENT::ORDER::PAYMENT::MODE_SELECT',
  ON_PAYMENT_SUBMIT: 'EVENT::ORDER::PAYMENT::SUBMIT',

  // CONFIRM
  ON_CONFIRM_ORDER_DETAIL: 'EVENT::ORDER::CONFIRM::ORDER_DETAIL',
  ON_CONFIRM_CALLBACK: 'EVENT::ORDER::CONFIRM::CALLBACK',

  // OTHER
  ON_CHANGE_SLOT: 'EVENT::ORDER::OTHER::CHANGE_SLOT',
  ON_CHANGE_TABLE: 'EVENT::ORDER::OTHER::CHANGE_TABLE',
  ON_SET_DUE_DATE_ASAP: 'EVENT::ORDER::OTHER::SET_DUE_DATE::ASAP',
  ON_SET_DUE_DATE_SLOT: 'EVENT::ORDER::OTHER::SET_DUE_DATE::SLOT',
}

const ACTION = {
  CTA: 'calls-to-action',
  SEC_CTA: 'secondary-calls-to-action',
  LINKS: 'links',
}

const formatItem = (item, category, restaurant) => ({
  item_id: item.id,
  item_name: item.name,
  item_category: !isNil(category) ? category.name : null,
  item_brand: !isNil(restaurant) ? restaurant.name : null,
  price: (!isNil(item.totalPriceWithQuantity) ? item.totalPriceWithQuantity : item.price).toFixed(
    2
  ),
  quantity: item.quantity || 1,
})

const makeEvent = (catPrefix = '', action, label, value) =>
  sendEvent({
    category: `${catPrefix}${window.location.pathname.replace(/\//g, '-')}`,
    action,
    label,
    value,
  })

const getLabelForMode = (mode) => {
  switch (mode) {
    case 0:
      return 'on-site'
    case 1:
      return 'take-away'
    case 2:
      return 'delivery'
    default:
      return 'unknown'
  }
}

const listeners = {
  [EventManager.Events.ON_ANALYTICS_INIT]: () => {
    // init plugin if needed
  },
  [events.ON_HEADER_MODE_SELECT]: ({ data }) => {
    makeEvent('header', ACTION.SEC_CTA, 'select-mode', data.mode)
  },
  [events.ON_HEADER_BUTTON_CHANGE]: ({ data }) => {
    makeEvent('header', ACTION.SEC_CTA, 'change-button', data.mode)
  },
  [events.ON_BREADCRUMB_LINK]: ({ data }) => {
    makeEvent('breadcrumb', ACTION.LINKS, data.link)
  },
  [events.ON_MODE_SELECT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, getLabelForMode(data.mode), data.mode)
  },
  [events.ON_STORE_LOCATOR_GEOLOCATION]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'geolocation', data.mode)
  },
  [events.ON_STORE_LOCATOR_RESTAURANT_SELECT]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, data.restaurant.id, data.mode)
  },
  [events.ON_STORE_LOCATOR_SUBMIT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'main-validate-store', data.mode)
  },
  [events.ON_CATALOG_CATEGORY_SELECT]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'catalog-category')
  },
  [events.ON_CATALOG_ITEM_DETAIL]: ({ data }) => {
    sendViewItem(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.LINKS, 'details-items')
  },
  [events.ON_CATALOG_ITEM_ADD_PRODUCT]: ({ data }) => {
    sendAddToCart(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.CTA, 'add-product')
  },
  [events.ON_CATALOG_ITEM_ADD_MENU]: ({ data }) => {
    sendAddToCart(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.CTA, 'add-menu')
  },
  [events.ON_CATALOG_MENU_ADD_PRODUCT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'add-product-menu')
  },
  [events.ON_CATALOG_MENU_BREADCRUMB]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'menu-part-side-breadcrumbs')
  },
  [events.ON_CATALOG_MENU_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-menu')
  },
  [events.ON_CATALOG_MENU_ITEM_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-menu-item')
  },
  [events.ON_CATALOG_MENU_ADD_TOP]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'add-cart-top')
  },
  [events.ON_CATALOG_MENU_ADD_BOTTOM]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'add-cart-bottom')
  },
  [events.ON_CATALOG_MENU_ADD_STICKY]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'add-cart-sticky')
  },
  [events.ON_CATALOG_CART_SUBMIT]: ({ data }) => {
    const formattedItems = data.items.map((item) => formatItem(item, '', data.restaurant))
    sendViewCart(formattedItems, 1)
    makeEvent('page', ACTION.CTA, 'main-order-cart')
  },
  [events.ON_CART_ITEM_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-items')
  },
  [events.ON_CART_ITEM_DELETE]: ({ data }) => {
    sendRemoveFromCart(formatItem(data.item, '', data.restaurant))
    makeEvent('page', ACTION.SEC_CTA, 'delete-item')
  },
  [events.ON_CART_ITEM_INCREASE]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'increase-item-quantity')
  },
  [events.ON_CART_ITEM_DECREASE]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'decrease-item-quantity')
  },
  [events.ON_CART_ADD_COMMENT]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'add-global-comment')
  },
  [events.ON_CART_SUBMIT]: ({ data }) => {
    const formattedItems = data.items.map((item) => formatItem(item, '', data.restaurant))
    sendCartSubmitted(formattedItems, 2)
    makeEvent('page', ACTION.CTA, 'main-order-final')
  },
  [events.ON_REGISTER_SUBMIT_REGISTER]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'main-signup')
  },
  [events.ON_LOGIN_SUBMIT_LOGIN]: ({ data }) => {
    sendLogin(3)
    makeEvent('page', ACTION.CTA, 'main-login')
  },
  [events.ON_FORGOT_PASSWORD_SUBMIT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'main-reset-password')
  },
  [events.ON_PROFILE_SUBMIT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'main-update-profile')
  },
  [events.ON_DELIVERY_ADDRESS_ADD_ADDRESS]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'add-address')
  },
  [events.ON_DELIVERY_ADDRESS_ADD_GEOLOCATION]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'add-geolocation')
  },
  [events.ON_DELIVERY_ADDRESS_EDIT_ADDRESS]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'edit-address')
  },
  [events.ON_DELIVERY_ADDRESS_EDIT_GEOLOCATION]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'edit-geolocation')
  },
  [events.ON_DELIVERY_ADDRESS_DELETE_ADDRESS]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'delete-address')
  },
  [events.ON_DELIVERY_ADDRESS_CONFIRM]: ({ data }) => {
    sendAddDeliveryInfo([], 4)
    makeEvent('page', ACTION.CTA, 'main-validate-address')
  },
  [events.ON_PAYMENT_MODE_SELECT]: ({ data }) => {
    sendAddPaymentInfo([], data.paymentMode, 4 )
    makeEvent('page', ACTION.SEC_CTA, 'payment-mode-select', data.paymentMode)
  },
  [events.ON_PAYMENT_SUBMIT]: ({ data }) => {
    const formattedItems = data.items.map((item) => formatItem(item, '', data.restaurant))
    sendPaymentSubmitted(formattedItems, 5)
    makeEvent('page', ACTION.CTA, 'main-pay')
  },
  [EventManager.Events.ON_PAYMENT_SUCCESS]: ({ data }) => {
    const formattedItems = concat(data.order.products, data.order.menus).map(
      (item) => item && formatItem(item, '', data.restaurant)
    )
    sendPaymentSucceeded(
      formattedItems,
      data.order.id,
      data.order.totalPrice.toFixed(2),
      data.order.currency
    )
    makeEvent('page', ACTION.CTA, 'payment-success')
  },
  [events.ON_CONFIRM_ORDER_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'order-details')
  },
  [events.ON_CONFIRM_CALLBACK]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'callback')
  },
  [events.ON_SET_DUE_DATE_ASAP]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'asap', data.mode)
  },
  [events.ON_SET_DUE_DATE_SLOT]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'time-slot', data.mode)
  },
  [events.ON_CHANGE_SLOT]: ({ data }) => {
    makeEvent('side', ACTION.SEC_CTA, 'change-slot', data.mode)
  },
  [events.ON_CHANGE_TABLE]: ({ data }) => {
    makeEvent('side', ACTION.SEC_CTA, 'change-table', data.mode)
  },
}

const EventsConfig = {
  events,
  listeners,
}

export default EventsConfig
