import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '16px',
    [theme.breakpoints.up('md')]: {
      lineHeight: '40px',
    },
  },
  orderIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

const OrderModeText = ({ classes, orderProperties, width }) =>
  !isNil(orderProperties.mode) && (
    <div className={classes.root}>
      <IconOrderMode type={orderProperties.mode} className={classes.orderIcon} />
      <div>
        <TypographyCustom component="span" type={isWidthUp('sm', width) ? 164 : 144}>
          {orderProperties.isDelivery && tr('order.global.delivery')}
          {orderProperties.isOnSite && tr('order.global.on_site')}
          {orderProperties.isTakeAway && tr('order.global.take_away')}
        </TypographyCustom>
      </div>
    </div>
  )

export default compose(
  withWidth(),
  withStyles(styles)
)(OrderModeText)
