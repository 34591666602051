import { createMuiTheme, dark, light, getLuminance } from 'pmt-ui/styles'

import grey from 'pmt-ui/colors/grey'
import blue from 'pmt-ui/colors/blue'
import green from 'pmt-ui/colors/green'
import yellow from 'pmt-ui/colors/yellow'
import red from 'pmt-ui/colors/red'

import { getQueryParam } from 'pmt-utils/url'

const PMTColors = {
  grey100: grey[100],
  grey300: grey[300],
  grey500: grey[500],
  grey600: grey[600],
  grey700: grey[700],
  blue800: blue[800],
  green500: green[500],
  green800: green[800],
  yellow900: yellow[900],
  red800: red[800],
  red900: red[900],
  white: '#fff',
  greyBackground: '#efeff4',
  grey91: '#919ca7',
  grey97: '#979797',
  darkGrey: '#262626',
  // https://paymytable.atlassian.net/browse/PP-158
  itemPlaceholderBackground: '#EFEFF4',
  itemPlaceholderBackgroundSecondary: 'rgba(239, 239, 244, 0.4)',
}

const getPMTColor = color => PMTColors[color]

const getPMTColors = () => PMTColors

const muiTheme = createMuiTheme({
  palette: {
    type: getQueryParam('theme', window.location.uri) || 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#387651', //'#85a60f',
    },
    secondary: {
      main: '#00A695',
    },
    contrastThreshold: 0.5,
    text: {
      primary: getPMTColor('darkGrey'),
    },
  },
  pmt: {
    appConfig: {}, // can be overwritten by api consumer theme settings
    colors: getPMTColors(),
    status: {
      info: getPMTColor('blue800'),
      error: getPMTColor('red800'),
      warning: getPMTColor('yellow900'),
      inactive: getPMTColor('grey500'),
      valid: getPMTColor('green800'),
    },
    alert: {
      error: getPMTColor('red800'),
      warning: getPMTColor('yellow900'),
      info: getPMTColor('blue800'),
      success: getPMTColor('green800'),
    },
    circularProgress: {
      width: 50,
    },
    overlay: {
      background: 'rgba(255, 255, 255, .6)',
      backgroundBlack: 'rgba(0, 0, 0, .7)',
    },
    loading: {
      color: getPMTColor('grey500'),
    },
    header: {
      mobile: {
        height: 48,
        verticalPadding: 8,
        horizontalPadding: 8,
        contentHeight: 40, // height - verticalPadding
      },
    },
    // a set of css mixins
    mixins: {
      textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    // https://material.io/guidelines/components/buttons-floating-action-button.html#buttons-floating-action-button-floating-action-button
    floatingActionButton: {
      fromLeftEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
      fromBottomEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
    },
    typography: {
      fonts: {
        '107': {
          style: {
            fontSize: 10,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '124': {
          style: {
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '127': {
          style: {
            fontSize: 12,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '144': {
          style: {
            fontSize: 14,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '147': {
          style: {
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '164': {
          style: {
            fontSize: 16,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '167': {
          style: {
            fontSize: 16,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '204': {
          style: {
            fontSize: 20,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '207': {
          style: {
            fontSize: 20,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '244': {
          style: {
            fontSize: 24,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '247': {
          style: {
            fontSize: 24,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '284': {
          style: {
            fontSize: 28,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '287': {
          style: {
            fontSize: 28,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
      },
    },
  },
  zIndex: {
    stickyButton: 1200,
  },
})

const theme = {
  muiTheme,
}

// https://github.com/callemall/material-ui/issues/8183
// override getContrastText function to have a white color on green buttons
// TODO : check the evolution of the issue above
theme.muiTheme.palette.getContrastText = color => {
  if (getLuminance(color) <= theme.muiTheme.palette.contrastThreshold) {
    return dark.text.primary
  } else {
    return light.text.primary
  }
}

export default theme
