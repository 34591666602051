import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { OrderMode } from 'pmt-modules/order'

import Grid from 'pmt-ui/Grid'
import Hidden from 'pmt-ui/Hidden'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import BlockContainer from '../../components/BlockContainer'
import { ButtonSquare } from '../../components/Button'

const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    margin: '0 auto',
    marginTop: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText('#000'),
    },
    [theme.breakpoints.down('xs')]: {
      color: 'inherit',
    },
  },
  buttonfloatingArrow: {
    color: theme.palette.primary.main,
  },
  blockContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(13),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  modesWrapper: {
    maxWidth: 800,
    margin: '0 auto',
  },
})

const View = ({ classes, appConfig, onSelectMode }) => (
  <Grid spacing={0} className={classes.view} container justify="center">
    <BlockContainer
      type={BlockContainer.Type.WHITE}
      mobileType={BlockContainer.Type.GREY}
      className={classes.blockContainer}
    >
      <Hidden xsDown>
        <TypographyCustom type="284" align="center" className="u-marginBottom40">
          {tr('order.mode.choose_mode')}
        </TypographyCustom>
      </Hidden>
      <Hidden smUp>
        <TypographyCustom type="204" align="center" className="u-marginBottom20">
          {tr('order.mode.choose_mode')}
        </TypographyCustom>
      </Hidden>
      <Grid container justify="center" className={classes.modesWrapper} spacing={0}>
        {appConfig.order.modes.map((mode, index) => (
          <ButtonSquare
            key={index}
            classes={{
              root: classes.button,
              floatingArrow: classes.buttonfloatingArrow,
            }}
            icon={<IconOrderMode type={mode} />}
            label={
              OrderMode.DELIVERY === mode
                ? tr('order.global.delivery')
                : OrderMode.ON_SITE === mode
                  ? tr('order.global.on_site')
                  : tr('order.global.take_away')
            }
            onClick={() => onSelectMode(parseInt(mode, 10))}
          />
        ))}
      </Grid>
    </BlockContainer>
  </Grid>
)

export default withStyles(styles)(View)
