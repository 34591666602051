export default {
  authentication: {
    mode: 0,
    oauth2: {
      clientId: null,
      clientSecret: null,
    },
  },
  user: {
    login: null,
    registration: {
      form: {
        fields: [
          {
            type: 'FIRST_NAME',
            required: true,
          },
          {
            type: 'LAST_NAME',
            required: true,
          },
          {
            type: 'EMAIL',
            required: true,
          },
          {
            type: 'PHONE',
            required: true,
          },
          {
            type: 'PASSWORD',
            required: true,
          },
          {
            type: 'MARKETING_ALLOW_CONTACT',
            required: false,
          },
        ],
      },
    },
  },
  order: {
    modes: [],

    onSite: {
      canOrderUsingSlots: false,
      canOrderUsingAsap: true,
    },
    takeAway: {
      canOrderUsingSlots: true,
      canOrderUsingAsap: true,
    },
    delivery: {
      canOrderUsingSlots: false,
      canOrderUsingAsap: true,
    },

    hasTableNumber: true,
    bypassManufactureDelay: false,
    bypassOpeningHours: false,

    comments: {
      global: {
        enabled: true,
        maxLength: 250,
      },
      items: {
        enabled: true,
        maxLength: 40,
      },
    },
  },
  front: {
    display: {
      paymentPage: true,
      totalPrices: true,
    },
    theme: {
      customCss: {},
      logo: {
        display: true,
      },
    },
    restaurants: {
      list: {
        limit: 5,
      },
    },
  },
  texts: {
    ORDER__LOGIN__MARKETING__OPTIN: {
      fr: "J'accepte de recevoir des offres promotionnelles de l'enseigne",
      en: 'I accept to receive special offers from the store',
      de: 'Ich akzeptiere Angebote von uns erhalten',
    },
  },
}
