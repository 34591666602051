import { tr } from 'pmt-modules/i18n'
/**
 * the commented code below will be soon used from cart modifiers
 */
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
// import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
// import isEmpty from 'lodash/isEmpty'

import { AppConfigContainer } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { getCartData, forceGetItemListFromCart } from 'pmt-modules/cart'
import { isFetchingCheck } from 'pmt-modules/payment/check'
// import { displayCartModifierDialog } from 'pmt-modules/dialog'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import InfoOutlineIcon from 'pmt-ui/svg-icons/action/info-outline'
import LoadingBlock from 'pmt-ui/LoadingBlock'
// import CartModifier from 'pmt-ui/svg-icons/pmt/CartModifier'

import { ButtonLink } from '../../../components/Button'
import BlockContainer from '../../../components/BlockContainer'
import SlotsContainer from '../../../components/Slots'
import { CartItemList, CartFooter } from '../../../components/Cart'
import TableNumberInput from '../../../components/TableNumberInput'
import TipWithIcon from '../../../components/TipWithIcon'

const styles = (theme) => ({
  root: {
    minHeight: 450,
    display: 'flex',
    flexDirection: 'column',
  },
  sideSection: {
    marginBottom: theme.spacing(2),
  },
  sideInformationTitle: {
    paddingBottom: theme.spacing(2),
  },
  modifyButton: {
    textTransform: 'initial',
  },
  tipWithIcon: {
    float: 'left',
    maxWidth: 'none',
    paddingBottom: 0,
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  tipWithIconText: {
    paddingLeft: theme.spacing(4),
  },
  // cartModifierIcon: {
  //   width: 36,
  //   height: 36,
  //   color: theme.palette.primary.main,
  // },
  // cartModifierText: {
  //   float: 'left',
  //   marginLeft: theme.spacing(2),
  //   marginTop: theme.spacing(0.25),
  // },
  // cartModifierTitle: {
  //   color: theme.palette.primary.main,
  //   textTransform: 'uppercase',
  // },
  // cartModifierNumber: {
  //   color: theme.palette.primary.main,
  //   marginTop: theme.spacing(0.25),
  // },
  // cartModifierButton: {
  //   float: 'right',
  //   height: 36,
  //   lineHeight: '36px',
  // },
})

class SideInformation extends React.PureComponent {
  componentDidMount() {
    if (this.props.orderProperties.isMissingTableNumber) {
      this.props.updateUI({
        showTableNumberForm: true,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    // when tableNumber is automatically set after fetching the Check with posCheckId
    if (
      props.orderProperties.isMissingTableNumber &&
      !nextProps.orderProperties.isMissingTableNumber &&
      !nextProps.enableModifyTableNumber
    ) {
      props.updateUI({
        showTableNumberForm: false,
      })
    }
  }

  toggleTableShow = () => {
    EventManager.dispatch(EventManager.Events.ON_CHANGE_TABLE)
    this.props.updateUI({
      showTableNumberForm: !this.props.ui.showTableNumberForm,
    })
  }

  render() {
    const {
      classes,
      onRedirectToCart,
      orderProperties,
      // cartModifiers,
      itemListFromCart,
      cartData,
      enableModifyTableNumber,
      restaurant,
      // displayCartModifierDialog,
      route,
      ui,
      updateUI,
      isFetchingCheck,
    } = this.props

    return (
      <AppConfigContainer>
        {({ appConfigPreset, orderAppConfig }) => (
          <div className={classes.root}>
            {appConfigPreset.isPluginOrder && (
              <BlockContainer type={BlockContainer.Type.WHITE} className={classes.sideSection}>
                {orderProperties.mustChooseTableNumber && (
                  <React.Fragment>
                    {/* ------------------------------------------------------------ */}
                    {/* table number                                                 */}
                    {/* ------------------------------------------------------------ */}

                    <TypographyCustom
                      type="284"
                      component="div"
                      className={classes.sideInformationTitle}
                    >
                      {tr('order.menu.table_number.title')}
                    </TypographyCustom>
                    <div className="u-marginTop10">
                      <LoadingBlock show={isFetchingCheck}>
                        {ui.showTableNumberForm || orderProperties.isMissingTableNumber ? (
                          <TableNumberInput
                            type={TableNumberInput.Type.SMALL}
                            tableNumber={orderProperties.tableNumber}
                            handleCloseInput={() => {
                              updateUI({ showTableNumberForm: false })
                            }}
                            displayError
                          />
                        ) : (
                          !ui.showTableNumberForm && (
                            <div className="u-flex">
                              <TypographyCustom className="u-floatLeft" component="p" type="167">
                                {tr('order.menu.table_number.catch_phrase', {
                                  tableNumber: orderProperties.tableNumber,
                                })}
                              </TypographyCustom>
                              {!isNil(restaurant) && enableModifyTableNumber && (
                                <ButtonLink
                                  classes={{ root: 'u-marginLeft10' }}
                                  onClick={this.toggleTableShow}
                                  label={tr('order.global.modify')}
                                />
                              )}
                            </div>
                          )
                        )}
                      </LoadingBlock>
                    </div>
                  </React.Fragment>
                )}

                {(orderProperties.canOrderUsingSlots ||
                  (!orderProperties.mustChooseTableNumber &&
                    orderProperties.canOrderUsingAsap)) && (
                  <div>
                    {/* Date */}
                    <TypographyCustom type="284" className={classes.sideInformationTitle}>
                      {tr('order.menu.cart.when')}
                    </TypographyCustom>

                    {/* ------------------------------------------------------------ */}
                    {/* slots                                   */}
                    {/* ------------------------------------------------------------ */}

                    <SlotsContainer
                      type={SlotsContainer.Type.SMALL}
                      showSlots={!orderProperties.isAsap && isNil(orderProperties.slot)}
                      orderProperties={orderProperties}
                      modificationEnabled={orderProperties.canOrderUsingSlots}
                      checkCartDiff
                    />

                    {/* ------------------------------------------------------------ */}
                    {/* closing hour                                                 */}
                    {/* ------------------------------------------------------------ */}

                    {!orderAppConfig.bypassOpeningHours &&
                      get(orderProperties, 'closingHourFormatted.show', false) && (
                        <TipWithIcon
                          classes={{ root: classes.tipWithIcon, tipText: classes.tipWithIconText }}
                          icon={<InfoOutlineIcon />}
                          label={tr(`order.sideInformations.closingHour.${orderProperties.mode}`, {
                            closingHour: orderProperties.closingHourFormatted.time.hourFormatted,
                            closingMinute:
                              orderProperties.closingHourFormatted.time.minuteFormatted,
                            lastTimeToOrderHour:
                              orderProperties.closingHourFormatted.lastTimeToOrder.hourFormatted,
                            lastTimeToOrderMinute:
                              orderProperties.closingHourFormatted.lastTimeToOrder.minuteFormatted,
                          })}
                        />
                      )}
                  </div>
                )}
              </BlockContainer>
            )}

            {/* {!isEmpty(cartModifiers) && (
          <BlockContainer
            className={classNames('u-overflowHidden', classes.sideSection)}
            type={BlockContainer.Type.WHITE}
          >
            <div className="u-floatLeft">
              <CartModifier className={classes.cartModifierIcon} />
            </div>
            <div className={classes.cartModifierText}>
              <TypographyCustom component="div" type="124" className={classes.cartModifierTitle}>
                {tr('order.catalog.cartModifier.title')}
              </TypographyCustom>
              <TypographyCustom component="div" type="167" className={classes.cartModifierNumber}>
                {tr('order.catalog.cartModifier.available', {
                  smart_count: cartModifiers.length,
                })}
              </TypographyCustom>
            </div>
            <div className={classes.cartModifierButton}>
              <ButtonLink
                classes={{ root: 'u-marginLeft10' }}
                onClick={() => {
                  displayCartModifierDialog(cartModifiers)
                }}
                label={tr('order.global.see')}
              />
            </div>
          </BlockContainer>
        )} */}

            {/* ------------------------------------------------------------ */}
            {/* Cart itel list (SMALL version)                               */}
            {/* ------------------------------------------------------------ */}

            <BlockContainer className="u-flex1" type={BlockContainer.Type.WHITE}>
              <TypographyCustom type="284" component="div" className={classes.sideInformationTitle}>
                {/*
                  display a different label for digital menu
                  https://www.notion.so/paymytable/Finalisation-du-Menu-Digital-2228f83526ba4e8897619991c1f9c393
                */}
                {appConfigPreset.isDigitalMenu
                  ? tr('order.cart.title_digital_menu')
                  : tr('order.cart.title')}
              </TypographyCustom>

              <CartItemList
                orderProperties={orderProperties}
                itemList={itemListFromCart}
                type={CartItemList.Type.SMALL}
                fromRoute={route}
                appConfigPreset={appConfigPreset}
              />
            </BlockContainer>

            {/* ------------------------------------------------------------ */}
            {/* cart footer                                                  */}
            {/* ------------------------------------------------------------ */}

            <BlockContainer type={BlockContainer.Type.LIGHTGREY}>
              <CartFooter
                appConfigPreset={appConfigPreset}
                orderProperties={orderProperties}
                itemList={itemListFromCart}
                comment={cartData.comment}
                fees={orderProperties.fees}
                minimumPrice={cartData.minimumPrice}
                minimumPriceFormatted={cartData.minimumPriceFormatted}
                restaurant={restaurant}
                totalCartPrice={cartData.totalCartPrice}
                totalCartPriceFormatted={cartData.totalCartPriceFormatted}
                totalCartPriceAndFeesFormatted={orderProperties.totalCartPriceAndFeesFormatted}
                onSubmitAction={onRedirectToCart}
                type={CartFooter.Type.SMALL}
              />
            </BlockContainer>
          </div>
        )}
      </AppConfigContainer>
    )
  }
}

SideInformation.defaultProps = {
  enableModifyTableNumber: true,
}

const mapStateToProps = (state) => ({
  itemListFromCart: forceGetItemListFromCart(state),
  cartData: getCartData(state),
  isFetchingCheck: isFetchingCheck(state),
})

export default compose(
  ui({
    state: {
      showTableNumberForm: false,
    },
  }),
  connect(mapStateToProps, {
    // displayCartModifierDialog,
  }),
  withStyles(styles)
)(SideInformation)
