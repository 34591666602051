//
// This module store global configuration and data for the order plugin,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { OrderPluginAction } from './actions'
import { EditUserAddressAction } from 'pmt-modules/userAddress'

import { GetCheckWithPosCheckIdAction } from 'pmt-modules/payment/check'

export * from './actions'
export * from './components'
export * from './constants'
export * from './selectors'
export * from './middlewares'

const DEFAULT = {
  isAsap: false,
  day: null,
  slot: null,
  tableNumber: null,
  disableModifyTableNumber: false,
  posCheckId: null,
  from: null,
  deliveryAddress: {
    id: null,
    name: null,
    complement: null,
    street: null,
    postCode: null,
    city: null,
    country: null,
    error: false,
  },
  selectedCategory: null,
  // used to find the first category that the user arrived
  // when no category id is filled. Eg : /catalog
  firstSelectedCategory: null,
  // /!\ the data for each category is not always up to date (eg. availabilities)
  // it would probably be better to just have an array containing the ids of the parentCategories here
  // and, when needed, retrieve the category object from the catalog (which is up to date with the availabilities)
  parentCategories: [],
  configurationError: null,
  paymentMethod: null,
  alcoholApprovalAnswer: null, // true if the user said he's authorized to buy alcohol
}

export const orderPluginReducer = (state = Immutable.fromJS(DEFAULT), action) => {
  switch (action.type) {
    case OrderPluginAction.SET_DUE_DATE_ASAP:
      return state.merge({
        isAsap: true,
        slot: null,
      })

    case OrderPluginAction.SELECT_DAY:
      return state.merge({
        day: action.date,
        slot: null,
        isAsap: false,
      })

    case OrderPluginAction.SELECT_SLOT:
      return state.merge({
        slot: action.slot,
        isAsap: false,
        // the action can override the day
        day: action.day ? action.day : state.get('day'),
      })

    case OrderPluginAction.SET_TABLE_NUMBER:
      return state.merge({
        tableNumber: action.tableNumber,
      })

    case OrderPluginAction.DISABLE_TABLE_NUMBER_MODIFICATION:
      return state.merge({
        disableModifyTableNumber: true,
      })

    // we don't put directly the posCheckId from query param to the state, because for some POS (like TCPOS)
    // the posCheckId we have in the url is not the same as the one we will put in Order.poscheckId
    // (ex: for TCPOS, in the url we have posCheckId=suspensionGuid, this suspensionGuid will be saved in Order.posCheckGuid, and Order.posCheckId will store the TCPOS order guid - which is the value we need to store in the state)
    // so we read the posCheckId from the Check we get from the API
    case GetCheckWithPosCheckIdAction.SUCCESS:
      return state.merge({
        posCheckId: action.response.posCheckId,
        posCheckGuid: action.response.posCheckGuid, // can be null
      })

    case OrderPluginAction.SET_DELIVERY_ADDRESS:
      return state.merge({
        deliveryAddress: {
          id: action.id,
          name: action.name,
          complement: action.complement,
          street: action.street,
          postCode: action.postCode,
          city: action.city,
          country: action.country,
          errorMessage: null,
        },
      })

    case EditUserAddressAction.SUCCESS:
      if (state.toJS().deliveryAddress && state.toJS().deliveryAddress.id === action.response.id) {
        return state.merge({
          deliveryAddress: {
            id: action.response.id,
            name: action.response.name,
            complement: action.response.complement,
            street: action.response.street,
            postCode: action.response.postCode,
            city: action.response.city,
            country: action.response.country,
            errorMessage: null,
          },
        })
      } else {
        return state
      }

    case OrderPluginAction.RESET_DELIVERY_ADDRESS:
      return state.merge({
        deliveryAddress: null,
      })

    case OrderPluginAction.SET_DELIVERY_ADDRESS_ERROR:
      return state.setIn(['deliveryAddress', 'errorMessage'], action.value)

    case OrderPluginAction.SET_CONFIGURATION_ERROR:
      return state.merge({
        configurationError: action.configurationError,
      })

    case OrderPluginAction.RESET_DAY_AND_SLOT:
      return state.merge({
        day: null,
        slot: null,
        isAsap: false,
      })

    case OrderPluginAction.SET_SELECTED_CATEGORY:
      return state.merge({
        selectedCategory: action.category,
      })

    case OrderPluginAction.SET_FIRST_SELECTED_CATEGORY:
      return state.merge({
        firstSelectedCategory: action.firstSelectedCategory,
      })

    case OrderPluginAction.RESET_SELECTED_CATEGORY:
      return state.merge({
        selectedCategory: null,
        firstSelectedCategory: null,
      })

    case OrderPluginAction.SET_PARENT_CATEGORIES:
      return state.merge({
        parentCategories: action.parentCategories || [],
      })

    case OrderPluginAction.BACK_TO_STORE_LOCATOR:
      return state.merge({
        isAsap: false,
        day: null,
        slot: null,
      })

    case OrderPluginAction.RESET:
      return state.merge(
        Immutable.fromJS({
          ...DEFAULT,
        })
      )

    case OrderPluginAction.SET_FROM:
      return state.merge({
        from: action.from,
      })

    case OrderPluginAction.ALCOHOL_ACCEPT:
      return state.merge({
        alcoholApprovalAnswer: true,
      })

    case OrderPluginAction.ALCOHOL_RESET:
      return state.merge({
        alcoholApprovalAnswer: null,
      })

    default:
      return state
  }
}
