import React from 'react'
import isFunction from 'lodash/isFunction'

import Tooltip from 'pmt-ui/Tooltip'

const CartModifierTag = ({ cartModifier, chipElement, key, tooltipMsg = '' }) => (
  <Tooltip
    key={key}
    title={tooltipMsg}
    placement="bottom"
    // on mobile, display the tooltip as soon as the CartModifierTag is touched (default value is 700ms, aka long touch)
    // stackoverflow.com/questions/69030402/material-ui-tooltip-is-not-working-on-mobile
    enterTouchDelay={0}
  >
    {/* By default disabled elements like <button> do not trigger user interactions so a Tooltip
        will not activate on normal events like hover. To accommodate disabled elements, add a simple
         wrapper element like a span.
         https://material-ui.com/components/tooltips/
 */}
    <span>{isFunction(chipElement) ? chipElement(cartModifier.tag, key) : chipElement}</span>
  </Tooltip>
)

export default CartModifierTag
