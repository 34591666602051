import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { getOrderProperties } from 'pmt-modules/orderPlugin'

import { withStyles } from 'pmt-ui/styles'
import { CardItem } from 'pmt-ui/Card'
import { CardItemOrder } from 'pmt-ui/Card/components/CardItem'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import StateMachine from 'pmt-ui/StateMachine'
import AlertButton from 'pmt-ui/AlertButton'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { saveAlcoholApproval, getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'

const styles = (theme) => {
  const cardItemDefaultStyles = CardItemOrder.defaultStyles(theme)
  return {
    mediaInactive: {
      ...cardItemDefaultStyles.mediaInactive,
    },
    title: {
      color: theme.palette.primary.main,
    },
    categoryName: {
      color: theme.palette.primary.main,
    },
    categoryIcon: {
      color: theme.palette.primary.main,
    },
    mediaPlaceholder: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.pmt.colors.itemPlaceholderBackgroundSecondary,
      },
    },
    cartModifierChip: {
      display: 'table',
      marginBottom: theme.spacing(0.5),
      cursor: 'pointer',
      borderRadius: 4,
    },
    cartModifierUnavailable: {
      opacity: 0.2,
    },
  }
}

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

const Item = ({
  classes,
  item,
  isCategory,
  restaurant,
  selectedCategory,
  onClickCard,
  orderProperties,
  addToCartFromShortcut,
  openMenu,
  openProduct,
  alreadySelectedQuantity,
  width,
  saveAlcoholApproval,
  frontSettings,
}) => {
  const displaySelectedQuantity = isWidthDown('sm', width) && alreadySelectedQuantity > 0
  return (
    <StateMachine
      states={State}
      default={State.NORMAL}
      transitions={[
        {
          state: State.ADDING_TO_CART,
          to: State.NORMAL,
          duration: 1000,
        },
      ]}
    >
      {({ currentState, transitionTo }) => {
        const addBtnLabel =
          currentState === State.NORMAL
            ? displaySelectedQuantity
              ? alreadySelectedQuantity
              : tr('order.catalog.add')
            : tr('order.catalog.added')

        const addBtnIcon =
          currentState === State.NORMAL && !displaySelectedQuantity ? (
            <ContentAdd />
          ) : (
            <ActionDone />
          )

        const processAddSimpleProductToCart = () => {
          transitionTo(State.ADDING_TO_CART)
          if (!item.hasSuggestion) {
            EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
              dueDate: orderProperties.dueDate,
              item,
              category: selectedCategory,
              restaurant,
            })
          }
          addToCartFromShortcut(item)
        }

        return (
          <CardItem
            type={<CardItemOrder />}
            classes={{
              media: classNames({}),
              title: classes.title,
              categoryName: classes.categoryName,
              categoryIcon: classes.categoryIcon,
              mediaPlaceholder: classes.mediaPlaceholder,
            }}
            // give item even if it is not used, to simplify debug on react dev tools
            item={item}
            description={item.description}
            image={item.image}
            restaurantLogo={!isNil(restaurant) ? restaurant.logo : null}
            name={item.name}
            price={item.priceFormatted}
            hasCartModifier={item.hasCartModifier}
            basePrice={item.hasCartModifier ? item.basePriceFormatted : null}
            cartModifierTag={(cartModifier, index) => (
              <CartModifierTag
                cartModifier={cartModifier}
                chipElement={
                  <Chip
                    key={index}
                    label={cartModifier.tag}
                    noBorder
                    classes={{
                      root: classNames(classes.cartModifierChip, {
                        [classes.cartModifierUnavailable]: !item.available,
                      }),
                    }}
                  />
                }
                key={index}
                overEventsEnabled={false}
              />
            )}
            isAvailable={item.available}
            isCategory={isCategory}
            onClickCard={() => {
              onClickCard(item)
            }}
            buttonAdd={
              frontSettings.display.ageCheckForAlcohol &&
              item.isProduct &&
              !item.isComplexItem &&
              item.containsAlcohol &&
              !orderProperties.alcoholApprovalAnswer ? (
                <CustomTextsContainer>
                  {({ texts }) => (
                    <AlertButton
                      button={<Button size="small" icon={addBtnIcon} />}
                      label={addBtnLabel}
                      onAgree={(e) => {
                        e.stopPropagation()
                        saveAlcoholApproval()
                        processAddSimpleProductToCart()
                      }}
                      alertTitle={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                          : tr('order.cart.age_check_for_alcohol.dialog.title')
                      }
                      alertContent={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                          : tr('order.cart.age_check_for_alcohol.dialog.description')
                      }
                      okLabel={
                        !isNil(texts) && !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                          : tr('order.cart.age_check_for_alcohol.dialog.ok')
                      }
                      cancelLabel={
                        !isNil(texts) &&
                        !isNil(texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL)
                          ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                          : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                      }
                    />
                  )}
                </CustomTextsContainer>
              ) : (
                <Button
                  size="small"
                  icon={addBtnIcon}
                  label={addBtnLabel}
                  onClick={(e) => {
                    e.stopPropagation()

                    // we don't allow to add an item while the transition "adding to cart" in in progress
                    // this will prevent unwanted double-click
                    if (!item.disableAdd && currentState !== State.ADDING_TO_CART) {
                      if (item.isComplexItem) {
                        if (item.isProduct) {
                          EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                            item,
                            category: selectedCategory,
                            restaurant,
                          })
                          openProduct(item, {
                            restaurantId: restaurant.id,
                            showDialog: false,
                          })
                        } else if (item.isMenu) {
                          EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
                            item,
                            category: selectedCategory,
                            restaurant,
                          })
                          openMenu(item, {
                            restaurantId: restaurant.id,
                          })
                        }
                      } else {
                        processAddSimpleProductToCart()
                      }
                    }
                  }}
                />
              )
            }
          />
        )
      }}
    </StateMachine>
  )
}

Item.defaultProps = {
  isCategory: false,
}

const mapStateToProps = (state) => ({
  orderProperties: getOrderProperties(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { saveAlcoholApproval }),
  withWidth()
)(Item)
