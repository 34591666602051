import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import last from 'lodash/last'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import { EventManager } from 'pmt-modules/event'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { setSelectedCategory, setParentCategories } from 'pmt-modules/orderPlugin'

import MobileHeader from 'pmt-ui/MobileHeader'
import { withStyles } from 'pmt-ui/styles'
import Select from 'pmt-ui/Select'
import { MenuItem } from 'pmt-ui/Menu'

import { findOnArray } from 'pmt-utils/array'

const styles = theme => ({
  root: {
    background: 'white',
  },
  formControl: {
    minWidth: 40,
  },
  separator: {
    width: 2,
    height: theme.pmt.header.contentHeight,
    background: theme.pmt.colors.grey300,
  },
  categorySelect: {
    maxWidth: '100%',
  },
  lineHeight16: {
    lineHeight: '16px',
  },
})

const SimpleCategory = ({ category, classes }) => (
  <span className={classes.lineHeight16}>{category.name}</span>
)

const SelectedCategory = ({ onSelect, category, parentCategories, classes }) => {
  // there is no category alongside our selected category, so no need to display a Select
  if (isEmpty(parentCategories)) {
    return <SimpleCategory category={category} classes={classes} />
  }

  const otherCategories = last(parentCategories).categories // we only want the categories that are alongside our current category, plus our current
  // category

  // there is no category alongside our selected category, so no need to display a Select
  if (isEqual(otherCategories.length, 1)) {
    return <SimpleCategory category={category} classes={classes} />
  }

  return (
    <Select
      disableUnderline
      // Note: we need to use the id as value, otherwise the 'selected' prop on MenuItem is set to
      // false, since Select can't compare objects.
      value={category && category.id}
      onChange={event =>
        onSelect(findOnArray(otherCategories, event.target.value), parentCategories)
      }
      // trick to display our current category name as value
      // we don't take any argument since it would be the value (category.id)
      renderValue={() => <span>{category.name}</span>}
      native={false}
      className={classes.categorySelect}
    >
      {otherCategories.map((otherCategory, index) => (
        <MenuItem
          // it could have same category multiple times (should not, but we ensure we don't
          // have a bug if it happens)
          key={`${index}_${otherCategory.id}`}
          // must put id
          value={otherCategory.id}
        >
          {otherCategory.name}
        </MenuItem>
      ))}
    </Select>
  )
}

// https://zpl.io/aRNqder
const CategoryMobileHeader = ({
  selectedCategory,
  parentCategories = [],
  restaurant,
  setSelectedCategory,
  setParentCategories,
  redirectTo,
  classes,
}) => (
  // hide the header when we are on mobile and are on the root category, since we display
  // the categories slider
  <MobileHeader
    hiddenMobile={isEmpty(parentCategories)}
    withBack
    classes={{
      root: classes.root,
    }}
    onBack={() => {
      let newParentCategories = []
      if (!isEmpty(parentCategories)) {
        newParentCategories = parentCategories.slice(0, -1)
      }
      EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
        category: last(parentCategories),
      })

      setParentCategories(newParentCategories)

      const parentCategory = last(parentCategories)
      redirectTo(getRoute('ORDER__CATEGORY'), {
        restaurantId: restaurant.id,
        categoryId: parentCategory.id,
      })
    }}
  >
    <SelectedCategory
      onSelect={(category, parents) => {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
          category,
        })
        setParentCategories(parents)
        redirectTo(getRoute('ORDER__CATEGORY'), {
          restaurantId: restaurant.id,
          categoryId: category.id,
        })
      }}
      category={selectedCategory}
      parentCategories={parentCategories}
      classes={classes}
    />
  </MobileHeader>
)

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      setSelectedCategory,
      setParentCategories,
      redirectTo,
    }
  )
)(CategoryMobileHeader)
