import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import invariant from 'invariant'
import Logger from 'pmt-utils/logger'
import { getQueryParam } from 'pmt-utils/url'
import { haveAuthCredentials } from 'pmt-modules/auth'
import {
  GetAsyncPaymentAction,
  isAsyncPaymentRedirectToPspResponse,
} from 'pmt-modules/asyncPayment'
import {
  setCart,
  setModificationDate,
  cartModifierActions,
  setMinimumPrice,
  CartAction,
} from 'pmt-modules/cart/actions'
import {
  getItemListFromCart,
  forceGetItemListFromCart,
  getItemListFromCartForOrderPreview,
  getCartData,
} from 'pmt-modules/cart/selectors'
import { ORDER_PREVIEW_DELAY } from 'pmt-modules/cart/constants'
import { CartDiffAction, setCartDiffItemList } from 'pmt-modules/cartDiff/actions'
import { getItemList as getItemListCartDiff } from 'pmt-modules/cartDiff/selectors'
import {
  getUnavailableItemsFromCart,
  removeUnavailableItemsFromList,
} from 'pmt-modules/cartDiff/utils'
import { GetCatalogAction } from 'pmt-modules/catalog/actions'
import { getCatalogFormatted } from 'pmt-modules/catalog/selectors'
import { PostPspDatasAction, resetUserCreditCardToPspSend } from 'pmt-modules/creditCard/actions'
import { PspRegisterUserCardType } from 'pmt-modules/creditCard/constants'
import { getCustomTexts } from 'pmt-modules/customTexts/actions'
import { EventManager } from 'pmt-modules/event'
import { OrderErrorCode, OrderMode } from 'pmt-modules/order/constants'
import { displayCartDiffItemsDialog } from 'pmt-modules/dialog'
import { resetFullGeolocation } from 'pmt-modules/geolocation'
import {
  OrderFrontAction,
  setRestaurantId,
  setDueDate,
  setPaymentMethod,
  savePostedOrderData,
} from 'pmt-modules/orderFront/actions'
import { OrderProductAction } from 'pmt-modules/orderProduct/actions'
import { OrderMenuAction } from 'pmt-modules/orderMenu/actions'
import { postOrder, PostOrderAction, resetPostOrder } from 'pmt-modules/orderPost'

import { resetOrderSettings } from 'pmt-modules/orderSettings/actions'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings/constants'
import { getOrderSettingsForMode } from 'pmt-modules/orderSettings/utils'
import {
  postOrderPreview,
  PostOrderPreviewAction,
  setOrderPreviewModificationDate,
} from 'pmt-modules/orderPreview/actions'
import { fetchCheckWithPosCheckId, GetCheckWithPosCheckIdAction } from 'pmt-modules/payment/check'
import { PaymentStatus } from 'pmt-modules/payment/constants'
import {
  getRestaurantAvailabilities,
  getForDay,
  isSlotInOpeningHours,
} from 'pmt-modules/orderSettings/utils'
import createMiddleware from 'pmt-modules/redux/createMiddleware'
import {
  RestaurantAction,
  GetRestaurantAction,
  setRestaurant,
} from 'pmt-modules/restaurant/actions'
import {
  RegisterIncognitoUserAction,
  RegisterUserLightAction,
} from 'pmt-modules/registration/actions'

import { RestaurantStatus } from 'pmt-modules/restaurant/constants'

import { makeGetRestaurant } from 'pmt-modules/restaurant/selectors'
import { getRoute, redirectTo, redirectToExternal, replaceWith } from 'pmt-modules/routing'
import { getCurrentTheme, changeCurrentTheme } from 'pmt-modules/theme'
import { PostUserAddressAction } from 'pmt-modules/userAddress/actions'
import { PutUserMeAction } from 'pmt-modules/userMe'
import { FetchUserUserAccountsAction, makeGetDefaultUserUserAccount } from 'pmt-modules/userAccount'

import {
  getMs,
  getMsFromHour,
  getMsFromMinute,
  getPmtDayIndex,
  getDateWithHoursAndMinutes,
} from 'pmt-utils/date'
import { getDistanceFromLatLon } from 'pmt-utils/geoloc'
import { createMuiThemeWithPmtRestaurantColors } from 'pmt-utils/theme/theme'
import { debounce } from 'pmt-utils/debounce'
import { setCurrency } from 'pmt-utils/currency'

import {
  setSelectedCategory,
  setParentCategories,
  showOrderModeDisabledDialog,
  showUnavailableDueDateDialog,
  showTableNotFoundDialog,
  setDueDateAsap,
  resetDayAndSlot,
} from '../actions'

import {
  OrderPluginAction,
  setDeliveryAddress,
  selectDay,
  selectSlot,
  setDeliveryAddressError,
  showProductDialog,
} from '../actions'
import { getAppConfigFrontSettings, getOrderData, getOrderProperties } from '../selectors'

import { userOrderPluginMiddlewares } from './user'
import { setTableNumber } from '..'

//
// Debounced to avoid too many calls.
//

const dispatchPostOrderPreview = (dispatch, getState, options = {}) => {
  const sendDate = getMs(moment())
  const itemList = getItemListFromCartForOrderPreview(getState())

  const {
    dueDate,
    dueDateMode,
    fees,
    mode,
    posCheckId,
    restaurantId,
    tableNumber,
    modifierCode,
    modifiers,
  } = getOrderProperties(getState())

  const formattedCatalog = getCatalogFormatted(getState())

  if (!isNil(mode) && !isNil(formattedCatalog) && !isNil(restaurantId)) {
    let orderPreview = {
      dueDateMode,
      itemList,
      sendDate,
      restaurantId,
      mode: parseInt(mode, 10),
      tableNumber,
      posCheckId,
      fees,
      modifierCode,
      modifiers,
    }

    if (!isNil(dueDate)) {
      orderPreview = { ...orderPreview, dueDate }
    }

    const hasAuthCredentials = haveAuthCredentials(getState())
    if (hasAuthCredentials) {
      const getDefaultUserUserAccount = makeGetDefaultUserUserAccount()
      const defaultUserAccount = getDefaultUserUserAccount(getState(), { userId: 'me' })

      if (defaultUserAccount) {
        orderPreview = {
          ...orderPreview,
          userAccountPayment: {
            userAccountId: defaultUserAccount.id,
          },
        }
      }
    }

    dispatch(postOrderPreview(orderPreview, formattedCatalog, options))
  }
}

const dispatchPostOrderPreviewWithDebounce = debounce(dispatchPostOrderPreview, ORDER_PREVIEW_DELAY)

/**
 * Intercepts all cart actions
 * and set cart's last modification date
 *
 * @returns {Function}
 */

export const catchCartActionMiddleware = createMiddleware(
  [
    ...cartModifierActions,
    CartAction.RESET_CART,
    FetchUserUserAccountsAction.SUCCESS,
    OrderFrontAction.ADD_MODIFIER_CODE,
  ],
  ({ getState, dispatch, next, action }) => {
    const modificationDate = getMs(moment())
    dispatch(setModificationDate(modificationDate))
    dispatch(setOrderPreviewModificationDate(modificationDate))

    // must be !== false because default true does not exists.
    // In some cases (see storeLoator page index) we do not want to make an order preview
    if (action.doOrderPreview !== false) {
      let options = {}
      if (action.type === FetchUserUserAccountsAction.SUCCESS) {
        options = {
          verifyUserAccount: true,
          getMaxGrantableAmount: true,
          getAdmissionFeesAmount: true,
        }
      }

      // do not do an order preview when we testLockedBeforePayment.
      // The goal is to test locked when we pay.
      // Here we would have an order preview made directly, and the item removed from the cart
      // before being on the payment page
      if (getQueryParam('testLockedBeforePayment') !== 'true') {
        dispatchPostOrderPreviewWithDebounce(dispatch, getState, options)
      }
    }
  }
)

export const setCartDetailsFromRestaurantMiddleware = createMiddleware(
  RestaurantAction.SET,
  ({ getState, dispatch, action }) => {
    const { mode } = getOrderProperties(getState())

    if (!isNil(mode) && !isNil(action.restaurant)) {
      const orderSettings = getOrderSettingsForMode(
        mode,
        action.restaurant.orderSettings.onSiteSettings,
        action.restaurant.orderSettings.takeAwaySettings,
        action.restaurant.orderSettings.deliverySettings
      )

      dispatch(setMinimumPrice(orderSettings.minimumPrice))
    }
  }
)

const setRestaurantIdOnRestaurantSet = createMiddleware(
  RestaurantAction.SET,
  ({ dispatch, getState, action }) => {
    const orderProperties = getOrderProperties(getState())

    if (isNil(orderProperties.restaurantId) && !isNil(action.restaurant)) {
      dispatch(setRestaurantId(action.restaurant.id))
    }
  }
)

const fetchCheckOnPosCheckIdSet = createMiddleware(
  OrderPluginAction.SET_POS_CHECK_ID,
  ({ dispatch, getState, action }) => {
    const orderProperties = getOrderProperties(getState())

    if (!isNil(action.posCheckId)) {
      dispatch(fetchCheckWithPosCheckId(orderProperties.restaurantId, action.posCheckId))
    }
  }
)

const setTableNumberOnFetchCheckSuccess = createMiddleware(
  GetCheckWithPosCheckIdAction.SUCCESS,
  ({ dispatch, getState, action }) => {
    const orderProperties = getOrderProperties(getState())

    if (isNil(orderProperties.tableNumber) && !isNil(action.response.tableNumber)) {
      dispatch(setTableNumber(action.response.tableNumber))
    }
  }
)

export const selectModeMiddleware = createMiddleware(
  OrderFrontAction.SELECT_MODE,
  ({ dispatch, action, getState }) => {
    const { mode } = getOrderProperties(getState())

    // after changing mode, reset geolocation to
    // 1. simplify code about geolocation
    // 2. avoid mess with differents modes allowing or not reverse geocoding / precise geolocation
    // 3. be sure the user fill the address he wants
    if (!isNil(action.mode) && mode !== action.mode) {
      dispatch(resetFullGeolocation())
    }
  }
)

const setFrontOrderDueDateMiddleware = createMiddleware(
  OrderPluginAction.SELECT_SLOT,
  ({ dispatch, getState, action }) => {
    invariant(!isNil(action.slot), `selectSlot action: slot is nil`)

    const { day } = getOrderProperties(getState())

    // when selecting a slot, a day can be define on the action (the day is not yet set on our reducer)

    // /!\ if you change this part:
    // test the cases with summer time changing at 3am (when ordering D-1 for D, and D for D - D being the day with time change)

    const dueDate = getDateWithHoursAndMinutes(action.day || day, action.slot)
    // if delivery, add delivery time. If not delivery, deliveryTime is 0 by default.
    // .add(deliveryTime, 'ms')

    const dueDateMs = parseInt(dueDate.valueOf(), 10)
    dispatch(setDueDate(dueDateMs))
  }
)

const resetFrontOrderDueDateMiddleware = createMiddleware(
  [OrderPluginAction.RESET_DUE_DATE, OrderPluginAction.SET_DUE_DATE_ASAP],
  ({ dispatch }) => {
    dispatch(setDueDate(null))
  }
)

const redirectToStoreLocatorWithRedirection = createMiddleware(
  OrderPluginAction.BACK_TO_STORE_LOCATOR,
  ({ dispatch }) => {
    dispatch(redirectTo(getRoute('ORDER__STORE_LOCATOR')))
  }
)

const resetOrderSettingsWhenResetingRestaurant = createMiddleware(
  OrderFrontAction.SET_RESTAURANT_ID,
  ({ action, dispatch, getState }) => {
    if (isNil(action.restaurantId)) {
      dispatch(resetOrderSettings())
    }

    const orderProperties = getOrderProperties(getState())
    if (orderProperties.forceAsap) {
      dispatch(setDueDateAsap())
    } else {
      dispatch(resetDayAndSlot())
    }
  }
)

// the due date is set here while arriving directly on the menu
// if the customer takes more time to order than the order delay
// the order's products has to be checked again
const setRestaurantAndDueDateWhenIsAsap = createMiddleware(
  GetRestaurantAction.SUCCESS,
  ({ action, dispatch, next, getState }) => {
    const restaurant = action.response
    const { mode, delay, day, isAsap } = getOrderProperties(getState())
    const restaurantAvailabilities = getRestaurantAvailabilities(restaurant, mode)

    if (
      restaurant.status === RestaurantStatus.ENABLED &&
      ((restaurant.orderSettings.onSiteSettings.enabled && mode === OrderMode.ON_SITE) ||
        (restaurant.orderSettings.takeAwaySettings.enabled && mode === OrderMode.TAKE_AWAY) ||
        (restaurant.orderSettings.deliverySettings.enabled && mode === OrderMode.DELIVERY)) &&
      !isEmpty(get(restaurantAvailabilities, 'availabilities', []))
    ) {
      // if we are in isAsap, we do not need to search for a date, but dispatch setDueDateAsap just
      // in case, to override potentially messed up data.
      if (isAsap) {
        dispatch(setDueDateAsap())
        return
      }

      // here we check that the user has not already set a day for his order
      // so we don't override it with a new due date
      if (!isNil(day)) {
        return
      }

      // try to find a default date to use: asap or the first available slot.

      const now = moment()
      const slot = getMsFromHour(now.format('HH')) + getMsFromMinute(now.format('mm'))
      const slotsForToday = getForDay(restaurant.orderSettings.openingHours, getPmtDayIndex(now))

      if (isSlotInOpeningHours(slotsForToday, slot, delay)) {
        // if now is in opening hours (restaurant is opened)
        dispatch(setDueDateAsap())
      } else {
        dispatch(selectDay(getMs(restaurantAvailabilities.availabilities[0].date)))
        dispatch(selectSlot(restaurantAvailabilities.availabilities[0].slots[0].value))
      }
    }
  }
)

const setRestaurantNullIfGetRestaurantFails = createMiddleware(
  GetRestaurantAction.FAILURE,
  ({ action, dispatch, getState }) => {
    dispatch(setRestaurant(null))
    dispatch(setRestaurantId(null))
  }
)

const setRestaurantWhenGetRestaurantSuccess = createMiddleware(
  GetRestaurantAction.SUCCESS,
  ({ action, dispatch }) => {
    setCurrency(action.response.currency)
    dispatch(setRestaurant(action.response))
  }
)

const setCurrencyWhenRestauranSet = createMiddleware(
  RestaurantAction.SET,
  ({ action, dispatch }) => {
    setCurrency(action.restaurant.currency)
  }
)

/**
 * Intercepts restaurant selection and update theme
 */
export const setRestaurantMiddleware = createMiddleware(
  RestaurantAction.SET,
  ({ getState, dispatch, next, action }) => {
    if (!isNil(action.restaurant)) {
      const currentTheme = getCurrentTheme(getState())
      const newTheme = createMuiThemeWithPmtRestaurantColors(currentTheme, action.restaurant.colors)

      const customTextsContainerId = action.restaurant.customContainers?.customTextsContainerId
      if (customTextsContainerId) {
        dispatch(getCustomTexts(customTextsContainerId))
      }

      next(changeCurrentTheme(newTheme))
    }
  }
)

const openProductMiddleware = createMiddleware(
  OrderProductAction.SELECT_PRODUCT,
  ({ dispatch, action }) => {
    if (!isNil(action.viewOptions.showDialog)) {
      if (!action.viewOptions.showDialog) {
        dispatch(
          redirectTo(getRoute('ORDER__PRODUCT'), {
            restaurantId: action.viewOptions.restaurantId,
            productId: action.product.id,
          })
        )
      } else {
        dispatch(showProductDialog(action.product, action.viewOptions))
      }
    }
  }
)

const openMenuMiddleware = createMiddleware(
  [OrderMenuAction.SELECT_MENU, OrderMenuAction.EDIT_MENU],
  ({ dispatch, action }) => {
    if (action.options.redirectToMenuPage) {
      dispatch(
        redirectTo(getRoute('ORDER__MENU'), {
          menuId: action.menu.id,
          restaurantId: action.options.restaurantId,
        })
      )
    }
  }
)

const redirectToPaymentWhileAddingANewAddressHasSucceeded = createMiddleware(
  PostUserAddressAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const { restaurant } = action.data
    const { id, name, complement, street, postCode, city, country } = action.response
    const rangeFromRestaurant = getDistanceFromLatLon(
      restaurant.geoPt.latitude,
      restaurant.geoPt.longitude,
      action.response.geoPt.latitude,
      action.response.geoPt.longitude
    )

    if (rangeFromRestaurant * 1000 <= restaurant.orderSettings.deliverySettings.maxRangeInMeters) {
      dispatch(setDeliveryAddress(id, name, complement, street, postCode, city, country))

      const frontSettings = getAppConfigFrontSettings(getState())
      if (frontSettings.display.paymentPage) {
        dispatch(redirectTo(getRoute('ORDER__PAYMENT')))
      } else {
        const orderData = getOrderData(getState())
        dispatch(setPaymentMethod(PaymentMethodsAllowed.IRL)) // this doesn't change the orderData we use for postOrder, so the API receive paymentMethod=null
        dispatch(postOrder(orderData))
      }
    } else {
      dispatch(setDeliveryAddressError(true))
    }
  }
)

const orderPostSuccessMiddleware = createMiddleware(
  PostOrderAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const cartItems = getItemListFromCart(getState())
    const cartData = getCartData(getState())
    const orderProperties = getOrderProperties(getState())
    const orderData = action.response

    dispatch(savePostedOrderData({ cartData, orderData, cartItems, orderProperties }))

    EventManager.dispatch(EventManager.Events.ON_PAYMENT_SUCCESS, {
      order: action.response,
    })

    dispatch(redirectTo(getRoute('ORDER__CONFIRM')))
  }
)

/**
 * When in 3DSecure mode, API will return an http code 303 along with the payment information.
 * The payment information contains a property payIn3DSecureUrl indicating where to redirect the
 * user to process the 3DSecure verification.
 */
const orderPostFailureMiddleware = createMiddleware(
  PostOrderAction.FAILURE,
  ({ action, dispatch, next }) => {
    if (action.error.code === '403') {
      try {
        console.log(btoa(JSON.stringify(action)))
        Logger.info('postOrder 403', 'posting order 403', {
          action,
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (isAsyncPaymentRedirectToPspResponse(action) && action.error.body.payment.asyncPaymentData) {
      next(action)
      return dispatch(redirectTo(getRoute('PAYMENT__ASYNC__FORM')))
    } else if (
      isAsyncPaymentRedirectToPspResponse(action) &&
      action.error.body.payment.asyncPaymentSecureUrl
    ) {
      dispatch(redirectToExternal(action.error.body.payment.asyncPaymentSecureUrl))
    } else if (get(action.error, 'code', null) === OrderErrorCode.INVALID_DUE_DATE) {
      dispatch(setDueDateAsap())
      dispatch(showUnavailableDueDateDialog())
    }
  }
)

const deleteCartItemMiddleware = createMiddleware(
  CartDiffAction.DELETE_ITEM_LIST,
  ({ getState, dispatch, action }) => {
    const cartDiffItemList = getItemListCartDiff(getState())
    const cartItemList = getItemListFromCart(getState())
    const cleanCartItemList = removeUnavailableItemsFromList(cartItemList, cartDiffItemList)

    dispatch(setCart({ itemList: cleanCartItemList }))

    // if we are on the payment page we want to redirect to the cart so the user can see its new
    // cart and check it before paying.
    if (window.location.pathname === '/payment') {
      if (cleanCartItemList.length === 0) {
        const orderProperties = getOrderProperties(getState())
        dispatch(
          replaceWith(getRoute('ORDER__CATALOG'), {
            restaurantId: orderProperties.restaurantId,
          })
        )
      } else {
        dispatch(replaceWith(getRoute('ORDER__CART')))
      }
    }
  }
)

/*
const resetTableNumberIfOnSiteAndOrderPreviewFails = createMiddleware(
  PostOrderPreviewAction.FAILURE,
  ({ action, dispatch, getState }) => {
    const orderProperties = getOrderProperties(getState())
    if (orderProperties.isOnSite && action.error.code === '402') {
      dispatch(setTableNumber(null))
    }
  }
)
*/

/**
 * Intercepts update user SUCCESS action
 */
const updateProfileSuccess = createMiddleware(PutUserMeAction.SUCCESS, ({ next, action }) => {
  const props = action.data.props || {}

  if (!isEmpty(props.redirectTo)) {
    return next(replaceWith(props.redirectTo, props.redirectToParams))
  }

  return next(action)
})

/**
 * @returns {Function}
 */
const postOrderAfterPostPspDatas = createMiddleware(
  PostPspDatasAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    let orderData = getOrderData(getState())
    // TODO: payment is null, see orderPlugin/utils/generateOrderData -> paymentMethod is null
    orderData.payment.cardId = action.response.id

    dispatch(postOrder(orderData))
  }
)

/**
 * We get the payment after a 3DSecure transaction in order to verify its status
 * on success, we redirect to payment confirmation page
 */
const finalizeAsyncPaymentSuccess = createMiddleware(
  GetAsyncPaymentAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    // the API can gives us a success with an error model on it to avoid spamming error logs.
    // But the real success is when we have the payment data
    if (action.response.isFinalized && action.response.payment?.status === PaymentStatus.PAID) {
      setCurrency(action.response.payment.currency)
      const cartItems = forceGetItemListFromCart(getState()) // force re-formatting, so the currency is taken into account
      const cartData = getCartData(getState())
      const orderProperties = getOrderProperties(getState())
      const orderData = { ...action.response.linkedObject, payment: action.response.payment }

      dispatch(savePostedOrderData({ cartData, orderData, cartItems, orderProperties }))
      dispatch(redirectTo(getRoute('ORDER__CONFIRM')))
    }
  }
)

const finalizeAsyncPaymentFailure = createMiddleware(
  GetAsyncPaymentAction.FAILURE,
  ({ action, dispatch }) => {
    if (action.error) {
      dispatch(redirectTo(getRoute('ORDER__PAYMENT')))
    }
  }
)

const resetStatusUserCreditCardToPspSend = createMiddleware(
  [PostPspDatasAction.FAILURE, PostOrderAction.FAILURE],
  ({ dispatch, getState }) => {
    const orderProperties = getOrderProperties(getState())
    const getRestaurant = makeGetRestaurant()
    const restaurant = getRestaurant(getState(), { restaurantId: orderProperties.restaurantId })

    if (restaurant && restaurant.pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES) {
      dispatch(resetUserCreditCardToPspSend())
    }
  }
)

const getCatalogFromDueDateMiddleware = createMiddleware(
  GetCatalogAction.SUCCESS,
  ({ getState, dispatch, action, next }) => {
    const { isAsap, slot } = getOrderProperties(getState())
    const cartItemList = getItemListFromCart(getState())

    // finalize action in order to wait it has fully ended (i.e: data is in reducer)
    // so we can getUnavailableItemsFromCart with a catalog fetched and formatable
    next(action)

    if (!isEmpty(cartItemList)) {
      const formattedCatalog = getCatalogFormatted(getState())
      const unavailableItems = getUnavailableItemsFromCart(cartItemList, formattedCatalog)

      if (getQueryParam('testLockedBeforePayment') !== 'true') {
        dispatch(setCartDiffItemList(unavailableItems))

        if (!isEmpty(unavailableItems)) {
          dispatch(displayCartDiffItemsDialog())

          // to put out, on submit on cart diff dialog
          if (isAsap) {
            dispatch(setDueDateAsap())
          } else {
            dispatch(selectSlot(slot))
          }
          return
        }
      }
    }
  }
)

const postOrderPreviewFailure = createMiddleware(
  PostOrderPreviewAction.FAILURE,
  ({ getState, action, dispatch }) => {
    // handle api errors that will make the order impossible to finish (restaurant / mode disabled)
    // or the due date should be changed.

    if (window.location.pathname !== '/confirm') {
      switch (get(action.error, 'code', null)) {
        case OrderErrorCode.INVALID_DUE_DATE:
          dispatch(showUnavailableDueDateDialog())
          break
        case OrderErrorCode.ORDER_MODE_DISABLED:
          dispatch(showOrderModeDisabledDialog())
          break
        case OrderErrorCode.TABLE_NOT_FOUND:
          const orderProperties = getOrderProperties(getState())
          dispatch(showTableNotFoundDialog(orderProperties.restaurant.id))
          break

        default:
          break
      }
    }
  }
)

const resetCategoryWhileGoBackToStoreLocator = createMiddleware(
  OrderPluginAction.BACK_TO_STORE_LOCATOR,
  ({ dispatch, action }) => {
    dispatch(setSelectedCategory(null))
    dispatch(setParentCategories([]))
  }
)

const resetOrderPluginMiddleware = createMiddleware(OrderPluginAction.RESET, ({ dispatch }) => {
  // TODO: move all reseters here?
  dispatch(resetPostOrder())
})

const registeredUserLightOrIncognitoSuccessOrderPluginMiddleware = createMiddleware(
  [RegisterUserLightAction.SUCCESS, RegisterIncognitoUserAction.SUCCESS],
  ({ getState, action, dispatch, next }) => {
    // saving incognito/userlight cookie and using redirectToParam if any is done in the common registrationMiddleware
    // we want it to be made now, before we do anything else
    next(action)

    // and then, here, we handle the redirections specific to the order plugin
    const frontSettings = getAppConfigFrontSettings(getState())
    const orderProperties = getOrderProperties(getState())
    const orderData = getOrderData(getState())
    if (frontSettings.display.paymentPage) {
      if (orderProperties.isDelivery) {
        dispatch(redirectTo(getRoute('ORDER__DELIVERY_ADDRESS')))
      } else {
        dispatch(redirectTo(getRoute('ORDER__PAYMENT')))
      }
    } else {
      // not displaying paymentPage
      // so we can now send the order
      dispatch(setPaymentMethod(PaymentMethodsAllowed.IRL)) // this doesn't change the orderData we use for postOrder, so the API receive paymentMethod=null
      if (orderProperties.isDelivery) {
        dispatch(redirectTo(getRoute('ORDER__DELIVERY_ADDRESS')))
      } else {
        dispatch(postOrder(orderData))
      }
    }

    return createMiddleware.STOP_PROPAGATION
  }
)

const postOrderPreviewAndPostOrderIfSucceededMiddleware = createMiddleware(
  [OrderPluginAction.POST_ORDER_PREVIEW_AND_POST_ORDER_IF_SUCCEEDED],
  ({ getState, dispatch }) => {
    dispatchPostOrderPreview(dispatch, getState, {
      postOrderIfSucceeded: true,
    })
  }
)

// order preview succeeded, and action flagged as postOrderIfSucceeded, we post the order.
// The goal is to do an order preview before sending the order, to avoid having an error after
// the payment when we send the order to the pos.
const postOrderPreviewSucceededAndPostOrderIfSucceededMiddleware = createMiddleware(
  PostOrderPreviewAction.SUCCESS,
  ({ getState, dispatch, action }) => {
    // hack for the moment: the order preview always fails with SQS due to invalid ID mapping
    // on menu parts
    const isBuffaloGrill = window.location.host.includes('buffalo-grill')
    const isOrderPreviewSuccess =
      action.data.options.postOrderIfSucceeded &&
      action.response.succeeded &&
      // succeeded  is true even if verifications.availability.succeeded is false
      action.response.verifications.availability.succeeded
    if (action.data.options.postOrderIfSucceeded && (isBuffaloGrill || isOrderPreviewSuccess)) {
      const orderData = getOrderData(getState())
      dispatch(postOrder(orderData))
    } else {
      // cardDiff postOrderPreviewSuccessMiddleware will handle cart modification
    }
  }
)

export const orderPluginMiddlewares = [
  selectModeMiddleware,
  setRestaurantIdOnRestaurantSet,
  fetchCheckOnPosCheckIdSet,
  setTableNumberOnFetchCheckSuccess,
  setRestaurantWhenGetRestaurantSuccess,
  setCurrencyWhenRestauranSet,
  redirectToStoreLocatorWithRedirection,
  setRestaurantAndDueDateWhenIsAsap,
  finalizeAsyncPaymentSuccess,
  finalizeAsyncPaymentFailure,
  redirectToPaymentWhileAddingANewAddressHasSucceeded,
  orderPostSuccessMiddleware,
  orderPostFailureMiddleware,
  setRestaurantMiddleware,
  setRestaurantNullIfGetRestaurantFails,
  openProductMiddleware,
  openMenuMiddleware,
  postOrderPreviewFailure,
  deleteCartItemMiddleware,
  // comment the code beyond because the cashpad system does not handle right the table number
  // resetTableNumberIfOnSiteAndOrderPreviewFails,
  ...userOrderPluginMiddlewares,
  updateProfileSuccess,
  postOrderAfterPostPspDatas,
  resetStatusUserCreditCardToPspSend,
  catchCartActionMiddleware,
  setCartDetailsFromRestaurantMiddleware,
  resetOrderSettingsWhenResetingRestaurant,
  getCatalogFromDueDateMiddleware,
  resetCategoryWhileGoBackToStoreLocator,
  setFrontOrderDueDateMiddleware,
  resetFrontOrderDueDateMiddleware,
  resetOrderPluginMiddleware,
  registeredUserLightOrIncognitoSuccessOrderPluginMiddleware,
  postOrderPreviewAndPostOrderIfSucceededMiddleware,
  postOrderPreviewSucceededAndPostOrderIfSucceededMiddleware,
]
