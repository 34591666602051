import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import RoutingContainer from 'pmt-modules/routing/components/RoutingContainer'

import { makeStyles } from '@material-ui/core/styles'

import Grid from 'pmt-ui/Grid'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Hidden from 'pmt-ui/Hidden'
import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import HardwareKeyboardArrowLeft from 'pmt-ui/svg-icons/hardware/keyboard-arrow-left'

import BlockContainer from '../../components/BlockContainer'
import SideSummary from '../../components/SideSummary'
import Cart from './components/Cart'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px 0`,
    },
  },
  blockContainerPrimary: {
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
  },
  gridInverse: {
    flexDirection: 'row-reverse',
  },
  backToMenu: {
    lineHeight: '22px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const View = ({
  appConfigPreset,
  isFetching,
  route,
  handleCartSubmission,
  orderProperties,
  orderAppConfig,
  restaurant,
  width,
  errorPostOrder,
}) => {
  const classes = useStyles()

  let modeInformationText = false
  let modeInformationTooltipEnabled = false
  switch (true) {
    case orderProperties.isDelivery:
      modeInformationText = (
        <React.Fragment>{tr('order.sideSummary.deliveryModeInformation')}</React.Fragment>
      )
      modeInformationTooltipEnabled = true
      break
    case orderProperties.isOnSite:
      modeInformationText = orderAppConfig.hasTableNumber && (
        <React.Fragment>{tr('order.sideSummary.onSiteModeInformation')}</React.Fragment>
      )
      modeInformationTooltipEnabled = orderAppConfig.hasTableNumber
      break
    default:
      modeInformationText = false
      break
  }

  const shouldDisplayBreadCrumb = appConfigPreset.isPluginOrder
  const shouldDisplayComment = orderAppConfig.comments.global.enabled
  const shouldDisplaySummary = appConfigPreset.isPluginOrder
  const shouldEnableSubmitCart = appConfigPreset.isPluginOrder

  return (
    <RoutingContainer>
      {({ redirectTo, getRoute }) => (
        <div className={classes.root}>
          <Grid container spacing={0} className={classes.gridInverse}>
            <Grid item xs={12} md={shouldDisplaySummary && 4} lg={shouldDisplaySummary && 3}>
              <Hidden mdUp={appConfigPreset.isPluginOrder}>
                <BlockContainer>
                  <TypographyCustom
                    type="164"
                    component="p"
                    className={classNames(classes.backToMenu)}
                    onClick={() => {
                      if (!isNil(restaurant)) {
                        redirectTo(getRoute('ORDER__CATALOG'), {
                          restaurantId: restaurant.id,
                        })
                      } else {
                        redirectTo(getRoute('ORDER__STORE_LOCATOR'))
                      }
                    }}
                  >
                    <HardwareKeyboardArrowLeft className="u-floatLeft u-marginRight10" />
                    {tr('order.sideSummary.back_to_catalog')}
                  </TypographyCustom>
                </BlockContainer>
              </Hidden>
              {shouldDisplaySummary && (
                <SideSummary
                  classes={{ root: classNames({ 'u-block': isWidthDown('sm', width) }) }}
                  orderProperties={orderProperties}
                  restaurant={restaurant}
                  modeInformationText={modeInformationText}
                  modeInformationTooltipEnabled={modeInformationTooltipEnabled}
                />
              )}
            </Grid>
            <Grid item xs={12} md={shouldDisplaySummary && 8} lg={shouldDisplaySummary && 9}>
              <LoadingBlockWrapper show={isFetching} />
              <Cart
                appConfigPreset={appConfigPreset}
                orderAppConfig={orderAppConfig}
                orderProperties={orderProperties}
                restaurant={restaurant}
                handleCartSubmission={handleCartSubmission}
                route={route}
                shouldDisplayBreadCrumb={shouldDisplayBreadCrumb}
                shouldDisplayComment={shouldDisplayComment}
                shouldEnableSubmitCart={shouldEnableSubmitCart}
                errorPostOrder={errorPostOrder}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </RoutingContainer>
  )
}

export default withWidth()(View)
