import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import { tr } from 'pmt-modules/i18n'

import { EventManager } from 'pmt-modules/event'
import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'
import { resetRestaurantList } from 'pmt-modules/restaurant'
import { setRestaurantId } from 'pmt-modules/orderFront'
import { isAddressTypeValid } from 'pmt-modules/userAddress'
import {  getAppConfigFrontSettings} from 'pmt-modules/orderPlugin'
import { getAddressElementsFromGooglePlace } from 'pmt-utils/google'

import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import Hidden from 'pmt-ui/Hidden'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { CircularProgress } from 'pmt-ui/Progress'
import MapsMyLocation from 'pmt-ui/svg-icons/maps/my-location'
import ErrorBlock from 'pmt-ui/ErrorBlock'

import Button, { ButtonLink } from 'app/components/Button'
import BlockContainer from 'app/components/BlockContainer'
import SearchAddress from 'app/components/SearchAddress'
import MessageBlock from 'app/components/MessageBlock'

import RestaurantList from './RestaurantList'

const styles = theme => ({
  topBlockContainer: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
  },
  bottomBlockContainer: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  restaurantGoogleMap: {
    width: '100%',
    height: 300,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  restaurantListTitle: {
    padding: `${theme.spacing(2)}px`,
    paddingBottom: 0,
    textTransform: 'uppercase',
    color: theme.pmt.colors.grey500,
    background: theme.pmt.colors.grey100,
  },
  geolocButton: {
    float: 'left',
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    color: theme.pmt.status.error,
  },
  hideDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  hideSmallMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonNext: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bigButton: {
    width: '100%',
  },
  stickyButton: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    marginTop: theme.spacing(1),
    borderRadius: 0,
    zIndex: theme.zIndex.stickyButton,
  },
  inputFontImportant: {
    fontSize: '20px !important',
    '&:focus': {
      fontSize: '20px !important',
    },
  },
  restaurantUnavailable: {
    padding: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
})

const WhereSection = ({
  ui,
  updateUI,
  orderProperties,
  restaurant,
  restaurantError,
  isFetchingRestaurantList,
  loadRestaurantList,
  restaurantListData,
  restaurantListError,
  resetRestaurantList,
  setRestaurantId,
  classes,
  width,
  frontSettings,
}) => {
  // use reverse geocoding only when user clicks "geolocate me" button
  const [shouldReverseGeocoding, setShouldReverseGeocoding] = React.useState(false)

  const restaurantList = restaurantListData?.restaurants

  React.useEffect(() => {
    if (isNil(restaurant) && !isNil(restaurantList) && restaurantList.length === 1) {
      setRestaurantId(restaurantList[0].id)
    }
  }, [restaurant, restaurantList, setRestaurantId])

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={7}
      // this help us to handle the hidden parts of mobile display #responsive
      className={classNames('u-paddingTop0', {
        [classes.hideMobile]: ui.displaySecondPart,
        [classes.showMobile]: !ui.displaySecondPart,
      })}
    >
      <GeolocationContainer
        successGeolocationCallback={({ latitude, longitude }) => {
          setRestaurantId(null)
          resetRestaurantList()

          loadRestaurantList({ lat: latitude, lng: longitude })
        }}
      >
        {({
          geolocationId,
          geolocateUser,
          geolocationAddress,
          geolocationComputeAddress,
          geolocationError,
          geolocationLatitude,
          geolocationLongitude,
          geolocationOrigin,
          isFetchingGeolocation,
          resetGeolocation,
          setGeolocationAddress,
          setGeolocationComputeAddress,
          setGeolocationComputeAddressExtra,
          setGeolocationCoordinates,
        }) => {
          const calculateIsAddressValid = ({ addressElements, types }) => {
            if (isNil(addressElements)) {
              return false
            }

            const shouldAskForValidType = orderProperties.isDelivery
            if (shouldAskForValidType) {
              return isAddressTypeValid(types)
            }

            return true
          }

          const handleSelectLocation = autocomplete => {
            const place = autocomplete.getPlace()

            const types = place.types
            const addressElements = getAddressElementsFromGooglePlace(place)

            const isAddressValid = calculateIsAddressValid({ addressElements, types })

            updateUI({
              addressLocationNotGood: !isAddressValid,
            })

            if (isUndefined(place.geometry)) {
              return
            }
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()

            resetRestaurantList()

            setRestaurantId(null)
            if (isAddressValid) {
              setGeolocationCoordinates(lat, lng)
              loadRestaurantList({ lat, lng })
            }

            setGeolocationComputeAddress(addressElements)
            setGeolocationComputeAddressExtra(types)
            setGeolocationAddress(null)
          }

          const handleReverseGeocodingSuccess = ({ addressElements, types }) => {
            const isAddressValid = calculateIsAddressValid({ addressElements, types })

            updateUI({
              addressLocationNotGood: !isAddressValid,
            })

            setGeolocationComputeAddress(addressElements)
            setGeolocationComputeAddressExtra(types)
            setGeolocationAddress(null)

            // reverse geocoding is done, we reset its state
            setShouldReverseGeocoding(false)
          }

          return (
            <React.Fragment>
              <BlockContainer
                type={BlockContainer.Type.WHITE}
                className={classes.topBlockContainer}
              >
                <TypographyCustom type={isWidthDown('sm', width) ? 247 : 287}>
                  {tr(`order.store_locator.catch_phrase.${orderProperties.mode}`)}
                </TypographyCustom>
                <div className="u-marginBottom20 u-marginTop10 u-overflowHidden">
                  <SearchAddress
                    geolocationId={geolocationId}
                    label={tr(`order.store_locator.address_field.label.${orderProperties.mode}`)}
                    placeholder={tr(
                      `order.store_locator.address_field.placeholder.${orderProperties.mode}`
                    )}
                    inputClassName={classes.inputFontImportant}
                    selectLocationCallback={handleSelectLocation}
                    // use reverse geocoding only when user clicks "geolocate me" button
                    reverseGeocoding={shouldReverseGeocoding}
                    reverseGeocodingSuccessCallback={handleReverseGeocodingSuccess}
                    resetAddressCallback={() => {
                      if (ui.addressLocationNotGood) {
                        updateUI({ addressLocationNotGood: false })
                      }

                      resetGeolocation()
                      resetRestaurantList()
                      // if the user clears its address, we have to reset the restaurant too.
                      setRestaurantId(null)
                    }}
                    geolocationLatitude={geolocationLatitude}
                    geolocationLongitude={geolocationLongitude}
                    geolocationOrigin={geolocationOrigin}
                    geolocationAddress={geolocationAddress}
                    geolocationComputeAddress={geolocationComputeAddress}
                    countryRestriction={frontSettings.geoApiCountryRestriction}
                    geolocationError={geolocationError}
                    error={ui.addressLocationNotGood}
                    errorText={tr('order.addresses.form.error_message.address')}
                    onChangeCallback={event => {
                      setGeolocationAddress(event.target.value)
                      if (!isNil(geolocationComputeAddress)) {
                        setGeolocationComputeAddress(null)
                        setGeolocationComputeAddressExtra(null)
                      }

                      if (ui.addressLocationNotGood) {
                        updateUI({ addressLocationNotGood: false })
                      }
                    }}
                  />
                  <ButtonLink
                    classes={{ root: classes.geolocButton }}
                    disabled={
                      isFetchingGeolocation ||
                      isFetchingRestaurantList ||
                      isNil(orderProperties.mode)
                    }
                    icon={isFetchingGeolocation ? <CircularProgress /> : <MapsMyLocation />}
                    label={tr('order.store_locator.geolocate_me')}
                    onClick={() => {
                      EventManager.dispatch(EventManager.Events.ON_STORE_LOCATOR_GEOLOCATION, {
                        mode: orderProperties.mode,
                      })
                      // we need to use reverse geocoding now
                      setShouldReverseGeocoding(true)
                      geolocateUser()
                    }}
                  />
                </div>
              </BlockContainer>
              <BlockContainer
                type={BlockContainer.Type.WHITE}
                className={classes.bottomBlockContainer}
              >
                <div className="u-relative">
                  <Hidden smDown>
                    <LoadingBlock show={isFetchingRestaurantList} />
                  </Hidden>

                  {restaurantError && !isFetchingRestaurantList && (
                    <div className={classes.restaurantUnavailable}>
                      <ErrorBlock
                        error={restaurantError}
                        mode={ErrorBlock.Mode.CUSTOM}
                        customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                      />
                    </div>
                  )}

                  {/*
                    Restaurant list
                    not displayed in delivery because the slot make the restaurant selection
                  */}
                  {((orderProperties.isDelivery && geolocationComputeAddress) ||
                    !orderProperties.isDelivery) &&
                    !isNil(restaurantList) &&
                    !isFetchingRestaurantList &&
                    isNil(restaurantListError) && (
                      <React.Fragment>
                        {!isEmpty(restaurantList) && (
                          <TypographyCustom
                            type="124"
                            component="p"
                            className={classes.restaurantListTitle}
                          >
                            {/* spec: https://www.notion.so/paymytable/Am-liorer-l-affichage-des-r-sultats-du-store-locator-2669fffd951f483280655bcbb2124fc6# */}
                            {restaurantListData?.geoPtIsLowAccuracy &&
                              restaurantListData.addressFromRequest &&
                              tr('order.store_locator.restaurant_list.counter_with_address', {
                                smart_count: restaurantList.length,
                                address: restaurantListData.addressFromRequest,
                              })}

                            {restaurantListData?.geoPtIsLowAccuracy &&
                              !restaurantListData.addressFromRequest &&
                              tr('order.store_locator.restaurant_list.counter', {
                                smart_count: restaurantList.length,
                              })}

                            {!restaurantListData?.geoPtIsLowAccuracy &&
                              geolocationComputeAddress &&
                              tr('order.store_locator.restaurant_list.counter_with_address', {
                                smart_count: restaurantList.length,
                                address: geolocationComputeAddress.formattedAddress,
                              })}
                          </TypographyCustom>
                        )}
                        <RestaurantList
                          restaurantListData={restaurantListData}
                          isFetching={isFetchingRestaurantList}
                          orderProperties={orderProperties}
                          restaurantSelected={restaurant}
                        />
                      </React.Fragment>
                    )}
                  {!isNil(restaurantListError) && (
                    <div className="u-marginTop20 u-marginBottom20">
                      <ErrorBlock
                        error={restaurantListError}
                        mode={ErrorBlock.Mode.CUSTOM}
                        customElement={<MessageBlock type={MessageBlock.Type.ERROR} />}
                      />
                    </div>
                  )}
                </div>
                <Hidden mdUp smDown={isNil(restaurant)}>
                  <Button
                    size="large"
                    classes={{ root: classes.stickyButton }}
                    label={tr('order.global.continue')}
                    onClick={() => {
                      updateUI({
                        displaySecondPart: true,
                      })
                    }}
                  />
                </Hidden>
              </BlockContainer>
            </React.Fragment>
          )
        }}
      </GeolocationContainer>
    </Grid>
  )
}


const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    setRestaurantId,
    resetRestaurantList,
  }),
  withStyles(styles),
  withWidth()
)(WhereSection)
