import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import compose from 'recompose/compose'

import { EventManager } from 'pmt-modules/event'
import { FormType, resetForm, withForm } from 'pmt-modules/form'
import GeolocationContainer from 'pmt-modules/geolocation/container/GeolocationContainer'
import EditUserAddressFormView from 'pmt-modules/userAddress/forms/edit/EditUserAddressFormView'
import { isAddressTypeValid, editUserAddress, deleteUserAddress } from 'pmt-modules/userAddress'
import {  getAppConfigFrontSettings} from 'pmt-modules/orderPlugin'

import { getAddressElementsFromGooglePlace } from 'pmt-utils/google'
import { withFormattedAddress } from 'pmt-utils/address'

import Grid from 'pmt-ui/Grid'
import { CircularProgress } from 'pmt-ui/Progress'
import MapsMyLocation from 'pmt-ui/svg-icons/maps/my-location'

import SearchAddress from '../SearchAddress'
import Button, { ButtonLink } from '../Button'

class GeolocationAddress extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isGeolocating: false,
      addressLocationNotGood: false,
    }

    props.resetGeolocation()



    props.setGeolocationComputeAddress(withFormattedAddress(
      {
        street: props.formData.street,
        postCode: props.formData.postCode,
        city: props.formData.city,
        country: props.formData.country,
      }
    )
    )
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.formData, this.props.formData)) {
      nextProps.setGeolocationComputeAddress(
        withFormattedAddress(
        {
          street: nextProps.formData.street,
          postCode: nextProps.formData.postCode,
          city: nextProps.formData.city,
          country: nextProps.formData.country,
        })
      )
    }
  }

  handleReverseGeocodingSuccess = ({ addressElements, types }) => {
    if (this.handleAddressEdition({ addressElements, types })) {
      this.props.setGeolocationComputeAddress(addressElements)
      this.props.setGeolocationComputeAddressExtra(types)
      this.props.setGeolocationAddress(null)
    }

    // reverse geocoding is done, we reset its state
    this.setState({ ...this.state, isGeolocating: false })
  }

  handleSelectLocation = autocomplete => {
    const place = autocomplete.getPlace()

    if (isUndefined(place.geometry)) {
      return
    }
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    this.props.setGeolocationCoordinates(lat, lng)
    const addressElements = getAddressElementsFromGooglePlace(place)
    this.props.setGeolocationComputeAddress(addressElements)
    this.props.setGeolocationComputeAddressExtra(place.types)
    this.props.setGeolocationAddress(null)

    this.handleAddressEdition({addressElements, types: place.types})
  }

  handleAddressEdition = ({ addressElements, types }) => {
    if (isAddressTypeValid(types)) {
      if (!isNil(addressElements)) {

        const { street, postCode, city, country } = addressElements

        this.setState({ ...this.state, addressLocationNotGood: false })

        this.props.onChange({
          ...this.props.formData,
          street,
          postCode,
          city,
          country,
        })

        return true
      } else {
        this.resetAddressFormDatas()
        this.setState({ ...this.state, addressLocationNotGood: true })
        return false
      }
    }

    this.setState({ ...this.state, addressLocationNotGood: true })
    return false
  }

  resetAddressFormDatas = () => {
    this.props.onChange({
      ...this.props.formData,
      street: null,
      postCode: null,
      city: null,
      country: null,
    })
  }

  render() {
    // we only want the address to be reverse geocoded when the user click on "geolocate me".
    // Otherwise GoogleMapsReverseGeocoding will change the adress to the current location on mount.
    const { addressLocationNotGood, isGeolocating } = this.state

    const {
      classes,
      geolocationId,
      geolocateUser,
      geolocationAddress,
      geolocationComputeAddress,
      geolocationError,
      geolocationLatitude,
      geolocationLongitude,
      geolocationOrigin,
      isFetchingGeolocation,
      resetGeolocation,
      setGeolocationAddress,
      setGeolocationComputeAddress,
      setGeolocationComputeAddressExtra,
      countryRestriction,
    } = this.props

    if (!geolocationComputeAddress) {
      // TODO: mini lag, add loading or do not care ? Maybe only on dev mode.
    }

    return (
      <React.Fragment>
        <SearchAddress
          label={tr('order.addresses.form.edit.labels.address')}
          placeholder={tr('order.addresses.form.edit.placeholders.address')}
          error={addressLocationNotGood}
          errorText={tr('order.addresses.form.error_message.address')}
          geolocationAddress={geolocationAddress}
          geolocationComputeAddress={geolocationComputeAddress}
          geolocationError={geolocationError}
          geolocationId={geolocationId}
          geolocationLatitude={geolocationLatitude}
          geolocationLongitude={geolocationLongitude}
          geolocationOrigin={geolocationOrigin}
          onChangeCallback={event => {
            setGeolocationAddress(event.target.value)
            if (!isNil(geolocationComputeAddress)) {
              setGeolocationComputeAddress(null)
              setGeolocationComputeAddressExtra(null)
            }

            if (this.state.addressLocationNotGood) {
              this.setState({ ...this.state, addressLocationNotGood: false })
            }
          }}
          reverseGeocoding={isGeolocating}
          reverseGeocodingSuccessCallback={this.handleReverseGeocodingSuccess}
          resetAddressCallback={() => {
            this.resetAddressFormDatas()
            resetGeolocation()
          }}
          selectLocationCallback={this.handleSelectLocation}
          countryRestriction={countryRestriction}
        />
        <ButtonLink
          label={tr('order.store_locator.geolocate_me')}
          disabled={isFetchingGeolocation}
          icon={isFetchingGeolocation ? <CircularProgress /> : <MapsMyLocation />}
          classes={{ root: classes.geolocButton }}
          onClick={() => {
            this.setState({ ...this.state, isGeolocating: true })
            EventManager.dispatch(EventManager.Events.ON_DELIVERY_ADDRESS_EDIT_GEOLOCATION)
            geolocateUser()
          }}
        />
      </React.Fragment>
    )
  }
}

class AddressEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editAddressLocationNotPrecise: false,
    }

    //props.resetForm(FormType.EDIT_USER_ADDRESS, this.props.defaultData)
  }

  handleSubmitEditUserAddress = (addressId, addressData) => {
    const { editUserAddress, user } = this.props

    editUserAddress(user.id, addressId, addressData)
  }

  handleDeleteUserAddress = addressId => {
    const { deleteUserAddress, user } = this.props

    deleteUserAddress(user.id, addressId)
  }

  render() {
    const { address, formData, formErrors, onChange, formIsValid, classes, frontSettings } = this.props

    return (
      <div className={classes.formContainer}>
        <form
          onSubmit={e => {
            e.preventDefault()
            EventManager.dispatch(EventManager.Events.ON_DELIVERY_ADDRESS_EDIT_ADDRESS)
            this.handleSubmitEditUserAddress(address.id, formData)
          }}
        >
          <GeolocationContainer geolocationId={String(address.id)}>
            {geoLocationProps => (
              <GeolocationAddress
                {...geoLocationProps}
                classes={classes}
                formData={formData}
                onChange={onChange}
                countryRestriction={frontSettings.geoApiCountryRestriction}
              />
            )}
          </GeolocationContainer>

          <EditUserAddressFormView
            formData={formData}
            formErrors={formErrors}
            onChange={onChange}
            labels={{
              complement: tr('order.addresses.form.edit.labels.complement'),
              name: tr('order.addresses.form.edit.labels.name'),
            }}
            placeholders={{
              complement: tr('order.addresses.form.edit.placeholders.complement'),
              name: tr('order.addresses.form.edit.placeholders.name'),
            }}
            classes={{
              inputStyles: classes.editFormInputStyles,
              inputStreet: classes.hidden,
              inputPostCode: classes.hidden,
              inputCity: classes.hidden,
              inputCountry: classes.hidden,
            }}
          />

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                label={tr('order.addresses.form.edit.submit_button')}
                disabled={!formIsValid}
                classes={{ root: 'u-marginTop20 u-sizeFullWidth' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="large"
                label={tr('order.addresses.form.edit.delete_button')}
                classes={{ root: 'u-marginTop10 u-sizeFullWidth' }}
                onClick={() => {
                  EventManager.dispatch(EventManager.Events.ON_DELIVERY_ADDRESS_DELETE_ADDRESS)
                  this.handleDeleteUserAddress(address.id)
                }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  withForm(FormType.EDIT_USER_ADDRESS),
  connect(mapStateToProps, {
    editUserAddress,
    deleteUserAddress,
    resetForm,
  })
)(AddressEditForm)
