import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import GoogleMapsApiContainer from 'pmt-modules/google/components/GoogleMapsApiContainer'

import {
  failureGeolocation,
  initGeolocation,
  resetFullGeolocation,
  resetGeolocation,
  resetGeolocationCoordinates,
  resetGeolocationError,
  setGeolocationAddress,
  setGeolocationComputeAddress,
  setGeolocationComputeAddressExtra,
  setGeolocationCoordinates,
  successGeolocation,
} from '../actions'

import {
  getGeolocationAddress,
  getGeolocationComputeAddress,
  getGeolocationCoordinates,
  getGeolocationError,
  getGeolocationIsFetching,
  getGeolocationOrigin,
} from '../selectors'

import { GeolocationType, DEFAULT_GEOLOCATION_ID } from '../constants'

class GeolocationContainer extends React.Component {
  successGeolocate = position => {
    const { latitude, longitude } = position.coords

    this.props.successGeolocation(this.props.geolocationId, latitude, longitude)
    if (!isNil(this.props.successGeolocationCallback)) {
      this.props.successGeolocationCallback({
        latitude,
        longitude,
      })
    }
  }

  failureGeolocate = () => {
    this.props.failureGeolocation(this.props.geolocationId, tr('global.geolocation.error.position'))
  }

  geolocateUser = () => {
    this.props.initGeolocation(this.props.geolocationId, GeolocationType.REVERSE_GEOCODING)

    const options = {
      enableHighAccuracy: true,
      timeout: 4000,
      maximumAge: 0,
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.successGeolocate,
        this.failureGeolocate,
        options
      )
    } else {
      this.props.failureGeolocation(
        this.props.geolocationId,
        tr('global.geolocation.error.unsupported_browser')
      )
    }
  }

  render() {
    const {
      children,
      loadingElement,

      isFetching,
      geolocationId,
      geolocationOrigin,
      geolocationError,
      geolocationAddress,
      resetGeolocationError,
      setGeolocationAddress,
      geolocationComputeAddress,
      setGeolocationComputeAddress,
      setGeolocationComputeAddressExtra,
      resetGeolocation,
      resetFullGeolocation,
      geolocationCoordinates,
      setGeolocationCoordinates,
      resetGeolocationCoordinates,
    } = this.props

    return (
      <GoogleMapsApiContainer loadingElement={loadingElement}>
        {children({
          geolocationId,

          isFetchingGeolocation: isFetching,

          geolocationOrigin,
          geolocateUser: this.geolocateUser,

          //
          //
          //

          geolocationAddress,
          setGeolocationAddress: address => {
            setGeolocationAddress(geolocationId, address)
          },

          geolocationComputeAddress,
          setGeolocationComputeAddress: address => {
            setGeolocationComputeAddress(geolocationId, address)
          },
          setGeolocationComputeAddressExtra: extra => {
            setGeolocationComputeAddressExtra(geolocationId, extra)
          },

          //
          //
          //

          geolocationCoordinates,
          geolocationLatitude: geolocationCoordinates.latitude,
          geolocationLongitude: geolocationCoordinates.longitude,

          setGeolocationCoordinates: (latitude, longitude) => {
            setGeolocationCoordinates(geolocationId, latitude, longitude)
          },

          resetGeolocationCoordinates: () => {
            resetGeolocationCoordinates(geolocationId)
          },

          //
          //
          //

          resetGeolocation: () => {
            resetGeolocation(geolocationId)
          },

          resetFullGeolocation: () => {
            resetFullGeolocation()
          },

          //
          //
          //

          geolocationError,
          resetGeolocationError,
        })}
      </GoogleMapsApiContainer>
    )
  }
}

GeolocationContainer.defaultProps = {
  successGeolocationCallback: null,
  geolocationId: DEFAULT_GEOLOCATION_ID,
}

const mapStateToProps = (state, props) => ({
  geolocationOrigin: getGeolocationOrigin(state, props),
  geolocationAddress: getGeolocationAddress(state, props),
  isFetching: getGeolocationIsFetching(state, props),
  geolocationCoordinates: getGeolocationCoordinates(state, props),
  geolocationError: getGeolocationError(state, props),
  geolocationComputeAddress: getGeolocationComputeAddress(state, props),
})

export default connect(
  mapStateToProps,
  {
    failureGeolocation,
    initGeolocation,
    resetFullGeolocation,
    resetGeolocation,
    resetGeolocationCoordinates,
    resetGeolocationError,
    setGeolocationAddress,
    setGeolocationComputeAddress,
    setGeolocationComputeAddressExtra,
    setGeolocationCoordinates,
    successGeolocation,
  }
)(GeolocationContainer)
