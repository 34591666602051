import { tr } from 'pmt-modules/i18n'
import classNames from 'classnames'
import isNil from 'lodash/isNil'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { setTableNumber } from 'pmt-modules/orderPlugin'

import NumberField from 'pmt-ui/NumberField'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button from '../Button'

const styles = (theme) => ({
  inputTable: {
    display: 'flex',
    width: '100%',
    height: 36,
    padding: 0,
    fontSize: '20px !important',
    '&:focus': {
      fontSize: '20px !important',
    },
  },
  errorMessage: {
    color: theme.pmt.colors.red800,
  },
})

const Type = {
  BIG: 'big',
  SMALL: 'small',
}

const handleSetTableNumber = (tableNumber, setTableNumber) => {
  setTableNumber(tableNumber)
}

const TableNumberInput = ({
  tableNumber,
  type = Type.BIG,
  handleCloseInput = null,
  setTableNumber,
  displayError = false,
  classes,
  tableNumberInputButtonLabel = tr('order.global.validate'),
}) => (
  <React.Fragment>
    <TypographyCustom type="204">
      <NumberField
        value={!isNil(tableNumber) ? tableNumber : ''}
        onChange={(event, value) => {
          handleSetTableNumber(value, setTableNumber)
        }}
        label={tr('order.store_locator.table_number.label')}
        placeholder={tr('order.store_locator.table_number.placeholder')}
        classes={{ textField: classes.inputTable }}
        helperText={tr('order.store_locator.table_number.example')}
      />
    </TypographyCustom>
    {!tableNumber && displayError && (
      <TypographyCustom type="164" className={classNames('u-marginTop20', classes.errorMessage)}>
        {tr('order.error_message.tableNumber_missing')}
      </TypographyCustom>
    )}
    {type === Type.SMALL && (
      <Button
        classes={{ root: 'u-marginTop10' }}
        disabled={isNil(tableNumber)}
        onClick={handleCloseInput}
        label={tableNumberInputButtonLabel}
      />
    )}
  </React.Fragment>
)

const mapStateToProps = (state, props) => ({})

const TableNumberInputContainer = compose(
  connect(mapStateToProps, { setTableNumber }),
  withStyles(styles)
)(TableNumberInput)

TableNumberInputContainer.Type = Type

export default TableNumberInputContainer
